import { useTranslation } from "react-i18next";
import { IApolloCompanyDetails } from "../../../../../interfaces/apollo_search_import";
import { CallIcon, CompaniesIcon, GlobeIcon, LocationIcon } from "../../../../../icons";
import { addressParser, getArrayStrings, getHttpsString } from "../../../../../utils";
import { useAppDispatch } from "../../../../../redux/store";
import { useState } from "react";
import UserAvatar from "../../../../../components/user_avatar/user_avatar";
import SocialMediaProfile from "../../../../../components/social_media_profile/social_media_profile";
import { ProfileSocialMedia } from "../../../../../interfaces";
import './apollo_company_details_section.scss';
interface Props {
    company: IApolloCompanyDetails | null;
}

const ApolloCompanyDetailsSection: React.FunctionComponent<Props> = (props) => {
    const { company } = props;
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState<boolean>(false)
    const companyProfileSections = [
        {
            sectionTitle: 'industry',
            sectionIcon: CompaniesIcon,
            sectionContent: [
                { title: 'industry', valueField: 'industry' },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: '', valueField: addressParser({
                        'street_address': company ? company['street_address'] : null,
                        'city': company ? company['city'] : null,
                        'state': company ? company['state'] : null,
                        'zip_code': company ? company['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: '', valueField: 'phone' },
            ]
        },

        {
            sectionTitle: 'website',
            sectionIcon: GlobeIcon,
            sectionContent: [
                { title: 'website', valueField: 'website_url' },
            ]
        },
    ];
    const companySocialMedia: ProfileSocialMedia = {
        linkedin_url: company?.linkedin_url,
        twitter_url: company?.twitter_url,
        facebook_url: company?.facebook_url,
    };
    return (<div className="acds-container cds-container">
        <div className="cds-user-info">
            <div className="avatar-holder">
                <div className="avatar background-img" style={company?.logo_url ? { backgroundImage: `url(${company?.logo_url})` } : {}}></div>

            </div>
            <div className="cds-user-name">
                <span>{company?.name}</span>
            </div>
        </div>

        {
            companyProfileSections.map((doc, index) => {
                return (
                    <div key={index} className={doc.sectionTitle === "contact" ? "cds-info-address-container" : "cds-info-container"}>
                        <div className="cds-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="cds-info-content">
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);
                                return (
                                    <div key={index} className="cds-info-holder">
                                        <div className="cds-into-title">
                                            {
                                                doc.sectionTitle === "address" || doc.sectionTitle === "contact" ?
                                                    <span style={{ display: "grid" }}>{t(data.title).toUpperCase()}</span> :
                                                    <span>{t(data.title).toUpperCase()}</span>
                                            }
                                        </div>
                                        <div
                                            className="cds-info-value"
                                            style={{ marginBottom: data.lineTwo ? '0px' : '0.5em' }}
                                        >
                                            {arrayValue}
                                        </div>
                                        {data.lineTwo && <div className="cds-info-value">{getValues(data.lineTwo)}</div>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })
        }
        <SocialMediaProfile
            title={t('social_media').toUpperCase()}
            profile={companySocialMedia}
        />
        <div>
            {company?.short_description !== null &&
                <div className="short-desc" key={company?.id}>
                    {showMore ? `${company?.short_description}` : `${company?.short_description.substring(0, 150)}...`}
                    <button className="show-more-btn" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "Show Less" : "Show More"}
                    </button>
                </div>
            }
            <div className="info-table-div">
                <table>
                    <tbody>
                        <tr>
                            <td>{t('founding_year')}</td>
                            <td>{company?.founded_year ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>{t('employees')}</td>
                            <td>{`${formatNumber(company?.estimated_num_employees) ?? "-"}`}</td>
                        </tr>
                        <tr>
                            <td>{t('trading')}</td>
                            <td>{(company?.publicly_traded_exchange && company?.publicly_traded_exchange) ? (<div><span style={{ textTransform: 'uppercase' }}>{company?.publicly_traded_exchange}</span> : {company?.publicly_traded_symbol}</div>) : "-"}</td>
                        </tr>
                        {/* <tr>
                            <td>{t('market_cap')}</td>
                            <td>{company?.market_cap !== null && company?.market_cap !== "" ? company?.market_cap : "-"}</td>
                        </tr> */}
                        <tr>
                            <td>{t('annual_revenue')}</td>
                            <td>{company?.annual_revenue_printed ? ("$ "+company?.annual_revenue_printed) : "-"}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>

    </div >);
    function getValues(obj: any) {
        if (company == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, company);
        }
        if (obj === "website_url") {
            return (
                <a href={getHttpsString(company?.website_url)} target="_blank" rel="noreferrer">{company?.website_url}</a>
            )
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && company![obj] !== null) ? company![obj] : 'NA';
        return (
            <span>{value}</span>
        );
    }
}
function formatNumber(n: number | undefined) {
    // format number 1000000 to 1,234,567
    return n?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default ApolloCompanyDetailsSection;