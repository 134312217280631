import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { IRCEmployerTax } from "../../../../../interfaces/report_center";
import { getEmployerTaxThunk, getReportDownloadURL, getReportStartEndDate, selectEmployerTaxState, selectReportsDownloadState, selectReportStartEndDateState } from "../../../../../redux/report_center";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { convertDateToTimeStamp, dateType, getDateFromTimeStamp } from "../../../../../variables";
import { LoadingType, THEME } from "../../../../../enums";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import EmployerTaxTable from "../../../components/employer_tax_table/employer_tax_table";
import { currencyConversion, CustomButton, SpinnerScreen } from "../../../../../utils";
import './employer_tax_report.scss';
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";
import ApiError from "../../../../../components/api_error";
import { employerTaxSliceActions } from "../../../../../redux/report_center/payroll_tax/employer_tax_report/employer_tax_reducer";
interface Props extends RouteComponentProps<any> { }
const EmployerTaxReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const etState = useAppSelector((state) => selectEmployerTaxState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [eTaxData, setETaxData] = useState<IRCEmployerTax | null>(null)
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [isCheckDate, setIsCheckDate] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isCheckDate)) {
            getEmployerTaxData();
            setDatePickerOpen(false);
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getEmployerTaxData();
            setDatePickerOpen(true);
        }
    }, [formState?.available_from, formState?.available_to, isCheckDate]);

    useEffect(() => {
        if (etState.loading === LoadingType.succeeded && etState.response) {
            setETaxData(etState.response);
        }
        return () => {
            setETaxData(null) 
        }
    }, [etState.loading, etState.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getEmployerTaxData = () => {
        if (formState) {
            dispatch(getEmployerTaxThunk({ start_date: formState.available_from, end_date: formState.available_to, is_check_date: isCheckDate }));
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
        // dispatch(payrollCashSliceActions.clearPRCState());
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.rcEmployerTax,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    is_check_date: isCheckDate ? isCheckDate : false,
                    download: true
                }));
        }
    }

    const handleClearError = () => {
        dispatch(employerTaxSliceActions.clearETErrorState())
    }

    return (
        <div className="rc-wc-container et-container">
            <div className="header-container">
                <div className="table-header">
                    <span>{t("employer_taxes")}</span>
                </div>
                <div>
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="rc-wc-header">
                <div className="et-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="dt-drdn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={dateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsCheckDate}
                            value={isCheckDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                        />
                    </div>
                    {etState?.error && <div className="error-holder">
                        <ApiError message={etState?.error?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="et-right">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">{eTaxData?.employer_taxes?.length}</span>
                        </span>
                    </div>
                    <div className="dt-drdn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={(eTaxData && eTaxData?.employer_taxes?.length > 0)! && true}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                </div>
            </div>
            <div className='rc-wc-content'>
                {etState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    <>
                        {eTaxData?.employer_taxes?.map((record) => (
                            <EmployerTaxTable
                                record={record}
                            />
                        ))}
                        <div>
                            {eTaxData && eTaxData?.employer_taxes?.length > 0 ?
                                <table>
                                    <tbody>
                                        <tr className="total-row">
                                            <td>{t("grand_total_tax_amount")}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{eTaxData.grand_total_tax_amount ? currencyConversion(eTaxData.grand_total_tax_amount) : "$0.00"}</td>

                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <div className='na_div'>
                                    <span >{t('no_data_available')}</span>
                                </div>

                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default EmployerTaxReport;
