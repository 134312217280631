import { useTranslation } from 'react-i18next';
import { FormInputOption } from '../../interfaces/form_option';
import { CustomCheckBox, FormInput } from '../../utils';
import { DateInput } from '../date_input/date_input';
import CustomFormSelect, { CustomMultiFormSelect } from '../form_selector/form_select';
import { PDFFormInput } from '../pdf_form_input/pdf_form_input';
import ToggleSwitch from '../toggle_switch/toggle_switch';
import './render_input.scss';
import { MenuPlacement } from 'react-select';


interface Props {
    formState: any,
    inputValue?: any,
    doc: FormInputOption,
    index?: number,
    formValidators?: (value: {
        text: string;
        field: string;
    }) => string | null,
    handleFieldChange: (id: string, value: any, parent?: string) => void,
    className?: string,
    customStyle?: React.CSSProperties,
    inputLabelStyle?: React.CSSProperties,
    inputCustomStyle?: React.CSSProperties,
    isClearable?: boolean,
    menuPlacement?: MenuPlacement | undefined,
    checkValidationOnSubmit?: boolean,
}
const RenderInput: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const {
        doc,
        formState,
        inputValue,
        className,
        customStyle,
        inputLabelStyle,
        inputCustomStyle,
        formValidators,
        handleFieldChange,
        checkValidationOnSubmit
    } = props;

    if (doc.type === "selector") {
        if (doc.multi) {
            return (
                <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
                    <CustomMultiFormSelect
                        name={doc.field}
                        list={doc.selectorList ?? []}
                        onChange={(value) => handleFieldChange(doc.field, value, doc.parentField)}
                        required={doc.required}
                        placeholder={doc.placeholder}
                        value={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                        label={t(doc.label)}
                        labelPosition={doc.labelPosition}
                        disabled={doc.disabled}
                        customStyle={doc.customStyle}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                        validations={formValidators}
                        menuPlacement={doc.menuPlacement}
                    />
                </div>
            )
        }
        return (
            <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
                <CustomFormSelect
                    customStyle={inputCustomStyle ?? doc.customStyle}
                    name={doc.field}
                    value={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                    required={doc.required}
                    label={t(doc.label)}
                    placeholder={t(doc.placeholder ?? "")}
                    list={doc.selectorList ?? []}
                    onChange={(value) => handleFieldChange(doc.field, value, doc.parentField)}
                    labelPosition={doc.labelPosition}
                    disabled={doc.disabled}
                    isClearable={doc.isClearable}
                    menuPlacement={doc.menuPlacement}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    validations={formValidators}
                />
            </div>
        );
    }

    if (doc.type === "switch") {
        return (
            <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
                <ToggleSwitch
                    onChange={(value) => handleFieldChange(doc.field, value, doc.parentField)}
                    label={t(doc.label)}
                    checked={inputValue ? inputValue : (formState[doc.field] ? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field]) : false)}
                    tooltip={doc.tooltip ? t(doc.tooltip) : undefined}
                    labelPosition={doc.labelPosition}
                    id={doc.field}
                    disabled={doc.disabled}
                />
            </div>
        )
    }

    if (doc.type === "checkbox") {
        return (
            <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
                <CustomCheckBox
                    name={doc.field}
                    title={t(doc.label)}
                    checked={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                    onClick={() => handleFieldChange(doc.field, !formState[doc.field], doc.parentField)}
                    labelStyle={inputLabelStyle}
                    customStyle={inputCustomStyle ?? doc.customStyle}
                    labelPosition={doc.labelPosition}
                    disabled={doc.disabled}
                />
            </div>

        )
    }

    if (doc.type === "date") {
        return (
            <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
                <DateInput
                    validations={formValidators}
                    id={doc.field}
                    onChange={(id, value) => handleFieldChange(id, value, doc.parentField)}
                    secure={doc.secure}
                    required={doc.required}
                    placeholder={t(doc.placeholder ?? "")}
                    value={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                    label={t(doc.label)}
                    labelStyles={inputLabelStyle}
                    customStyle={inputCustomStyle ?? doc.customStyle}
                    setHoursToZero={doc.setHoursToZero}
                    labelPosition={doc.labelPosition}
                    views={doc.views}
                    format={doc.format}
                    isJobForm={doc.isJobFormDateField}
                    disabled={doc.disabled}
                />
            </div>
        )
    }

    return (
        <div key={doc.field} className={className} style={customStyle ?? doc.containerStyle ?? undefined}>
            {(doc.uiType && doc.uiType === "pdf") ? <PDFFormInput
                {...doc}
                validations={formValidators}
                id={doc.field}
                onChange={(id, value) => handleFieldChange(id, value, doc.parentField)}
                placeholder={doc.placeholder}
                value={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                label={t(doc.label)}
                keyboardtype={doc?.keyboardtype}
                labelStyles={inputLabelStyle}
                customStyle={inputCustomStyle ?? doc.customStyle}
            /> :
                <FormInput
                    {...doc}
                    validations={formValidators}
                    id={doc.field}
                    onChange={(id, value) => handleFieldChange(id, value, doc.parentField)}
                    placeholder={doc.placeholder}
                    value={inputValue ?? (doc.parentField ? formState[doc.parentField][doc.field] : formState[doc.field])}
                    label={t(doc.label)}
                    keyboardtype={doc?.keyboardtype}
                    labelStyles={inputLabelStyle}
                    customStyle={inputCustomStyle ?? doc.customStyle}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    disabled={doc.disabled}
                />}
        </div>
    );

}

export default RenderInput;