import { ActionType, ExportMappingGroup } from "../enums";
import {
  IBatchQueryParams,
  ICreateBatch,
  PayrollBatch,
  ITimeCardsData,
  ITimecardsQueryParams,
  IBillingBatch,
  IIEImport,
  ICorrectTimeCards,
  InvoiceCorrectionQueryParam,
} from "../interfaces";
import { IRecordPayment } from "../interfaces/back_office_acnts_receivable";
import { ICreateDeductionAuthorityBatch } from "../interfaces/back_office_deduction_authority";
import { ThirdPartyDeductionType } from "../interfaces/third_party_deduction_type";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const BackOfficeAPI = {
  getPayrollTimecards(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.payrollTimecards}?week_accounting_id=${week_accounting_id}`
    );
  },
  getPayrollBatch(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.payrollBatch}?week_accounting_id=${week_accounting_id}`
    );
  },
  createPayrollBatch(ignore_elg_change_file: boolean, batch: PayrollBatch,) {
    return interceptor.post(`${UrlBuilder.payrollBatch}?ignore_elg_change_file=${ignore_elg_change_file}`, batch);
  },
  deletePayrollBatch(payroll_batch_id: string) {
    return interceptor.delete(`${UrlBuilder.payrollBatch}?payroll_batch_id=${payroll_batch_id}`);
  },
  postPayrollBatch(payroll_batch_id: string) {
    return interceptor.patch(`${UrlBuilder.payrollBatchPost}?payroll_batch_id=${payroll_batch_id}`);
  },
  getPayCheckData(week_accounting_id: string, batch_id?: string) {
    if (batch_id) {
      return interceptor.get(
        `${UrlBuilder.payrollPayCheck}?week_accounting_id=${week_accounting_id}&batch_id=${batch_id}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.payrollPayCheck}?week_accounting_id=${week_accounting_id}`
    );
  },
  getTransactionSummary(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.payrollTransactionSummary}?week_accounting_id=${week_accounting_id}`
    );
  },
  getWeeklyAccounting() {
    return interceptor.get(`${UrlBuilder.weeklyAccounting}`);
  },
  getWeeklyAErrorReports(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.weeklyAccountingErrorReport}?week_accounting_id=${week_accounting_id}`
    );
  },
  weeklyAErrorsReportsApprove(doc: string[]) {
    return interceptor.patch(`${UrlBuilder.weeklyAccountingERApprove}`, doc);
  },
  weeklyAErrorsReportsResolve(accounting_error_id: string) {
    return interceptor.patch(
      `${UrlBuilder.weeklyAccountingERResolve}?accounting_error_id=${accounting_error_id}`
    );
  },
  getWAErrorReportDownloadUrl(week_accounting_id: string) {
    return interceptor.get(`${UrlBuilder.weeklyAccountingERDownloadUrl}?week_accounting_id=${week_accounting_id}`);
  },
  getWeekEndingDatesDropdown() {
    return interceptor.get(`${UrlBuilder.weekEndingDates}`);
  },
  getWeeklyAccountingOpen(week_accounting_id: string) {
    return interceptor.patch(
      `${UrlBuilder.weeklyAccountingOpen}?week_accounting_id=${week_accounting_id}`
    );
  },
  getWeeklyAccountingClose(week_accounting_id: string) {
    return interceptor.patch(
      `${UrlBuilder.weeklyAccountingClose}?week_accounting_id=${week_accounting_id}`
    );
  },
  createWeeklyTransaction(week_accounting_id: string) {
    return interceptor.post(
      `${UrlBuilder.weeklyTransactionCreate}?week_accounting_id=${week_accounting_id}`
    );
  },
  getWeeklyTransactions(weekly_accounting_id?: string) {
    if (weekly_accounting_id)
      return interceptor.get(
        `${UrlBuilder.weeklyTransaction}?weekly_accounting_id=${weekly_accounting_id}`
      );
    return interceptor.get(UrlBuilder.weeklyTransaction);
  },
  getWeeklyTransactionAssignments(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.weeklyTransactionAssignments}?week_accounting_id=${week_accounting_id}`
    );
  },
  createTimeCards(week_accounting_id: string, doc: string[]) {
    return interceptor.post(
      `${UrlBuilder.timeCards}?week_accounting_id=${week_accounting_id}`,
      doc
    );
  },
  getTimeCardsBatchDropdown(
    week_accounting_id: string,
    query?: IBatchQueryParams
  ) {
    // if (closed_batches) return interceptor.get(`${UrlBuilder.batchDropdown}?week_accounting_id=${week_accounting_id}&closed_batches=${closed_batches}`);
    if (query) {
      let queryString = "";
      for (let param of Object.keys(query))
        queryString += `&${param}=${query[param]}`;
      queryString = queryString.slice(1);
      return interceptor.get(
        `${UrlBuilder.batchDropdown}?week_accounting_id=${week_accounting_id}&${queryString}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.batchDropdown}?week_accounting_id=${week_accounting_id}`
    );
  },

  getTimecardsData(week_accounting_id: string, query?: ITimecardsQueryParams) {
    if (query) {
      let queryString = "";
      for (let param of Object.keys(query))
        if (query[param]) queryString += `&${param}=${query[param]}`;
      queryString = queryString.slice(1);
      return interceptor.get(
        `${UrlBuilder.timeCards}?week_accounting_id=${week_accounting_id}&${queryString}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.timeCards}?week_accounting_id=${week_accounting_id}`
    );
  },
  addTimecardsBatch(doc: ITimeCardsData) {
    return interceptor.patch(`${UrlBuilder.timeCards}`, doc);
  },
  createBatch(doc: ICreateBatch) {
    return interceptor.post(`${UrlBuilder.batch}`, doc);
  },
  deleteTimeCards(time_card_id: string) {
    return interceptor.delete(
      `${UrlBuilder.timeCards}?time_card_id=${time_card_id}`
    );
  },
  patchVerifyBatch(batch_id: string) {
    return interceptor.patch(`${UrlBuilder.verifyBatch}?batch_id=${batch_id}`);
  },
  getTimecardsReportData(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.timeCardsReport}?week_accounting_id=${week_accounting_id}`
    );
  },
  getTimeCardsReportDownloadUrl(week_accounting_id: string, batch_id?: string) {
    if (batch_id) return interceptor.get(`${UrlBuilder.timeCardsRepotDownloadUrl}?week_accounting_id=${week_accounting_id}&batch_id=${batch_id}`)
    return interceptor.get(`${UrlBuilder.timeCardsRepotDownloadUrl}?week_accounting_id=${week_accounting_id}`);
  },
  patchCloseBatch(batch_id: string) {
    return interceptor.patch(`${UrlBuilder.closeBatch}?batch_id=${batch_id}`);
  },
  deleteBatch(batch_id: string) {
    return interceptor.delete(`${UrlBuilder.batch}?batch_id=${batch_id}`);
  },
  uploadTimeCardsDocument(data) {
    return interceptor.post(`${UrlBuilder.uploadTimeCardsDoucumentUrl}`, data);
  },
  deleteTimeCardsDocument(
    time_card_id: string,
    pay_type_id: string,
    file_name: string
  ) {
    return interceptor.delete(
      `${UrlBuilder.deleteTimeCardsDoucumentUrl}?time_card_id=${time_card_id}&pay_type_id=${pay_type_id}&file_name=${file_name}`
    );
  },
  removeTimeCardBatch(time_card_id: string, batch_id: string) {
    return interceptor.patch(
      `${UrlBuilder.removeTimeCard}?time_card_id=${time_card_id}&batch_id=${batch_id}`
    );
  },
  getTimeCardsErrorReports(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.timeCardsErrorReports}?week_accounting_id=${week_accounting_id}`
    );
  },
  resolveTimeCardsErrorReports(time_card_error_id: string) {
    return interceptor.patch(
      `${UrlBuilder.timeCardsErrorsReportsResolve}?time_card_error_id=${time_card_error_id}`
    );
  },
  getTimeCardsErrorReportDownloadUrl(week_accounting_id: string) {
    return interceptor.get(`${UrlBuilder.timeCardsErrorRepotDownloadUrl}?week_accounting_id=${week_accounting_id}`);
  },
  approveTimeCardsErrorReports(doc: string[]) {
    return interceptor.patch(`${UrlBuilder.timeCardsErrorsReportApprove}`, doc);
  },

  getPayrollErrorReports(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.payrollErrorReports}?week_accounting_id=${week_accounting_id}`
    );
  },
  payrollErrorsReportsApprove(doc: string[]) {
    return interceptor.patch(`${UrlBuilder.payrollErrorsReportsApprove}`, doc);
  },
  payrollErrorsReportsResolve(payroll_error_id: string) {
    return interceptor.patch(
      `${UrlBuilder.payrollErrorsReportsResolve}?payroll_error_id=${payroll_error_id}`
    );
  },
  getPayrollErrorReportDownloadUrl(week_accounting_id: string) {
    return interceptor.get(`${UrlBuilder.payrollErrorRepotDownloadUrl}?week_accounting_id=${week_accounting_id}`);
  },

  getBillingInvoicesErrorReports(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.billingInvoicesErrorReports}?week_accounting_id=${week_accounting_id}`
    );
  },
  billingInvoicesReportsApprove(doc: string[]) {
    return interceptor.patch(`${UrlBuilder.billingInvoicesReportsApprove}`, doc);
  },
  billingInvoicesReportsResolve(billing_error_id: string) {
    return interceptor.patch(
      `${UrlBuilder.billingInvoicesReportsResolve}?billing_error_id=${billing_error_id}`
    );
  },
  getBillingErrorReportDownloadUrl(week_accounting_id: string) {
    return interceptor.get(`${UrlBuilder.billingErrorRepotDownloadUrl}?week_accounting_id=${week_accounting_id}`);
  },
  getBillingInvoicesList(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.billingInvoices}?week_accounting_id=${week_accounting_id}`
    );
  },
  getBillingTimecards(week_accounting_id: string) {
    return interceptor.get(
      `${UrlBuilder.billingTimecards}?week_accounting_id=${week_accounting_id}`
    );
  },
  createBillingBatch(payload: IBillingBatch) {
    return interceptor.post(`${UrlBuilder.billingBatch}`, payload);
  },
  postBillingInvoicesProcess(billing_batch_id: string) {
    return interceptor.patch(`${UrlBuilder.billingInvoicesProcessPost}?billing_batch_id=${billing_batch_id}`);
  },
  postBillingBatch(billing_batch_id: string) {
    return interceptor.patch(`${UrlBuilder.billingInvoicesPost}?billing_batch_id=${billing_batch_id}`);
  },
  deleteBillingBatch(billing_batch_id: string) {
    return interceptor.delete(`${UrlBuilder.billingBatch}?billing_batch_id=${billing_batch_id}`);
  },
  postImportFileUpload(data: IIEImport, force_upload?: boolean) {
    return interceptor.post(`${UrlBuilder.getELGFileUploadURL}?force_upload=${force_upload}`, data);
  },
  patchImportFile(elg_file_id: string) {
    return interceptor.patch(`${UrlBuilder.importELGFileURL}?elg_file_id=${elg_file_id}`);
  },

  getReportsDirectoryList(week_ending_id: string, action_type: string) {
    return interceptor.get(`${UrlBuilder.ieReportsDirectory}?week_ending_id=${week_ending_id}&action_type=${action_type}`)
  },

  getBillingInvoiceReportData(week_accounting_id: string, batch_id?: string) {
    if (batch_id) {
      return interceptor.get(
        `${UrlBuilder.billingInvoicesReport}?week_accounting_id=${week_accounting_id}&batch_id=${batch_id}`
      );
    }
    return interceptor.get(
      `${UrlBuilder.billingInvoicesReport}?week_accounting_id=${week_accounting_id}`
    );
  },
  backOfficeExport(param: string) {
    if (param in ExportMappingGroup) {
      const exportMappingValue = ExportMappingGroup[param as keyof typeof ExportMappingGroup];

      if (exportMappingValue === ExportMappingGroup.ESC_PAYROLL_DEDUCTION) {
        return interceptor.post(`${UrlBuilder.exportPayrollDeduction}`);
      } else if (exportMappingValue === ExportMappingGroup.ESC_CENSUS) {
        return interceptor.post(`${UrlBuilder.exportCensusFile}`);
      } else if (exportMappingValue === ExportMappingGroup.MASTER_TAX_WEEKLY_PAYROLL_TAX_SUMMARY) {
        return interceptor.get(`${UrlBuilder.masterTaxExportPT}`);
      } else if (exportMappingValue === ExportMappingGroup.NEW_HIRE) {
        return interceptor.post(`${UrlBuilder.exportNewHireFile}`);
      } else if (exportMappingValue === ExportMappingGroup.MASTER_TAX_RECONCILIATION_TAX_SUMMARY) {
        return interceptor.get(`${UrlBuilder.exportRTS}`);
      } else if (exportMappingValue === ExportMappingGroup.TERMINATION) {
        return interceptor.post(`${UrlBuilder.exportTerminationFile}`);
      }
    }
    return;
  },
  getPaycheckDownloadPDFURL(paycheck_id: string) {
    return interceptor.get(
      `${UrlBuilder.paycheckDownloadPDF}?paycheck_id=${paycheck_id}`
    );
  },
  getInvoiceDownloadPDFURL(invoice_id: string) {
    return interceptor.get(
      `${UrlBuilder.inoviceDownloadPDF}?invoice_id=${invoice_id}`
    );
  },

  getThirdPartyDeductionTypeDetails(record_id?: string) {
    if (record_id) {
      return interceptor.get(`${UrlBuilder.deductionType}?record_id=${record_id}`);
    }
    return interceptor.get(UrlBuilder.deductionType);
  },

  createDeductionEntity(doc: ThirdPartyDeductionType) {
    return interceptor.post(UrlBuilder.deductionType, doc);
  },

  updateDeductionEntity(doc: ThirdPartyDeductionType) {
    return interceptor.patch(UrlBuilder.deductionType, doc);
  },

  deleteDeductionEntity(id: string) {
    return interceptor.delete(UrlBuilder.deductionType, { params: { deduction_type_id: id } });
  },

  mailPaycheck(paycheck_ids: string[]) {
    return interceptor.post(`${UrlBuilder.emailPaychecks}`, paycheck_ids);
  },

  getPendingInvoices() {
    return interceptor.get(
      `${UrlBuilder.getPendingInvoices}`
    );
  },

  createRecordPayment(doc: IRecordPayment) {
    return interceptor.post(`${UrlBuilder.createRecordPayment}`, doc);
  },
  acntReceivableDocsUpload(object_id: string, docs_names: any) {
    return interceptor.post(`${UrlBuilder.getARUploadDocsURLs}?object_id=${object_id}`, { docs_names: docs_names });
  },

  getExportPTSMasterTax(type: string) {
    if (type === "pts")
      return interceptor.get(`${UrlBuilder.exportMasterTax}`);
    else
      return interceptor.get(`${UrlBuilder.exportRTSMasterTax}`);
  },

  voidCheck(paycheck_id: string, recreate: boolean) {
    return interceptor.patch(`${UrlBuilder.voidCheck}?paycheck_id=${paycheck_id}&recreate=${recreate}`);
  },

  reverseCheck(paycheck_id: string, recreate: boolean) {
    return interceptor.patch(`${UrlBuilder.reverseCheck}?paycheck_id=${paycheck_id}&recreate=${recreate}`);
  },
  getCorrectedTimecards(invoice_id: string) {
    return interceptor.get(`${UrlBuilder.getCorrectedTimecards}?invoice_id=${invoice_id}`);
  },
  correctInvoice(doc: InvoiceCorrectionQueryParam) {
    let url = `${UrlBuilder.correctInvoice}?invoice_id=${doc.invoice_id}`;
    if (doc.same_invoice_number) {
      url += `&same_invoice_number=${doc.same_invoice_number}`;
    }
    if (doc.invoice_date) {
      url += `&invoice_date=${doc.invoice_date}`;
    }
    return interceptor.patch(url, doc.timeCards);

  },

  /// [Deduction Authority]
  getDeductionAuthorityBatch(week_accounting_id: string) {
    return interceptor.get(`${UrlBuilder.payrollAuthDeduction}?week_accounting_id=${week_accounting_id}`);
  },
  getDeductionAuthorityPayChecksBatch(week_accounting_id: string, batch_id?: string) {
    if (batch_id) return interceptor.get(`${UrlBuilder.PayrollAuthpaycheck}?week_accounting_id=${week_accounting_id}&batch_id=${batch_id}`)
    return interceptor.get(`${UrlBuilder.PayrollAuthpaycheck}?week_accounting_id=${week_accounting_id}`);
  },
  createDeductionAuthorityBatch(batch: ICreateDeductionAuthorityBatch,) {
    return interceptor.post(`${UrlBuilder.PayrollAuthBatch}`, batch);
  },
  patchDeductionAuthorityBatch(payroll_batch_id: string) {
    return interceptor.patch(`${UrlBuilder.payrollBatchPost}?payroll_batch_id=${payroll_batch_id}`);
  },
  getDedAuthPaycheckDownloadPDFURL(paycheck_id: string) {
    return interceptor.get(
      `${UrlBuilder.PayrollDedAuthPaychechDirDownloadPdf}?paycheck_id=${paycheck_id}`
    );
  },
};
