import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import CurveTabNavigation from "../../../../components/curve_tab_navigation/curve_tab_navigation";
import { TableErrorHandler } from "../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../enums";
import { EditIcon, MagnifyGlassIcon } from "../../../../icons";
import {
  clearCompetitorDetailsState,
  selectCompetitorDetailsState,
} from "../../../../redux/sales_marketing/competitor_sheet/competitor_details/competitor_details_selector";
import { getCompetitorDetails } from "../../../../redux/sales_marketing/competitor_sheet/competitor_details/competitor_details_thunk";
import { manageCompetitorActions } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_reducer";
import { selectManageCompetitorUpdateState } from "../../../../redux/sales_marketing/competitor_sheet/manage_competitors/manage_competitors_selector";
import {
  getCompetitorsList,
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { SpinnerScreen } from "../../../../utils";
import { AddCompetitor } from "./add_competitor";
import CompetitorDetails from "./competitor_details";
import { CompetitorSummary } from "./competitor_summary";

export const CompetitorDashboard: FC<RouteComponentProps<any>> = (
  props: RouteComponentProps<any>
) => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { params } = props.match;
  const { loading, error, competitorInfo } = useAppSelector((state) =>
    selectCompetitorDetailsState(state)
  );
  const { t } = useTranslation();
  const [editCompetitor, setEditCompetitor] = useState<boolean>(false);
  const updateState = useAppSelector((state) =>
    selectManageCompetitorUpdateState(state)
  );

  useEffect(() => {
    getCompetitorDetail();
    return () => {
      dispatch(clearCompetitorDetailsState());
    };
  }, []);

  useEffect(() => {
    if (updateState.loading == LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response ?? null));
      dispatch(manageCompetitorActions.clearUpdateState());
      dispatch(getCompetitorDetails(params.id));
    }
  }, [updateState.loading]);

  const getCompetitorDetail = () => {
    if (params && params.id) {
      dispatch(getCompetitorDetails(params.id));
      dispatch(getCompetitorsList());
    }
  };

  const DetailsLoading = () => {
    return (
      <div className="ac-um-usp-loading">
        <SpinnerScreen />
      </div>
    );
  };

  const getTabList = () => [
    {
      title: t("competitor_summary").toUpperCase(),
      icon: MagnifyGlassIcon,
      content: <CompetitorSummary history={props.history}/>,
    },
  ];

  const handleEdit = () => {
    setEditCompetitor(true);
  };

  const getEditAction = () => {
    if (editCompetitor) {
      return null;
    }
    return (
      <button className="icon-btn cdp-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  };

  return (
    <div className="ac-um-usp-container">
      {loading === LoadingType.pending && <DetailsLoading />}
      {error != null && (
        <TableErrorHandler error={error} onRefresh={getCompetitorDetail} />
      )}
      {loading === LoadingType.succeeded && error === null && (
        <div className="ac-um-usp-content">
          <div className="ac-um-usp-profile-container">
            {<CompetitorDetails competitorInfo={competitorInfo} />}
          </div>
          <div className="ac-um-usp-settings-content">
            <CurveTabNavigation
              tabs={getTabList()}
              selected={tabIndex}
              onChange={(value) => setTabIndex(value)}
              actions={getEditAction()}
            />
          </div>
        </div>
      )}
      {editCompetitor && (
        <Portal>
          <AddCompetitor
            showModal={editCompetitor}
            callbackHandler={() => setEditCompetitor(!editCompetitor)}
            competitorInfo={competitorInfo ?? undefined}
            callAPI={()=>{}}
          />
        </Portal>
      )}
    </div>
  );
};
