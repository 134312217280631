import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../../enums";
import {
  getBankAccount,
  getRunType,
} from "../../../../../redux/admin_center/back_office";
import {
  selectPayrollBatchList,
  selectPayrollBatchState,
} from "../../../../../redux/back_office/payroll/payroll_batch/payroll_batch_selector";
import { getPayrollThunk } from "../../../../../redux/back_office/payroll/payroll_batch/payroll_batch_thunk";
import {
  getWeekEndingDatesDropdownThunk,
  postSnackbarMessage,
  selectManagePayrollBatchState,
  selectProfileState,
  selectWeekEndingDatesDDList,
  selectWeekEndingDatesState,
  updateDeductionAuthorityBatchThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import {
  CustomButton,
  SpinnerScreen,
  getPlusBtnIcon,
} from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import { CreatePayrollBatchPopup } from "../../../popups/create_payroll_batch/create_payroll_batch_popup";
import './payroll_dashboard.scss';
import SearchBar from "../../../../../components/search_bar";
import NoteCard from "../../../../../components/note_card/note_card";
import { RightArrowIcon, BulbIcon, MenuDotIcon, PlusIcon } from "../../../../../icons";
import InfoTextPopup from "../../../popups/infoTextPopup/info_text_popup";
import { PayrollManageBatchSliceActions } from "../../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_reducer";
import { deletePayrollBatchThunk, postPayrollBatchThunk } from "../../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_thunk";
import popupInfoContent from "../../../popup_info_text";
import { PayrollBatch } from "../../../../../interfaces";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";
import { deductionAuthorityManageBatchSliceActions } from "../../../../../redux/back_office/payroll/manage_deduction_authority/deduction_authority_manage_batch_reducer";
import { CreateDeductionPaymentsBatchPopup } from "../../../popups/create_deduction_payments_batch/create_deduction_payments_batch";
export const PayrollDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const payrollBatchState = useAppSelector((state)=>selectManagePayrollBatchState(state)) 
  const weekEndingDatesListState = useAppSelector((state) =>
    selectWeekEndingDatesState(state)
  );
  const weekEndingDatesList = useAppSelector((state) =>
    selectWeekEndingDatesDDList(state)
  );
  const weeklyBatchState = useAppSelector((state) =>
    selectPayrollBatchState(state)
  );
  const weeklyBatch = useAppSelector((state) => selectPayrollBatchList(state));
  const profileState = useAppSelector((state) => selectProfileState(state));

  const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
    }`;
  const [search, setSearch] = useState('');
  const handleSearch = (value: string) => {
    setSearch(value);
  }
  const [selectedWeekDate, setSelectedWeekDate] = useState<{
    id: string;
    date: string;
  }>({ id: "", date: "" });
  const [addBatch, setAddBatch] = useState<boolean>(false);
  const [addDeductionAutority, setAddDeductionAuthority] = useState<boolean>(false);
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [openTimeCardInfoPopup, setOpenTimeCardInfoPopup] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<{
    visible: boolean;
    id: string;
  } | null>(null);

  const [viewPopup, setViewPopup] = useState<{ visible: boolean, data?: PayrollBatch | null } | null>(null);
  const [openMenu, setOpenMenu] = useState<{ visible: boolean, payroll_batch_id?: string } | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const moreOptionsBtnsList = [
    {
      id: "deduction_payment_batch",
      label: "create_deduction_payments_batch",
      icon: PlusIcon,
    },
  ];

  const [selectedMoreOption, setSelectedMoreOption] = useState<string>("");
  const handleMoreOptionsClick = (id: string) => {
    if (id === "deduction_payment_batch") {
      setAddDeductionAuthority(true);
      setSelectedMoreOption(id);
    }
  }

  useEffect(() => {
    dispatch(getWeekEndingDatesDropdownThunk());
    dispatch(getRunType());
    dispatch(getBankAccount());
  }, []);

  useEffect(() => {
    if (payrollBatchState && payrollBatchState.deleteBatch.loading === LoadingType.succeeded && payrollBatchState.deleteBatch.response) {
      setViewPopup(null);
      dispatch(postSnackbarMessage(payrollBatchState.deleteBatch.response))
      dispatch(PayrollManageBatchSliceActions.clearDeleteBatchState());
      dispatch(deductionAuthorityManageBatchSliceActions.clearDeleteBatchState());
      dispatch(getPayrollThunk(weekEndingDatesList[0].id));
    }
  }, [payrollBatchState.deleteBatch.loading, payrollBatchState.deleteBatch.response]);

  useEffect(() => {
    if (
      weekEndingDatesListState &&
      weekEndingDatesListState.loading === LoadingType.succeeded &&
      weekEndingDatesList.length > 0
    ) {
      setSelectedWeekDate({
        id: weekEndingDatesList[0].id,
        date: getDateString(
          weekEndingDatesList[0].week_ending_date,
          "mm/dd/yyyy"
        ),
      });
      dispatch(getPayrollThunk(weekEndingDatesList[0].id));
    }
  }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setOpenMenu({ visible: false, payroll_batch_id: "" });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const handleWeekEndChange = (val) => {
    const selectedWeekObject = weekEndingDatesList.find(
      (obj) => obj.id === val
    );
    setSelectedWeekDate({
      id: val,
      date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy"),
    });
    dispatch(getPayrollThunk(val));
  };

  const tableHeader = [
    { title: "status", code: "status" },
    { title: "batch_description", code: "batch_description" },
    { title: "batch_type", code: "run_type" },
    { title: "bank_name", code: "bank_name" },
    { title: "generate_original_pay_units", code: "" },
    { title: "transaction_count", code: "transaction_count" },
    { title: "check_count", code: "check_count" },
    { title: "date_ach_generated", code: "" },
    // { title: "date_printed", code: "" },
    { title: "check_date", code: "check_date" },
    // { title: "date_processed", code: "date_processed" },
    // { title: "created_date", code: "created_date" },
    { title: "created_by", code: "created_by" },
    { title: "", code: "" },
  ];

  function getFlexNumber(value: number) {
    if (value === 0) return 0.5;
    if (value === 1) return 1;
    if (value === 2) return 0.8;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 0.7;
    if (value === 6) return 0.5;
    if (value === 7) return 0.7;
    if (value === 8) return 0.6;
    if (value === 9) return 0.8;
    if (value === 10) return 0.5;
    if (value === 11) return 0.8;
    if (value === 12) return 0.8;
    return 1;
  }
  function handleMouseOver(id) {
    setIsHovering({ id: id, visible: true });
  }
  function handleMouseOut() {
    setIsHovering(null);
  }

  function getFilteredList() {
    let list = weeklyBatch;
    if (sortedField != null && weeklyBatch) {
      list = [...weeklyBatch].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    return list?.filter((doc => {
      const str = search.trim().toLowerCase();
      const descFilter = doc.batch_description ? doc.batch_description.toString().trim().toLowerCase().includes(str) : false;
      const bankNameFilter = doc.bank_name ? doc.bank_name.trim().toLowerCase().includes(str) : false;
      const statusFilter = doc.status ? doc.status.trim().toLowerCase().includes(str) : false;
      const typeFilter = doc.run_type ? doc.run_type.trim().toLowerCase().includes(str) : false;
      const createdbyFilter = doc.created_by ? doc.created_by.trim().toLowerCase().includes(str) : false;
      // const errorMsgFilter = doc.error_msg ? doc.error_msg.trim().toLowerCase().includes(str) : false;

      return descFilter || bankNameFilter || statusFilter || typeFilter || createdbyFilter;

    }));;
  }

  const handlePopupClose = () => {
    setAddBatch(false);
    dispatch(PayrollManageBatchSliceActions.clearState());
  };

  const handleDeductionPopupClose = () => {
    setAddDeductionAuthority(false);
    setSelectedMoreOption("")
    dispatch(deductionAuthorityManageBatchSliceActions.clearPostBatchState());
  };

  const handleSave = (payroll_batch_id: string) => {
    dispatch(postPayrollBatchThunk(payroll_batch_id));
  };

  const handleDeductionAuthoritySave = (payroll_batch_id : string) => {
    dispatch(updateDeductionAuthorityBatchThunk(payroll_batch_id));
  };

  const handlePopupOpen = (data: PayrollBatch) => {
    setViewPopup({ visible: true, data: data });
  }

  const handleOpenMenu = (payroll_batch_id: string) => {
    setOpenMenu({visible: true, payroll_batch_id: payroll_batch_id})
  }

  const handleOnDelete = (id: string) => {
    if (id) {
      dispatch(deletePayrollBatchThunk(id))
    }
  };
 
  const handleClearError = () => {
    dispatch(PayrollManageBatchSliceActions.clearDeleteBatchErrorState())
  }

  return (
    <div className="payroll-dashboard-container">
      {weekEndingDatesListState &&
        weekEndingDatesListState.loading === LoadingType.pending && (
          <SpinnerScreen></SpinnerScreen>
        )}
      {weekEndingDatesListState &&
        weekEndingDatesListState.loading === LoadingType.succeeded && (
          <>
            <div className="payroll-dashboard-header">
            <div className="left-col">
              <div>
                <CustomFormSelect
                  list={weekEndingDatesList.map((doc) => ({
                    label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                    value: doc.id,
                  }))}
                  customStyle={{ width: "100%" }}
                  name={"week_ending_date"}
                  value={selectedWeekDate.id}
                  label={t("week_ending_date")}
                  placeholder={"Select"}
                  onChange={handleWeekEndChange}
                  isClearable={false}
                />
              </div>
              <div className="more-options">
                <CustomFormSelect
                  list={
                    moreOptionsBtnsList.map((doc) => ({
                      label: doc.label,
                      value: doc.id,
                      icon: doc.icon
                    }))
                  }
                  name={""}
                  customStyle={{ minWidth: "15vw" }}
                  value={selectedMoreOption}
                  label={t("more_options")}
                  placeholder={"Select"}
                  onChange={(val) => handleMoreOptionsClick(val)}
                />
              </div>
            </div>
              <div className="right-col">
                <div>
                  <CustomButton
                    leftIcon={getPlusBtnIcon()}
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("create_employee_payroll_batch")}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={() => setAddBatch(true)}
                  />
                </div>
                <div className="ml search-bar">
                  <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                </div>
              </div>

            </div>
            <div className="payroll-dashboard-content">
              <div className="payroll-dashboard-table">
                {weeklyBatch &&
                  weeklyBatchState.loading === LoadingType.pending ? (
                  <>
                    <SpinnerScreen></SpinnerScreen>
                  </>
                ) : (
                  <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                  >
                    {getFilteredList()?.map((doc) => {
                      return (
                        <tr key={doc.id}>
                          <TableData customStyle={{ flex: getFlexNumber(0) }}>
                            <span>{doc.status}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(1) }}>
                            <div
                              style={{ cursor: "pointer" }}
                              onMouseOut={() => handleMouseOut()}
                              onMouseOver={() => handleMouseOver(doc.id)}
                              className="col-hover-container"
                            >
                              <span>
                                {doc != null &&
                                doc?.batch_description?.length > 25
                                  ? doc?.batch_description?.substring(0, 25) +
                                    "..."
                                  : doc?.batch_description}
                              </span>
                              {isHovering && doc.id === isHovering.id && (
                                <div className="report-sales-parent-position">
                                  <div className="report-sales-child-position">
                                    <span style={{ cursor: "pointer" }}>
                                      {doc?.batch_description}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(2) }}>
                            <span>{doc.run_type}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(3) }}>
                            <span>{doc.bank_name}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(4) }}>
                            {doc.generate_original_pay_units}
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(5) }}>
                            <span>{doc.transaction_count}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(6) }}>
                            <span>{doc.check_count}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(7) }}>
                            <span>
                              {getDateString(doc.date_ach_generated, "mm/dd/yyyy")}
                            </span>
                          </TableData>
                          {/* <TableData customStyle={{ flex: getFlexNumber(8) }}>
                            <span>
                              {getDateString(doc.date_printed, "mm/dd/yyyy")}
                            </span>
                          </TableData> */}
                          <TableData customStyle={{ flex: getFlexNumber(8) }}>
                            <span>
                              {getDateString(doc.check_date, "mm/dd/yyyy")}
                            </span>
                          </TableData>
                          {/* <TableData customStyle={{ flex: getFlexNumber(10) }}>
                            <span>
                              {getDateString(doc.date_processed, "mm/dd/yyyy")}
                            </span>
                          </TableData> */}
                          {/* <TableData customStyle={{ flex: getFlexNumber(11) }}>
                            <span>
                              {getDateString(doc.created_date, "mm/dd/yyyy")}
                            </span>
                          </TableData> */}
                          <TableData customStyle={{ flex: getFlexNumber(9) }}>
                            <span>{doc.created_by}</span>
                          </TableData>
                          <TableData customStyle={{ flex: getFlexNumber(10) }}>
                            <div
                              className={"menu-icon"}
                              onClick={() => handleOpenMenu(doc.id)}
                            >
                              <MenuDotIcon
                                width={"100%"}
                                height={"100%"}
                                style={{ color: "#fff" }}
                              />
                              {openMenu && openMenu?.payroll_batch_id === doc.id && openMenu.visible && (
                                <>
                                  {openMenu?.payroll_batch_id === doc.id && (
                                    <div ref={tooltipRef} className="parent-position">
                                      <div className="child-position">
                                        <div onClick={() => handlePopupOpen(doc)} className="item-menu-title">{t("delete_batch")}</div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </TableData>
                        </tr>
                      );
                    })}
                  </SortableTable>
                )}
              </div>
              <div className="pay-card-note1">
                <NoteCard
                  icon={<div><RightArrowIcon /><RightArrowIcon /></div>}
                  textLineOne={t("pay_dash_sec_msg_one", { name: currentLoginUserName })}
                  textLineTwo={t("pay_dash_sec_msg_two")}
                  // textLineThree={t("pay_dash_sec_msg_two")}
                  confirmBtn={false}
                  customTextColor={"#119646"}
                  customIconBGColor={"#E6FAED"}
                  customCardWidth={"fit-content"}
                  customCardBGColor={"#FAFFFB"}
                  customTextWidthLineOne={"fit-content"}
                  customTextWidthLineTwo={"fit-content"}
                  customTextWidthLineThree={"100%"}
                />
              </div>

              <div className="pay-card-note2">
                <NoteCard
                  icon={<BulbIcon />}
                  textLineOne={t("need_to_see_payroll_process_flow")}
                  confirmBtn={false}
                  customTextColor={"#074783"}
                  customIconBGColor={"#D9E6F4"}
                  customCardWidth={"82%"}
                  customCardBGColor={"#EAF4FF"}
                  customTextWidthLineOne={"84%"}
                  cursor="pointer"
                  onClick={() => setOpenTimeCardInfoPopup(true)}
                  customFontWeight={'800'}
                />
              </div>
            </div>
          </>
        )}
      {addBatch && (
        <Portal>
          <CreatePayrollBatchPopup
            onClose={handlePopupClose}
            onSuccessClose={() => { handlePopupClose(); dispatch(getPayrollThunk(selectedWeekDate.id)) }}
            onSave={handleSave}
            weekEndingDate={selectedWeekDate.id}
            weekEndingDateList={weekEndingDatesList}
          />
        </Portal>
      )}
      {addDeductionAutority && (
        <Portal>
          <CreateDeductionPaymentsBatchPopup
            onClose={handleDeductionPopupClose}
            onSuccessClose={() => { handleDeductionPopupClose(); dispatch(getPayrollThunk(selectedWeekDate.id)) }}
            onSave={handleDeductionAuthoritySave}
            weekEndingDate={selectedWeekDate.id}
            weekEndingDateList={weekEndingDatesList}
          />
        </Portal>
      )}
      {openTimeCardInfoPopup &&
        <Portal>
          <InfoTextPopup
            visible={openTimeCardInfoPopup}
            onClose={() => setOpenTimeCardInfoPopup(false)}
            onDismiss={() => setOpenTimeCardInfoPopup(false)}
            title={t("payroll_help")}
            textContent={popupInfoContent.payrollDashBoardInfoText} />
        </Portal>}
      {viewPopup?.visible && (
        <Portal>
          <DeleteConfirmationDialog
            message={t("delete_payroll_batch_message")}
            onCancel={() => setViewPopup(null)}
            onConfirm={() => handleOnDelete(viewPopup.data?.id!)}
            deleting={payrollBatchState.deleteBatch?.loading === LoadingType.pending}
            deleteType={t("payroll Batch")}
            state={payrollBatchState?.deleteBatch}
            onClearError={handleClearError}
          />
        </Portal>
      )}
    </div>
  );
};
