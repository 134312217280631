import React, { useState, useEffect } from "react";
import {
  TextField,
  Menu,
  MenuItem,
  List,
  Grid,
  makeStyles,
  Button,
  InputAdornment,
} from "@material-ui/core";
import "./time_picker.scss";
import { convertStringTimeToTimestamp } from "../../variables";

interface Props {
  id: string;
  value: any;
  placeholder: any;
  label?: string;
  icon?: React.ReactNode;
  required?: boolean;
  onClearTime?: () => void;
  onChange: (id: string, value: any) => void;
  validations?: (value: { text: string; field: string }) => string | null;
  checkValidationOnSubmit?: boolean;
}

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  paper: {
    width: "auto",
    minWidth: 200, // Set your preferred minimum width
    padding: 0, // Remove padding
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align items to the left
  },
  section: {
    position: "relative",
    overflow: "hidden",
    padding: theme.spacing(1), // Add padding for separation
  },
  okButton: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignSelf: "flex-end", // Align the button to the end (right)
    marginTop: theme.spacing(1), // Add some top margin for spacing
    marginRight: theme.spacing(1), // Add right margin for spacing
  },
}));

function TimePickerComponent(props: Props) {
  const { id, value, placeholder, label, required, onChange, onClearTime } =
    props;
  const classes = useStyles();

  const formattedValue = convertStringTimeToTimestamp(value);
  const date = new Date(formattedValue!);
  // Extract hours, minutes, and AM/PM from the Date object
  let formattedHours = date.getHours();
  let formattedMinutes = date.getMinutes();
  const ampm = formattedHours < 12 ? "AM" : "PM";

  const [selectedTime, setSelectedTime] = useState<{
    hour: number | null;
    minute: number | null;
    ampm: string | null;
  }>({
    hour: formattedHours ?? 0,
    minute: formattedMinutes ?? 0,
    ampm: ampm,
  });
  const [formattedTime, setFormattedTime] = useState<string>(value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState<string | null>("");

  useEffect(() => {
    // Format the time when selectedTime changes
    formatTime(selectedTime.hour!, selectedTime.minute!, selectedTime.ampm!);
  }, [selectedTime]);

  useEffect(() => {
    // Check if value is null, undefined, or an empty string
    if (value === null || value === undefined || value === "") {
      // Set default time to "12:00 AM"
      setSelectedTime({ hour: 0, minute: 0, ampm: "AM" });
      setFormattedTime("12:00 AM");
    } else {
      // Format the time when selectedTime changes
      const formattedValue = convertStringTimeToTimestamp(value);
      const date = new Date(formattedValue!);
      let formattedHours = date.getHours();
      let formattedMinutes = date.getMinutes();
      // const ampm = formattedHours < 12 ? 'AM' : 'PM';
      let ampm = "AM"; // Initialize AM/PM to AM by default

      // Check if the formatted hours are greater than or equal to 12
      // If so, set AM/PM to PM and convert hours to 12-hour format
      if (formattedHours >= 12) {
        ampm = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      setSelectedTime({
        hour: formattedHours,
        minute: formattedMinutes,
        ampm: ampm,
      });

      formatTime(formattedHours, formattedMinutes, ampm);
    }
  }, [value]);

  const handleValidation = (text: any) => {
    setError(
      props.validations ? props.validations({ text, field: props.id }) : null
    );
  };

  useEffect(() => {
    if (
      props.checkValidationOnSubmit !== undefined &&
      props.checkValidationOnSubmit
    ) {
      handleValidation(props.value);
    }
  }, [props.checkValidationOnSubmit, props.validations]);

  const handleHourChange = (hour: number) => {
    setSelectedTime({ ...selectedTime, hour });
  };

  const handleMinuteChange = (minute: number) => {
    setSelectedTime({ ...selectedTime, minute });
  };

  const handleAmPmChange = (ampm: string) => {
    setSelectedTime({ ...selectedTime, ampm });
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    onChange(id, formattedTime);
    setAnchorEl(null);
  };

  const handleOkClick = () => {
    handleCloseMenu();
  };

  const handleKeyDown = (event) => {
    // Check if Ctrl+A is pressed followed by Backspace
    if (event.key === "Backspace") {
      event.preventDefault(); // Prevent default behavior of Ctrl+A (select all)
      setFormattedTime(""); // Clear the input field
      onChange(id,"");
      onClearTime!();
    }
  };

  const formatTime = (hour: number, minute: number, ampm: string) => {
    // Format the time and update the state
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const formattedText = `${formattedHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${ampm}`;
    setFormattedTime(formattedText);
  };

  const hours = Array.from({ length: 12 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);
  const ampmOptions = ["AM", "PM"];
  return (
    <div className={`time-picker-container ${error ? "required" : ""}`}>
      <TextField
        id="time-picker"
        onClick={handleOpenMenu}
        value={formattedTime} // Use the formatted time here
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        required={required}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{props.icon}</InputAdornment>
          ),
        }}
      />
      <Menu
        id="time-picker-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <div className={classes.section}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <List style={{ maxHeight: 200, overflow: "auto" }}>
                {hours.map((hour) => (
                  <MenuItem
                    key={hour}
                    onClick={() => handleHourChange(hour)}
                    className={
                      selectedTime.hour === hour ? classes.selectedItem : ""
                    }
                  >
                    {hour === 0 ? "12" : hour.toString().padStart(2, "0")}
                  </MenuItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <List style={{ maxHeight: 200, overflow: "auto" }}>
                {minutes.map((minute) => (
                  <MenuItem
                    key={minute}
                    onClick={() => handleMinuteChange(minute)}
                    className={
                      selectedTime.minute === minute ? classes.selectedItem : ""
                    }
                  >
                    {minute.toString().padStart(2, "0")}
                  </MenuItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <List style={{ maxHeight: 200, overflow: "auto" }}>
                {ampmOptions.map((ampm) => (
                  <MenuItem
                    key={ampm}
                    onClick={() => handleAmPmChange(ampm)}
                    className={
                      selectedTime.ampm === ampm ? classes.selectedItem : ""
                    }
                  >
                    {ampm}
                  </MenuItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
        <Button onClick={handleOkClick} className={classes.okButton}>
          OK
        </Button>
      </Menu>
      <div className="time-input-er-mes">
        {error ? error : null}
      </div>
    </div>
  );
}

export default TimePickerComponent;
