import { useTranslation } from 'react-i18next';
import { CallIcon, CompaniesIcon, EmailIcon, FacebookIcon, LinkedInIcon, LocationIcon, SocialMediaIcon, TwitterIcon } from '../../icons';
import { Profile, ProfileSocialMedia } from '../../interfaces';
import { addressParser, getArrayStrings } from '../../utils';
import SocialMediaProfile from '../social_media_profile/social_media_profile';
import './user_profile_section.scss';

interface Props {
    profile: Profile | null,
}



const UserProfileSection: React.FunctionComponent<Props> = (props) => {
    const { profile } = props;
    const { t } = useTranslation();

    const profileSections = [
        {
            sectionTitle: 'company',
            sectionIcon: CompaniesIcon,
            sectionContent: [
                { title: 'company_name', valueField: 'agency_name' },
                { title: 'job_title', valueField: 'job_title' },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            sectionContent: [
                {
                    title: 'address', valueField: addressParser({
                        'street_address': profile ? profile['street_address'] : null,
                        'city': profile ? profile['city'] : null,
                        'state': profile ? profile['state'] : null,
                        'zip_code': profile ? profile['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            sectionContent: [
                { title: 'contact', valueField: '' },
                { title: 'mobile', valueField: 'phone' },
                { title: 'office', valueField: 'office_phone' },
            ]
        },

        {
            sectionTitle: 'email',
            sectionIcon: EmailIcon,
            sectionContent: [
                { title: 'email', valueField: 'email' },
            ]
        },
    ]

    return (
        <div className="ups-container">
            <UserInfo name={`${profile?.first_name ?? ""} ${profile?.last_name ?? ""}`} uri={profile?.picture_url} />
            {profileSections.map((doc, index) => {
                return (
                    <div key={index} className="ups-info-container">
                        <div className="ups-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="ups-info-content">
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);

                                return (
                                    <div key={index} className="ups-info-holder">
                                        {/* <div className="ups-into-title">
                                            <span>{t(data.title).toUpperCase()}</span>
                                        </div>
                                        <div className="ups-info-value"
                                        style={{ marginBottom: '0.5em' }}
                                        >
                                            {arrayValue}
                                        </div> */}
                                        {/* {data.lineTwo && <div className="ups-info-value">{getValues(data.lineTwo)}</div>} */}


                                        {(data.title !== 'mobile' && data.title !== 'office') ?
                                            <div>
                                                <div className="ups-into-title">
                                                    <span>{t(data.title).toUpperCase()}</span>
                                                </div>
                                                <div className="ups-info-value"
                                                    style={{ marginBottom: '0.5em' }}
                                                >
                                                    {arrayValue}
                                                </div>
                                            </div>
                                            :
                                            <table className='contact-table'>
                                                <tr><td><span>{t(data.title)}</span></td>
                                                    {/* {
                                                        data.title === 'email' ?
                                                            <a href={`mailto:${myProfile?.email}`}><td><span className='email-link'>{arrayValue}</span></td></a>
                                                            :  */}
                                                            <td><span>{arrayValue}</span></td>
                                                    {/* } */}
                                                </tr>
                                            </table>
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={profile?.social_media!}
            />
        </div>
    );

    function getValues(obj: any) {
        if (profile == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, profile);
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && profile![obj] != null) ? profile![obj] : '';
        return (
            <span>{value}</span>
        );
    }
}

const UserInfo = (props) => {
    const { name, uri } = props;
    return (
        <div className="ups-user-info">
            <div className="ups-avatar-holder">
                <div className="ups-avatar background-img" style={uri ? { backgroundImage: `url(${uri})` } : {}}></div>
            </div>
            <div className="ups-user-name">
                <span>{name}</span>
            </div>
            {/*<div className="ups-user-tag">*/}
            {/*    <span>{`@${name}0007`}</span>*/}
            {/*</div>*/}
        </div>
    );
}

export default UserProfileSection;
