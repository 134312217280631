import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ErrorHandler,
  LoadingHandler,
} from "../../../../components/error_handler/error_handler";
import { AppError } from "../../../../interfaces";
import {
  selectInterviewCalendarCompletedCount,
  selectInterviewCalendarRemainingCount,
  useAppSelector,
  selectOnlineApplicationsReceivedCountState,
  selectOnlineApplicationsSentCountState,
  selectOnboardingCountState
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import TitleSectionHeader from "../title_section_header/title_section_header";
import "./interviews_list.scss";
import { IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import * as MatIcons from '@material-ui/icons';


export interface RecruiteListItem {
  header: { title: string, children: React.ReactNode },
  options: Array<{ title: string, value: any, color: string, route: string, onClick?: () => void }>,
}

interface Props {
  list: any[];
  loading: boolean;
  error?: AppError | null;
  onClick: (value: any) => void;
  onRefresh: () => void;
}

const InterviewsList: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const applicationRecievedCount = useAppSelector((state) => selectOnlineApplicationsReceivedCountState(state));
  const applicationSentCount = useAppSelector((state) => selectOnlineApplicationsSentCountState(state));
  const remainingInterviewCount = useAppSelector((state) => selectInterviewCalendarRemainingCount(state));
  const completedInterviewCount = useAppSelector((state) => selectInterviewCalendarCompletedCount(state));
  const onboardingCountState = useAppSelector((state) => selectOnboardingCountState(state));
  const OCIRCount = onboardingCountState.response ? onboardingCountState.response.input_required : '-';
  const ORFACount = onboardingCountState.response ? onboardingCountState.response.pending_for_review : '-';
  const OCTWCount = onboardingCountState.response ? onboardingCountState.response.completed_this_week : '-';

  if (props.loading) {
    return <LoadingHandler />;
  }

  if (props.error) {
    return <ErrorHandler error={props.error} onRefresh={props.onRefresh} />;
  }

  return (
    <div className="interviews-list-holder">
      {/* <TitleSectionHeader title={t("interviews").toUpperCase()}>
        <></>
      </TitleSectionHeader> */}
      <ExpandableListItem
        title={t("interviews").toUpperCase()}
        list={[
          <div>
            <InterviewsListItem
              onClick={() => {
                history.push(
                  `${AppRoutes.internalInterviewsScheduled}`
                );
              }}
              title={t("remaining_scheduled_internal_interviews_this_week")}
              value={remainingInterviewCount}
              color={"#D10000"}
            />
            <InterviewsListItem
              onClick={() => {
                history.push(
                  `${AppRoutes.internalInterviewsCompleted}`
                );
              }}
              title={t("completed_internal_interviews_this_week_to_date")}
              value={completedInterviewCount}
              color={"#00BE4B"}
            />
          </div>
        ]} />

      {/* <TitleSectionHeader title={t("applications").toUpperCase()}>
        <></>
      </TitleSectionHeader> */}
      <ExpandableListItem
        title={t("applications").toUpperCase()}
        list={[
          <div>
            <InterviewsListItem
              onClick={() => {
                history.push(
                  `${AppRoutes.onlineApplicationsReceived}`
                );
              }}
              title={t("online_applications_received")}
              value={applicationRecievedCount.response ?? '-'}
              color={"#D10000"}
            />
            <InterviewsListItem
              onClick={() => {
                history.push(`${AppRoutes.onlineApplicationsSent}`);
              }}
              title={t("online_applications_sent")}
              value={applicationSentCount.response ?? '-'}
              color={"#00BE4B"}
            />
          </div>
        ]} />
      {/* <TitleSectionHeader title={t("onboarding").toUpperCase()}>
        <></>
      </TitleSectionHeader> */}
      {/* hiding for production  */}
      <ExpandableListItem
        title={t("onboarding").toUpperCase()}
        list={[
          <div>
            <InterviewsListItem
              onClick={() => history.push(`${AppRoutes.onboardingCandidateInputRequired}`)}
              title={t("on_boarding", { name: t("candidate_input_required") })}
              value={OCIRCount}
              color={"#EEAC17"}
            />
            <InterviewsListItem
              onClick={() => history.push(`${AppRoutes.onboardingReadyForApprovalByRecruiter}`)}
              title={t("on_boarding", { name: t("ready_for_approval_by_recruiter") })}
              value={ORFACount}
              color={"#D10000"}
            />
            <InterviewsListItem
              onClick={() => history.push(`${AppRoutes.onboardingCompletedThisWeek}`)}
              title={t("on_boarding", { name: t("completed_this_week_to_date") })}
              value={OCTWCount}
              color={"#00BE4B"}
            />
          </div>
        ]} />
    </div>
  );
};

interface InterviewsListItemProps {
  onClick: () => void;
  color: string;
  title: string;
  value: any;
}

const InterviewsListItem: React.FunctionComponent<InterviewsListItemProps> = (
  props
) => {
  return (
    <button
      onClick={props.onClick}
      style={{
        backgroundColor: `${props.color}12`,
      }}
      className={"recruite-chip"}
    >
      <div className="holder">
        <div className="info">
          <span style={{ color: props.color }}>{props.title}</span>
        </div>
        <div className="actions">
          <span style={{ color: props.color }}>{`${props.value}`}</span>
        </div>
      </div>
    </button>
  );
};


interface ExpandableListItemProps {
  title: string,
  actions?: React.ReactNode,
  list: React.ReactNode,
}

const ExpandableListItem: React.FunctionComponent<ExpandableListItemProps> = (props) => {
  const [expand, setExpand] = useState<boolean>(true);
  // useEffect(() => {
  //   if (props.title === "INTERVIEWS") {
  //     setExpand(true)
  //   }
  // }, [])

  return (
    <>
      <TitleSectionHeader
        title={`${props.title}`}
        onClick={() => setExpand(!expand)}
      >
        {props.actions ?? getExpanIcon()}
      </TitleSectionHeader>
      {expand && props.list}
    </>
  )

  function getExpanIcon() {
    return (
      <div className="expnd-icon">
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setExpand(!expand)}
        >
          {expand ? <MatIcons.ExpandLess fontSize="small" color="inherit" /> : <MatIcons.ExpandMore fontSize="small" color="inherit" />}
        </IconButton>
      </div>
    )
  }
}

export default InterviewsList;
