import './view_ssn.scss';

import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, THEME } from '../../../../enums';
import { LockOpenIcon } from '../../../../icons';
import { AppError, LoginCredentials } from '../../../../interfaces';
import {
    useAppDispatch,
    useAppSelector,
    selectSingleTalentRequestSSNStateById,
    validateUserBeforeSSNThunk,
    getTalentAvailableDatesThunk,
    selectProfileState
} from '../../../../redux/store';
import { CustomButton, RegxPattern, SpinnerScreen } from '../../../../utils';
import { talentRequestSSNActions } from '../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import RenderInput from '../../../../components/render_input/render_input';


interface Props {
    visible: boolean,
    title: string,
    talentId: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}
const ViewSSNPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        onSuccessClose,
        title,
        talentId,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const ssnRequestState = useAppSelector((state) => selectSingleTalentRequestSSNStateById(props.talentId)(state));
    const validateState = ssnRequestState.validate;
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        getTalentAvailabilityDates();
        return () => { }
    }, [])

    // useEffect(() => {
    //     if (validateState.loading == LoadingType.succeeded) {
    //         dispatch(postSnackbarMessage(validateState.response ?? null));
    //         // closePopup(onSuccessClose);
    //     }
    //     return () => { }
    // }, [validateState.loading])

    function getTalentAvailabilityDates() {
        dispatch(getTalentAvailableDatesThunk(props.talentId));
    }

    function getLoading() {
        return ssnRequestState.loading === LoadingType.pending;
    }



    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDissmiss}
            customStyle={{ maxWidth: '30%' }}
        >
            <DialogWrapper
                onClose={() => {
                    if (ssnRequestState.loading === LoadingType.succeeded) {
                        handleOnCloseSuccess();
                    } else {
                        closePopup(onClose);
                        handleClearError();
                    }
                }}
            >
                {getLoading() ? <div className="loading-spinner" ><SpinnerScreen /></div> : <SSNVieForm
                    title={title}
                    loading={validateState ? validateState.loading : LoadingType.pending}
                    ssnLoading={ssnRequestState.loading}
                    error={validateState.error || ssnRequestState.error}
                    username={profileState.profile?.email ?? ''}
                    response={ssnRequestState.data ?? { ssn: '' }}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    onClearError={handleClearError}
                    onSuccessClose={handleOnCloseSuccess}

                />}
            </DialogWrapper>
        </ActionDialogHolder>
    );

    ///Handle clear error
    function handleClearError() {
        dispatch(talentRequestSSNActions.clearSingleTalentRequestSSNError(props.talentId));
    }

    function handleSubmit(value: LoginCredentials) {
        dispatch(validateUserBeforeSSNThunk({ talent_id: props.talentId, credentials: value }));
    }

    function handleOnCloseSuccess() {
        dispatch(talentRequestSSNActions.clearSingleTalentRequestSSNManageState(props.talentId));
        closePopup(onSuccessClose);
    }

    function handleCancel() {
        handleClearError();
        closePopup(onClose);
    }




    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }



}

interface SSNVieFormProps {
    title: string,
    loading: LoadingType,
    ssnLoading: LoadingType,
    error?: AppError | null,
    username: string,
    response: { ssn: string },
    onSubmit: (value: LoginCredentials) => void,
    onSuccessClose: () => void,
    onCancel: () => void,
    onClearError: () => void,
}

const SSNVieForm: React.FunctionComponent<SSNVieFormProps> = (props) => {
    const { t } = useTranslation();
    const [formState, setFormState] = useState<LoginCredentials>({ username: props.username, password: '' });

    function handleFieldChange(fieldId, value) {
        setFormState({ ...formState, [fieldId]: value });
    }

    return (
        <div className="tlnt-ssn-view-f-container">
            <div className="tlnt-ssn-view-f-header">
                <FormDialogTilteHeader
                    title={props.title}
                    titleIcon={<LockOpenIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                />
            </div>
            <div className="tlnt-ssn-view-f-content">
                {getUI()}
                {props.error && <div className={"error-section"}>
                    <ApiError message={props.error.message} onClose={props.onClearError} />
                </div>}
            </div>
            {getActions()}
        </div>
    );

    function getActions() {
        if (props.ssnLoading === LoadingType.succeeded) {
            return (
                <div className="tlnt-ssn-view-f-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('ok')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => props.onSuccessClose()}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className="tlnt-ssn-view-f-actions">
                <div className="btn-cancel">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('i_do_not_have_password')}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={props.onCancel}
                    />
                </div>

                <div className="btn-save">
                    <CustomButton
                        loading={props.loading === LoadingType.pending}
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('confirm')}
                        enable={_isFormValid()}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => props.onSubmit(formState)}
                    />
                </div>
            </div>
        )
    }


    function getUI() {
        if (props.ssnLoading === LoadingType.succeeded) {
            const ssn = props.response.ssn;
            const formatSSN = (ssn) => {
                // Remove non-numeric characters to check if it's 9 digits
                const cleanedSSN = ssn.replace(/\D/g, '');
        
                // Check if the SSN is already in the correct format (XXX-XX-XXXX)
                if (ssn.match(/^\d{3}-\d{2}-\d{4}$/)) {
                    return ssn;  // Already formatted, return as is
                }
        
                // If the SSN is exactly 9 digits, format it as XXX-XX-XXXX
                if (cleanedSSN.length === 9) {
                    return `${cleanedSSN.slice(0, 3)}-${cleanedSSN.slice(3, 5)}-${cleanedSSN.slice(5)}`;
                }
        
                // If SSN is not in valid format, return it as is
                return ssn;
            };
        
            const formattedSSN = formatSSN(ssn);
        
            return (
                <div className="ssn-validation-holder">
                    <div className="ssn-validation-txt ssn-number">
                        <span>{formattedSSN}</span>
                    </div>
                </div>
            );
        }
        

        return (
            <div className="ssn-validation-holder">
                <div className="ssn-validation-txt">
                    <span>{`${t('ssn_enter_login_password')}`}</span>
                </div>
                    <input type={"email"} value={formState.username} style={{ width: 0, height: 0, margin: 0, minHeight: 0 }} />
                    
                <div className="ssn-password-input">
                    <RenderInput
                        doc={{
                            field: 'password',
                            label: 'password',
                            secure: true,
                            required: true,
                            type: 'password',
                            placeholder: 'password',
                            keyboardtype: ''
                        }}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"ssn-password-input"}
                        customStyle={{ width: "-webkit-fill-available" }}
                    />
                </div>
            </div>
        )
    }

    function validators(value: { text: string, field: string }) {
        switch (value.field) {
            case "username": {
                if (value.text == '') return t('validators.required');
                if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };

            case "password": {
                if (value.text == '') return t('validators.required');
                return null;
            };

            default: {
                return null;
            }
        }
    }

    function _isFormValid() {
        const { username, password } = formState;
        if (
            username === '' ||
            password === ''
        ) return false;
        return true;
    }
}

export default ViewSSNPopup;