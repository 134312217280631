import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import config from "../config";
import { adminCenterReducers } from "./admin_center";
import { companiesReducers } from "./companies";
import { contactsReducers } from "./contacts";
import { loginSlice } from "./login/login_reducer";
import { profileSlice } from "./profile/profile_reducer";
import { snackbarSlice } from "./snackbar/snackbar_reducer";
import { selectorsReducers } from "./selectors";
import { talentReducers } from "./talent";
import { jobsReducers } from "./job_order";
import { dashboardReducers } from "./dashboard";
import { salesAndMarketingReducers } from "./sales_marketing";
import { assignmentsReducers } from "./assignments";
import { employeePortalReducers } from "./employee_portal";
import { riskManagementReducers } from "./risk_management";
import { engagementListSlice } from "./engagement/engagement_reducer";
import { forgotPasswordSlice } from "./forgot_password/forgot_password_reducer";
import { onlineApplicationReducers } from "./online_application";
import { filesReducers } from "./files";
import { onboardingPortalReducers } from "./onboarding_portal";
import { employeeDashboardReducers } from "./employee_dashboard";
import { createLoginForTalentSlice } from "./talent/details/onboarding_and_docs/create_talent_login/create_talent_login_reducer";
import { employeeDashboardSlice } from "./employee_dashboard/employee_dashboard_reducer";
import { textEmAllSlice } from "./textEmAll/text_Em_All_reducer";
import { kinisoJobOrdersReducers } from "./kiniso_job_orders";
import { myProfileReducers } from "./my_profile";
import { apolloImportReducers } from "./apollo_search_import";
import { backOfficeReducers } from "./back_office";
import { reportCenterReducers } from "./report_center";
import { importRecordReducers } from "./upload_files";
import { sendEmailReducers } from "./send_email";
import { agencyLoginLogoSlice } from "./agency_login_logo/agency_login_logo_reducer";

const reducer = combineReducers({
  [snackbarSlice.name]: snackbarSlice.reducer,
  [loginSlice.name]: loginSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [agencyLoginLogoSlice.name]: agencyLoginLogoSlice.reducer,
  "selectors": selectorsReducers,
  "dashboard": dashboardReducers,
  "companies": companiesReducers,
  "contacts": contactsReducers,
  "talent": talentReducers,
  "jobs": jobsReducers,
  "assignments": assignmentsReducers,
  'reportCenter': reportCenterReducers,
  "backOffice": backOfficeReducers,
  "riskManagement": riskManagementReducers,
  "employeePortal": employeePortalReducers,
  "salesMarketing": salesAndMarketingReducers,
  // ['clientPortal']: clientPortalReducers,
  "adminCenter": adminCenterReducers,
  "myProfile": myProfileReducers,
  [engagementListSlice.name]: engagementListSlice.reducer,
  [forgotPasswordSlice.name]: forgotPasswordSlice.reducer,
  "onlineApplication": onlineApplicationReducers,
  "onboardingPortal": onboardingPortalReducers,
  "kinisoJobOrders": kinisoJobOrdersReducers,
  "employeeDashboard": employeeDashboardReducers,
  "files": filesReducers,
  [createLoginForTalentSlice.name]: createLoginForTalentSlice.reducer,
  [employeeDashboardSlice.name]: employeeDashboardSlice.reducer,
  [textEmAllSlice.name]: textEmAllSlice.reducer,
  "apolloImport": apolloImportReducers,
  "universalDirectoriesImportRecord": importRecordReducers,
  "sendEmail": sendEmailReducers

});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: config.ENV !== "production",
  // preloadedState,
  // enhancers: [reduxBatch],
});
export type IRootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

/// agency logo
export * from "./agency_login_logo/agency_login_logo_selector";
export * from "./agency_login_logo/agency_login_logo_thunk";


/// login
export * from "./login/login_selector";
export * from "./login/login_thunk";

/// profile
export * from "./profile/profile_selector";
export * from "./profile/profile_thunk";

/// snackbar
export * from "./snackbar/snackbar_selector";
export * from "./snackbar/snackbar_thunk";

/// Dashboard
export * from "./dashboard";

/// Sales & Marketing
export * from "./sales_marketing";

///Companies
export * from "./companies";

/// Contacts
export * from "./contacts";

///Talent
export * from "./talent";

/// Jobs
export * from "./job_order";

/// Assignments
export * from "./assignments";

/// Report Center
export * from "./report_center";

/// Back office
export * from './back_office'

/// Risk Management
export * from "./employee_portal";

/// Client Portal
export * from "./client_portal";

/// Admin center
/// export * from './admin_center';

/// selectors
export * from "./selectors";

/// engagement
/// export * from './engagement/engagement_selector';
export * from "./engagement/engagement_thunk";

/// Online application
export * from "./online_application";
/// Onboarding
export * from "./onboarding_portal";

/// Files
export * from "./files";

export * from "./dashboard";

/// createLoginForTalentSlice
export * from "./talent/details/onboarding_and_docs/create_talent_login/create_talent_login_selector";
export * from "./talent/details/onboarding_and_docs/create_talent_login/create_talent_login_thunk";

/// employeeDashboard
export * from "./employee_dashboard/employee_dashboard_selector";
export * from "./employee_dashboard/employee_dashboard_thunk";
/// empdashboard e-docs
export * from "./employee_dashboard/e_docs/e_docs_onboarding_selector";
export * from "./employee_dashboard/e_docs/e_docs_onboarding_thunk";
// employee dashboard direct deposit
export * from "./employee_dashboard/direct_deposit/direct_deposit_selector";
export * from "./employee_dashboard/direct_deposit/direct_deposit_thunk";

//text_em_all

export * from "./textEmAll/text_Em_All_selector";
export * from "./textEmAll/text_Em_All_thunk";

export * from './apollo_search_import';

export * from './send_email';


export default store;
