import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../../interfaces";
import { BackOfficeAPI } from "../../../../apis/back_office_api";
import { catchRequestError } from "../../../../utils";
import { ICreateDeductionAuthorityBatch, IDeductionAuthority, IPaycheckDeductionAuthority } from "../../../../interfaces/back_office_deduction_authority";

export const getDeductionAuthorityBatchThunk = createAsyncThunk<
    IDeductionAuthority,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/create_deduction_authority_batch/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getDeductionAuthorityBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });
export const getDeductionAuthorityPaychecksBatchThunk = createAsyncThunk<
    IPaycheckDeductionAuthority[],
    { week_accounting_id: string, batch_id?: string },
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/create_deduction_authority_batch/paychecks/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getDeductionAuthorityPayChecksBatch(payload.week_accounting_id, payload.batch_id);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const createDeductionAuthorityBatchThunk = createAsyncThunk<
    ICreateDeductionAuthorityBatch,
    ICreateDeductionAuthorityBatch,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/create_deduction_authority_batch/post",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.createDeductionAuthorityBatch(payload);
            return res.data.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const updateDeductionAuthorityBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/post_deduction_authority_batch/patch",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.patchDeductionAuthorityBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });
export const deleteDeductionAuthorityBatchThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/delete_deduction_authority_batch/delete",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.deletePayrollBatch(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getDedAuthPaycheckPDFURLThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/payroll/payroll_dashboard/deduction_authority_paycheck_dir/view_paycheck_url/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getDedAuthPaycheckDownloadPDFURL(payload);
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });