import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchRequestError } from "../../utils";
import { AccountAPI } from "../../apis";
import { AppError } from "../../interfaces";


export const getAgencyLoginLogoThunk = createAsyncThunk<
    string,
    { domain: string, api_key: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>("@agency-login-logo/post", async (data, thunkAPI) => {
    try {
        const res = await AccountAPI.agencyLoginLogo(data.domain, data.api_key);
        return res.data.url;
    } catch (error) {
        return catchRequestError(error, thunkAPI);
    }
})