import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/store";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, FormInput, RegxPattern, allowNumbers, formatToMoney } from "../../../../utils";
import "./create_action.scss"
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { adminCenterBackOfficeBankAccountPurposeOptions, onBoardingDirectDepositAccountTypeOptions } from "../../../../variables";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { BackOfficeIcon } from "../../../../icons";

const CreateAction = ({ onClose, onDismiss, visible, category, category_id, reduxMapping }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    // const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState<{ value: any, bank_name: String, account_type: string, routing_number: string, account_number: string, purpose: string, description: string, taxable: boolean }>({ value: '', description: '', bank_name: "", routing_number: "", account_number: "", account_type: "", purpose: "Payments", taxable: false });
    // const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping()?.state;
        if (sentRequest && state?.create?.loading === LoadingType.succeeded) {
            setMethodValue({ ...methodValue, value: '', description: '', bank_name: "", routing_number: "", account_number: "", account_type: "", purpose: "", taxable: false });
            // setShowButton(false);
            setSentRequest(false);
            // setSuccessMessage(state?.create?.response);
            // setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping()?.state?.create?.loading])

    function handleInput(id, value) {
        if (category.label === 'mark_up' && id === "value") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setMethodValue({ ...methodValue, [id]: value })
            }
        }
        else {
            setMethodValue({ ...methodValue, [id]: value })
        }
        // if (value.trim())
        //     setShowButton(true);
        // else
        //     setShowButton(false);
        // if (id === 'input-value') {
        //     setMethodValue({ ...methodValue, value: value })
        //     if (value.trim())
        //         setShowButton(true);
        //     else
        //         setShowButton(false);
        // }
        // else {
        //     setMethodValue({ ...methodValue, description: value })
        //     if (methodValue.value.trim())
        //         setShowButton(true);
        //     else
        //         setShowButton(false);
        // }
    }

    function handleSave() {
        setSentRequest(true);
        if (category.label === 'bank_account') {
            setCheckValidationOnSubmit(true);
            if (_isFormValid()) {
                dispatch(category.methods.create({
                    'bank_name': methodValue.bank_name.trim(),
                    'routing_number': methodValue?.routing_number!.trim(),
                    'account_number': methodValue?.account_number!.trim(),
                    'account_type': methodValue?.account_type!.trim(),
                    'purpose': methodValue?.purpose!.trim()
                }));
                setCheckValidationOnSubmit(false);
            }
        }
        else if (category.label === "pay_type") {
            dispatch(category.methods.create({
                'value': methodValue.value.trim(),
                'description': methodValue.description.trim(),
                'taxable': methodValue.taxable
            }));
        }
        else {
            dispatch(category.methods.create({
                'value': methodValue.value.trim(),
                'description': methodValue.description.trim()
            }));
        }
        // setSnackBarMessage(category.deleteAlertMessage)
    }

    function _isFormValid() {
        const { bank_name, routing_number, account_number, purpose } = methodValue;
        if (bank_name === '') return false;
        if (purpose === '') return false;
        if (routing_number === '' || routing_number && routing_number.length < 9) return false;
        if (account_number === '') return false;
        return true;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={onClose}>
                <div className="ac-bo-dialog-header">
                    <div className="dialog-header">
                    <FormDialogTilteHeader
                            title={t(category.buttonLabel)}
                            titleIcon={<BackOfficeIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={category.label! === "bank_account" ? "bank_name" : "value"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={category.label! === "bank_account" ? methodValue.bank_name : methodValue.value}
                            label={t(category.inputLabel)}
                            placeholder=""
                            required={true}
                            suffix={category.label! === "mark_up" ? <span>%</span> : ""}
                            onBlur={category.label! === "mark_up" ? formatToMoney : undefined}
                            onKeyDown={category.label! === "mark_up" ? formatToMoney : undefined}
                            onKeyUp={category.label! === "mark_up" ? formatToMoney : undefined}
                            validations={category.label! === "bank_account" ? formValidators : undefined}
                            checkValidationOnSubmit={category.label! === "bank_account" ? checkValidationOnSubmit : false}
                        />
                        {category.label! === "bank_account" ?
                            <>
                                {/* <FormInput
                                    id={"account_type"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.account_type}
                                    label={t(category.inputLabel2)}
                                    placeholder=""
                                /> */}
                                <CustomFormSelect
                                    name={'account_type'}
                                    value={methodValue.account_type}
                                    placeholder={t('select')}
                                    list={onBoardingDirectDepositAccountTypeOptions.map((doc) => ({
                                        label: doc.label,
                                        value: doc.value,
                                    }))}
                                    label={t(category.inputLabel2)}
                                    onChange={(val) => (handleInput("account_type", val))}
                                    required={false}
                                />
                                <FormInput
                                    id={"routing_number"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.routing_number}
                                    label={t(category.inputLabel3)}
                                    placeholder=""
                                    required={true}
                                    validations={formValidators}
                                    onKeyUp={allowNumbers}
                                    maxLength={9}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                                <FormInput
                                    id={"account_number"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.account_number}
                                    label={t(category.inputLabel4)}
                                    placeholder=""
                                    required={true}
                                    validations={formValidators}
                                    onKeyUp={allowNumbers}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                                <CustomFormSelect
                                    name={'purpose'}
                                    value={methodValue.purpose}
                                    placeholder={t('select')}
                                    list={adminCenterBackOfficeBankAccountPurposeOptions.map((doc) => ({
                                        label: doc.label,
                                        value: doc.value,
                                    }))}
                                    label={t(category.inputLabel5)}
                                    onChange={(val) => (handleInput("purpose", val))}
                                    required={true}
                                    validations={formValidators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </>
                            :
                            <>
                                <FormInput
                                    id={"description"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.description}
                                    label={t(category.inputLabel2)}
                                    placeholder=""
                                />
                                {category.label === "pay_type" ? (
                                    <div className="toggle-switch">
                                        <ToggleSwitch
                                            label={t("taxable")}
                                            checked={methodValue.taxable}
                                            onChange={(value) => handleInput('taxable', value)}
                                            labelPosition={"left"}
                                        />
                                    </div>
                                ) : null}
                            </>
                        }
                    </div>
                    <div className="dialog-footer">
                        <div>
                            {getMapping()?.state?.create?.error && sentRequest &&
                                (<p style={{ color: 'red' }} className="message">{getMapping()?.state?.create?.error.response.detail.error}</p>) 
                                // : (<p style={{ color: 'green' }} className="message">{successMessage}</p>)
                            }
                        </div>
                        <div className="btns">
                            <div className="btn-cancel">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('cancel')}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onClose)}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('save')}
                                    enable={category.label! === "bank_account" ? true : methodValue.value}
                                    loading={getMapping()?.state?.create?.loading == LoadingType.pending}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={handleSave}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "bank_name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "purpose": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "routing_number": {
                if (value.text === "") return t("validators.required");
                if (value.text && value.text.length < 9) return t("validators.routinglimit");
                if (!value.text) return null;
                return null;
            }
            case "account_number": {
                if (value.text === "") return t("validators.required");

                return null;
            }

            default: {
                return null;
            }
        }
    }

}

export default CreateAction;