
export enum ProfilePictureType {
    systemUser = "SYSTEM_USER",
    companies = "COMPANIES",
    department = "DEPARTMENT",
    talent = "TALENT",
    lead = "LEAD",
    jobs = "JOBS",
    contact = "CONTACT",
    assignments = "ASSIGNMENTS",
    onlineApplicant = "ONLINE_APPLICANT",
    onlineApplication = "ONLINE_APPLICATION", 
    branch = "BRANCH", 
    workCompClaim="WORK_COMP_CLAIM", 
    unemploymentClaim = "UNEMPLOYMENT_CLAIM",
    auditReport = "AUDIT_REPORT",
    agencyLogo= "AGENCY_LOGO"
}