import { useTranslation } from "react-i18next";
import { THEME } from "../../enums";
import { ReloadIcon } from "../../icons";
import { AppError } from "../../interfaces";
import { CustomButton, SpinnerScreen } from "../../utils";
import './table_empty.scss';

interface Props {
    title: string,
    onClick: () => void,
}
const TableEmpty = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="table-empty-container">
            <div className="empty-msg">
                <span>{t(props.title)}</span>
            </div>
            <div className="refresh-action">
                <CustomButton
                    leftIcon={getIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('refresh')}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={props.onClick}
                />
            </div>
        </div>
    );

    function getIcon() {
        return (
            <div className="table-empty-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
            </div>

        )
    }
}

/// Table empty
interface TableFilterEmptyProps {
    title: string,
}

export const TableFilterEmpty = (props: TableFilterEmptyProps) => {
    const { t } = useTranslation();
    return (
        <div className="table-empty-container">
            <div className="empty-msg">
                <span>{t(props.title)}</span>
            </div>
        </div>
    );

}

/// Table loading
export const TableLoading = (props) => {

    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}

interface TableErrorHandlerProps {
    error: AppError,
    onRefresh: () => void,
}

/// Table error
export const TableErrorHandler = (props: TableErrorHandlerProps) => {
    const { t } = useTranslation();
    return (
        <div className="table-error-handler-container">
            <div className="error-msg">
                <span style={{textTransform: "none"}}>{props.error.message ?? "Something went wrong. Please try again later."}</span>
            </div>
            <div className="error-action">
                <CustomButton
                    leftIcon={getIcon()}
                    loading={false}
                    textStyle={{ textTransform: 'capitalize' }}
                    name={t('refresh')}
                    enable={true}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={props.onRefresh}
                />
            </div>
        </div>
    );

    function getIcon() {
        return (
            <div className="table-empty-add-icon">
                <ReloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
            </div>

        )
    }
}


export default TableEmpty;
