import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../enums";
import { CalenderIcon, CompletedCallIcon, CompletedCallWhiteIcon, EditIcon, ScheduledCallIcon, ScheduledCallWhiteIcon, TickGreenIcon, TickGreyIcon } from "../../../../icons";
import { ISalesCallsByPage, SalesCall } from "../../../../interfaces/sales_call";
import { getContactStatus, selectContactStatusList } from "../../../../redux/admin_center";
import { manageSalesCallActions } from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_reducer";
import { getRoleUsersList, postSnackbarMessage, selectProfileState, selectRoleUsersList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { CustomButton, CustomCheckBox, getContactIcon, getPlusBtnIcon, getUserNameFromProfileDropdown } from "../../../../utils";
import { convertDateToTimeStamp, dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateString } from "../../../../variables";
import { PlanMySalesCalls } from "../plan_my_sales_calls/plan_my_sales_calls";
import { CompleteSalesCall } from "./complete_sales_call";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { selectSalesCallListState } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_selector";
import { getSalesCallFilteredListByPage } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_thunk";
import { getSalesCallByIdThunk } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_thunk";
import { selectSalesCallDetailsState } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_selector";
import { salesCallDetailActions } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_reducer";
import Pagination from "../../../components/pagination/pagination";
import { selectManageCompleteSalesCallUpdateState, selectManageSalesCallCreateState, selectManageSalesCallUpdateState } from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_selector";


interface Props extends RouteComponentProps<any> { }
const initialState = {
  accomplishment: "",
  agency_id: "",
  branch_id: "",
  call_date_time: 0,
  call_goal: "",
  call_made_to: "",
  call_result: "",
  communicated_with_dm: false,
  communicated_with_someone: false,
  company_id: "",
  company_name: "",
  company_status: "",
  contact_first_name: "",
  contact_id: "",
  contact_last_name: "",
  contact_method: null,
  department_id: "",
  date_of_completed: 0,
  department_name: "",
  dm_reached: false,
  gross_profit_potential: "",
  gross_revenue_potential: "",
  id: "",
  is_completed: false,
  industry_sector_id: "",
  industry_sector_name: "",
  schedule_date_time: 0,
  schedule_date: 0,
  schedule_time: "12:00 AM",
  sequence_number: 0,
  status: "",
  user_id: "",
  website_url: "",
  account_managers: [],
  open_orders:0,
  order_status:"",
  modified_by:"",
  created_by:"",
  planned_by_id: "",
  planned_by_name: "",
  assigned_to_id: "",
  assigned_to_name: "",
}
const SalesCallActivity: React.FunctionComponent<Props> = (props) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { location, history } = props;

  const tableHeader = [
    { title: "complete_call", code: "complete_call" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "", code: "" },
  ];

  const completedCallsTableheader = [
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "Date of Completed Sales Call", code: "call_date_time" },
    { title: "Call Results", code: "call_result" },
    { title: "", code: "" },
  ];

  const hoveringStateColumn = {
    callGoal: "call_goal",
    callResult: "call_result",
  };

  const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
  const profileState = useAppSelector((state) => selectProfileState(state));
  const usersList = useAppSelector((state) => selectRoleUsersList(state));
  const { loading, error, salesCallsListByPage } = useAppSelector((state) => selectSalesCallListState(state));
  const salescallDetailData = useAppSelector((state) => selectSalesCallDetailsState(state));

  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('All');
  const [managerFilter, setManagerFilter]: any = useState("");
  const [managerList, setManagerList]: any = useState([]);
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [showCompletePopup, setShowCompletePopup] = useState<boolean>(false);


  const [selectedCall, setSelectedCall] = useState<SalesCall>(initialState);
  const [salesCallsList, setSalesCallsList] = useState<ISalesCallsByPage | null>(null);

  const [showPlanPopup, setShowPlanPopup] = useState<boolean>(false);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [salesFormState, setSalesFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
  const [salesDateRange, setSalesDateRange] = useState([thirtyDaysAgo, new Date()]);
  const [salesStartDate, salesEndDate] = salesDateRange;
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [stateSource, setStateSource] = useState<{ source: string, contactId?: string, companyId?: string }>({ source: "", contactId: "", companyId: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100)
  const [statusId, setStatusId] = useState<string>("");
  const [dmReached, setDMReached] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<{
    visible: boolean;
    id: string;
    column: string;
  } | null>(null);

  const createState = useAppSelector((state) =>
    selectManageSalesCallCreateState(state)
  );
  const updateState = useAppSelector((state) =>
    selectManageSalesCallUpdateState(state)
  );
  const completeUpdateState = useAppSelector((state) =>
    selectManageCompleteSalesCallUpdateState(state)
  );
  //useEffects
  useEffect(() => {
    dispatch(getRoleUsersList());
    dispatch(getContactStatus());
  }, []);

  useEffect(() => {
    if (search?.length >= 2) return getSalesCallsList({ pageNum: 1 });
    return () => {}
  }, [search])

  useEffect(() => {
    if (salesCallsListByPage?.loading === LoadingType.succeeded && salesCallsListByPage?.response) {
      setSalesCallsList(salesCallsListByPage?.response)
      return () => { };
    }
  }, [salesCallsListByPage?.loading]);


  useEffect(() => {
    if (createState.loading === LoadingType.succeeded) {
      dispatch(postSnackbarMessage(createState.response?.status ?? null));
      getSalesCallsList();
    }
    if (updateState.loading === LoadingType.succeeded) {
      dispatch(postSnackbarMessage(updateState.response?.status ?? null));
      handleAddSalesCallSuccess();
    }
    if (completeUpdateState.loading === LoadingType.succeeded) {
      // setStateSource({ source: "", contactId: "", companyId: "" });
      dispatch(postSnackbarMessage(completeUpdateState.response?.status ?? null));
      getSalesCallsList();

      // dispatch(manageSalesCallActions.clearCompleteUpdateSalesCallState());
    }

  }, [createState.loading, updateState.loading, completeUpdateState.loading]);


  useEffect(() => {
    if (usersList && usersList.length > 0) {
      const list: any = [];
      usersList.map((h: any) => {
        list.push({
          value: h.id,
          label: h.last_name + " " + h.first_name,
        });
      });
      setManagerList(list);
    }
  }, [usersList]);

  useEffect(() => {
    if (error != null && salesCallsList?.items?.length !== 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);
  useEffect(() => {
    const state = location.state as any;
    if (state) {
      setStateSource({
        source: state["source"],
        contactId: state["contactId"],
        companyId: state["companyId"]
      });

      if (state["planCall"]) {
        setSelectedCall({
          ...selectedCall,
          contact_id: state["contactId"] ?? "",
          company_id: state["companyId"] ?? "",
          department_id: state["departmentId"] ?? "",
          planned_by_id: state["planned_by_id"] ?? "",
          planned_by_name: state["planned_by_name"] ?? "",
        });
        setShowPlanPopup(true);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
      if (state["completeCall"] || state["editCall"]) {
        dispatch(getSalesCallByIdThunk(state["id"]));
      }
    }
  }, [location]);

  useEffect(() => {
    const state = location.state as any;

    if (state && salescallDetailData && salescallDetailData.loading === LoadingType.succeeded && salescallDetailData.response !== null) {
      const call = salescallDetailData.response;
      if (state['completeCall']) {
        setSelectedCall({ ...call });
        setShowCompletePopup(true);
        setTab(call.company_status);
        setShowCompleted(call.is_completed);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
      if (state['editCall']) {
        const dateSting = extractDateFromTimestamp(call.schedule_date_time);
        const dateTimeStamp = dateToTimestamp(dateSting);
        const time = extractTimeFromTimestamp(call.schedule_date_time);
        const updatedSalesCall = {
          ...call,
          schedule_date: dateTimeStamp,
          schedule_time: time,
        };
        setSelectedCall(updatedSalesCall);

        setShowPlanPopup(true);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
    }
  }, [salescallDetailData, salescallDetailData.response]);

  useEffect(() => {
    const isStatusValid = statusId !== "" && statusId !== undefined;
  
    if (((salesFormState.available_from !== 0 && salesFormState.available_to !== 0) || (salesFormState.available_from === 0 && salesFormState.available_to === 0)) &&
      (isStatusValid || showCompleted !== undefined)) {
      getSalesCallsList({ pageNum: 1, status_id: isStatusValid ? statusId : undefined });
    }
  }, [showCompleted, salesFormState.available_from, salesFormState.available_to, statusId]);

  useEffect(() => {
    if ((salesStartDate === null && salesEndDate === null) || (salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
      setSalesDateRange([thirtyDaysAgo, new Date()]);
      setSalesFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    }
  }, [showCompleted]);


  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0)) {
      setDatePickerOpen(false)
    }
  }, [salesFormState.available_from, salesFormState.available_to])

  //functions 

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };
  const getSalesCallsList = (doc?: { status_id?: string, pageNum?: number, pageSize?: number, users_ids?: Array<string>, search_str?: string, show_only_dm_reached?: boolean }) => {
    const statusIdToSend = statusId === "All" ? undefined : doc?.status_id ?? statusId;
    dispatch(getSalesCallFilteredListByPage({ status_id: statusIdToSend, page: doc?.pageNum ?? currentPage, size: doc?.pageSize ?? size, my_upcoming_calls: !showCompleted, start_date: salesFormState.available_from, end_date: salesFormState.available_to, users: doc?.users_ids ?? managerFilter, search_str: doc?.search_str ?? search, show_only_dm_reached: doc?.show_only_dm_reached ?? dmReached }))
    setCurrentPage(doc?.pageNum ?? currentPage)
  }
  const planNextCallWithContact = (call: SalesCall) => {
    // setStateSource({ source: "", contactId: "", companyId: "" })
    setShowCompletePopup(false);
    const newCall = new SalesCall();
    setSelectedCall({
      ...newCall,
      contact_id: call.contact_id ?? "",
      company_id: call.company_id ?? "",
      department_id: call.department_id ?? "",
    });
    setShowPlanPopup(true);

    getSalesCallsList();
  };

  ///Add user action
  function handleAddSalesCallSuccess() {
    getSalesCallsList();
    handlePlanClose();
  }
  const handleCompleteCallSuccess = () => {
    getSalesCallsList();
    handleCompleteCallClose();
  }

  function handleChange(value) {
    setSalesFormState({
      ...salesFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
    });
    setSalesDateRange(value);

  }

  function handleGotoAgencyDashboard() {
    handleAddSalesCallSuccess();
    props.history.push(AppRoutes.dashboardPage);
    dispatch(salesCallDetailActions.clearSalesCallDetailState());
    setStateSource({ source: "", contactId: "", companyId: "" })
  }
  function handleCreateNew() {
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
  }

  function handleOnGotoSalesCall(id: string, name: string) {
    handleAddSalesCallSuccess();
  }
  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };
  function handleCompanySelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }
  function handleDepartmentSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleContactSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
      state: {
        id: value.contact_id,
        name: `${value.contact_first_name ?? ""} ${value.contact_last_name ?? ""
          }`,
      },
    });
    return;
  }
  function getFlexNumber(value: number) {
    if (value === 0 && !showCompleted) return 1.2;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7 && !showCompleted) return 2;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    if (value === 11) return 1;
    return 1;
  }

  function handleMouseOver(id, column) {
    setIsHovering({ id: id, visible: true, column: column });
  }
  function handleMouseOut() {
    setIsHovering(null);
  }

  const showSpinner = () => salesCallsListByPage?.loading === LoadingType.pending;

  const handleEdit = (val: SalesCall, isCompletePlanForm?: boolean) => {
    const dateSting = extractDateFromTimestamp(val.schedule_date_time)
    const dateTimeStamp = dateToTimestamp(dateSting)
    const time = extractTimeFromTimestamp(val.schedule_date_time)
    const updatedSalesCall = {
      ...val,
      schedule_date: dateTimeStamp,
      schedule_time: time
    }
    setSelectedCall(updatedSalesCall);
    if (isCompletePlanForm) {
      setShowPlanPopup(true);
      setShowCompletePopup(false);
    } else {
      val.is_completed ? setShowCompletePopup(true) : setShowPlanPopup(true);
    }
  };


  const getFilteredList = () => {
    let list: SalesCall[] = [...salesCallsList?.items! ?? []];
    let sortedList: SalesCall[] | undefined;
    if (sortedField !== null) {
      sortedList = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return sortedList ?? list
  }
  const handleCompleteCallClose = (plan_next?: boolean) => {
    setShowCompletePopup(false);
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
    dispatch(manageSalesCallActions.clearCompleteUpdateSalesCallState());
    dispatch(salesCallDetailActions.clearSalesCallDetailState());
    if (!plan_next) {
      setSelectedCall(new SalesCall());
      if (stateSource && stateSource.source === "dashboard") {
        // handleGotoAgencyDashboard();
        props.history.push(AppRoutes.dashboardPage);
        setStateSource({ source: "", contactId: "", companyId: "" });
      }
      if (stateSource && stateSource.source === "contacts") {
        props.history.push({ pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}/sales-profile` });
      }
    }

  }

  function handlePlanClose() {
    setShowPlanPopup(false);
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
    dispatch(manageSalesCallActions.clearUpdateSalesCallState());
    dispatch(salesCallDetailActions.clearSalesCallDetailState());
    if (stateSource && stateSource.source === "dashboard") {
      getSalesCallsList();
      props.history.push(AppRoutes.dashboardPage);
      setStateSource({ source: "", contactId: "", companyId: "" });
    }
    if (stateSource && stateSource.source === "contacts") {
      props.history.push({
        pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}/sales-profile`,
      });
    }
    if (stateSource && stateSource.source === "contacts-details") {
      props.history.push({
        pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}`,
      });
    }
    if (stateSource && stateSource.source === "companies") {
      props.history.push({
        pathname: `${AppRoutes.companiesDetailsPage}/${stateSource.companyId}`,
      });
    }
    setSelectedCall(new SalesCall());
  }

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && salesCallsList?.items?.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getSalesCallsList} />;
    }

    if (salesCallsList?.items?.length === 0 || getFilteredList()?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getSalesCallsList} />;
    }

    if (getFilteredList()?.length !== 0) {
      return (
        <SortableTable
          headerList={showCompleted ? completedCallsTableheader : tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList()?.map((doc) => {
            return (
              <tr key={doc.id}>
                {!showCompleted ? (
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    <button
                      className="complete-call"
                      onClick={() => {
                        setSelectedCall(doc);
                        setShowCompletePopup(true);
                      }}
                    >
                      <span>{t("complete_call")}</span>
                    </button>
                  </TableData>
                ) : (
                  <TableData
                    customStyle={{ flex: getFlexNumber(0) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                {!showCompleted && (
                  <TableData
                    customStyle={{ flex: getFlexNumber(1) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  isButton={true}
                  onClick={() => handleDepartmentSelect(doc)}
                >
                  <span>{doc.department_name ?? t("corporate")}</span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(3) }}
                  isButton={true}
                  onClick={() => handleContactSelect(doc)}
                >
                  <span>
                    {doc.contact_first_name
                      ? doc.contact_first_name
                      : "" + " " + doc.contact_last_name
                        ? doc.contact_last_name
                        : ""}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  {doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  {getContactIcon(doc.contact_method)}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  <span>{doc.industry_sector_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <div
                    style={{ cursor: "pointer" }}
                    onMouseOut={() => handleMouseOut()}
                    onMouseOver={() =>
                      handleMouseOver(doc.id, hoveringStateColumn.callGoal)
                    }
                    className="col-hover-container"
                  >
                    <span>
                      {doc != null && doc?.call_goal?.length > 100
                        ? doc?.call_goal?.substring(0, 100) + "..."
                        : doc?.call_goal}
                    </span>
                    {isHovering &&
                      doc.id === isHovering.id &&
                      isHovering.column === hoveringStateColumn.callGoal && (
                        <div className="parent-position">
                          <div className="child-position">
                            <span style={{ cursor: "pointer" }}>
                              {doc?.call_goal}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span>{getDateString(doc.schedule_date_time && doc.schedule_date_time)}</span>
                </TableData>
                {showCompleted && (
                  <>
                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                      <span>{getDateString(doc.call_date_time)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                      <div
                        style={{ cursor: "pointer" }}
                        onMouseOut={() => handleMouseOut()}
                        onMouseOver={() =>
                          handleMouseOver(
                            doc.id,
                            hoveringStateColumn.callResult
                          )
                        }
                        className="col-hover-container"
                      >
                        <span>
                          {doc != null && doc?.call_result?.length > 100
                            ? doc?.call_result?.substring(0, 100) + "..."
                            : doc?.call_result}
                        </span>
                        {isHovering &&
                          doc.id === isHovering.id &&
                          isHovering.column ===
                          hoveringStateColumn.callResult && (
                            <div className="parent-position">
                              <div className="child-position">
                                <span style={{ cursor: "pointer" }}>
                                  {doc?.call_result}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </TableData>
                  </>
                )}
                <TableData customStyle={{ flex: getFlexNumber(11) }}>
                  <span>
                    <button
                      className="icon-btn tdp-edit-icon"
                      onClick={() => handleEdit(doc)}
                    >
                      <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                      />
                    </button>
                  </span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  const handlePageChange = (pageNum: number, size: number) => {
    getSalesCallsList({ pageNum: pageNum, pageSize: size })
    setSize(size ? size : salesCallsList?.size!)
  };

  const handleUsers = (value) => {
    setManagerFilter(value)
    getSalesCallsList({ pageNum: 1, pageSize: size, users_ids: value });
  }

  function handleOnChange(value) {
    setDMReached(value);
    getSalesCallsList({ pageNum: 1, show_only_dm_reached: value });
  }

  const handleClickTabData = (status_id: string) => {
    setStatusId(status_id)
  }

  return (
    <div className="sales-call-activity">
      <div className="flex-row main-header">
        <div
          className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
            }`}
          onClick={() => {
            setShowCompleted(false)
            setDMReached(false)
            setManagerFilter([]); // Clear manager filter
            setSearch(""); // Clear search
          }}
        >
          {!showCompleted ? (
            <ScheduledCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <ScheduledCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Scheduled Sales Calls
        </div>
        <div
          className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
            }`}
          onClick={() => {
            setShowCompleted(true)
            setDMReached(false)
            setManagerFilter([]); // Clear manager filter
            setSearch(""); // Clear search
          }}
        >
          {showCompleted ? (
            <CompletedCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <CompletedCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Completed Sales Calls
        </div>
      </div>
      <div className="tab-navigation-container">
        <div className="header">
          <div className="tab-options-container">
            {props && contactStatusList && [
              // Adding the "All" tab as a hardcoded option
              <div
                key="all"
                className={`tab-option ${tab === "All" ? "selected" : ""}`}
                onClick={() => {
                  setTab("All");
                  handleClickTabData("All"); // No status_id for "All"
                  // setManagerFilter([]); // Clear manager filter
                  setSearch(""); // Clear search
                }}
              >
                <span>All</span>
              </div>,
              // Mapping over the contactStatusList array
              ...contactStatusList.map((doc, index) => (
                <div
                  key={index}
                  className={`tab-option ${tab === doc.contact_status ? "selected" : ""}`}
                  onClick={() => {
                    setTab(doc.contact_status);
                    handleClickTabData(doc.id);
                    // setManagerFilter([]); // Clear manager filter
                    setSearch(""); // Clear search
                  }}
                >
                  <span>{t(doc.contact_status)}</span>
                </div>
              )),
            ]}
          </div>
        </div>
      </div>
      <div className="table-container ">
        <div className="actions">
          <div className="table-header">
            <CustomMultiFormSelect
              customStyle={{ width: "initial", marginLeft: "1em" }}
              label={t("select_user")}
              name={"select_user"}
              value={managerFilter}
              placeholder={"All"}
              list={managerList}
              onChange={handleUsers}
            />
            <div className="dateRange">
              <label>{t("date_range")}</label>
              <div className="date-input-icon">
                <div className="calendar-icon-div">
                  <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                </div>
                <DatePicker
                  selectsRange={true}
                  startDate={salesStartDate}
                  endDate={salesEndDate}
                  onChange={handleChange}
                  isClearable={true}
                  placeholderText={'Start Date -  End Date'}
                  dateFormat={'MM.dd.yyyy'}
                  onClickOutside={() => setDatePickerOpen(false)}
                  open={isDatePickerOpen}
                  onFocus={() => setDatePickerOpen(true)}
                />
              </div>
            </div>
            <div className="total-count-action">
              <span>Total <b>{(tab !== "All" ? t(tab) : "") + ":"}</b></span> <span className="total-count-number">{salesCallsList?.total}</span>
            </div>
            
          </div>
          <div className="sm-sca-search">
          {showCompleted && <div className="serviceCall-toggle">
              <CustomCheckBox
                name={"show_only_dm_reached"}
                title={t("show_only_dm_reached")}
                checked={dmReached}
                onClick={() => handleOnChange(!dmReached)}
              />
            </div>}
            {showCompleted &&
              <div className="total-count-action">
                <span>Total <strong>DM</strong> Reached:</span> <span className="total-count-number">{salesCallsList?.total_dm_reached ?? 0}</span>
              </div>}
            <div>
              <SearchBar
                value={search}
                onChange={(value) => {
                  setSearch(value)
                  if (value?.length === 0) {
                    getSalesCallsList({ pageNum: 1, search_str: "" });
                  }
                }}
              onSearch={()=>{}}
              />
            </div>
            {!showCompleted && (
              <div className="add-campaign">
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  name={t("Plan a Sales Call")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => setShowPlanPopup(true)}
                  customStyle={{ marginLeft: "1rem" }}
                />
              </div>
            )}
          </div>
        </div>

        <div>
          {getTable()}
        </div>
      </div>
      <div className="table-footer">
        <div className="as-table-footer">
          <Pagination
            totalPages={salesCallsList?.pages!}
            onPageChange={handlePageChange}
            curPageNum={currentPage}
            totalCount={salesCallsList?.total!}
            size={salesCallsList?.size!}
            loadingState={salesCallsListByPage?.loading}
          />
        </div>
        <div className="call-counts">
          {contactStatusList.map((company) => {
            return <div className="total-count">
              Total  {company.contact_status}  Calls:
              {salesCallsList ? <span>{salesCallsList[company.id] ?? 0}</span> : 0}
            </div>
          })}

          <div className="sales-count total-count">
            {`Total ${showCompleted ? "Completed" : "Scheduled"} Sales Calls: `}
            <span>{salesCallsList ? contactStatusList.reduce((sum, company) => {
              // Get the number of calls from salesCallsList for the current company's id
              const callsForCompany = salesCallsList[company.id] ?? 0; // Default to 0 if no calls are found
              return sum + callsForCompany; // Add the calls for the current company to the total sum
            }, 0) : 0}</span>
          </div>
        </div>
      </div>
      {showCompletePopup && profileState?.profile && (
        <Portal>
          <CompleteSalesCall
            showModal={showCompletePopup}
            callType={tab}
            salesCall={selectedCall}
            successTitle={t("complete_call")}
            currentLoginUserName={getUserNameFromProfileDropdown(
              profileState?.profile
            )}
            usersList={usersList}
            onClose={(plan_next?: boolean) => handleCompleteCallClose(plan_next)}
            onSuccessClose={handleCompleteCallSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoSalesCall}
            planNextCall={planNextCallWithContact}
            availableFrom={salesFormState.available_from}
            availableTo={salesFormState.available_to}
            showCompleted={showCompleted}
            clearStateSource={() => setStateSource({ source: "", contactId: "", companyId: "" })}
            completeUpdateState={completeUpdateState}
            onHandlePlanSalescallEdit={handleEdit}
          />
        </Portal>
      )}
      {showPlanPopup && profileState?.profile && (
        <Portal>
          <PlanMySalesCalls
            showModal={showPlanPopup}
            onClose={handlePlanClose}
            salesCallInfo={selectedCall}
            successTitle={t("plan_my_sales_calls")}
            currentLoginUserName={getUserNameFromProfileDropdown(
              profileState?.profile
            )}
            currentLoginUserId={profileState?.profile?.id}
            onSuccessClose={handleAddSalesCallSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoSalesCall}
            onCreateNew={handleCreateNew}
            usersList={usersList}
            availableFrom={salesFormState.available_from}
            availableTo={salesFormState.available_to}
            showCompleted={showCompleted}
            stateSource={stateSource}
            createState={createState}
            updateState={updateState}
          />
        </Portal>
      )}
    </div>
  );


}

export default SalesCallActivity;