export class ICreateDeductionAuthorityBatch {
    modified_by: string;
    created_by: string;
    created_date: number;
    modified_date: number;
    id: string;
    week_accounting_id: string;
    week_ending_date: number;
    check_date: number;
    bank_id: string;
    bank_name: string;
    bank_description: string;
    run_type_id: string;
    run_type: string;
    check_message: string;
    batch_number: string;
    batch_description: string;
    batch_type: string;
    make_all_checks_live: true;
    time_cards: Array<string>;
    deduction_authority_ids: Array<string>;
    starting_check_number: number;
    ending_check_number: number;
    check_sort_options: Array<string>;
    preview_check: true;
    status: string;
    transaction_count: number;
    check_count: number;
    generate_original_pay_units: number;
    date_ach_generated: number;
    date_printed: number;
    date_processed: number;
    check_reversal_deductions: Array<string>;
    migrated: false;
    migrated_id: string;
    data_migration_id: string
}

export class IDeductionAuthorityBatch {
    deduction_id: string;
    deduction_amount: number;
    deduction_name: string;
    week_ending_date: number;
}

export class IDeductionAuthority {
    total_pay: number;
    payroll_deductions: IDeductionAuthorityBatch[];
}

export class IPaycheckDeductionAuthority {
    modified_by: string | null;
    created_by: string;
    created_date: string | null;
    modified_date: string | null;
    id: string;
    week_accounting_id: string;
    payroll_batch_id: string;
    check_date: number;
    check_number: number;
    deduction_id: string;
    deduction_name: string;
    deduction_details: [
        {
            modified_by: string | null;
            created_by: string | null;
            created_date: string | null;
            modified_date: string | null;
            pay_check_id: string;
            talent_id: string;
            deduction_amount: number;
        }
    ];
    total_amount: number;
    is_deleted: boolean;
    migrated: boolean;
    migrated_id: string | null;
    data_migration_id: string | null
}