import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import {
  JOB_STATUS,
  LoadingType,
  ProfilePictureType,
  THEME,
} from "../../enums";
import {
  LocationIcon,
  PlusWhiteIcon,
  SuccessProfileIcon,
  CompaniesIcon,
  OrderTypeIcon,
  ContactPlacementIcon,
} from "../../icons";
import { IJobOrderDetail } from "../../interfaces";
import {
  getPreSignedURLForDownload,
  selectSingleProfilePictureStateById,
  useAppDispatch,
  useAppSelector,
} from "../../redux/store";
import { addressParser, CustomButton, getArrayStrings } from "../../utils";
import { FieldNav } from "../field_nav/field_nav";
import UploadProfile from "../image_crop/image_crop_popup";
import UserAvatar from "../user_avatar/user_avatar";
import "./job_details_section.scss";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../routes";

interface Props {
  branchId: string | null;
  onBranchChange: (value: string) => void;
  onActionClick: (value: string) => void;
  jobOrder: IJobOrderDetail | null;
  handleOnClickFiled: (value: string) => void;
}

const JobDetailsSection: React.FunctionComponent<Props> = (props) => {
  const { onActionClick, jobOrder, handleOnClickFiled } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const jobProfileSections = [
    {
      sectionTitle: "company_name",
      sectionIcon: CompaniesIcon,
      sectionContent: [
        {
          title: "company_name",
          valueField: renderText("company", "company_name"),
        },
        {
          title: "department_name",
          valueField: renderText("department", "department_name"),
        },
      ],
    },

    {
      sectionTitle: "address",
      sectionIcon: LocationIcon,
      sectionContent: [
        {
          title: "",
          valueField: addressParser({
            street_address: jobOrder ? jobOrder["street_address"] : null,
            city: jobOrder ? jobOrder["city"] : null,
            state: jobOrder ? jobOrder["state"] : null,
            zip_code: jobOrder ? jobOrder["zip_code"] : null,
          }),
        },
      ],
    },

    {
      sectionTitle: "placement_type",
      sectionIcon: OrderTypeIcon,
      sectionContent: [
        { title: "placement_type", valueField: "placement_type_value" },
      ],
    },
    {
      sectionTitle: "job_type",
      sectionIcon: OrderTypeIcon,
      sectionContent: [{ title: "job_type", valueField: "job_type" }],
    },
    {
      sectionTitle: "contact_for_placement",
      sectionIcon: ContactPlacementIcon,
      sectionContent: [
        {
          title: "contact_for_placement",
          valueField: renderText(
            "contact_for_placement",
            "contact_name_for_placement"
          ),
        },
        {
          title: "ordered_by",
          valueField: renderText("order_by_contact", "ordered_by_contact_name"),
        },
        {
          title: "report_to",
          valueField: renderText("report_to_contact", "report_to_contact_name"),
        },
      ],
    },
  ];

  function renderText(type: string, field: string) {
    const value =
      field !== "" && jobOrder![field] !== null ? (
        jobOrder![field]
      ) : field === "department_name" && jobOrder![field] === null ? (
        "Corporate"
      ) : (
        <span className="na-span">NA</span>
      );
    if (jobOrder == null) return <span>{""}</span>;

    return (
      <FieldNav isButton={true} onClick={() => handleOnClickFiled(type)}>
        <span>{value}</span>
      </FieldNav>
    );
  }

  function getValues(obj: any) {
    if (jobOrder == null) return <span>{""}</span>;

    if (Array.isArray(obj)) {
      return getArrayStrings(obj, jobOrder);
    }
    if (typeof obj === "object") {
      return obj;
    }
    if (obj === "job_order_source_value") {
      return jobOrder.job_order_source_value !== null &&
        jobOrder.job_order_source_value !== "" ? (
        <span>{jobOrder.job_order_source_value}</span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    if (obj === "job_type") {
      return jobOrder.job_type !== null && jobOrder.job_type !== "" ? (
        <span>
          {jobOrder?.job_type
            ?.replace(/_/g, " ")
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </span>
      ) : (
        <span className="na-span">NA</span>
      );
    }
    const value = obj !== "" && jobOrder![obj] != null ? jobOrder![obj] : "";
    return <span>{value}</span>;
  }
  function jobStatus(status: string) {
    if (status === JOB_STATUS.open)
      return <span style={{ color: THEME.red }}>{t("open")}</span>;
    else if (status === JOB_STATUS.filled)
      return <span style={{ color: THEME.buttonColor17 }}>{t("filled")}</span>;
    else if (status === JOB_STATUS.cancelledByClient)
      return <span style={{ color: "#B1B1B1" }}>{t("cancel_by_client")}</span>;
    else if (status === JOB_STATUS.mistakenOrder)
      return <span style={{ color: "#B1B1B1" }}>{t("mistaken_order")}</span>;
    else if (status === JOB_STATUS.closed)
      return <span style={{ color: "#B1B1B1" }}>{t("closed")}</span>;
    else return <span>{""}</span>;
  }

  function handleStatusClick() {
    history.push({
      pathname: `${AppRoutes.jobsUniversalDirectory}/${jobOrder?.id}`,
      state: {
        id: jobOrder?.id,
        status: jobOrder?.status,
      },
    });
    return;
  }

  return (
    <div className="job-container">
      <JobInfo
        name={jobOrder?.job_title}
        objectId={jobOrder?.id}
        uri={jobOrder?.logo}
        rating={jobOrder?.engagement_rating}
        level={jobOrder?.engagement_level}
      />
      <div className="job-branch-selector">
        <div className="job-status">
          <span>
            {`${t("status")}:`}{" "}
            <span
              className="status-txt"
              onClick={
                jobOrder?.status !== "" && jobOrder?.status !== null
                  ? handleStatusClick
                  : () => {}
              }>
              {jobOrder?.status ? jobStatus(jobOrder.status) : ""}
            </span>
          </span>
        </div>
      </div>
      {jobProfileSections.map((doc, index) => {
        return (
          <div key={index} className="job-info-container">
            <div className="job-info-icon">
              <doc.sectionIcon
                width={"100%"}
                height={"100%"}
                style={{ color: THEME.defaultHighLightColor }}
              />
            </div>
            <div className="job-info-content">
              {doc.sectionContent.map((data, index) => {
                const arrayValue = getValues(data.valueField);

                return (
                  <div key={index} className="job-info-holder">
                    <div className="job-into-title">
                      {doc.sectionTitle === "address" ||
                      doc.sectionTitle === "contact" ||
                      doc.sectionTitle === "email" ? (
                        <span style={{ display: "grid" }}>
                          {t(data.title).toUpperCase()}
                        </span>
                      ) : (
                        <span>{t(data.title).toUpperCase()}</span>
                      )}
                    </div>
                    <div
                      className="job-info-value"
                      style={{ marginBottom: data.lineTwo ? "0px" : "0.5em" }}>
                      {arrayValue}
                    </div>
                    {data.lineTwo && (
                      <div className="job-info-value">
                        {getValues(data.lineTwo)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <JobActions onClick={onActionClick} />
    </div>
  );
};

const JobInfo = (props) => {
  const { name, rating, level } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const profilePictureState = useAppSelector((state) =>
    selectSingleProfilePictureStateById(
      ProfilePictureType.jobs + "-" + props.objectId
    )(state)
  );

  useEffect(() => {
    setSentRequest(true);
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.jobs,
      })
    );
  }, []);

  function updateProfile() {
    dispatch(
      getPreSignedURLForDownload({
        object_id: props.objectId,
        object_type: ProfilePictureType.jobs,
      })
    );
    setSentRequest(true);
  }
  useEffect(() => {
    if (
      sentRequest &&
      profilePictureState.download.loading === LoadingType.succeeded
    ) {
      setSentRequest(false);
      setImageURL(profilePictureState.download.response ?? "");
    }
  }, [profilePictureState.download.loading]);

  return (
    <div className="job-user-info">
      <UserAvatar
        onClick={() => setShowImageUpload(true)}
        url={imageURL}
        rating={{ value: rating, level: level }}
      />
      <div className="job-title">
        <span style={{ textTransform: "uppercase" }}>{t("job_title")}</span>
      </div>
      <div className="job-user-name">
        <span>{name}</span>
      </div>
      {showImageUpload && (
        <Portal>
          <UploadProfile
            onClose={() => setShowImageUpload(false)}
            onDismiss={() => setShowImageUpload(false)}
            visible={showImageUpload}
            onApprove={updateProfile}
            objectId={props.objectId}
            objectType={ProfilePictureType.jobs}
          />
        </Portal>
      )}
    </div>
  );
};

const buttonsList = [
  {
    title: "assign_talent_to_job_order",
    icon: PlusWhiteIcon,
    type: "assign_talent_to_job_order",
    color: THEME.defaultHighLightColor,
  },
  {
    title: "access_success_profile",
    icon: SuccessProfileIcon,
    type: "access_success_profile",
    color: THEME.buttonColor17,
  },
  {
    title: "gross_profit_calculator",
    icon: SuccessProfileIcon,
    type: "gross_profit_calculator",
    color: THEME.buttonColor5,
  },
];

interface JobActionsProps {
  onClick: (type: string) => void;
}

const JobActions: React.FunctionComponent<JobActionsProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="job-action-container">
      {buttonsList.map((doc, index) => {
        return (
          <div key={index} className="job-action">
            <CustomButton
              customStyle={{ width: "-webkit-fill-available" }}
              leftIcon={
                <div className="job-action-icon">
                  <doc.icon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: "#fff" }}
                  />
                </div>
              }
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t(doc.title)}
              enable={true}
              backgroundColor={doc.color}
              onClick={() => props.onClick(doc.type)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default JobDetailsSection;
