import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { THEME } from '../../../../enums';
import { OnBoardingIcon } from '../../../../icons';
import { IOnboardingDocsReviewStatus } from '../../../../interfaces';
import { getDownloadWOTCUrlThunk, selectProfileState, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { CustomButton } from '../../../../utils';
import './wotc.scss';

interface Props {
    visible: boolean,
    type: string,
    title: string,
    // talentId: string,
    // state: IOnboardingDocsReviewStatus,
    status: string | undefined,
    cti_confirmation_number: string | undefined,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: (type: string, value?: any) => void,
}

const WOTCPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        // talentId,
        // state,
        status,
        cti_confirmation_number,
        title,
        onClose,
        onDissmiss,
        onSuccessClose,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    // useEffect(() => {
    //     dispatch(getDownloadWOTCUrlThunk({ talent_id: talentId }));
    //     return () => { }
    // }, [])

    function handleClose() {
        closePopup(onClose)
        // if(createState.loading === LoadingType.succeeded || updateState.loading === LoadingType.succeeded){
        //     closePopup(onSuccessClose);
        // }else {
        //     closePopup(onClose)
        // }
    }


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"30%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                <div className="wotc-container">
                    <div className="wotc-header">
                        <FormDialogTilteHeader
                            title={t('work_opportunity_tax_credit_program')}
                            subTitle={''}
                            subTitleStyle={{ fontWeight: 'normal' }}
                            titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="wotc-content">
                        <div className='status-div'>
                            <div>{t('status')}: <span style={{ color: (status?.toLocaleLowerCase() === "eligible" || status?.toLocaleLowerCase() === "qualified") ? THEME.statusActiveColor : "#FF7600", marginLeft:'0.3rem' }}>{status ? status : "N/A"}</span></div>
                            <div>{t('cti_confirmation_number')}: <span style={{ color: cti_confirmation_number ? THEME.statusActiveColor : "#FF7600", marginLeft:'0.3rem' }}>{cti_confirmation_number ? cti_confirmation_number : "N/A"}</span></div>
                        </div>
                    </div>
                    <div className="wotc-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-new">
                            <CustomButton
                                loading={false}
                                textStyle={{
                                    textTransform: "capitalize",
                                }}
                                name={t("ok")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {

    }

    /// handle submit
    function handleSave() {

    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default WOTCPopup;