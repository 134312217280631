import { useTranslation } from "react-i18next";
import { IWeekEndingDatesDropdown } from "../../../../interfaces";
import { useRef, useState, useEffect, ReactNode } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import CustomStepIndicator from "../../../../components/custom_step_indicator/custom_step_indicator";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { LoadingType, THEME } from "../../../../enums";
import { BOOpenCloseIcon } from "../../../../icons";
import { selectBankAccountList, selectBankAccountListState } from "../../../../redux/admin_center/back_office";
import { selectDeductionAuthorityBatchState, createDeductionAuthorityBatchThunk, deleteDeductionAuthorityBatchThunk, getDeductionAuthorityBatchThunk, getDeductionAuthorityPaychecksBatchThunk } from "../../../../redux/back_office";
import { PayrollManageBatchSliceActions } from "../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_reducer";
import { useAppDispatch, useAppSelector, selectProfileState } from "../../../../redux/store";
import { SpinnerScreen, FormInput, CustomButton } from "../../../../utils";
import { convertDateToTimeStamp } from "../../../../variables";
import { ICreateDeductionAuthorityBatch } from "../../../../interfaces/back_office_deduction_authority";
import { CreateDeductionPaymentsStepSix } from "./create_deduction_payments_step_six";
import { CreateDeductionPaymentsStepTwo } from "./create_deduction_payments_step_two";
import { CreateDeductionPaymentsStepOne } from "./create_deduction_payments_step_one";
import { deductionAuthorityManageBatchSliceActions } from "../../../../redux/back_office/payroll/manage_deduction_authority/deduction_authority_manage_batch_reducer";

interface Props {
  onClose: () => void;
  onSuccessClose: () => void;
  weekEndingDate: string;
  onSave: (payroll_batch_id: string) => void;
  weekEndingDateList: IWeekEndingDatesDropdown[];
}

export const CreateDeductionPaymentsBatchPopup = (props: Props) => {
  const { onClose, onSave, weekEndingDate, weekEndingDateList, onSuccessClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actionRef = useRef<ActionDialogHolderType>(null);
  const bankAccountList = useAppSelector((state) =>
    selectBankAccountList(state)
  );

  const profileState = useAppSelector((state) => selectProfileState(state));
  const bankAccountListState = useAppSelector((state) =>
    selectBankAccountListState(state)
  );

  const authorityDeductions = useAppSelector((state) =>
    selectDeductionAuthorityBatchState(state)
  );

  const getAuthDedctions = authorityDeductions.getDedAuth
  const getAuthDedctionsPaychecks = authorityDeductions.getDedAuthPayChecks
  const createAuthDedctions = authorityDeductions.dedAuthPost
  const updateAuthDedctions = authorityDeductions.dedAuthPatch
  const deleteAuthDedctions = authorityDeductions.dedAuthDelete

  const [batch, setBatch] = useState<ICreateDeductionAuthorityBatch>({
    ...new ICreateDeductionAuthorityBatch(),
    check_date: convertDateToTimeStamp(new Date()),
    week_accounting_id: weekEndingDate,
  });

  const [currentStep, setCurrentStep] = useState<{
    label: string;
    value: number;
  }>({ label: t("step_option", { value: 1 }), value: 1 });

  const [payChecks, setPayChecks] = useState<boolean>(false)
  const [proceed, setProceed] = useState<boolean>(false)

  useEffect(() => {
    if (batch.week_accounting_id) {
      dispatch(getDeductionAuthorityBatchThunk(batch.week_accounting_id));
    }
  }, [batch.week_accounting_id]);

  useEffect(() => {
    if (createAuthDedctions.loading === LoadingType.succeeded) {
      setCurrentStep(stepsList[2]);
      if (createAuthDedctions.response) {
        setBatch(createAuthDedctions.response);
      }
    }
  }, [createAuthDedctions.loading]);

  const stepsList: Array<{ label: string; value: number }> = Array.from(
    Array(payChecks ? (proceed ? 9 : 8) : (proceed ? 8 : 7)).keys()
  ).map((_doc, index) => ({
    label: t("step_option", { value: index + 1 }),
    value: index + 1,
  }));

  const getSubtitle = () => {
    switch (currentStep.value) {
      case 1:
        return `Hey ${profileState?.profile?.first_name}, let's process your Payment(s). Verify and complete the information below and click Next.`;
      case 2:
        return `Hey ${profileState?.profile?.first_name}, select the checks you would like to process and click Next.`;
      case 5:
        return `Hey ${profileState?.profile?.first_name}, you are almost finished. Verify and complete the information below and click Next.`;
      default:
        return "";
    }
  };

  const getTitle = () => {
    switch (currentStep.value) {
      case 1:
        return "Create Deduction Payment(s) Batch";
      case 2:
        return "Select Deduction Payment(s)";
      case 3:
      case 4:
        return "Process Payment(s) Transactions";
      default:
        return "Post Payment(s)";
    }
  };

  const handleNextStep = () => {
    if (!createAuthDedctions?.error && currentStep.value === 2) {
      dispatch(createDeductionAuthorityBatchThunk(batch));
      return;
    }
    if (!payChecks && currentStep.value === 6) {
      onSave(batch.id);
      if (updateAuthDedctions.loading === LoadingType.succeeded) {
        setCurrentStep(stepsList[currentStep.value]);
        return;

      }
    }
    if (!payChecks && proceed && currentStep.value === 7) {
      dispatch(deleteDeductionAuthorityBatchThunk(batch.id))
    }
    if (payChecks && currentStep.value === 7) {
      onSave(batch.id);
      if (updateAuthDedctions.loading === LoadingType.succeeded) {
        setCurrentStep(stepsList[currentStep.value]);
        return;

      }
    }
    if (currentStep.value === 7) {
      if (payChecks) {
        setCurrentStep(stepsList[currentStep.value]);
      } else if (proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      } else {
        onSuccessClose();
      }
    } else {
      setCurrentStep(stepsList[currentStep.value]);
    }
    if (payChecks && proceed && currentStep.value === 8) {
      dispatch(deleteDeductionAuthorityBatchThunk(batch.id))
    }
    if (currentStep.value === 8) {
      if (payChecks && !proceed) {
        onSuccessClose();
      } else if (!payChecks && proceed) {
        onSuccessClose();
      } else if (payChecks && proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      } else if (!payChecks && !proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      }
    }
    if (payChecks && proceed && currentStep.value === 9) {
      onSuccessClose();
    }
  };

  const handleBack = () => {
    if (currentStep.value === 1) {
      onClose();
    }
    if (!payChecks && proceed && currentStep.value === 7 || payChecks && proceed && currentStep.value === 8) {
      setCurrentStep(stepsList[currentStep.value]);
      setProceed(false)
    }
    if (!payChecks && currentStep.value === 6 || payChecks && currentStep.value === 7) {
      setCurrentStep(stepsList[currentStep.value]);
      setProceed(true)
    }
    else {
      setCurrentStep(stepsList[currentStep.value - 2]);
    }
  };

  const handleToggleChange = (val) => {
    setPayChecks(val)
  }

  const updateBatchProp = (key: string, value: any) => {
    const newBatch = { ...batch };
    newBatch[key] = value;
    setBatch(newBatch);
  };

  const handleDeductionsSelection = (id?: string) => {
    let deductionsSet = new Set(batch.deduction_authority_ids);
    if (id) {
      if (deductionsSet.has(id)) {
        deductionsSet.delete(id);
      } else {
        deductionsSet.add(id);
      }
    } else {
      if (deductionsSet.size === getAuthDedctions.response?.payroll_deductions.length) {
        deductionsSet = new Set();
      } else {
        deductionsSet = new Set(getAuthDedctions.response?.payroll_deductions.map((x: any) => x.deduction_id));
      }
    }
    updateBatchProp("deduction_authority_ids", Array.from(deductionsSet));
  };
  const getCurrentStepView = (): ReactNode => {
    if (bankAccountListState.loading === LoadingType.pending) {
      return <SpinnerScreen></SpinnerScreen>;
    }

    switch (currentStep.value) {
      case 1:
        return (
          <CreateDeductionPaymentsStepOne
            batch={batch}
            bankAccountList={bankAccountList}
            weekEndingDatesList={weekEndingDateList}
            selectedWeekEndingDate={weekEndingDate}
            updateBatchProp={updateBatchProp}
          />
        );
      case 2:
        return (
          <CreateDeductionPaymentsStepTwo
            deductions={getAuthDedctions?.response!}
            handleSelection={handleDeductionsSelection}
            selectedCards={new Set(batch.deduction_authority_ids)}
          />
        );
      case 3:
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>{`Well done ${profileState.profile?.first_name}.`}</span>
            <span>{`There are no errors or warnings.`}</span>
            <span>{`Click Next to process your checks.`}</span>
          </DocSavedText>
        );
      case 4:
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>{`Payment(s) Processing is complete.`}</span>
            <span>{`Total checks Processed: ${batch.check_count}`}</span>
            <span>{`Click Next to process your checks.`}</span>
          </DocSavedText>
        );
      case 5: {
        return (
          <div className="pptp-content">
            <div style={{ width: "50%" }}>
              <FormInput
                id={"starting_check_number"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.starting_check_number}
                label={t("starting_check_number")}
              />
              <FormInput
                id={"ending_check_number"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.ending_check_number}
                label={t("ending_check_number")}
              />
              <FormInput
                id={"bank_name"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.bank_name}
                label={t("checking_account")}
              />
            </div>
            <div className="pptp-toggle">
              <ToggleSwitch
                label={t("preview_check_prior_to_processing")}
                checked={payChecks}
                onChange={(val) => handleToggleChange(val)}
              />
            </div>
          </div>
        );
      }
      case 6: {
        if (payChecks) {
          return (
            <div className="pc-directory">
              <CreateDeductionPaymentsStepSix
                batchId={batch.id ?? "batchId"}
                weekAccountingId={batch.week_accounting_id}
                authDedctionsPaychecks={getAuthDedctionsPaychecks}
              />
            </div>
          );
        }
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>Click Yes and this payment(s) batch will be posted</span>
          </DocSavedText>
        );
      }
      case 7: {
        if (payChecks) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Did all payment(s) process correctly?</span>
              <span>If so, click Yes and this Payment(s) Batch will be posted.</span>
            </DocSavedText>
          );
        }
        if (proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>This payment(s) batch will be deleted, please confirm proceed</span>
            </DocSavedText>
          );
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payment(s) has been posted.</span>
            </DocSavedText>
          );
        }
      }
      case 8: {
        if (payChecks && proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>This Payment(s) batch will be deleted, please confirm proceed</span>
            </DocSavedText>
          );
        }
        if (!payChecks && proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Payment(s) batch deleted successfully</span>
            </DocSavedText>
          )
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payment(s) has been posted.</span>
            </DocSavedText>
          );
        }
      }
      case 9: {
        if (proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Payment(s) batch deleted successfully</span>
            </DocSavedText>
          )
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payment(s) has been posted.</span>
            </DocSavedText>
          );
        }
      }
    }
  };

  const getBackBtnText = () => {
    const { value } = currentStep;
    const isStep6 = value === 6;
    const isStep7 = value === 7;
    const isStep8 = value === 8;
    const isStep9 = value === 9;

    if (value === 1) {
      return t("cancel");
    }

    if (isStep6) {
      return payChecks ? t("back") : t("no");
    }

    if (isStep7) {
      if (payChecks) {
        return t("no");
      }
      return proceed ? t("cancel") : null;
    }

    if (isStep8) {
      if (payChecks) {
        return proceed ? t("cancel") : null;
      }
      return proceed ? null : t("no");
    }

    if (isStep9 && payChecks && proceed) {
      return null;
    }

    // Default case
    return t("back");

  };
  const getNextbtnText = () => {
    const { value } = currentStep;
    const isStep6 = value === 6;
    const isStep7 = value === 7;
    const isStep8 = value === 8;
    const isStep9 = value === 9;

    if (isStep6) {
      return !payChecks ? t("yes") : t("next");
    }

    if (isStep7) {
      if (payChecks) {
        return t("yes");
      }
      return proceed ? t("Proceed") : t("ok");
    }

    if (isStep8) {
      if (payChecks) {
        return proceed ? t("Proceed") : t("ok");
      }
      return t("ok");
    }

    if (isStep9 && payChecks && proceed) {
      return t("ok");
    }

    return t("next");

  };
  function handleNextButtonEnable() {
    if (currentStep.value === 1 && getAuthDedctions.error) return false;
    if (currentStep.value === 2 && !(getAuthDedctions.response?.payroll_deductions?.length! > 0 && batch?.deduction_authority_ids?.length! > 0)) return false;
    return true;
}

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={"50%"}
      maxWrapperWidth={"50%"}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
        <div className="create-payroll-batch-container">
          <div className="create-payroll-batch-header">
            <FormDialogTilteHeader
              title={getTitle()}
              subTitle={getSubtitle()}
              titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <CustomStepIndicator
            onClick={(val) => setCurrentStep(stepsList[val.value])}
            selected={currentStep}
            stepsList={stepsList}
          ></CustomStepIndicator>
          <div className="create-payroll-batch-content">
            {getCurrentStepView()}
          </div>
          <div className="create-payroll-batch-actions">

            <div className="oc-error-holder">
              {currentStep.value === 2 && createAuthDedctions.error &&
                <ApiError message={createAuthDedctions.error.message} onClose={() => dispatch(deductionAuthorityManageBatchSliceActions.clearPostBatchErrorState())} />
              }
              {currentStep.value === 2 && createAuthDedctions.loading === LoadingType.pending &&
                <span className="alert-message">{t("please_wait_we_are_processing_your_payments")}</span>
              }
              {currentStep.value === 6 && updateAuthDedctions.error &&
                <ApiError message={updateAuthDedctions.error.message} onClose={() => dispatch(deductionAuthorityManageBatchSliceActions.clearPatchBatchErrorState())} />
              }
              {currentStep.value === 1 && getAuthDedctions.error &&
                <ApiError message={getAuthDedctions.error.message} onClose={() => dispatch(deductionAuthorityManageBatchSliceActions.clearPatchBatchErrorState())} />
              }
            </div>
            <div className="actions-row">
              <div className="btn-cancel" style={{ marginRight: "1em" }}>
                {getBackBtnText() && (
                  <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={getBackBtnText()}
                    enable={currentStep.value === 3 ? (createAuthDedctions.loading === LoadingType.succeeded ? false : true) : true}
                    backgroundColor={THEME.toggleDisableColor}
                    onClick={() => handleBack()}
                  />
                )}
              </div>
              <div className="btn-save">
                <CustomButton
                  loading={createAuthDedctions.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={getNextbtnText()}
                  enable={handleNextButtonEnable()}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => handleNextStep()}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};
