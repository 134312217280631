import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { LoadingType } from "../../../../enums";
import { getDeductionAuthorityPaychecksBatchThunk } from "../../../../redux/back_office";
import { useAppDispatch } from "../../../../redux/store";
import { SpinnerScreen, currencyConversion } from "../../../../utils";
import { getDateString } from "../../../../variables";
import { IPaycheckDeductionAuthority } from "../../../../interfaces/back_office_deduction_authority";
import { AppError } from "../../../../interfaces";

const tableHeader = [
    { title: "deduction_name", code: "deduction_name" },
    { title: "check_number", code: "check_number" },
    { title: "check_date", code: "check_date" },
    { title: "total_amount", code: "total_amount" },
];

interface Props {
    batchId: string;
    weekAccountingId: string;
    authDedctionsPaychecks: {
        error?: AppError | null;
        loading: LoadingType;
        response: IPaycheckDeductionAuthority[] | null;
    }
}

export const CreateDeductionPaymentsStepSix = (props: Props) => {
    const { authDedctionsPaychecks, batchId, weekAccountingId } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(getDeductionAuthorityPaychecksBatchThunk({ week_accounting_id: weekAccountingId, batch_id: batchId }));
    }, []);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function getFilteredList() {
        // Ensure list is initialized as an empty array if deductions or payroll_deductions is invalid
        let list: IPaycheckDeductionAuthority[] = [];

        // Check if deductions and payroll_deductions are valid arrays
        if (authDedctionsPaychecks && Array.isArray(authDedctionsPaychecks.response)) {
            list = [...authDedctionsPaychecks.response]; // Create a shallow copy of the payroll_deductions array
        }

        if (authDedctionsPaychecks && authDedctionsPaychecks.response && sortedField) {
            // Sort the list if sortedField is provided
            list = list.sort((a, b) => {
                const valueA = a[sortedField] != null
                    ? typeof a[sortedField] === 'string'
                        ? a[sortedField].trim().toLowerCase()
                        : a[sortedField]
                    : "";
                const valueB = b[sortedField] != null
                    ? typeof b[sortedField] === 'string'
                        ? b[sortedField].trim().toLowerCase()
                        : b[sortedField]
                    : "";

                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
                } else {
                    return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
                }
            });
        }

        // Ensure the list is a valid array before calling filter
        return Array.isArray(list) ? list.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        }) : []; // Return an empty array if list is not an array
    }

    return (
        <div className="payroll-paycheck-container form-step6-container" style={{ boxShadow: "none" }}>
            <div className="payroll-paycheck-header">
                <div className="select-row">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}{" "}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div>
                    <SearchBar value={search} onChange={setSearch} onSearch={() => { }} />
                </div>
            </div>
            <div className="payroll-paycheck-content">
                <div className="payroll-paycheck-table">
                    {authDedctionsPaychecks.response &&
                        authDedctionsPaychecks.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ?
                                getFilteredList()?.map((doc, index) => {
                                    return (
                                        <tr key={doc.id}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <span>{doc.deduction_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                <span>{doc.check_number}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{getDateString(doc.check_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{currencyConversion(doc.total_amount, 2)}</span>
                                            </TableData>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(14) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                </tr>
                            }
                        </SortableTable>
                    )}
                </div>
            </div>
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 4) return 1.1;
        if (value === 10) return 1.6;
        return 1;
    }
}