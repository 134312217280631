import { createAsyncThunk } from "@reduxjs/toolkit";
import { SalesMarketingAPI } from "../../../../apis/sales_marketing_api";
import { AppError, ICreateResponse } from "../../../../interfaces";
import { SalesCall, SalesCallsDataParam } from "../../../../interfaces/sales_call";
import { catchRequestError } from "../../../../utils";
import { getSalesCallFilteredList, getSalesCallList } from "../../my_call_deck/sales_call_list/sales_call_list_thunk";


/// Create user
export const createSalesCall = createAsyncThunk<
    ICreateResponse,
    SalesCall,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-marketing/sales-call-activity/sales-call/create',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.createSalesCall(payload)
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Update user
export const updateSalesCall = createAsyncThunk<
    ICreateResponse,
    { data: SalesCall, salesCallsShowCompleted: boolean, salesCallsAvailableFrom: number, salesCallsAvailableTo: number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-marketing/sales-call-activity/sales-call/update',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.updateSalesCall(payload.data);
            thunkAPI.dispatch(getSalesCallFilteredList({ my_upcoming_calls: !payload.salesCallsShowCompleted, start_date: payload.salesCallsAvailableFrom, end_date: payload.salesCallsAvailableTo }));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

/// Complete Call Update user
export const completeUpdateSalesCall = createAsyncThunk<
    ICreateResponse,
    { data: SalesCall, salesCallsShowCompleted: boolean, salesCallsAvailableFrom: number, salesCallsAvailableTo: number },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@sales-marketing/sales-call-activity/sales-call/complete/update',
    async (payload, thunkAPI) => {
        try {
            const res = await SalesMarketingAPI.updateSalesCall(payload.data);
            thunkAPI.dispatch(getSalesCallFilteredList({ my_upcoming_calls: !payload.salesCallsShowCompleted, start_date: payload.salesCallsAvailableFrom, end_date: payload.salesCallsAvailableTo }));
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);