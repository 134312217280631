import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TalentAPI } from "../../../../apis";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import RenderInput from "../../../../components/render_input/render_input";
import {
  CONTACT_METHODS,
  LoadingType,
  TALENT_STATUS,
  THEME
} from "../../../../enums";
import { LinkIcon, TalentIcon } from "../../../../icons";
import {
  AppError,
  FormInputOption,
  IBusinessSector,
  ICompanyCertification,
  ICompanyCredential,
  ICompanyEducationRequirement,
  ICompanyRequiredEquipment,
  ICompanySkillSet,
  ICompetitor,
  ICreateTalent,
  ITalent,
  Profile,
  TalentStatus
} from "../../../../interfaces";
import { postSnackbarMessage, useAppDispatch } from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  convertNumberToMoney,
  CustomButton,
  enforceFormat,
  formatToEIN,
  formatToMoney,
  formatToPhone,
  formatToSSN,
  FormInput,
  getUserNameFromProfileDropdown,
  RegxPattern
} from "../../../../utils";
import { US_states } from "../../../../utils/States_US";
import {
  contactMethodOptions,
  lowBtnStyle,
  outlineBtnStyle,
  // statusOptions
} from "../../../../variables";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "./talent_form.scss";

interface Props {

  isTalent?: boolean;

  title: string;
  successTitle: string;
  currentLoginUserName?: string;
  loading: LoadingType;
  error: AppError | null | undefined;
  talent?: ITalent;
  accountManagers: Array<Profile>;
  recruiters: Array<Profile>;
  businessSectors: Array<IBusinessSector>;
  competitors: Array<ICompetitor>;

  skillSetList: Array<ICompanySkillSet>;
  credentialsList: Array<ICompanyCredential>;
  certificationsList: Array<ICompanyCertification>;
  educationList: Array<ICompanyEducationRequirement>;
  requiredEquipmentList: Array<ICompanyRequiredEquipment>;

  showSSN?: boolean | undefined;

  onClose: () => void;
  onSubmit: (doc: { forceAdd: boolean; value: ICreateTalent }) => void;
  onClearError: () => void;
  /// photo
  selectedPhoto: File | null;
  onPhotoChange: (value: File) => void;
  onRemovePhoto: () => void;
  /// resume
  selectedResume: File | null;
  onResumeChange: (value: File) => void;
  onRemoveResume: () => void;

  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (name: string) => void;
  talentStatus?: Array<TalentStatus>;
}

const getTalentFormData = (value: ITalent): ICreateTalent => {
  return {
    first_name: value.first_name ?? "",
    middle_name: value.middle_name ?? "",
    last_name: value.last_name ?? "",
    title: value.title ?? "",
    street_address: value.street_address ?? "",
    city: value.city ?? "",
    state: value.state ?? "",
    zip_code: value.zip_code ?? "",
    phone: value.phone ?? "",
    ssn: value.ssn ? `XXX-XX-${value.ssn}` : "",
    last_4_digits_of_ssn: value.last_4_digits_of_ssn ?? "",
    text: value.text ?? "",
    website: value.website ?? "",
    email: value.email ?? "",
    email_2: value.email_2 ?? "",
    contact_photo: value.contact_photo ?? "",
    agency_id: value.agency_id ?? "",
    branch_id: value.branch_id ?? "",
    preferred_contact_method:
      value.preferred_contact_method ?? CONTACT_METHODS.email,
    status: "",
    status_id: value.status_id ?? "",
    engagement_rating: value.engagement_rating ?? "",
    social_media_links: value.social_media_links ?? {
      linkedin_url: null,
      instagram_url: null,
      facebook_url: null,
      youtube_url: null,
    },
    professional_skills: value.professional_skills ?? [],
    certifications: value.certifications ?? [],
    education: value.education ?? [],
    credentials: value.credentials ?? [],
    safety_equipment: value.safety_equipment ?? [],
    staffing_agency_competitors: value.staffing_agency_competitors ?? [],
    recruiter_id: value.recruiter_id ?? "",
    business_sector_id: value.business_sector_id ?? "",
    worked_for_other_staffing_agency:
      value.worked_for_other_staffing_agency ?? false,
    pay_rate: value.pay_rate ?? 0,
  };
};
const initialForm: ICreateTalent = {
  first_name: "",
  middle_name: "",
  last_name: "",
  title: "",
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  phone: "",
  ssn: "",
  last_4_digits_of_ssn: "",
  text: "",
  website: "",
  email: "",
  email_2: "",
  contact_photo: "",
  preferred_contact_method: CONTACT_METHODS.email,
  // status: TALENT_STATUS.active,
  status: "",
  status_id: "",
  social_media_links: {
    linkedin_url: null,
    youtube_url: null,
    facebook_url: null,
    instagram_url: null,
  },
  professional_skills: [],
  certifications: [],
  education: [],
  credentials: [],
  safety_equipment: [],
  staffing_agency_competitors: [],
  recruiter_id: "",
  worked_for_other_staffing_agency: false,
  pay_rate: 0,
  business_sector_id: "",
};

const TalentForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<ICreateTalent>(
    props.talent ? getTalentFormData(props.talent) : initialForm
  );


  const isApolloTalent = props.talent?.imported_from_apollo;
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await TalentAPI.deleteTalent(props.talent?.id ?? "");
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.talentPage);
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  function handleFieldChange(fieldId: string, value: any, parent?: string) {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }

  const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
    /// 1st row
    [
      {
        field: "first_name",
        type: "text",
        label: "first_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        className: "width-cls",
      },
      {
        field: "middle_name",
        type: "text",
        label: "middle_name",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "last_name",
        type: "text",
        label: "last_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "status_id",
        type: "selector",
        label: "status",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        // selectorList: statusOptions,
        selectorList: props?.talentStatus?.map((doc) => ({
          label: doc.talent_status,
          value: doc.id,
        })),
        multi: false,
      },
      {
        field: "title",
        type: "text",
        label: "job_title",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],


    /// 2nd row

    [
      {
        field: "street_address",
        type: "text",
        label: "street_address",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "city",
        type: "text",
        label: "city",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "state",
        type: "selector",
        label: "state",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zip_code",
        type: "text",
        label: "zip_code",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        maxLength: 5,
      },
      {
        field: props.showSSN ? "ssn" : "last_4_digits_of_ssn",
        type: "text",
        label: props.showSSN ? "SSN" : "SSN - Last 4 Digits",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        maxLength: 11,
        disabled: props.showSSN ? false : true,
        onKeyUp: formatToSSN,
      },
    ],


    /// 3rd row
    [
      {
        field: "school_district",
        type: "selector",
        label: "school_district",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: [],
        multi: false,
      },
      {
        field: "geo_code",
        type: "selector",
        label: "geo_code",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: [],
        multi: false,
      },
      {
        field: "phone",
        type: "tel",
        label: "phone",
        secure: false,
        required: !isApolloTalent,
        placeholder: "(555) 555 - 5555",
        keyboardtype: "",
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "email",
        type: "email",
        label: "personal_email",
        pattern: RegxPattern.email,
        secure: false,
        required: isApolloTalent ? false : formState.preferred_contact_method === CONTACT_METHODS.email,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "email_2",
        type: "email",
        label: "work_email",
        pattern: RegxPattern.email,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
    [
      {
        field: "preferred_contact_method",
        type: "selector",
        label: "preferred_contact",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: contactMethodOptions,
        multi: false,
        isClearable: false,
      },
      {
        field: "linkedin_url",
        parentField: "social_media_links",
        type: "text",
        label: "linked_in",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "facebook_url",
        parentField: "social_media_links",
        type: "text",
        label: "facebook",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "youtube_url",
        parentField: "social_media_links",
        type: "text",
        label: "youtube",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "instagram_url",
        parentField: "social_media_links",
        type: "text",
        label: "instagram",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
  ];

  const formProfessionalInformation: (FormInputOption | FormInputOption[])[] = [
    [
      {
        field: "professional_skills",
        type: "selector",
        label: "professional_skills",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.skillSetList.map((doc) => ({
          label: doc.skill_set,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "certifications",
        type: "selector",
        label: "certifications",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.certificationsList.map((doc) => ({
          label: doc.certification,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "education",
        type: "selector",
        label: "education",
        // pattern: '',
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.educationList.map((doc) => ({
          label: doc.education_requirement,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "credentials",
        type: "selector",
        label: "credentials",
        // pattern: '',
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.credentialsList.map((doc) => ({
          label: doc.credential,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "business_sector_id",
        type: "selector",
        label: "sector",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.businessSectors.map((doc) => ({
          label: doc.business_sector,
          value: doc.id,
        })),
        multi: false,
      },
    ],
  ];

  if (props.loading === LoadingType.succeeded) {
    return (
      <div className="tlnt-f-container">
        <div className="tlnt-f-header">
          <FormDialogTilteHeader
            title={props.successTitle}
            titleIcon={<TalentIcon width={"100%"} height={"100%"} />}
          />
        </div>
        <div className="tlnt-f-content">
          <div className="saved-doc-holder">
            <DocSavedText>
              <span className="aknw-name">
                {t("well_done_on_creating_new", {
                  field: t("talent"),
                  name: props.currentLoginUserName,
                })}
              </span>
              <span className="info-txt">{`${t("a_new_created", {
                name: t("talent"),
              })}:`}</span>
              <span className="name">{`${formState.first_name ?? ""} ${formState.last_name ?? ""
                }`}</span>
            </DocSavedText>
          </div>
        </div>
        <div className="tlnt-f-actions">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
              name={t("go_to_my_agency_dashboard")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onGotoAgencyDashboard}
              customStyle={lowBtnStyle.btn}
            />
          </div>
          <div className="btn-new">
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
                ...outlineBtnStyle.text,
              }}
              name={t("create_new", { name: t("talent") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setFormState(initialForm);
                props.onCreateNew();
              }}
              customStyle={outlineBtnStyle.btn}
            />
          </div>

          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("go_to_this_newly_created", { name: t("talent") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() =>
                props.onGotoCreated(
                  `${formState.first_name ?? ""} ${formState.last_name ?? ""}`
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };

  return (
    <div className="tlnt-f-container">
      <div className="tlnt-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={<TalentIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="tlnt-f-content">
        {getSectionOne()}
        {getSectionTwo()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="tlnt-f-actions">
        {props.isTalent ? null : props.talent && (
          <div style={{ marginRight: "auto" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("delete_field", { name: t("talent") })}
              enable={true}
              backgroundColor={THEME.statusInActiveColor}
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        )}
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onClose}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            // enable={_isFormValid()}
            enable={true}
            backgroundColor={
              props.error != null && props.error?.code === 409
                ? THEME.secondaryColor6
                : THEME.defaultHighLightColor
            }
            onClick={() => {
              setCheckValidationOnSubmit(true);
              if (_isFormValid()) {
                props.onSubmit({
                  forceAdd:
                    props.error != null && props.error?.code === 409
                      ? true
                      : false,
                  value: {
                    ...formState,
                    first_name: formState?.first_name.trim(),
                    last_name: formState?.last_name.trim(),
                    middle_name: formState?.middle_name.trim(),
                    pay_rate: convertNumberToMoney(formState.pay_rate, "number") as number,
                    staffing_agency_competitors:
                      formState.worked_for_other_staffing_agency
                        ? formState.staffing_agency_competitors
                        : [],
                  },
                })
                setCheckValidationOnSubmit(false);
              }
            }
            }
            textStyle={{ textTransform: "capitalize" }}
            name={
              props.error != null && props.error?.code === 409
                ? t("force_add")
                : props.talent
                  ? t("update_field", { name: t("talent") })
                  : t("add_field", { name: t("talent") })
            }
          />
        </div>
      </div>
      {showDeleteDialog && props.talent && (
        <DeleteConfirmationDialog
          message={t("delete_talent_message", {
            talent: props.talent?.first_name + " " + props.talent?.last_name,
          })}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          deleting={deleting}
          deleteType={t("talent")}
          onClearError={()=>{}}
        />
      )}
      <Snackbar
        open={snackBarMessage !== null}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={snackbarAction()}
      />
    </div>
  );

  /// Section one
  function getSectionOne() {
    return (
      <div className="tlnt-f-section-divider">
        <div className="tlnt-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("basic_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="tlnt-f-row ">
                {doc.map((subDoc, subIndex) => {
                  if (props.isTalent && subDoc.field === "status_id") {
                    return ""
                  }
                  if (props.isTalent && subDoc.field === "title") {
                    return ""
                  }
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={formValidators}
                      className={`tlnt-f-input-holder ${subDoc.className}`}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={formValidators}
              className={"tlnt-f-input-holder"}
              checkValidationOnSubmit={checkValidationOnSubmit}
            />
          );
        })}
      </div>
    );
  }

  function getSectionTwo() {
    return (
      <div className="tlnt-f-section-divider">
        <div className="tlnt-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("professional_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {
          props.isTalent ? null :
            <div>
              {formProfessionalInformation.map((doc, index) => {
                if (Array.isArray(doc)) {
                  return (
                    <div key={index + "row"} className="tlnt-f-row ">
                      {doc.map((subDoc, subIndex) => {

                        return (
                          <RenderInput
                            key={index + subIndex}
                            doc={subDoc}
                            index={subIndex}
                            formState={formState}
                            handleFieldChange={handleFieldChange}
                            formValidators={formValidators}
                            className={"tlnt-f-input-holder"}
                          />
                        );
                      })}
                    </div>
                  );
                }


                return (
                  <RenderInput
                    key={index}
                    doc={doc}
                    index={index}
                    formState={formState}
                    handleFieldChange={handleFieldChange}
                    formValidators={formValidators}
                    className={"tlnt-f-input-holder"}
                  />
                );
              })}
            </div>
        }


        <div className="tlnt-f-row ">
          {
            props.isTalent ? null : <div className={"tlnt-f-input-holder"}>
              <RenderInput
                doc={{
                  field: "safety_equipment",
                  type: "selector",
                  label: "safety_equipment",
                  secure: false,
                  required: false,
                  placeholder: "select",
                  keyboardtype: "",
                  selectorList: props.requiredEquipmentList.map((doc) => ({
                    label: doc.required_equipment,
                    value: doc.id,
                  })),
                  multi: true,
                }}
                formState={formState}
                handleFieldChange={handleFieldChange}
                formValidators={formValidators}
                className={"tlnt-f-input-holder"}
                customStyle={{ width: "-webkit-fill-available" }}
              />
            </div>

          }
          {
            props.isTalent ? null :
              <div className={"tlnt-f-input-holder"}>
                <DragAndDrop
                  type={"small"}
                  label={t("talent_photo")}
                  placeholder={t("upload_or_drag_a_photo")}
                  selectedFile={props.selectedPhoto}
                  accept={"image/*"}
                  onChange={props.onPhotoChange}
                  onRemove={props.onRemovePhoto}
                />
              </div>
          }
          <div className={"tlnt-f-input-holder"}>
            <DragAndDrop
              type={"small"}
              label={t("resume")}
              placeholder={t("upload_or_drag_a_photo")}
              selectedFile={props.selectedResume}
              accept={
                ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              onChange={props.onResumeChange}
              onRemove={props.onRemoveResume}
            />
          </div>
          <div className={"tlnt-f-input-holder"}>
            {/* <RenderInput
              doc={{
                field: "pay_rate",
                type: "number",
                label: "minimum_payrate",
                secure: false,
                required: false,
                placeholder: "",
                keyboardtype: "",
              }}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={formValidators}
              className={"tlnt-f-input-holder"}
              customStyle={{ width: "-webkit-fill-available" }}
            /> */}
            <FormInput
              id={"pay_rate"}
              onChange={handleFieldChange}
              type={"text"}
              value={convertNumberToMoney(formState.pay_rate, "string") as string}
              label={t("minimum_payrate")}
              placeholder=""
              prefix={<span>$</span>}
              step="0.01"
              min={"0.00"}
              onBlur={formatToMoney}
              onKeyDown={formatToMoney}
              onKeyUp={formatToMoney}
              customStyle={{ minWidth: "4vw" }}
            />
          </div>
          {
            props.isTalent ? null : <div className={"tlnt-f-input-holder"}>
              <RenderInput
                doc={{
                  field: "recruiter_id",
                  type: "selector",
                  label: "recruiter",
                  secure: false,
                  required: false,
                  placeholder: "select",
                  keyboardtype: "",
                  selectorList: props.recruiters.map((doc) => ({
                    label: getUserNameFromProfileDropdown(doc),
                    value: doc.id,
                  })),
                  multi: false,
                }}
                formState={formState}
                handleFieldChange={handleFieldChange}
                formValidators={formValidators}
                className={"tlnt-f-input-holder"}
                customStyle={{ width: "-webkit-fill-available" }}
              />
            </div>
          }
        </div>
        {
          props.isTalent ? null :
            <div className="tlnt-f-row ">
              <RenderInput
                doc={{
                  field: "worked_for_other_staffing_agency",
                  type: "switch",
                  label: "has_talent_ever_worked_for_a_staffing_agency",
                  secure: false,
                  required: false,
                  placeholder: "",
                  keyboardtype: "",
                  labelPosition: "top",
                }}
                formState={formState}
                handleFieldChange={handleFieldChange}
                formValidators={formValidators}
                className={"tlnt-f-input-holder"}
                customStyle={{ flex: 1 }}
              />
              {formState.worked_for_other_staffing_agency && (
                <RenderInput
                  doc={{
                    field: "staffing_agency_competitors",
                    type: "selector",
                    label: "if_yes_which_agency_has_this_person_worked_for",
                    secure: false,
                    required: false,
                    placeholder: "select",
                    keyboardtype: "",
                    selectorList: props.competitors.map((doc) => ({
                      label: doc.competitor_name,
                      value: doc.id,
                    })),
                    multi: true,
                  }}
                  formState={formState}
                  handleFieldChange={handleFieldChange}
                  formValidators={formValidators}
                  className={"tlnt-f-input-holder"}
                  customStyle={{ flex: 2 }}
                />
              )}
            </div>
        }
      </div>
    );
  }

  function getLinkIcon() {
    return (
      <div className="tlnt-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }


  function _isFormValid() {
    const {
      first_name,
      last_name,
      phone,
      zip_code,
      email,
      preferred_contact_method,
      social_media_links,
    } = formState;
    const { facebook_url, instagram_url, youtube_url, linkedin_url } =
      social_media_links;

    if (first_name === "" || preferred_contact_method === "" || last_name === "")
      // return false;
      return;
    if (!isApolloTalent && phone === "")
      // return false;
      return;
    if (RegxPattern.username.test(first_name) === false) return false;
    if (zip_code !== "" && RegxPattern.zipCode.test(zip_code) === false)
      return false;
    // if (!isApolloTalent && RegxPattern.phone.test(phone) === false) return false;
    if (!isApolloTalent && RegxPattern.phone.test(phone) === false) return;
    if (
      !isApolloTalent && preferred_contact_method === CONTACT_METHODS.email &&
      RegxPattern.email.test(email) === false
    )
      // return false;
      return;
    if (
      linkedin_url != null &&
      linkedin_url !== "" &&
      RegxPattern.url.test(linkedin_url) === false
    )
      return false;
    if (
      facebook_url != null &&
      facebook_url !== "" &&
      RegxPattern.url.test(facebook_url) === false
    )
      return false;
    if (
      youtube_url != null &&
      youtube_url !== "" &&
      RegxPattern.url.test(youtube_url) === false
    )
      return false;
    if (
      instagram_url != null &&
      instagram_url !== "" &&
      RegxPattern.url.test(instagram_url) === false
    )
      return false;
    return true;
  }

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "first_name":
      case "last_name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      case "preferred_contact_method": {
        if (!value.text) return t("validators.required");
        return null;
      }
      case "website":
      case "linkedin_url":
      case "facebook_url":
      case "youtube_url":
      case "instagram_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "phone": {
        if (isApolloTalent && value.text === "") return null;
        if (!isApolloTalent && value.text === "") return t("validators.required");
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "email": {
        if (!value.text) return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "ssn": {
        if (!value.text) return null;
        if (RegxPattern.ssn.test(value.text) === false)
          return t("validators.enterValidSnnNumber");
        return null;
      }
      case "last_4_digits_of_ssn": {
        if (!value.text) return null;
        if (RegxPattern.ssn.test(value.text) === false)
          return t("validators.enterValidSnnNumber");
        return null;
      }
      case "zip_code": {
        if (!value.text) return null;
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default TalentForm;
