import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, ICreateResponse } from "../../../../interfaces";
import * as thunk from "./manage_sales_calls_thunk";

export interface ManageSalesCallState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
    completeUpdate: {
        error?: AppError | null,
        loading: LoadingType,
        response?: ICreateResponse,
    },
} 

const initialState: ManageSalesCallState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    completeUpdate:{
        error: null,
        loading: LoadingType.idle,
    }
}


export const manageSalesCallSlice = createSlice({
    name: 'manageSalesCall',
    initialState: initialState,
    reducers: {
        clearCreateSalesCallState(state) {
            return { ...state, create : {...initialState.create} };

        },
        clearUpdateSalesCallState(state) {
            return { ...state, update : {...initialState.update} };
        },
        clearCompleteUpdateSalesCallState(state) {
            return { ...state, completeUpdate : {...initialState.completeUpdate} };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createSalesCall.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createSalesCall.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createSalesCall.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateSalesCall.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateSalesCall.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateSalesCall.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            
            .addCase(thunk.completeUpdateSalesCall.pending, (state, action) => ({ ...state, completeUpdate: { ...state.completeUpdate, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.completeUpdateSalesCall.rejected, (state, action) => ({ ...state, completeUpdate: { ...state.completeUpdate, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.completeUpdateSalesCall.fulfilled, (state, action) => ({ ...state, completeUpdate: { ...state.completeUpdate, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            
            
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageSalesCallActions = manageSalesCallSlice.actions;
export const selectState = (state: ManageSalesCallState) => state;
export const selectCreateState = (state: ManageSalesCallState) => state.create;
export const selectUpdateState = (state: ManageSalesCallState) => state.update;
export const selectCompleteUpdateState = (state: ManageSalesCallState) => state.completeUpdate;



