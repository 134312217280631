import { LoginCredentials } from "../interfaces";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const AccountAPI = {

    login(credentials) {
        return interceptor.post(UrlBuilder.login, credentials);
    },

    getProfile() {
        return interceptor.get(UrlBuilder.getProfile);
    },

    logout(credentials) {
        return interceptor.post(UrlBuilder.logout, credentials);
    },

    forgotPassword(email) {
        return interceptor.post(UrlBuilder.forgotPassword, { 'email': email });
    },

    changePassword(password) {
        return interceptor.post(UrlBuilder.changePassword, { 'password': password })
    },
    agencyLoginLogo(domain, api_key) {
        return interceptor.post(UrlBuilder.getAgencyLoginLogo, { "domain": domain, "api_key": api_key })
    }
}

