import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import TabNavigation from "../../../components/tab_navigation/tab_navigation";
import { AppRoutes } from "../../../routes";
import { GetCloseAction } from "../../../utils";
import { PayrollDashboard } from "../pages/payroll/payroll_dashboard/payroll_dashboard";
import PayRollErrorsReportsPage from "../pages/payroll/errors_report/errors_report";
import PayrollTransactionSummary from "../pages/payroll/payroll_transaction_summary/payroll_transaction_summary";
import EmployeePayCheckDirectoryPage from "../pages/payroll/paycheck_directory/employee_paycheck_directory";
import DeductionPaymentsDirectoryPage from "../pages/payroll/deduction_payments_directory/deduction_payments_directory";

const PayrollTabNav = (props: RouteComponentProps<any>) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  return (
    <TabNavigation
      activeRoute={location.pathname}
      tabList={[
        {
          title: "payroll_dashboard",
          route: `${AppRoutes.payrollDashboard}`,
        },
        {
          title: "employee_paycheck_directory",
          route: `${AppRoutes.payrollPaycheckDirectory}`,
        },
        {
          title: "deduction_payments_directory",
          route: `${AppRoutes.authDedPaycheckDirectory}`,
        },
        {
          title: "errors_report",
          route: `${AppRoutes.payrollErrorsReport}`,
        },
        {
          title: "payroll_transaction_summary",
          route: `${AppRoutes.payrollTransactionSummary}`,
        },
      ]}
      onChange={(value) =>
        history.push({ pathname: value, state: location.state })
      }
      actions={
        <GetCloseAction
          name={t("close")}
          onClick={() => history.push(AppRoutes.backOfficePage)}
        />
      }
    >
      <Switch>
        <Route
          exact
          path={`${AppRoutes.payrollDashboard}`}
          component={PayrollDashboard}
        />
        <Route
          exact
          path={`${AppRoutes.payrollPaycheckDirectory}`}
          component={EmployeePayCheckDirectoryPage}
        />
        <Route
          exact
          path={`${AppRoutes.authDedPaycheckDirectory}`}
          component={DeductionPaymentsDirectoryPage}
        />
        <Route
          exact
          path={`${AppRoutes.payrollErrorsReport}`}
          component={PayRollErrorsReportsPage}
        />
        <Route
          exact
          path={`${AppRoutes.payrollTransactionSummary}`}
          component={PayrollTransactionSummary}
        />
        <Route
          path={"/"}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `${location.pathname}/dashboard`,
                state: { ...(location.state as any) },
              }}
            />
          )}
        />
      </Switch>
    </TabNavigation>
  );
};

export default PayrollTabNav;
