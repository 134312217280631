import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UserAvatar from "../../../../../components/user_avatar/user_avatar";
import { FieldNav } from "../../../../../components/field_nav/field_nav";
import { TALENT_STATUS, THEME } from "../../../../../enums";
import {
  CompaniesIcon,
  ContactPlacementIcon,
  MarkTalentIcon,
  TextEmAllIcon,
} from "../../../../../icons";
import { UpdateAssignment } from "../../../../../interfaces";
import { AppRoutes } from "../../../../../routes";
import { CustomButton } from "../../../../../utils";
import "./assignment_dashboard.scss";
interface Props {
  assignmentInfo: UpdateAssignment;
  onActionClick: (value: string) => void;
}
const AssignmentHeader = (props) => {
  const { name, uri, rating, level } = props;

  return (
    <div className="ads-user-info">
      <UserAvatar
        onClick={() => {}}
        url={uri}
        rating={{ value: rating, level: level }}
      />

      <div className="ads-user-name">
        <span>{name}</span>
      </div>
    </div>
  );
};

export const AssignmentDetailsSection: React.FunctionComponent<Props> = (
  props
) => {
  const { assignmentInfo, onActionClick } = props;
  const { t } = useTranslation();
  const history = useHistory();
  function assignmentStatus(status: string | undefined) {
    if (status === TALENT_STATUS.active)
      return <span style={{ color: THEME.buttonColor17 }}>{t("active")}</span>;
    else if (status === TALENT_STATUS.inactive)
      return <span style={{ color: "#b1b1b1" }}>{t("in_active")}</span>;
    else return <span>{""}</span>;
  }
  function handleTalentSelect(talent_id, talent_name) {
    history.push({
      pathname: `${AppRoutes.talentDetailsPage}/${talent_id}`,
      state: {
        id: talent_id,
        name: talent_name,
      },
    });
    return;
  }
  function handleJobSelect(job_id, job_title) {
    history.push({
      pathname: `${AppRoutes.jobDetailsPage}/${job_id}`,
      state: {
        id: job_id,
        name: job_title,
      },
    });
    return;
  }
  function handleCompanySelect(company_id, company_name) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${company_id}`,
      state: {
        id: company_id,
        name: company_name,
      },
    });
    return;
  }
  function handleDepartmentSelect(value: UpdateAssignment) {
    history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleStatusClick() {
    history.push({
      pathname: `${AppRoutes.assignmentsUniversalDirectory}/${assignmentInfo?.id}`,
      state: {
        id: assignmentInfo?.id,
        status: assignmentInfo?.status,
      },
    });
    return;
  }
  return (
    <div className="ads-container">
      <AssignmentHeader
        name={assignmentInfo?.talent_name + "-" + assignmentInfo?.job_title}
        uri={assignmentInfo?.talent_profile_url}
        rating={assignmentInfo?.engagement_rating}
        level={assignmentInfo?.engagement_level}
      />
      <div className="ads-branch-selector">
        <div className="ads-status">
          <span>
            {`${t("status")}:`}
            <span
              className="status-txt"
              onClick={
                assignmentInfo?.status !== "" && assignmentInfo?.status !== null
                  ? handleStatusClick
                  : () => {}
              }>
              {assignmentStatus(assignmentInfo?.status)}
            </span>
          </span>
        </div>
      </div>
      <div className="ads-info-container">
        <div className="ads-info-icon">
          <ContactPlacementIcon width={"100%"} height={"100%"} />
        </div>
        <div className="ads-info-content">
          <div className="ads-info-holder">
            <div className="ads-into-title">
              <span>{t("employee_name").toUpperCase()}</span>
            </div>
            {assignmentInfo.talent_name !== null &&
            assignmentInfo.talent_name !== "" ? (
              <FieldNav
                isButton={true}
                onClick={() =>
                  handleTalentSelect(
                    assignmentInfo?.talent_id,
                    assignmentInfo?.talent_name
                  )
                }>
                <span>{assignmentInfo?.talent_name}</span>
              </FieldNav>
            ) : (
              <span className="na-span">NA</span>
            )}
            <div className="ads-into-title">
              <span>{t("job_title").toUpperCase()}</span>
            </div>
            {assignmentInfo.job_title !== null &&
            assignmentInfo.job_title !== "" ? (
              <FieldNav
                isButton={true}
                onClick={() =>
                  handleJobSelect(
                    assignmentInfo?.job_order_id,
                    assignmentInfo?.job_title
                  )
                }>
                <span>{assignmentInfo?.job_title}</span>
              </FieldNav>
            ) : (
              <span className="na-span">NA</span>
            )}
          </div>
        </div>
      </div>

      <div className="ads-info-container">
        <div className="ads-info-icon">
          <CompaniesIcon width={"100%"} height={"100%"} />
        </div>
        <div className="ads-info-content">
          <div className="ads-info-holder">
            <div className="ads-into-title">
              <span>{t("company_name").toUpperCase()}</span>
            </div>
            {assignmentInfo.company_name !== null &&
            assignmentInfo.company_name !== "" ? (
              <FieldNav
                isButton={true}
                onClick={() =>
                  handleCompanySelect(
                    assignmentInfo?.company_id,
                    assignmentInfo?.company_name
                  )
                }>
                <span>{assignmentInfo?.company_name}</span>
              </FieldNav>
            ) : (
              <span className="na-span">NA</span>
            )}
            <div className="ads-into-title">
              <span>{t("department").toUpperCase()}</span>
            </div>
            <FieldNav
              isButton={true}
              onClick={() => handleDepartmentSelect(assignmentInfo)}>
              <span>
                {assignmentInfo?.department_name !== null
                  ? assignmentInfo?.department_name
                  : t("corporate")}
              </span>
            </FieldNav>
          </div>
        </div>
      </div>
      <AssignmentsActions onClick={onActionClick} />
    </div>
  );
};

const buttonsList = [
  {
    title: "send_message_to_talent",
    icon: TextEmAllIcon,
    type: "send_message_to_talent",
    color: "#ff4d00",
  },
];
interface AssignmentsActionsProps {
  onClick: (type: string) => void;
}

const AssignmentsActions: React.FunctionComponent<AssignmentsActionsProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <div className="tds-action-container">
      {buttonsList.map((doc, index) => {
        return (
          <div key={index} className="tds-action">
            <CustomButton
              customStyle={{ width: "-webkit-fill-available" }}
              leftIcon={
                <div className="tds-action-icon">
                  <span style={{ color: "#fff" }}>
                    <doc.icon width={"100%"} height={"100%"} />
                  </span>
                </div>
              }
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t(doc.title)}
              enable={true}
              backgroundColor={doc.color}
              onClick={() => props.onClick(doc.type)}
              customClassName={doc.title}
            />
          </div>
        );
      })}
    </div>
  );
};
