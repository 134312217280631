import {  useState } from "react";
import { useTranslation } from "react-i18next";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import RenderInput from "../../../../components/render_input/render_input";

import {
  LoadingType,
  THEME,
} from "../../../../enums";
import { ContactsIcon, LinkIcon } from "../../../../icons";
import {
  AppError,
  FormInputOption,
} from "../../../../interfaces";
import { IInBoundLeads } from "../../../../interfaces/inbound_leads";
import {
  CustomButton,
  enforceFormat,
  formatToPhone,
  RegxPattern,
} from "../../../../utils";
import { US_states } from "../../../../utils/States_US";
import "./inbound_leads_form.scss"

interface Props {
  title: string;
  loading: LoadingType;
  error: AppError | null | undefined;
  inboundLead: IInBoundLeads | null;
  
  onClose: () => void;
  onClearError : ()=> void;
  onSubmit: (value: IInBoundLeads ) => void;
  
}

const getEditInboundFormData = (value: IInBoundLeads): IInBoundLeads => {
  return {
    first_name: value.first_name ?? "",
    last_name: value.last_name ?? "",
    street_address: value.street_address ?? "",
    city: value.city ?? "",
    state: value.state ?? "",
    zip_code: value.zip_code ?? "",
    contact_number : value.contact_number?? "",
    social_media_links: value.social_media_links ?? {
      linkedin_url: null,
      instagram_url: null,
      facebook_url: null,
      youtube_url: null,
    },
    company_name: value.company_name ?? "",
    goal1: value.goal1 ?? "",
    goal2: value.goal2 ?? "",
    goal3: value.goal3 ?? "",
    id: value.id,
    role: value.role ?? "",
    email: value.email ?? "",
    demo_sent: value.demo_sent ?? false,
    received_date: value.received_date ?? 0,
    status: value.status ?? "",
    business_goals: value.business_goals ?? "",
    website: value.website ?? "",
    created_date: value.created_date ?? 0,
    modified_by: value.modified_by ?? "",
    created_by: value.created_by ?? "",
    read: value.read ?? false,
    street_address2: value.street_address2 ?? "",
    is_archived: value.is_archived ?? false,
    is_deleted: value.is_deleted ?? false,
  };
};
function getInitialForm(): IInBoundLeads {
  return {
    first_name: "",
    last_name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    contact_number: "",
    social_media_links: {
      linkedin_url: null,
      youtube_url: null,
      facebook_url: null,
      instagram_url: null,
    },
    company_name: "",
    email: "",
    id: "",
    role: "",
    demo_sent: false,
    received_date: 0,
    status: "",
    business_goals: "",
    website: "",
    created_date: 0,
    modified_by: "",
    created_by: "",
    read: false,
    street_address2: "",
    is_archived: false,
    is_deleted: false,
    goal1: "",
    goal2: "",
    goal3: "",
  };
}

const EditInboundLeadForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<IInBoundLeads>(
    props.inboundLead
      ? getEditInboundFormData(props.inboundLead)
      : getInitialForm()
  );
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] =
    useState<boolean>(false);

  function handleFieldChange(fieldId: string, value: any, parent?: string) {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }

  const formBasicInformation:
    | Array<FormInputOption>
    | Array<Array<FormInputOption>> = [
    /// 1st row
    [
      {
        field: "first_name",
        type: "text",
        label: "first_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "last_name",
        type: "text",
        label: "last_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "company_name",
        type: "text",
        label: "company",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "role",
        type: "text",
        label: "role",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      
    ],

    /// 2nd row
    [
      {
        field: "street_address",
        type: "text",
        label: "street_address",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "city",
        type: "text",
        label: "city",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "state",
        type: "selector",
        label: "state",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zip_code",
        type: "text",
        label: "zip_code",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        maxLength: 5

      },
    ],

    /// 3rd row
    [
      {
        field: "email",
        type: "text",
        label: "Email",
        pattern: RegxPattern.email,
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "contact_number",
        type: "tel",
        label: "mobile",
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: "",
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "website",
        type: "text",
        label: "website",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
  ];

  const formSocialMediaInformation : (FormInputOption | FormInputOption[])[]= [
    
    [
      {
        field: "linkedin_url",
        parentField: "social_media_links",
        type: "text",
        label: "linked_in",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "facebook_url",
        parentField: "social_media_links",
        type: "text",
        label: "facebook",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "youtube_url",
        parentField: "social_media_links",
        type: "text",
        label: "youtube",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "instagram_url",
        parentField: "social_media_links",
        type: "text",
        label: "instagram",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
  ];

     const handleSubmit = () => { 
       setCheckValidationOnSubmit(true);
       const requiredFieldArray = ['first_name']
       requiredFieldArray.map((field) => {
        handleFieldChange(field, formState[field]);
       })
     if (_isFormValid()) {
          props.onSubmit({
                  ...formState,
                  first_name: formState?.first_name.trim(),
                  last_name: formState?.last_name.trim(),
              });
       setCheckValidationOnSubmit(false);
       }
       
     }

  return (
    <div className="ibl-f-container">
      <div className="ibl-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={<ContactsIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="ibl-f-content">
        {getSectionOne()}
        {getSectionTwo()}
       
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="ibl-f-actions">
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onClose}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={t("update")
            }
            enable={true}
            backgroundColor={props.error != null
                ? THEME.secondaryColor6
                : THEME.defaultHighLightColor
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    
      
    </div>
  );

  /// Section one
  function getSectionOne() {
    return (
      <div className="ibl-f-section-divider">
        <div className="ibl-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("basic_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="ibl-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={contactFormValidators}
                      className={`ibl-f-input-holder`}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={contactFormValidators}
              className={"ibl-f-input-holder"}
              checkValidationOnSubmit={checkValidationOnSubmit}
            />
          );
        })}
      </div>
    );
  }

  function getSectionTwo() {
    return (
      <div className="ibl-f-section-divider">
        <div className="ibl-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("social_media").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formSocialMediaInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="ibl-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={contactFormValidators}
                      className={"ibl-f-input-holder"}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={contactFormValidators}
              className={"ibl-f-input-holder"}
            />
          );
        })}
        
      </div>
    );
  }


  function getLinkIcon() {
    return (
      <div className="ibl-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }

  function _isFormValid() {
    const { first_name, social_media_links,zip_code,contact_number,email,last_name } = formState;
    const { facebook_url, instagram_url, youtube_url, linkedin_url } =
      social_media_links ?? {};

    if (first_name === "") return false;
    if (last_name === "") return false;
    if (email === "") return false;
    
    if (RegxPattern.username.test(first_name) === false) return false;
    if(contact_number !='' && RegxPattern.phone.test(contact_number)===false) return false;
    if (zip_code !=='' && RegxPattern.zipCode.test(zip_code) === false) return false;
    if (email != null && email !== "" && RegxPattern.email.test(email) === false)
      return false;
    if (
      linkedin_url != null &&
      linkedin_url !== "" &&
      RegxPattern.url.test(linkedin_url) === false
    )
      return false;
    if (
      facebook_url != null &&
      facebook_url !== "" &&
      RegxPattern.url.test(facebook_url) === false
    )
      return false;
    if (
      instagram_url != null &&
      instagram_url !== "" &&
      RegxPattern.url.test(instagram_url) === false
    )
      return false;
    if (
      youtube_url != null &&
      youtube_url !== "" &&
      RegxPattern.url.test(youtube_url) === false
    )
      return false;
    return true;
  }

  function contactFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "first_name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "last_name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "email": {
        if (value.text==="") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "website":
      case "linkedin_url":
      case "facebook_url":
      case "youtube_url":
      case "instagram_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "contact_number": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "zip_code": {
        if (!value.text) return null;
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default EditInboundLeadForm;
