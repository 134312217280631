import { useEffect, useRef } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, allowNumbers, convertNumberToMoney, formatToMoney, mapOptions } from "../../../../utils";
import { TalentIcon } from "../../../../icons";
import { LoadingType, PayFrequency, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './add_deduction.scss';
import { AppError } from "../../../../interfaces";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { getThirdPartyDeductionType, selectThirdPartyDeductionTypeList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { ITalentBackOfficeDeduction } from "../../../../interfaces/talent_back_office";
import ApiError from "../../../../components/api_error";
import { DateInput } from "../../../../components/date_input/date_input";
import { getDeductionInstitution, selectDeductionInstitutionList } from "../../../../redux/admin_center/back_office";
interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    onClose: () => void,
    onDissmiss?: () => void,
    handleFormFieldChange: (fieldId: string, value: any, parent: string) => void;
    deductionFormState: ITalentBackOfficeDeduction;
    handleSave: () => void;
    currentLoginUserName: string;
    onSuccessClose: () => void;
    index: number | undefined;
    talentName: string;
    parentField: string;
    dedUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    handleClearError: () => void;
    checkValidationOnSubmit: boolean;

}

const AddDeductionPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onDissmiss,
        title,
        successTitle,
        checkValidationOnSubmit,
        handleFormFieldChange, deductionFormState, handleSave, handleClearError, currentLoginUserName, talentName, onSuccessClose, index, parentField, dedUpdateState
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);
    const loading = dedUpdateState.loading === LoadingType.succeeded;
    const isAddLoading = () => {
        return dedUpdateState?.loading === LoadingType.pending;
    };
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        // dispatch(getEmployeeDeduction());
        dispatch(getThirdPartyDeductionType());
        dispatch(getDeductionInstitution());

    }, []);
    // const employeeDeductionList = useAppSelector((state) => selectEmployeeDeductionList(state));
    const thirdPartyDeductionTypeList = useAppSelector(selectThirdPartyDeductionTypeList);
    const deductionInstitutionList = useAppSelector((state) => selectDeductionInstitutionList(state))


    function handleWrapperClose(action?: () => void) {
        if (loading) {
            closePopup(props.onSuccessClose);
        } else {
            if (action) closePopup(action);
        }
    }

    // const amountDisable = (deductionFormState.deduction_percentage !== 0 && deductionFormState.deduction_amount === 0) ? true : false;
    // const percentageDisable = (deductionFormState.deduction_percentage !== 0 && (deductionFormState.deduction_amount === 0 || deductionFormState.deduction_amount === "")) ? true : false;

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => handleWrapperClose(onClose)}>
                <div className="add-ded-container">
                    <div className="add-ded-header">
                        <FormDialogTilteHeader
                            title={loading ? (index !== undefined ? (parentField === "garnishment_deduction" ? t("update_garnishment_deduction") : t("update_deduction")) : successTitle) : (index !== undefined ? (parentField === "garnishment_deduction" ? t("update_garnishment_deduction") : t("update_deduction")) : title)}
                            titleIcon={<TalentIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="add-ded-content">
                        {loading ?
                            (<div className="saved-doc-holder">
                                <DocSavedText>
                                    <span className="aknw-name">
                                        {t("well_done_credit_profile", {
                                            name: currentLoginUserName,
                                        })}
                                    </span>
                                    <span>{index !== undefined ? (parentField === "garnishment_deduction" ? t("you_have_updated_garnishment_deduction", { talentName: talentName }) : t("you_have_updated_deduction", { talentName: talentName })) : (parentField === "garnishment_deduction" ? t("you_have_added_garnishment_deduction", { talentName: talentName }) : t("you_have_added_deduction", { talentName: talentName }))}</span>
                                </DocSavedText>
                            </div>)
                            :
                            (
                                <>
                                    <div className="add-ded-input-holder-row row1">
                                        <div className={"add-ded-input-holder"}>
                                            <CustomFormSelect
                                                name={t("deduction_id")}
                                                list={mapOptions(thirdPartyDeductionTypeList, "value", "id")}
                                                onChange={(val) => handleFormFieldChange("deduction_id", val, parentField)}
                                                value={deductionFormState.deduction_id}
                                                placeholder={t('select')}
                                                required={true}
                                                label={t('what_ded_name')}
                                                validations={formValidators}
                                                checkValidationOnSubmit={checkValidationOnSubmit}
                                            />
                                        </div>
                                        <div className="ded-input-row-holder">
                                            <div className="input-label">{t("deduction_amnt_percent")}<span>&nbsp;*</span></div>
                                            <div className="ded-input-row">
                                                <div>
                                                    <FormInput
                                                        id={'deduction_amount'}
                                                        onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                        required={false}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={convertNumberToMoney(deductionFormState.deduction_amount, "string") as string}
                                                        label={""}
                                                        prefix={<span>$</span>}
                                                        onBlur={formatToMoney}
                                                        onKeyDown={formatToMoney}
                                                        onKeyUp={formatToMoney}
                                                        disabled={deductionFormState.deduction_percentage ? true : false}
                                                        validations={formValidators}
                                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                                    />
                                                </div>
                                                <div className="spacer">
                                                    or
                                                </div>
                                                <div>
                                                    <FormInput
                                                        id={'deduction_percentage'}
                                                        onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                        required={false}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={deductionFormState.deduction_percentage}
                                                        label={""}
                                                        suffix={<span>%</span>}
                                                        onKeyUp={(e) => { allowNumbers(e); }}
                                                        onKeyDown={(e) => { allowNumbers(e); }}
                                                        disabled={deductionFormState.deduction_amount ? true : false}
                                                        validations={formValidators}
                                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="add-ded-input-holder-row row1">

                                        <div className={"add-ded-input-holder"}>
                                            <DateInput
                                                id={'deduction_start_date'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                placeholder={""}
                                                value={deductionFormState.deduction_start_date}
                                                label={t('what_date_ded_withheld')}
                                                setHoursToZero={true}
                                                required
                                                validations={formValidators}
                                                checkValidationOnSubmit={checkValidationOnSubmit}
                                            />
                                        </div>
                                        <div className="ded-input-row-holder">
                                            <div className="input-label">{t("is_date_ded_no_longer_withheld")}</div>
                                            <div className="ded-input-row">
                                                <div>
                                                    <ToggleSwitch
                                                        onChange={(val) => handleFormFieldChange('deduction_expires', val, parentField)}
                                                        label={""}
                                                        checked={deductionFormState.deduction_expires!}
                                                        labelPosition={'top'}
                                                    />
                                                </div>

                                                <div>
                                                    <DateInput
                                                        id={'deduction_end_date'}
                                                        onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                        placeholder={""}
                                                        value={deductionFormState.deduction_end_date}
                                                        label={''}
                                                        setHoursToZero={true}
                                                        disabled={!deductionFormState.deduction_expires}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="add-ded-input-holder-row row1">
                                        <div className={"add-ded-input-holder"}>
                                            <CustomFormSelect
                                                name={t("deduction_institution_id")}
                                                list={mapOptions(deductionInstitutionList, "title", "id")}
                                                onChange={(val) => handleFormFieldChange("deduction_institution_id", val, parentField)}
                                                value={deductionFormState.deduction_institution_id}
                                                placeholder={t('select')}
                                                required={false}
                                                label={t('select_institution_withhold_delivered')}
                                            />
                                        </div>
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'deduction_reference'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={deductionFormState.deduction_reference}
                                                label={t("reference_num_of_deduction")}
                                                onKeyUp={(e) => { allowNumbers(e); }}
                                                onKeyDown={(e) => { allowNumbers(e); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="add-ded-input-holder-row row3">
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'deduction_pay_period_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(deductionFormState.deduction_pay_period_limit, "string") as string}
                                                label={t("pay_period_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'deduction_monthly_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(deductionFormState.deduction_monthly_limit, "string") as string}
                                                label={t("monthly_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'deduction_annual_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(deductionFormState.deduction_annual_limit, "string") as string}
                                                label={t("annual_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'deduction_life_time_limit'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={convertNumberToMoney(deductionFormState.deduction_life_time_limit, "string") as string}
                                                label={t("lifetime_limit")}
                                                prefix={<span>$</span>}
                                                onBlur={formatToMoney}
                                                onKeyDown={formatToMoney}
                                                onKeyUp={formatToMoney}
                                            />
                                        </div>
                                    </div>
                                    <div className={parentField === "garnishment_deduction" ? "add-ded-input-holder-row row4" : "add-ded-input-holder-row row5"}>
                                        <div className={"add-ded-input-holder"}>
                                            <CustomFormSelect
                                                list={Object.keys(PayFrequency).map(id => ({
                                                    value: PayFrequency[id],
                                                    label: PayFrequency[id]
                                                }))}
                                                name={parentField === "garnishment_deduction" ? "garnishment_frequency" : "deduction_frequency"}
                                                value={(parentField === "garnishment_deduction" ? deductionFormState.garnishment_frequency : deductionFormState.deduction_frequency) ?? PayFrequency["WEEKLY"]}
                                                label={parentField === "garnishment_deduction" ? t("garnishment_frequency") : t("deduction_frequency")}
                                                placeholder={"Select"}
                                                onChange={(val) => handleFormFieldChange(parentField === "garnishment_deduction" ? "garnishment_frequency" : "deduction_frequency", val, parentField)}
                                                isClearable={false}
                                            />
                                        </div>
                                        {parentField === "garnishment_deduction" &&
                                            <div className={"add-ded-input-holder"}>
                                                <FormInput
                                                    id={'case_number'}
                                                    onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                    required={false}
                                                    placeholder={""}
                                                    type={"text"}
                                                    value={deductionFormState.case_number}
                                                    onKeyUp={allowNumbers}
                                                    label={t("case_number")}
                                                />
                                            </div>}
                                        <div className={"add-ded-input-holder"}>
                                            <FormInput
                                                id={'note'}
                                                onChange={(id, val) => handleFormFieldChange(id, val, parentField)}
                                                required={false}
                                                placeholder={""}
                                                type={"text"}
                                                value={deductionFormState.note}
                                                label={t("notes")}
                                                multiline={true}
                                            />
                                        </div>
                                        <div className={"add-ded-input-holder"}>
                                            <ToggleSwitch
                                                onChange={(val) => handleFormFieldChange('is_active', val, parentField)}
                                                label={t("active")}
                                                checked={deductionFormState.is_active!}
                                                labelPosition={'top'}
                                            />
                                        </div>
                                    </div>
                                    {dedUpdateState && dedUpdateState.error && (
                                        <div className={"error-section"}>
                                            <ApiError
                                                message={dedUpdateState?.error?.message}
                                                onClose={handleClearError}
                                            />
                                        </div>
                                    )}
                                </>
                            )
                        }

                    </div>
                    <div className="add-ded-actions">
                        {loading ?
                            <div className="btn-save">
                                <CustomButton
                                    loading={isAddLoading()}
                                    textStyle={{ textTransform: "capitalize" }}
                                    name={t("ok")}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onSuccessClose)}
                                    enable={true}
                                />
                            </div>
                            :
                            (
                                <>
                                    <div className="btn-cancel">
                                        <CustomButton
                                            loading={false}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("cancel")}
                                            enable
                                            backgroundColor={THEME.secondaryColor4}
                                            onClick={onClose}
                                        />
                                    </div>
                                    <div className="btn-save">
                                        <CustomButton
                                            loading={isAddLoading()}
                                            textStyle={{ textTransform: "capitalize" }}
                                            name={t("save")}
                                            backgroundColor={THEME.defaultHighLightColor}
                                            onClick={handleSave}
                                            enable={true}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function formValidators(value: { text: string; field: string }) {
        const isAmountDisabled = deductionFormState.deduction_percentage !== 0 && deductionFormState.deduction_percentage?.toString() !== ""; 
        const isPercentageDisabled = deductionFormState.deduction_amount !== 0 && deductionFormState.deduction_amount?.toString() !== "";
    
        switch (value.field) {
            case "deduction_id": {
                if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
                return null;
            }
            case "deduction_start_date": {
                if (value.text === null || parseInt(value.text) === 0) return t("validators.required");
                return null;
            }
            case "deduction_amount": {
                // Only validate deduction_amount if deduction_percentage is not provided
                if (!isAmountDisabled) {
                    if (value.text === null || parseInt(value.text) === 0 || value.text.toString() === "") return t("validators.required");
                }
                return null;
            }
            case "deduction_percentage": {
                // Only validate deduction_percentage if deduction_amount is not provided
                if (!isPercentageDisabled) {
                    if (value.text === null || parseInt(value.text) === 0 || value.text.toString() === "") return t("validators.required");
                }
                return null;
            }
            default: {
                return null;
            }
        }
    }
    
}

export default AddDeductionPopup;