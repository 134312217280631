import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../redux/store";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, FormInput, RegxPattern, allowNumbers } from "../../../../utils";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import "../create_action/create_action.scss"
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { adminCenterBackOfficeBankAccountPurposeOptions, onBoardingDirectDepositAccountTypeOptions } from "../../../../variables";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { BackOfficeIcon } from "../../../../icons";

const EditAction = ({ onClose, onDismiss, visible, methodValue, category, category_id, reduxMapping }) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [inputValue, setInputValue] = useState({ value: methodValue.title, description: methodValue.description, bank_name: methodValue.bank_name, routing_number: methodValue.routing_number, account_number: methodValue.account_number, account_type: methodValue.account_type, purpose: methodValue.purpose, is_active: methodValue.is_active, modified_by: methodValue.modified_by, modified_date: methodValue.modified_date, bank_type: methodValue.bank_type, created_by: methodValue.created_by, taxable: methodValue.taxable, agency_id: methodValue.agency_id, is_deleted: methodValue.is_deleted, check_number: methodValue.check_number, invoice_number: methodValue.invoice_number, updated: methodValue.updated });
    // const [showButton, setShowButton] = useState(false);
    // const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(id, value) {
        setInputValue({ ...inputValue, [id]: value })
    }


    function _isFormValid() {
        const { bank_name, routing_number, account_number, purpose } = inputValue;
        if (bank_name === '') return false;
        if (purpose === '') return false;
        if (routing_number === '' || routing_number && routing_number.length < 9) return false;
        if (account_number === '') return false;
        return true;
    }
    function _isCISNFormValid() {
        const { invoice_number, check_number } = inputValue;
        if (invoice_number === '' || invoice_number && invoice_number.length < 1) return false;
        if (check_number === '' || check_number && check_number.length < 1) return false;
        return true;
    }

    function handleUpdate() {
        setSentRequest(true);
        if (category.label === "check_and_invoice_starting_numbers") {
            setCheckValidationOnSubmit(true);
            if (_isCISNFormValid()) {
                dispatch(category.methods.update({
                    'id': methodValue.id,
                    'check_number': inputValue.check_number,
                    'invoice_number': inputValue.invoice_number,
                    'created_by': inputValue?.created_by!,
                    'modified_by': inputValue?.modified_by!,
                    'modified_date': inputValue?.modified_date!,
                    'updated': inputValue?.updated!,
                }));
            }
            setCheckValidationOnSubmit(false);
        }
        else if (category.label === 'bank_account') {
            setCheckValidationOnSubmit(true);
            if (_isFormValid()) {
                dispatch(category.methods.update({
                    'id': methodValue.id,
                    'bank_name': inputValue.bank_name.trim(),
                    'routing_number': inputValue?.routing_number!.trim(),
                    'account_number': inputValue?.account_number!.trim(),
                    'account_type': inputValue?.account_type!.trim(),
                    'purpose': inputValue?.purpose!.trim(),
                    'is_active': inputValue?.is_active!,
                    'modified_by': inputValue?.modified_by!,
                    'modified_date': inputValue?.modified_date!,
                    'bank_type': inputValue?.bank_type!,
                    'created_by': inputValue?.created_by!,
                }));
                setCheckValidationOnSubmit(false);
            }
        }
        else if (category.label === "pay_type") {
            dispatch(category.methods.update({
                'id': methodValue.id,
                'value': typeof inputValue.value === 'string' ? inputValue.value.trim() : inputValue.value,
                'description': (inputValue.description ?? ""),
                'taxable': inputValue.taxable,
                'agency_id': inputValue.agency_id,
                'created_by': inputValue?.created_by!,
                'is_deleted': inputValue.is_deleted,
                'modified_by': inputValue?.modified_by!,
                'modified_date': inputValue?.modified_date!,
            }));
        }
        else {
            dispatch(category.methods.update({
                'id': methodValue.id,
                'value': typeof inputValue.value === 'string' ? inputValue.value.trim() : inputValue.value,
                'description': (inputValue.description ?? "").trim()
            }));
        }
        // setSnackBarMessage(category.deleteAlertMessage)
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            // setSuccessMessage(getMapping().state.update.response);
            // setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={onClose}>
                <div className="ac-bo-dialog-header">
                    <div className="dialog-header">
                        <FormDialogTilteHeader
                            title={t(category.updateLabel)}
                            titleIcon={<BackOfficeIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    {category.label! === "check_and_invoice_starting_numbers" ?
                        <div className="dialog-content">
                            <FormInput
                                id={"check_number"}
                                onChange={(id, val) => (handleInput(id, val))}
                                type={"text"}
                                value={inputValue.check_number}
                                label={t(category.inputLabel)}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                onKeyUp={allowNumbers}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                            <FormInput
                                id={"invoice_number"}
                                onChange={(id, val) => (handleInput(id, val))}
                                type={"text"}
                                value={inputValue.invoice_number}
                                label={t(category.inputLabel2)}
                                placeholder=""
                                required={true}
                                validations={formValidators}
                                onKeyUp={allowNumbers}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                            />
                        </div>
                        :
                        <div className="dialog-content">
                            <FormInput
                                id={category.label! === "bank_account" ? "bank_name" : "value"}
                                onChange={(id, val) => (handleInput(id, val))}
                                type={"text"}
                                value={category.label! === "bank_account" ? inputValue.bank_name : inputValue.value}
                                label={t(category.inputLabel)}
                                placeholder=""
                                required={true}
                                validations={category.label! === "bank_account" ? formValidators : undefined}
                                checkValidationOnSubmit={category.label! === "bank_account" ? checkValidationOnSubmit : false}
                            />
                            {category.label! === "bank_account" ?
                                <>
                                    <CustomFormSelect
                                        name={'account_type'}
                                        value={inputValue.account_type}
                                        placeholder={t('select')}
                                        list={onBoardingDirectDepositAccountTypeOptions.map((doc) => ({
                                            label: doc.label,
                                            value: doc.value,
                                        }))}
                                        label={t(category.inputLabel2)}
                                        onChange={(val) => (handleInput("account_type", val))}
                                        required={false}
                                    />
                                    <FormInput
                                        id={"routing_number"}
                                        onChange={(id, val) => (handleInput(id, val))}
                                        type={"text"}
                                        value={inputValue.routing_number}
                                        label={t(category.inputLabel3)}
                                        placeholder=""
                                        required={true}
                                        validations={formValidators}
                                        onKeyUp={allowNumbers}
                                        maxLength={9}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                    <FormInput
                                        id={"account_number"}
                                        onChange={(id, val) => (handleInput(id, val))}
                                        type={"text"}
                                        value={inputValue.account_number}
                                        label={t(category.inputLabel4)}
                                        placeholder=""
                                        required={true}
                                        validations={formValidators}
                                        onKeyUp={allowNumbers}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                    <CustomFormSelect
                                        name={'purpose'}
                                        value={inputValue.purpose}
                                        placeholder={t('select')}
                                        list={adminCenterBackOfficeBankAccountPurposeOptions.map((doc) => ({
                                            label: doc.label,
                                            value: doc.value,
                                        }))}
                                        label={t(category.inputLabel5)}
                                        onChange={(val) => (handleInput("purpose", val))}
                                        required={true}
                                        validations={formValidators}
                                        checkValidationOnSubmit={checkValidationOnSubmit}
                                    />
                                </>
                                :
                                <>
                                    <FormInput
                                        id={"description"}
                                        onChange={(id, val) => (handleInput(id, val))}
                                        type={"text"}
                                        value={inputValue.description}
                                        label={t(category.inputLabel2)}
                                        placeholder=""
                                    />
                                    {category.label === "pay_type" ? (
                                        <div className="toggle-switch">
                                            <ToggleSwitch
                                                label={t("taxable")}
                                                checked={inputValue.taxable}
                                                onChange={(value) => handleInput('taxable', value)}
                                                labelPosition={"left"}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            }
                        </div>
                    }
                    <div className="dialog-footer">
                        <div>
                            {getMapping().state.update.error && sentRequest &&
                                (<p style={{ color: 'red' }} className="message">{getMapping().state.update.error.response.detail.error}</p>) 
                                // : (<p style={{ color: 'green' }} className="message">{successMessage}</p>)
                            }
                        </div>
                        <div className="btns">
                            <div className="btn-cancel">
                                <CustomButton
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('cancel')}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => closePopup(onClose)}
                                />
                            </div>
                            <div className="btn-save">
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('update')}
                                    enable={(category.label! === "bank_account" || category.label! === "check_and_invoice_starting_numbers") ? true : inputValue.value}
                                    loading={getMapping().state.update.loading == LoadingType.pending}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={handleUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "bank_name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "routing_number": {
                if (value.text === "") return t("validators.required");
                if (value.text && value.text.length < 9) return t("validators.routinglimit");
                if (!value.text) return null;
                return null;
            }
            case "invoice_number": {
                if (value.text === "") return t("validators.required");
                if (!value.text) return null;
                return null;
            }
            case "check_number": {
                if (value.text === "") return t("validators.required");
                if (!value.text) return null;
                return null;
            }
            case "purpose": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "account_number": {
                if (value.text === "") return t("validators.required");

                return null;
            }

            default: {
                return null;
            }
        }
    }
}

export default EditAction;