
import { AuthRoutes, AppRoutes, GlobalRoutes } from "./routes";
import { useState, useEffect } from "react";
import { Switch, Redirect, Route, useHistory, useLocation, RouteComponentProps, useParams } from "react-router-dom";
import LoginPage from "./authentication/pages/login/login_page";
import { LocationState } from "./interfaces";
import AuthenticationPageHolder from "./others/authentication_page_holder";
import preferences from "./preferences";
import { getAgencyLoginLogoThunk, selectAgencyLoginLogoState, useAppDispatch, useAppSelector } from "./redux/store";
import { selectProfileState } from "./redux/profile/profile_selector";
import { checkAuthToken } from "./redux/profile/profile_thunk";
import { LoadingType } from "./enums";
import SplashPage from "./authentication/pages/splash/splash_page";
import LogoutPage from "./authentication/pages/logout/logout_page";
import { NotFoundScreen } from "./others/not_found_page";
import { MainAppRoute } from "./main";
import OnlineApplication from "./online_application/pages/online_application/online_application";
import OnBoardingPortal from "./onboarding/pages/onboarding_portal/onboarding_portal";
import EmployeeDashboard from "./employee_dashboard/pages/details/dashboard/employee_dashboard"
import TopNavBar from "./components/nav_menu/top_navbar";
import EmployeeDashboardMainPageHolder from "./employee_dashboard/employee_dashboard_main_page_holder";
import { IconButton, Snackbar } from "@material-ui/core";
import { snackbarActions } from "./redux/snackbar/snackbar_reducer";
import { selectSnackbarState } from "../app/redux/store";
import * as MatIcons from '@material-ui/icons';
import React from "react";
import KinisoJobOrders from "./kiniso_job_orders/pages/job_order_list/job_orders_list";
import kinisoJobOrderDetailsPage from "./kiniso_job_orders/pages/job_order_details/job_order_details";
import config from "./config";


const ProtectedRoute = ({ component: Component, isAutherised, ...rest }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <Route
                {...rest}
                render={props => {
                    const location = (props.location && props.location.pathname === AppRoutes.dashboardPage) ? { pathname: "/", hash: "", search: "", state: undefined, } : props.location;
                    const returnUrl = (location && location.pathname === '/') ? '' : `returnURL=${location.pathname}`;
                    if (isAutherised) return (
                        <Component {...props} />
                    );

                    return (
                        <Redirect
                            to={{
                                pathname: AuthRoutes.loginPage,
                                state: { from: location },
                                search: returnUrl,
                            }}

                        />
                    )
                }}
            />
        </>
    );
};


const AuthRoute = ({ component: Component, isAutherised, agencyLogoURL, agencyLogoLoading, searchParams, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const from = (props.location.state != null && (props.location.state as any).from != null) ? (props.location.state as any).from : { pathname: '/', state: undefined };
                if (isAutherised) {
                    return (<Redirect to={from} />);
                }
                return (
                    <AuthenticationPageHolder location={props.location} history={props.history} agencyLogoURL={agencyLogoURL} agencyLogoLoading={agencyLogoLoading} searchParams={searchParams}>
                        <Component {...props} />
                    </AuthenticationPageHolder>
                )
            }

            }
        />
    );
}


export const AppNavigation = () => {
    const { message } = useAppSelector((state) => selectSnackbarState(state));
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { token, profile, loading, logoutLoading } = useAppSelector((state) => selectProfileState(state));
    const [userToken, setUserToken] = useState<string | null>(preferences.getAuthToken())

    const agencyLogoState = useAppSelector((state) => selectAgencyLoginLogoState(state));
    const searchParams = (new URLSearchParams(location.search)).get("domain");
    // console.log(searchParams)
    const [agencyLogoURL, setAgencyLogoURL] = useState("");
    useEffect(() => {
        if (searchParams && searchParams !== "") {
            dispatch(getAgencyLoginLogoThunk({ domain: searchParams, api_key: config.ENV_TOKEN ?? "" }))
        }
    }, [searchParams]);

    useEffect(() => {
        if (searchParams && agencyLogoState.loading === LoadingType.succeeded && agencyLogoState.response) {
            setAgencyLogoURL(agencyLogoState.response)
        }
        if(agencyLogoState.loading === LoadingType.failed){
            setAgencyLogoURL("")
        }
    }, [agencyLogoState.loading, agencyLogoState.response, searchParams])

    useEffect(() => {
        if (loading == LoadingType.idle && profile == null) dispatch(checkAuthToken());
        return () => { }
    }, []);

    useEffect(() => {
        if (profile !== null && profile?.is_talent === true) {
            history.push(GlobalRoutes.redirectEmployeeDashboard);
        }
        return () => { }
    }, [profile]);

    useEffect(() => {
        if (userToken == null || userToken != token) setUserToken(preferences.getAuthToken());
        return () => { }
    }, [token]);

    useEffect(() => {
        if (userToken != null && location.state && location.state.from) {
            history.replace(location.state.from);
        }
        return () => { }
    }, [userToken]);

    if (loading === LoadingType.pending && profile == null) {
        return (
            <SplashPage agencyLogoURL={agencyLogoURL} />
        );
    }

    if (logoutLoading === LoadingType.pending && profile != null) {
        return (
            <LogoutPage />
        );
    }

    /// sanck bar
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(snackbarActions.clearSnackbarState());
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <MatIcons.Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Switch>
                <Route exact path={`${GlobalRoutes.onlineApplication}/:key/:lng?`} component={OnlineApplication} />
                <Route exact path={`${GlobalRoutes.onBoardingPortal}/:key`} component={OnBoardingPortal} />
                <Route exact path={`${GlobalRoutes.kinisoJobOrders}/:key/:branch_id/:agency_id`} component={KinisoJobOrders} />
                <Route exact path={`${GlobalRoutes.kinisoJobOrders}/:key/:branch_id/:agency_id/:job_id`} component={kinisoJobOrderDetailsPage} />


                <AuthRoute exact isAutherised={userToken != null && profile != null} path={AuthRoutes.loginPage} component={LoginPage} agencyLogoURL={agencyLogoURL} agencyLogoLoading={agencyLogoState.loading} searchParams={searchParams} />
                {switchRouteBasedOnRole()}
                <Route component={NotFoundScreen} />
            </Switch>
            <Snackbar
                open={message != null}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message ?? ''}
                action={action}
            />
        </>
    );

    function switchRouteBasedOnRole() {
        if (profile?.is_talent) {
            return (
                <EmployeeDashboardMainPageHolder>
                    <ProtectedRoute exact isAutherised={userToken != null && profile != null} path={`${GlobalRoutes.redirectEmployeeDashboard}`} component={EmployeeDashboard} />
                </EmployeeDashboardMainPageHolder>
            );
        }

        return (
            <ProtectedRoute isAutherised={userToken != null && profile != null} path={'/'} component={MainAppRoute} />
        );
    }
}
