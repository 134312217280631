import { useEffect, useRef, useState } from "react";
import { LeftArrowIcon, RightArrowIcon } from "../../../icons";
import { LoadingType } from "../../../enums";
import "./pagination.scss";

interface Props {
    totalPages: number;
    curPageNum: number;
    totalCount: number;
    size: number;
    loadingState: LoadingType | null,
    onPageChange: (pageNum: number, size: number) => void
}

const Pagination: React.FunctionComponent<Props> = (props) => {
    const { totalPages, onPageChange, curPageNum, totalCount, size, loadingState } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [previousValue, setPreviousValue] = useState<string | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
 
    useEffect(() => {
        if (loadingState === LoadingType.succeeded) {
            setPreviousValue(
                `${formatNumber((curPageNum * size) - size + 1)} - ${curPageNum === totalPages
                    ? formatNumber(totalCount)
                    : formatNumber(curPageNum * size)
                }`
            );
        }
    }, [loadingState, curPageNum, size, totalCount, totalPages]);

    const handlePageChange = (page, size?: number) => {
        onPageChange(page, size!);
    };

    const renderPageOptions = (): JSX.Element[] => {
        const options: JSX.Element[] = [];
        
        // Check if totalPages is 0
        if (totalPages === 0) {
            options.push(<option key={0} value={0}>0</option>);
        } else {
            for (let i = 1; i <= totalPages; i++) {
                options.push(<option key={i} value={i}>{i}</option>);
            }
        }
        
        return options;
    };
    

    const renderShowSizeOptions = (): JSX.Element[] => {
        const fixedOptions = [100, 200, 300];

        return fixedOptions.map((value) => (
            <li
                key={`fixed-${value}`}
                className="custom-option"
                onClick={() => handlePageChange(1 , value)}
            >
                {value} Per Page
            </li>
        ));
    };

    const handleBlur = (event) => {
        // Delay the dropdown close to prevent immediate closure if the user is clicking the dropdown
        setTimeout(() => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget as Node)) {
                setIsOpen(false); // Close dropdown if click happens outside
            }
        }, 100); // Small delay to allow click inside the dropdown before closing
    };

    const indexOfLastItem = curPageNum * size;
    function formatNumber(n: number | undefined) {
        // format number 1000000 to 1,234,567
        return n?.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className='tb-footer-common-pagination'>
            <div className="page-nav-content">
                <div className={`per-page-content ${totalCount === 0 ? 'disabled' : ''}`}
                   ref={dropdownRef}
                   onBlur={handleBlur}
                   tabIndex={-1} // Makes the element focusable so that onBlur can work
                >
                    <div className="custom-select" onClick={() => {
                        if(totalCount > 0) {
                            setIsOpen(!isOpen)
                        }
                    }
                    }>
                        <div className="custom-select-value">
                            <span>{size ?? 0}</span> Per Page
                        </div>
                        <div className={`custom-arrow ${isOpen ? 'open' : ''}`}>&#9660;</div>
                        {isOpen && (
                            <ul className="custom-options-list">
                                {renderShowSizeOptions()}
                            </ul>
                        )}
                    </div>
                </div>
                <div className='prev-next'>
                    <div className="cp-size">
                        {loadingState === LoadingType.succeeded ? (
                            <span>
                                {curPageNum && size ? formatNumber((curPageNum * size) - size + 1) : 0}&nbsp;-&nbsp;
                                {curPageNum === totalPages && totalCount > 0
                                    ? formatNumber(totalCount)
                                    : (curPageNum * size) > 0 ? formatNumber(curPageNum * size) : 0}
                            </span>
                        ) : (
                            <span>{previousValue ?? 0}</span>
                        )}
                        <span>of {formatNumber(totalCount) ?? 0}</span></div>
                    <div className="divider"></div>
                    <div className="back-next-btns">
                        <button
                            onClick={() => handlePageChange((curPageNum - 1), size)}
                            disabled={curPageNum === 1}
                        >
                            <LeftArrowIcon />
                        </button>
                        <span>Page</span>
                        <select value={curPageNum} onChange={(e) => handlePageChange(Number(e.target.value), size)} disabled={totalPages === 0} className="select-gray">
                            {renderPageOptions()}
                        </select>
                        <span style={{textTransform: "none"}}>of {totalPages ?? 0}</span>
                        <button className="next-arrow"
                            onClick={() => handlePageChange((curPageNum + 1), size)}
                            disabled={indexOfLastItem >= totalCount}
                        >
                            <RightArrowIcon />
                        </button>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Pagination;
