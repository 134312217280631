import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../interfaces";
import { catchRequestError } from "../../utils";
import { SendEmailAPI } from "../../apis/send_email";
import { ISendEmail } from "../../interfaces/send_email";

export const sendEmailThunk = createAsyncThunk<
    string,
    ISendEmail,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    "@universal_directories//dashboard/send_email/post",
    async (payload, thunkAPI) => {
        try {
            const res = await SendEmailAPI.postSendEmail(payload);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);
