import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../../components/table_empty/table_empty";
import { ContactListItem, IJobOrderContact, IJobOrderContactsByPage } from "../../../../../entities/ContactInfo";
import { LoadingType, THEME } from "../../../../../enums";
import {
  CallIcon,
  EmailIcon,
  FacebookIcon,
  GoogleMeet,
  InPerson,
  IndeedIcon,
  LinkedInIcon,
  Teams,
  TextIcon,
  ZipRecruiterIcon,
  Zoom,
} from "../../../../../icons";
import { manageContactActions } from "../../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer";
import { jobOrderContactCreateActions } from "../../../../../redux/job_order/details/contacts/job_order_contact_create/job_order_contact_reducer";
import {
  getContactsByJobOrderByPage,
  getJobById,
  selectJobDetailsByIdState,
  selectJobOrderContactsState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import {
  CustomButton,
  getCompanyStatusText,
  getPlusBtnIcon,
  getTableAccessIcon,
  sortByOrders,
} from "../../../../../utils";
import getOpenOrdersRow from "../../../../../utils/open_orders";
import { getDaysAgoWithDateAMPM } from "../../../../../variables";
import ExistingContactPopup from "../../../popups/existing_contact/existing_contact";
import "./job_order_contacts_page.scss";
import Pagination from "../../../../components/pagination/pagination";

const headers = [
  { title: "access_contact", code: "access_contact" },
  { title: "orders", code: "open_orders" },
  { title: "name", code: "contact_name" },
  { title: "job_title", code: "contact_title" },
  { title: "company", code: "company_name" },
  { title: "departments", code: "department_name" },
  { title: "last_contact", code: "last_contact" },
  { title: "contact", code: "preferred_contact_method" },
  { title: "status", code: "status" },
];

const category = [
  "open_orders",
  "contact_name",
  "contact_title",
  "company_name",
  "department_name",
  "last_contact",
  "preferred_contact_method",
  "status",
];

const JobOrderContactPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id, departmentId } = props.match.params;
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sortedField, setSortedField] = useState<null | string>(null);

  const [sortDirection, setSortDirection] = useState("asc");

  const [sentRequest, setSentRequest] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(100)
  const [joborderContactsList, setJoborderContactsList] = useState<IJobOrderContactsByPage | null>(null);

  const jobOrderContactState = useAppSelector((state) =>
    selectJobOrderContactsState(state)
  );
  const jobState = useAppSelector((state) =>
    selectJobDetailsByIdState(id)(state)
  );

  useEffect(() => {
    setSentRequest(true);
  }, []);

  useEffect(() => {
    if (searchValue?.length === 0 || searchValue?.length >= 2) return callAPI(1);
    return () => {}
  }, [searchValue])

  useEffect(() => {
    if (jobOrderContactState && jobOrderContactState.loading === LoadingType.succeeded && jobOrderContactState.response) {
      setJoborderContactsList(jobOrderContactState.response);
      setTotalCount(jobOrderContactState.response.total)
      return () => { };
    }
  }, [jobOrderContactState.loading, jobOrderContactState.response]);


  useEffect(() => {
    if (
      jobOrderContactState?.loading === LoadingType.succeeded &&
      sentRequest
    ) {
      setSentRequest(false);
    }
  }, [jobOrderContactState?.loading]);

  function handleSearchChange(value) {
    setSearchValue(value);
  }

  function handleSortChange(value) {
    if (value === "open_orders") {
      if (sortedField && sortedField === value) {
      } else {
        setSortedField(value);
      }
    }
    else {
      if (sortedField && sortedField === value) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(value);
        setSortDirection('asc');
      }
    }
  }

  function getFilteredList() {
    let list;
    if (!joborderContactsList?.items) return [];
    if (sortedField != null) {
      if (sortedField === "open_orders") {
        list = sortByOrders([...joborderContactsList?.items], "order_status");
      } else {
        list = [...joborderContactsList?.items].sort((a, b) => {
          const valueA =
            a[sortedField] != null && a[sortedField] != undefined
              ? typeof a[sortedField] == typeof "1"
                ? a[sortedField].trim().toLowerCase()
                : a[sortedField]
              : "";
          const valueB =
            b[sortedField] != null
              ? typeof b[sortedField] == typeof "1"
                ? b[sortedField].trim().toLowerCase()
                : b[sortedField]
              : "";
          if (sortDirection === "asc") {
            return valueA > valueB ? 1 : -1;
          } else {
            return valueA < valueB ? 1 : -1;
          }
        });
      }
    }
    return list ?? joborderContactsList?.items;
  }

  function callAPI(pageNum?: number, pageSize?: number) {
    dispatch(getContactsByJobOrderByPage({
      page: pageNum ?? currentPage,
      size: pageSize ?? size, job_id: id, search_str: searchValue
    }));
    setCurrentPage(pageNum ?? currentPage);
    if (!jobState.data?.company_id) dispatch(getJobById({ job_id: id }));
  }

  function handleRetry() {
    callAPI();
  }

  const handlePageChange = (pageNum: number, size: number) => {
    callAPI(pageNum, size);
    setSize(size ? size : joborderContactsList?.size!)
  };

  return (
    <div className={"job-contact-main-container"}>
      <div className={"job-contact-header"}>
        <div className={"job-contact-left-fields"}>
          <div>
            <SearchBar
              value={searchValue}
              onChange={handleSearchChange}
              onSearch={()=>{}}
            />
          </div>

          <div className={"total-count"}>
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">{totalCount}</span>
            </span>
          </div>
        </div>
        <div>
          <CustomButton
            textStyle={{ textTransform: "capitalize" }}
            leftIcon={getPlusBtnIcon()}
            name={t("add_existing_contact")}
            fontSize="0.792vw"
            enable={jobState.data?.company_id ? true : false}
            backgroundColor={THEME.buttonColor1}
            onClick={() => setShowCreateDialog(true)}
          />
        </div>
      </div>
      <div className="job-contact-content">
        {jobOrderContactState?.loading === LoadingType.pending &&
          sentRequest ? (
          <TableLoading />
        ) : (
          <TableBody
            state={jobOrderContactState}
            getFilteredList={getFilteredList}
            sortedField={sortedField}
            handleSortChange={handleSortChange}
            onRetry={handleRetry}
            data={joborderContactsList?.items}
            handleContactSelect={handleContactSelect}
            handleCompanySelect={handleCompanySelect}
            handleDepartmentSelect={handleDepartmentSelect}
            sortDirection={sortDirection}
          />
        )}
      </div>
      <div className="jcp-table-footer">
        <Pagination
          totalPages={joborderContactsList?.pages!}
          onPageChange={handlePageChange}
          curPageNum={currentPage}
          totalCount={totalCount}
          size={joborderContactsList?.size!}
          loadingState={jobOrderContactState?.loading}
        />
      </div>
      {showCreateDialog && jobState.data && (
        <Portal>
          <ExistingContactPopup
            job={jobState.data}
            visible={showCreateDialog}
            onClose={handleCloseAddDialog}
            onDissmiss={handleCloseAddDialog}
            onSuccessClose={handleOnSuccessAddDialog}
          />
        </Portal>
      )}
    </div>
  );

  function handleCloseAddDialog() {
    setShowCreateDialog(false);
  }

  function handleOnSuccessAddDialog() {
    setShowCreateDialog(false);
    dispatch(jobOrderContactCreateActions.clearJobOrderContactCreateState(id));
    callAPI()
  }

  function handleContactSelect(value: IJobOrderContact): void {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
      state: {
        id: value.contact_id,
        name: value.contact_name,
      },
    });
  }

  function handleCompanySelect(value: IJobOrderContact) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }
  function handleDepartmentSelect(value: ContactListItem, index) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_ids[index]}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_ids[index],
        departmentName: value.department_names[index],
      },
    });
    return;
  }
};

const TableBody = ({
  state,
  getFilteredList,
  sortedField,
  handleSortChange,
  onRetry,
  data,
  handleContactSelect,
  handleCompanySelect,
  handleDepartmentSelect,
  sortDirection
}) => {
  const { t } = useTranslation();

  function getDateTime(timestamp) {
    if (!timestamp) return "NA";
    let dateString = getDaysAgoWithDateAMPM(t, timestamp).split("-");
    return (
      <>
        {dateString[0]} - {dateString[1]}
      </>
    );
  }

  function returnIcon(method) {
    if (method === "EMAIL") return <EmailIcon />;
    if (method === "LINKEDIN") return <LinkedInIcon />;
    if (method === "PHONE")
      return <CallIcon style={{ color: THEME.primaryColor }} />;
    if (method === "TEXT") return <TextIcon />;
    if (method === "FACEBOOK") return <FacebookIcon />;
    if (method === "IN_PERSON") return <InPerson />;
    if (method === "ZOOM") return <Zoom />;
    if (method === "TEAMS") return <Teams />;
    if (method === "GOOGLE_MEET") return <GoogleMeet />;
    if (method === "ZIPRECRUITER")
      return (
        <span className="zip-recruiter">
          <ZipRecruiterIcon />
        </span>
      );
    if (method === "INDEED")
      return (
        <span className="indeed">
          <IndeedIcon />
        </span>
      );
    return method;
  }

  if (state.loading === LoadingType.failed) {
    return <TableErrorHandler error={state.error} onRefresh={onRetry} />;
  } else if (data && data.length === 0) {
    return <TableEmpty title={"No data found"} onClick={onRetry} />;
  }
  return (
    <SortableTable
      headerList={headers}
      sortedField={sortedField}
      onSortChange={handleSortChange}
      isAsc={sortDirection}
    >
      {getFilteredList().map((doc, index) => {
        return (
          <tr key={"cmp_contact_row" + index}>
            <TableData customStyle={{ flex: getFlexNumber(0) }}>
              {getTableAccessIcon(() => handleContactSelect(doc))}
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1) }}>
              <span>{getOpenOrdersRow(doc, "order_status", t)}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(2) }}
              isButton={true}
              onClick={() => handleContactSelect(doc)}
            >
              <span>{doc["contact_name"]}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(3) }}>
              <span>{doc["contact_title"]}</span>
            </TableData>
            <TableData
              customStyle={{ flex: getFlexNumber(4) }}
              isButton={doc["company_name"] ? true : false}
              onClick={() => handleCompanySelect(doc)}
            >
              <span>{doc["company_name"]}</span>
            </TableData>
            <td
              style={{
                flex: getFlexNumber(5),
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="table-data-container"
            >
              {doc.department_names &&
                doc.department_names.length !== 0 ?
                doc.department_names.map((item, index, arr) => (
                  <>
                    <a
                      className="table-data-btn"
                      onClick={() => handleDepartmentSelect(doc, index)}
                    >
                      {item} {index !== arr.length - 1 ? ", " : ""}
                    </a>
                  </>
                )) :
                <span>{t('corporate')}</span>
              }
            </td>
            <TableData customStyle={{ flex: getFlexNumber(6) }}>
              <span>
                {doc["last_contact"] ? getDateTime(doc["last_contact"]) : ""}
              </span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(7) }}>
              <span className="contact-method-icon">
                {returnIcon(doc["preferred_contact_method"])}
              </span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(8) }}>
              <span>
                {doc["status"] ?? ""}
              </span>
            </TableData>
          </tr>
        );
      })}
    </SortableTable>
  );

  function getFlexNumber(value: number) {
    if (value === 0) return 1;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1.2;
    if (value === 11) return 1.3;
    return 1;
  }
};

export default JobOrderContactPage;
