import React from 'react';
import './splash_page.scss';
import kinisoIcon from '../../../../assets/icons/kiniso.svg';


const SplashPage = ({ agencyLogoURL }) => {

    const getBakckgroundURL = () => {
        return agencyLogoURL && agencyLogoURL.trim() !== ""
            ? agencyLogoURL
            : kinisoIcon;
    };
    return (
        <div className="spl-container">
            <div className="spl-logo-size">
                <div className="spl-app-logo background-img" style={{ backgroundImage: `url(${getBakckgroundURL()})` }}></div>
            </div>
        </div>
    );
}

export default SplashPage;