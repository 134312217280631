
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientCompanyInterviewsList, { InterviewFeedbackCard } from '../../../../../components/client_company_interviews_list/client_company_interviews_list';
import { TableErrorHandler } from '../../../../../components/table_empty/table_empty';
import { LoadingType, THEME } from '../../../../../enums';
import { ChatIcon, CloseIcon,CompaniesIcon } from '../../../../../icons';
import { IJobOrderDetail, ITalentClientCompanyInterview } from '../../../../../interfaces';
import { SpinnerScreen } from '../../../../../utils';
import { dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateString } from '../../../../../variables';

import "./job_order_talent_page.scss";
import InterviewDetailsHeader from '../../../../talent/pages/interviews/components/interview_details_header/interview_details_header';
import { getJobById, getJobOrderTalentInterviewsToClientCompanies, selectJobDetailsByIdState, selectSingleJobInterviewsToClientCompaniesStateById, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { RouteComponentProps } from 'react-router-dom';
import JobsTalentClientCompanyInterviewPopup from '../../../popups/client_company_interview/client_company_interview';
import { jobOrderTalentSliceActions } from '../../../../../redux/job_order/details/talent/talent_list/talent_list_reducer';
import { AppRoutes } from '../../../../../routes';
import CurveTabNavigation from '../../../../../components/curve_tab_navigation/curve_tab_navigation';
import JobInterviewSendOutsToClientCompanies from '../job_order_interview_send_outs_to_client_companies/job_order_interview_send_outs_to_client_companies';

interface Props extends RouteComponentProps<any> { }
const JobOrderTalentPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    useEffect(() => {
        getClientCompanyInterviews();
        return () => { }
    }, [])
    const clientCompanyInterviewsState = useAppSelector((state) => selectSingleJobInterviewsToClientCompaniesStateById(params.id)(state));
    const jobStateData = useAppSelector((state) =>
        selectJobDetailsByIdState(params.id)(state)
    );
    const getFirstInterview = (data) => data && typeof data === 'object' ? data[Object.keys(data)[0]] : {};
    const firstClientCompanyInterview = getFirstInterview(clientCompanyInterviewsState?.data);    
    useEffect(() => {
        if (params && params.id != null) {
            dispatch(getJobById({ job_id: params.id }));
        }
        return () => { };
    }, [params.id]);
    const [editClientCompanyInterview, setEditClientCompanyInterview] = useState<{ visible: boolean, value: ITalentClientCompanyInterview | null }>({ visible: false, value: null });
    const [selectedClientCompanyInterview, setSelectedClientCompanyInterview] = useState<ITalentClientCompanyInterview | null>(null);
    useEffect(() => {
        if (selectedClientCompanyInterview != null) {
            const findSelected = clientCompanyInterviewsState.data[selectedClientCompanyInterview.id];
            if (findSelected) handleOnClientComapnyInterviewChange(findSelected);
        } else{
            setSelectedClientCompanyInterview(firstClientCompanyInterview)
        }
        return () => { }
    }, [clientCompanyInterviewsState.loading]);
    const [jobState, setJobStateData] = useState<IJobOrderDetail>();
    useEffect(() => {
        if (jobStateData && jobStateData.loading === LoadingType.succeeded && jobStateData.data) {
            setJobStateData(jobStateData.data)
        }
    }, [jobStateData, jobStateData.loading])


    const initialForm = {
        id: "",
        contact_id: "",
        talent_id: "",
        talent_interview_status_id: "",
        job_order_id: params.id,
        interview_with: "",
        interview_date: 0,
        talent_name: "",
        interview_status: "",
        talent_interview_feedback: "",
        contact_interview_feedback: "",
        company_name: "",
        department_name: "",
        job_title: "",
        company_id: "",
        department_id: "",
    }
    function handleOnClientComapnyInterviewChange(value: ITalentClientCompanyInterview) {
        setSelectedClientCompanyInterview(value);
    }
    function handleOnClientComapnyInterviewClose() {
        setSelectedClientCompanyInterview(null);
    }
    function handleAddNewClientCompanyInterview() {
        setEditClientCompanyInterview({ visible: true, value: null });
    }
    function getClientCompanyInterviews() {
        dispatch(getJobOrderTalentInterviewsToClientCompanies(params.id));
    }

    function handleEditClientCompanyInterview(value: ITalentClientCompanyInterview) {
        const dateSting = extractDateFromTimestamp(value?.interview_date!)
        const dateTimeStamp= dateToTimestamp(dateSting)
        const time = extractTimeFromTimestamp(value?.interview_date!)
        const updatedClientCompanyInterview= {
            ...value,
            interview_schedule_date: dateTimeStamp,
            interview_schedule_time: time
          }
        // setEditClientCompanyInterview({ visible: true, value: value });
        setEditClientCompanyInterview({ visible: true, value: updatedClientCompanyInterview });
    }
    if (clientCompanyInterviewsState.loading === LoadingType.pending) {
        return (
            <div className="tint-isocc-container">
                <SpinnerScreen />
            </div>
        )
    }
    if (clientCompanyInterviewsState.error != null) {
        <div className="tint-isocc-container">
            <TableErrorHandler error={clientCompanyInterviewsState.error} onRefresh={getClientCompanyInterviews} />
        </div>
    }
    function handleOnEditClientCompanyInterviewSuccess() {
        setEditClientCompanyInterview({ visible: false, value: null });
        dispatch(jobOrderTalentSliceActions.clearSingleTalentJobInterviewsToClientCompaniesStateError(params.id));
        getClientCompanyInterviews();

    }

    const handleCompanySelect=()=>{
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${selectedClientCompanyInterview?.company_id}`,
            state: {
                id: selectedClientCompanyInterview?.company_id,
                name: selectedClientCompanyInterview?.company_name,
            },
        });
        
    }
    const handleDeptSelect=()=>{
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${selectedClientCompanyInterview?.company_id}/dashboard/${selectedClientCompanyInterview?.department_id}`,
            state: {
              id: selectedClientCompanyInterview?.company_id,
              name: selectedClientCompanyInterview?.company_name,
              departmentId: selectedClientCompanyInterview?.department_id,
              departmentName: selectedClientCompanyInterview?.department_name,
            },
          });

    }
    const handleContactSelect=()=>{
        props.history.push({
            pathname: `${AppRoutes.contactsDetailsPage}/${selectedClientCompanyInterview?.contact_id}`,
            state: {
              id: selectedClientCompanyInterview?.contact_id,
              name: selectedClientCompanyInterview?.interview_with,
            },
          });
    }
    const handleTalentSelect=()=>{
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${selectedClientCompanyInterview?.talent_id}`,
            state: {
              id: selectedClientCompanyInterview?.talent_id,
              name: selectedClientCompanyInterview?.talent_name,
            },
          });
    }

    const getTabList = () => {
        return [
          {
            title: t("interview_send_outs_to_client_companies").toUpperCase(),
            icon: CompaniesIcon,
            content: (
              <JobInterviewSendOutsToClientCompanies
                onInterviewClick={handleOnClientComapnyInterviewChange}
                onAddNew={handleAddNewClientCompanyInterview}
                state={clientCompanyInterviewsState}
                onRefresh={getClientCompanyInterviews}
                selectedInterview={selectedClientCompanyInterview}
                onEditInterview={handleEditClientCompanyInterview}
                onCloseInterview={handleOnClientComapnyInterviewClose}
                handleCompanySelect={handleCompanySelect}
                handleDeptSelect={handleDeptSelect}
                handleContactSelect={handleContactSelect}
                handleTalentSelect={handleTalentSelect}
              />
            ),
          },
        ];
      };

    return (
        <div className="job-orders-interviews">
      <CurveTabNavigation
        tabs={getTabList()}
        selected={0}
        onChange={() => {}}
      />
      {/*
            <>
        <div className="tint-isocc-container">
            <div className="tint-isocc-left-panel">
                <ClientCompanyInterviewsList
                    interviews={clientCompanyInterviewsState.data ? Object.keys(clientCompanyInterviewsState.data).map((key) => clientCompanyInterviewsState.data[key]) : []}
                    onClick={handleOnClientComapnyInterviewChange}
                    onAddNew={handleAddNewClientCompanyInterview}
                    selectedInterview={selectedClientCompanyInterview}
                />
                <div className="tint-left-panel-shape-pyramid"></div>
            </div>
            <div className="tint-isocc-right-panel">
                {selectedClientCompanyInterview && <div className="tint-isocc-details-holder">
                    <div className="tint-isocc-icon">
                        <ChatIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </div>
                    <div className="tint-isocc-content">
                        <div className="tint-isocc-title">
                            <span>{t('client_company_interview')}</span>
                            <button className="icon-btn close-icon" onClick={handleOnClientComapnyInterviewClose}>
                                <CloseIcon width={"100%"} height={"100%"} />
                            </button>
                        </div>
                        <div className="tint-isocc-info-header">
                            <InterviewDetailsHeader
                                list={[
                                    { title: t('company'), value: selectedClientCompanyInterview.company_name, isButton: true, handleCompanyClick: handleCompanySelect, style: {fontWeight: 800, width: "5vw" } },
                                    { title: t('department'), value: selectedClientCompanyInterview.department_name, isButton: true, handleDeptClick: handleDeptSelect, style: {fontWeight: 800, width: "5vw" } },
                                    { title: t('iterviewer'), value: selectedClientCompanyInterview.interview_with, isButton: true, handleContactClick: handleContactSelect, style: {fontWeight: 800, width: "5vw" } },
                                    { title: t('talent'), value: selectedClientCompanyInterview.talent_name, isButton: true, handleTalentClick: handleTalentSelect, style: {fontWeight: 800, width: "10vw" }},
                                    { title: t('interview_date'), value: getDateString(selectedClientCompanyInterview?.interview_date!), style: {fontWeight: 800, width: "5vw" } },
                                    { title: t('interview_status'), value: selectedClientCompanyInterview.interview_status, style: {fontWeight: 800, width: "10vw" } },
                                ]}
                                onEditInterview={() => handleEditClientCompanyInterview(selectedClientCompanyInterview!)}
                            />
                        </div>
                        <div className="tint-isocc-info-content">
                            <InterviewFeedbackCard
                                talent_feedback={selectedClientCompanyInterview.talent_interview_feedback}
                                client_company_contact_feedback={selectedClientCompanyInterview.contact_interview_feedback}
                            />
                             {(selectedClientCompanyInterview.talent_interview_questions ?? []).map((doc, index) => {
                                return (
                                    <InterviewQuestionCard
                                        doc={doc}
                                        index={index}
                                    />
                                )
                            })} 
                        </div>
                    </div>
                </div>
                </div>
                
        </>
}*/}

      {editClientCompanyInterview.visible && (
        <JobsTalentClientCompanyInterviewPopup
          visible={editClientCompanyInterview.visible}
          title={
            editClientCompanyInterview.value
              ? t("edit_client_company_interview")
              : t("new_client_company_interview")
          }
          jobId={params.id}
          interview={editClientCompanyInterview.value}
          onClose={() =>
            setEditClientCompanyInterview({ visible: false, value: null })
          }
          onDissmiss={() =>
            setEditClientCompanyInterview({ visible: false, value: null })
          }
          onSuccessClose={handleOnEditClientCompanyInterviewSuccess}
          jobState={jobState}
        />
      )}
    </div>

    )

}
export default JobOrderTalentPage;