import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '../../../components/date_input/date_input';
import RenderInput from '../../../components/render_input/render_input';
import { FormInputOption, IWorkHistory } from '../../../interfaces';
import { CustomCheckBox, FormInput } from '../../../utils';
import './work_history_form.scss';

interface Props {
    formState: IWorkHistory,
    handleFieldChange: (id: string, value: any) => void,
}

const WorkHistoryForm: React.FunctionComponent<Props> = (props) => {
    const { formState, handleFieldChange } = props;
    const { t } = useTranslation();
    const formOptions : (FormInputOption | FormInputOption[])[] = [
        [
            {
                field: "job_title",
                type: "text",
                label: 'job_title',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "start_date",
                type: "date",
                label: 'start_date',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: '',
                setHoursToZero: true,
            },
        ],
        [
            {
                field: "company_name",
                type: "text",
                label: 'company_name',
                secure: false,
                required: true,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "end_date",
                type: "date",
                label: 'end_date',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                setHoursToZero: true,
                disabled: props.formState.is_currently_work_here && true
            },
        ],
        [
            {
                field: "company_department",
                type: "text",
                label: 'department_name',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: ''
            },
            {
                field: "is_currently_work_here",
                type: "checkbox",
                label: 'i_currently_work_here',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                disabled: props.formState.end_date ? true : false
            },
        ],
        {
            field: "job_accomplishments_details_and_responsibilities",
            type: "text",
            label: 'job_accomplishments_details_and_responsibilities',
            secure: false,
            required: false,
            multiline: true,
            placeholder: '',
            keyboardtype: ''
        },
    
    ]
    return (
        <div className="oa-wrh-form-container">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {

                    return (
                        <div key={index+ 'row'} className="oa-wrh-form-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index+subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={formValidators}
                                        className={"oa-wrh-f-input-holder"}
                                    />
                                );
                            })}
                        </div>
                    )
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={formValidators}
                        className={"oa-wrh-f-input-holder"}
                    />
                )
            })}
        </div>
    );

    function formValidators(value: { text: string, field: string, }) {
        switch (value.field) {
            case "":
            case "":
            case "": {
                if (value.text == '') return t('validators.required');
                return null;
            };
            case "": {
                if (value.text == '') return t('validators.required');
                // if (RegxPattern.email.test(value.text) === false) return t('validators.enterValidEmail');
                return null;
            };
            default: {
                return null;
            }
        }
    }
}

export default WorkHistoryForm;