import { createSlice } from "@reduxjs/toolkit";
import * as thunk from "./deduction_authority_manage_batch_thunk";
import { AppError } from "../../../../interfaces";
import { LoadingType } from "../../../../enums";
import { ICreateDeductionAuthorityBatch, IDeductionAuthority, IPaycheckDeductionAuthority } from "../../../../interfaces/back_office_deduction_authority";

export interface DeductionAuthorityManageBatchState {
  getDedAuth: {
    error?: AppError | null;
    loading: LoadingType;
    response: IDeductionAuthority | null;
  }
  getDedAuthPayChecks: {
    error?: AppError | null;
    loading: LoadingType;
    response: IPaycheckDeductionAuthority[] | null;
  }
  dedAuthPost: {
    error?: AppError | null;
    loading: LoadingType;
    response: ICreateDeductionAuthorityBatch | null;
  },
  dedAuthPatch: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  dedAuthDelete: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  },
  dedAuthDwnPDFViewCheck: {
    error?: AppError | null;
    loading: LoadingType;
    response: string;
  },
}

const initialState: DeductionAuthorityManageBatchState = {
  getDedAuth: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  getDedAuthPayChecks: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  dedAuthPost: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  dedAuthPatch: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  dedAuthDelete: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  dedAuthDwnPDFViewCheck: {
    error: null,
    loading: LoadingType.idle,
    response: "",
  },
};

export const deductionAuthorityManageBatchSlice = createSlice({
  name: "deductionAuthorityManageBatch",
  initialState: initialState,
  reducers: {
    clearGetDedAuthBatchState(state) {
      return {
        ...state, getDedAuth: { ...initialState.getDedAuth }
      }
    },
    clearGetDedAuthBatchErrorState(state) {
      return {
        ...state, getDedAuth: { ...initialState.getDedAuth, error: null }
      }
    },
    clearGetDedAuthPayChecksBatchState(state) {
      return {
        ...state, getDedAuthPayChecks: { ...initialState.getDedAuthPayChecks }
      }
    },
    clearGetDedAuthPayChecksBatchErrorState(state) {
      return {
        ...state, getDedAuthPayChecks: { ...initialState.getDedAuthPayChecks, error: null }
      }
    },
    clearPostBatchState(state) {
      return {
        ...state, dedAuthPost: { ...initialState.dedAuthPost }
      }
    },
    clearPostBatchErrorState(state) {
      return {
        ...state, dedAuthPost: { ...initialState.dedAuthPost, error: null }
      }
    },
    clearPatchBatchState(state) {
      return {
        ...state, dedAuthPatch: { ...initialState.dedAuthPatch }
      }
    },
    clearPatchBatchErrorState(state) {
      return {
        ...state, dedAuthPatch: { ...initialState.dedAuthPatch, error: null }
      }
    },
    clearDeleteBatchState(state) {
      return {
        ...state, dedAuthDelete: { ...initialState.dedAuthDelete },
      };
    },
    clearDeleteBatchErrorState(state) {
      return {
        ...state, dedAuthDelete: { ...initialState.dedAuthDelete, error: null, },
      };
    },
    clearViewPaycheckBatchState(state) {
      return {
        ...state, dedAuthDwnPDFViewCheck: { ...initialState.dedAuthDwnPDFViewCheck },
      };
    },
    clearViewPaycheckBatchErrorState(state) {
      return {
        ...state, dedAuthDwnPDFViewCheck: { ...initialState.dedAuthDwnPDFViewCheck, error: null, },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getDeductionAuthorityBatchThunk.pending, (state, action) => ({ ...state, getDedAuth: { ...state.getDedAuth, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.getDeductionAuthorityBatchThunk.rejected, (state, action) => ({ ...state, getDedAuth: { ...state.getDedAuth, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.getDeductionAuthorityBatchThunk.fulfilled, (state, action) => ({ ...state, getDedAuth: { ...state.getDedAuth, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.getDeductionAuthorityPaychecksBatchThunk.pending, (state, action) => ({ ...state, getDedAuthPayChecks: { ...state.getDedAuthPayChecks, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.getDeductionAuthorityPaychecksBatchThunk.rejected, (state, action) => ({ ...state, getDedAuthPayChecks: { ...state.getDedAuthPayChecks, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.getDeductionAuthorityPaychecksBatchThunk.fulfilled, (state, action) => ({ ...state, getDedAuthPayChecks: { ...state.getDedAuthPayChecks, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.createDeductionAuthorityBatchThunk.pending, (state, action) => ({ ...state, dedAuthPost: { ...state.dedAuthPost, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.createDeductionAuthorityBatchThunk.rejected, (state, action) => ({ ...state, dedAuthPost: { ...state.dedAuthPost, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.createDeductionAuthorityBatchThunk.fulfilled, (state, action) => ({ ...state, dedAuthPost: { ...state.dedAuthPost, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.updateDeductionAuthorityBatchThunk.pending, (state, action) => ({ ...state, dedAuthPatch: { ...state.dedAuthPatch, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.updateDeductionAuthorityBatchThunk.rejected, (state, action) => ({ ...state, dedAuthPatch: { ...state.dedAuthPatch, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.updateDeductionAuthorityBatchThunk.fulfilled, (state, action) => ({ ...state, dedAuthPatch: { ...state.dedAuthPatch, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.deleteDeductionAuthorityBatchThunk.pending, (state, action) => ({ ...state, dedAuthDelete: { ...state.dedAuthDelete, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.deleteDeductionAuthorityBatchThunk.rejected, (state, action) => ({ ...state, dedAuthDelete: { ...state.dedAuthDelete, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.deleteDeductionAuthorityBatchThunk.fulfilled, (state, action) => ({ ...state, dedAuthDelete: { ...state.dedAuthDelete, loading: LoadingType.succeeded, error: null, response: action.payload } }))
      .addCase(thunk.getDedAuthPaycheckPDFURLThunk.pending, (state, action) => ({ ...state, dedAuthDwnPDFViewCheck: { ...state.dedAuthDwnPDFViewCheck, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.getDedAuthPaycheckPDFURLThunk.rejected, (state, action) => ({ ...state, dedAuthDwnPDFViewCheck: { ...state.dedAuthDwnPDFViewCheck, loading: LoadingType.failed, error: action.payload, } }))
      .addCase(thunk.getDedAuthPaycheckPDFURLThunk.fulfilled, (state, action) => ({ ...state, dedAuthDwnPDFViewCheck: { ...state.dedAuthDwnPDFViewCheck, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addDefaultCase((state) => ({ ...state }));
  },
});
export const deductionAuthorityManageBatchSliceActions = deductionAuthorityManageBatchSlice.actions;
export const selectState = (state: DeductionAuthorityManageBatchState) => state;
