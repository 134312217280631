import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import { DateInput } from "../../../../components/date_input/date_input";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { LoadingType, THEME } from "../../../../enums";
import { EditIcon, ScheduledCallIcon } from "../../../../icons";
import {
  AppError,
  ICreateResponse,
  IDepartment,
  IProfileDropdown,
} from "../../../../interfaces";
import { SalesCall } from "../../../../interfaces/sales_call";
import { getSalesCallFilteredList } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_thunk";
import { selectManageSalesCallUpdateState } from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_selector";
import {
  completeUpdateSalesCall,
  updateSalesCall,
} from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_thunk";
import { contactsDropdownListActions } from "../../../../redux/selectors/contacts_list/contact_list_reducer";
import { departmentsDropdownListActions } from "../../../../redux/selectors/departments_list/departments_list_reducer";
import {
  getCompaniesDropdown,
  getContactsByCompany,
  getContactsDropdownThunk,
  getDepartmentsDropdown,
  getDepartmentsList,
  postSnackbarMessage,
  selectCompaniesDropdownList,
  selectCompanyContactsList,
  selectContactsDropdownList,
  selectDepartmentsDropdownList,
  selectDepartmentsList,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import {
  CustomButton,
  FormInput,
  SpinnerScreen,
  mapOptions,
  renderDay,
} from "../../../../utils";
import {
  contactMethodOptions,
  convertDateToTimeStamp,
  lowBtnStyle,
} from "../../../../variables";
import "./complete_sales_call.scss";
import { manageSalesCallActions } from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_reducer";

export interface CompleteSalesCallProps {
  showModal: boolean;
  callType: string;
  salesCall: SalesCall;
  successTitle: string;
  currentLoginUserName?: string;
  currentLoginUserId?: string;
  usersList: IProfileDropdown[];
  onGotoAgencyDashboard: () => void;
  onGotoCreated: (id: string, name: string) => void;
  onClose: (plan_next?: boolean) => void;
  onDissmiss?: () => void;
  onSuccessClose: () => void;
  planNextCall: Function;
  availableFrom: number;
  availableTo: number;
  showCompleted: boolean;
  clearStateSource: () => void;
  completeUpdateState: {
    error?: AppError | null;
    loading: LoadingType;
    response?: ICreateResponse;
  };
  onHandlePlanSalescallEdit: (
    val: SalesCall,
    isCompletePlanForm: boolean
  ) => void;
}

export const CompleteSalesCall: FC<CompleteSalesCallProps> = (
  props: CompleteSalesCallProps
) => {
  const {
    showModal,
    callType,
    salesCall,
    planNextCall,
    usersList,
    availableFrom,
    availableTo,
    showCompleted,
    clearStateSource,
    completeUpdateState,
    onSuccessClose,
    onClose,
    onHandlePlanSalescallEdit,
  } = props;
  const [call, setCall] = useState<SalesCall>(salesCall);

  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<
    boolean | undefined
  >();
  const actionRef = useRef<ActionDialogHolderType>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const contactsList = useAppSelector((state) =>
    selectCompanyContactsList(state)
  );

  const contactsDropdownList = useAppSelector((state) =>
    selectContactsDropdownList(state)
  );

  const departmentsDropdown = useAppSelector((state) =>
    selectDepartmentsDropdownList(state)
  );

  const companiesDropdown = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );

  const allDepartments = useAppSelector((state) =>
    selectDepartmentsList(state)
  );

  useEffect(() => {
    dispatch(getDepartmentsList());
    dispatch(getCompaniesDropdown());
    if (salesCall && salesCall.company_id) {
      dispatch(
        getContactsDropdownThunk({
          company_id: salesCall.company_id,
          department_id: salesCall.department_id,
        })
      );
      dispatch(getDepartmentsDropdown(salesCall.company_id));
    }
  }, []);

  useEffect(() => {
    dispatch(
      getContactsByCompany({
        company_id: salesCall.company_id,
        department_id: salesCall.department_id ?? null,
      })
    );
    if (!salesCall.call_date_time) {
      setCall({ ...salesCall, call_date_time: salesCall.schedule_date_time });
    }
  }, []);

  useEffect(() => {
    if (
      (call && call.company_id === "") ||
      call.company_id === null ||
      call.company_id === undefined
    ) {
      setCall({
        ...call,
        contact_id: "",
        department_id: "",
      });
    }
  }, [salesCall.company_id]);

  const showSpinner = (): boolean => false;

  const handleSave = () => {
    setCheckValidationOnSubmit(true);
    if (!isFormValid()) {
      return;
    }
    const newCall = { ...call };
    newCall.is_completed = true;
    const today = new Date();
    newCall.date_of_completed = convertDateToTimeStamp(today);
    newCall.call_date_time = convertDateToTimeStamp(today);
    dispatch(
      completeUpdateSalesCall({
        data: newCall,
        salesCallsShowCompleted: showCompleted,
        salesCallsAvailableFrom: availableFrom,
        salesCallsAvailableTo: availableTo,
      })
    );
    setCheckValidationOnSubmit(false);
    // clearStateSource();
  };

  const updateSalesCallProp = (
    key: string,
    value: string | boolean | number
  ) => {
    const newsalesCall = { ...call };
    newsalesCall[key] = value;
    setCall(newsalesCall);
  };

  const handleDepartmentChange = (value) => {
    updateSalesCallProp("department_id", value);
    if (value) {
      dispatch(
        getContactsDropdownThunk({
          company_id: salesCall.company_id,
          department_id: value,
        })
      );
    } else {
      dispatch(contactsDropdownListActions.clearContactDropdownListState());
    }
  };

  const handleCompanyChange = (value) => {
    updateSalesCallProp("company_id", value);
    if (!value) {
      setDepartments(allDepartments);
      dispatch(
        departmentsDropdownListActions.clearDepartmentsDropdownListState()
      );
      dispatch(contactsDropdownListActions.clearContactDropdownListState());
    } else {
      dispatch(getDepartmentsDropdown(value));
      setDepartments(
        allDepartments.filter((dept) => dept.company_id === value)
      );
      /*dispatch(
        getContactsDropdownThunk({
          company_id: value,
          department_id: salesCall.department_id,
        })
      );*/
    }
  };

  const isFormValid = () =>
    !!call.call_result &&
    !!call.contact_id &&
    !!call.company_id &&
    !!call.contact_method;

  const formValidators = (value: { text: string; field: string }) => {
    switch (value.field) {
      case "call_result":
      case "contact_method":
      case "company_id":
      case "contact_id": {
        if (!value.text) return t("validators.required");
        return null;
      }
      default:
        return null;
    }
  };

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={showModal}
      wrapperWidth={"60%"}
      onClose={onClose}
      onDissmiss={onClose}>
      <DialogWrapper
        onClose={() => {
          closePopup(onClose);
          // onSuccessClose();
        }}>
        {showSpinner() ? (
          <div className="loading-spinner">
            <SpinnerScreen />
          </div>
        ) : (
          getForm()
        )}
      </DialogWrapper>
    </ActionDialogHolder>
  );

  function handleGotoAgencyDashboard() {
    closePopup(props.onGotoAgencyDashboard);
  }

  function handleGotoCreated(name: string) {
    if (props.salesCall && props.salesCall.id) {
      // closePopup(props.onSuccessClose);
    } else if (
      completeUpdateState.response &&
      completeUpdateState.response.object_id
    ) {
      closePopup(() =>
        props.onGotoCreated(completeUpdateState?.response?.object_id!, name)
      );
    }
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  function getForm() {
    if (completeUpdateState.loading === LoadingType.succeeded) {
      return (
        <div className="tlnt-f-container">
          <div className="tlnt-f-header">
            <FormDialogTilteHeader
              title={props.successTitle}
              titleIcon={<ScheduledCallIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <div className="tlnt-f-content">
            <div className="saved-doc-holder">
              <DocSavedText>
                <span className="aknw-name">
                  {t("good_job", {
                    name: props.currentLoginUserName,
                  })}
                </span>
                <span className="info-txt">{`${t(
                  "complete_call_recorded"
                )}`}</span>
              </DocSavedText>
            </div>
          </div>
          <div className="tlnt-f-actions">
            <div className="btn-cancel">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
                name={t("back_to_my_agency_dashboard")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={handleGotoAgencyDashboard}
                customStyle={lowBtnStyle.btn}
              />
            </div>

            <div className="btn-save">
              <CustomButton
                loading={false}
                textStyle={{ textTransform: "capitalize" }}
                name={t("Schedule My Next Call with this Contact")}
                enable={true}
                backgroundColor={THEME.defaultHighLightColor}
                onClick={() => {
                  planNextCall(salesCall);
                  onClose(true);
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="complete-sales-call">
        <div className="sales-call-header">
          <FormDialogTilteHeader
            title={`Complete Sales Call: ${salesCall.status}`}
            titleIcon={<ScheduledCallIcon width={"100%"} height={"100%"} />}
          />
          {!showCompleted && (
            <button
              className="edit-sales-call"
              onClick={() => onHandlePlanSalescallEdit(call, true)}>
              <EditIcon
                width={"100%"}
                height={"100%"}
                style={{ color: "#fff" }}
              />
            </button>
          )}
        </div>
        <div className="form-content">
          <div className="form-row section-divider">
            <div style={{ width: "45%" }}>
              <div className="form-row1 ">
                <div className="form-item">
                  <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    name="company_id"
                    value={call?.company_id ?? salesCall?.company_id}
                    label={"company"}
                    placeholder={"select"}
                    list={mapOptions(companiesDropdown, "name", "id")}
                    onChange={handleCompanyChange}
                    required
                    validations={formValidators}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    clickActionType={"company"}
                    clickableValue={true}
                  />
                </div>
                <div className="form-item">
                  <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    name="department_id"
                    value={call?.department_id ?? salesCall?.department_id}
                    label={"department"}
                    placeholder={t("Corporate")}
                    list={mapOptions(departmentsDropdown, "name", "id")}
                    onChange={handleDepartmentChange}
                  />
                </div>
                <div className="form-item">
                  <DateInput
                    id={"schedule_date_time"}
                    onChange={updateSalesCallProp}
                    label={"Date of Scheduled call"}
                    placeholder={""}
                    value={
                      call?.schedule_date_time ?? salesCall.schedule_date_time
                    }
                    disableFuture
                  />
                </div>
                <div className="form-item">
                  <CustomFormSelect
                    customStyle={{ width: "-webkit-fill-available" }}
                    name="contact_id"
                    value={call.contact_id ?? salesCall?.contact_id}
                    label={"Company Contact"}
                    placeholder={t("select_placeholder")}
                    list={mapOptions(contactsDropdownList ?? [], "name", "id")}
                    onChange={(value) =>
                      updateSalesCallProp("contact_id", value)
                    }
                    required
                    validations={formValidators}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    clickActionType={"contact"}
                    clickableValue={true}
                  />
                </div>
              </div>
            </div>
            <div className="form-item" style={{ width: "50%" }}>
              <FormInput
                id={"call_goal"}
                onChange={(f, v) => updateSalesCallProp(f, v)}
                multiline
                type={"text"}
                value={call.call_goal ?? ""}
                label={"Call Goal"}
                placeholder=""
                numberOfLines={10}
              />
            </div>
          </div>
          <div className="form-row section2">
            <div style={{ width: "45%" }}>
              <div className="form-row1">
                <div className="form-item">
                  <CustomFormSelect
                    value={call.contact_method ?? salesCall.contact_method}
                    name="contact_method"
                    label={"Call Method"}
                    placeholder={t("select_placeholder")}
                    list={contactMethodOptions}
                    onChange={(value) =>
                      updateSalesCallProp("contact_method", value)
                    }
                    customStyle={{ width: "-webkit-fill-available" }}
                    validations={formValidators}
                    checkValidationOnSubmit={checkValidationOnSubmit}
                    required
                  />
                </div>
                <div className="form-item">
                  <CustomFormSelect
                    value={call.call_made_to ?? salesCall.contact_id}
                    name="call_made_to"
                    label={"Call made to"}
                    placeholder={t("select_placeholder")}
                    list={mapOptions(
                      contactsList.data ?? [],
                      "contact_name",
                      "id"
                    )}
                    onChange={(value) =>
                      updateSalesCallProp("call_made_to", value)
                    }
                    customStyle={{ width: "-webkit-fill-available" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="form-item">
                <div className="label" style={{ marginTop: 0 }}>
                  On what date did you make this call?
                </div>
                <DateInput
                  id={"call_date_time"}
                  onChange={updateSalesCallProp}
                  placeholder={""}
                  value={convertDateToTimeStamp(new Date())}
                  renderDay={renderDay}
                  disableFuture
                />
              </div>
            </div>
          </div>
          <div className="form-row section3">
            <div style={{ width: "45%" }}>
              <div className="form-item">
                <div className="label">
                  Were you able to communicate with someone at this company?
                </div>
                <ToggleSwitch
                  label=""
                  checked={call.communicated_with_someone}
                  onChange={(value) => {
                    updateSalesCallProp("communicated_with_someone", value);
                  }}
                />
              </div>
              <div className="form-item">
                <div className="label">Is this person a Decision Maker ?</div>
                <ToggleSwitch
                  label=""
                  checked={call.communicated_with_dm}
                  onChange={(value) => {
                    updateSalesCallProp("communicated_with_dm", value);
                  }}
                />
              </div>
              <div className="call-cplan-assign">
                <div className="form-item">
                  <FormInput
                    id={"planned_by_id"}
                    placeholder={""}
                    type={"text"}
                    label={t("this_call_is_planned_by")}
                    labelStyles={{
                      fontSize: "0.850vw",
                      fontWeight: 700,
                      textTransform: "none",
                    }}
                    customStyle={{ width: "11.62vw" }}
                    value={call?.company_id && call?.planned_by_name}
                    onChange={updateSalesCallProp}
                    disabled
                  />
                </div>
                <div className="psc-assign-call">
                  <CustomFormSelect
                    customStyle={{ width: "11.62vw" }}
                    name="assigned_to_id"
                    value={call.assigned_to_id ?? salesCall.assigned_to_id}
                    label={t("this_call_is_assigned_to")}
                    placeholder={t("select_placeholder")}
                    list={usersList.map((doc) => ({
                      label: `${doc.first_name ?? ""} ${doc.last_name ?? ""}`,
                      value: doc.id,
                    }))}
                    onChange={(value) =>
                      updateSalesCallProp("assigned_to_id", value)
                    }
                    menuPlacement={"top"}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="form-item">
                <FormInput
                  id={"call_result"}
                  onChange={(f, v) => updateSalesCallProp(f, v)}
                  multiline
                  type={"text"}
                  value={call.call_result ?? ""}
                  label={"What were the Call Results?"}
                  placeholder=""
                  numberOfLines={10}
                  required
                  validations={formValidators}
                  checkValidationOnSubmit={checkValidationOnSubmit}
                  customInputStyle={{ height: "19vh" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-actions">
          <div className="btn-cancel">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => closePopup()}
              enable
            />
          </div>
          <div className="btn-save">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("record_this_call")}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleSave}
              loading={completeUpdateState.loading === LoadingType.pending}
            />
          </div>
        </div>
      </div>
    );
  }
};
