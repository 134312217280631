import SearchBar from '../../../../components/search_bar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IJobOrder, IJobOrdersByPage } from '../../../../interfaces';
import {
    postSnackbarMessage,
    selectJobsListState,
    useAppDispatch,
    useAppSelector,
    getRoleUsersList,
    selectAccountManagerRoleUsersList,
    selectRecruiterRoleUsersList,
    getJobsByPage
} from '../../../../redux/store';
import { AppRoutes } from '../../../../routes';
import './universal_directory_page.scss';
import { CustomButton, sortByOrders, currencyConversion, getTableAccessIcon, getPlusBtnIcon, convertNumberToMoney, formatNumber } from '../../../../utils';
import { JOB_STATUS, LoadingType, THEME } from '../../../../enums';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../../components/table_empty/table_empty';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import { Portal } from 'react-portal';
import { Rating } from '@material-ui/lab';
import { TickGreenIcon, TickGreyIcon, TickRedIcon } from '../../../../icons';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../components/form_selector/form_select';
import { jobsStatusOptions, payFeePlacementType } from '../../../../variables';
import CreateJobPopup from '../../popups/create_job/create_job';
import { manageJobActions } from '../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer';
import { jobPostQuestionSliceActions } from '../../../../redux/job_order/details/success_profile/job_post/job_post_reducer';
import Pagination from '../../../components/pagination/pagination';

interface Props extends RouteComponentProps<any> { }
const tableHeader = [
    { title: 'access_job', code: 'access_job' },
    { title: 'Orders', code: 'orders' },
    { title: 'Job Order', code: 'job_title' },
    { title: 'Company', code: 'company_name' },
    { title: 'Department', code: 'department_name' },
    { title: 'Rating', code: 'rating' },
    { title: 'Status', code: 'job_status' },
    { title: 'Pay Rate', code: 'regular_pay_rate' },
    { title: 'Bill Rate', code: 'regular_bill_rate' },
    { title: 'Requested', code: 'requested' },
    { title: 'Placed', code: 'placed' },
    { title: 'Still Needed', code: 'open_orders' },
];
interface State {
    from?: {
        state?: {
            id: string;
            status: string;
        };
    };
}

const JobsPage: React.FunctionComponent<Props> = (props) => {
    const { history } = props;
    const { location } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const jobListState = useAppSelector((state) => selectJobsListState(state));
    const [jobList, setJobList] = useState<IJobOrdersByPage | null>(null);
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);

    const [sortDirection, setSortDirection] = useState("asc");

    const [showAddJob, setShowAddJob] = useState<boolean>(false);
    const [gotoJobOrder, setGotoJobOrder] = useState(false);
    const [newJobId, setNewJobId] = useState<string>('');
    const [title, setTitle] = useState("");
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [recruiters, setRecruiters] = useState<string[]>([]);
    const [status, setStatus] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(100);
    const [prevFilter, setPrevFilter] = useState<boolean>(false);

    useEffect(() => {
        const state = location.state;
        if (state && state["openOrder"]) {
            const openOrder = ['OPEN']
            setStatus(openOrder)
            setPrevFilter(true)
        }
    }, [location]);

    useEffect(() => {
       const state = history.location.state as State;
       if (state?.from?.state) {
         status.push(state.from.state.status)
       }
    }, [history]);

    useEffect(() => {
        checkAddJob();
        dispatch(getRoleUsersList());
        dispatch(jobPostQuestionSliceActions.clearState());
        const openOrder = (location.state as any)?.openOrder;
        if (openOrder === false || openOrder === undefined || openOrder === null) {
            getJobs({ pageNum: 1 });
        }
    }, [location]);
    useEffect(() => {
        const openOrder = (location.state as any)?.openOrder;
        if (openOrder === true && prevFilter) {
            getJobs({ pageNum: 1 });
        }
    }, [location, prevFilter]);

    useEffect(() => {
        if (search?.length >= 2) return getJobs({ pageNum: 1 });
        return () => {}
    }, [search])

    useEffect(() => {
        if (jobListState?.jobsByPage?.loading === LoadingType.succeeded && jobListState?.jobsByPage?.response) {
            setJobList(jobListState?.jobsByPage?.response)
            return () => {
                setJobList(null)
            };
        }
    }, [jobListState?.jobsByPage?.loading, jobListState?.jobsByPage?.response]);

    useEffect(() => {
        if (jobListState?.jobsByPage?.error != null && jobList?.items?.length != 0) {
            dispatch(postSnackbarMessage(jobListState?.jobsByPage?.error?.message));
        }
        return () => {
        }
    }, [jobListState?.jobsByPage?.error]);

    useEffect(() => {
        if (gotoJobOrder && newJobId) {
            history.push({
                pathname: `${AppRoutes.jobDetailsPage}/${newJobId}/dashboard`,
                state: {
                    id: newJobId,
                    name: title
                },
            });
            setGotoJobOrder(false);

        }
    }, [gotoJobOrder])


    const getJobs = (doc?: { pageNum?: number, pageSize?: number, account_managers?: Array<string>, recruiters_ids?: Array<string>, status_ids?: Array<string>, search_str?: string }) => {
        dispatch(getJobsByPage({
            page: doc?.pageNum ?? currentPage,
            size: doc?.pageSize ?? size, filter_ids: { acc_manager_ids: doc?.account_managers ?? accountManagers, recruiter_ids: doc?.recruiters_ids ?? recruiters, statuses: doc?.status_ids ?? status }, search_str: doc?.search_str ?? search
        }));
        setCurrentPage(doc?.pageNum ?? currentPage);
        setSize(doc?.pageSize ?? size)
    }
    function checkAddJob() {
        setTimeout(() => {
            if (
                location &&
                location.state != null &&
                (location.state as any).newJoborder === true
            ) {
                setShowAddJob(true);
            }
        }, 1000);
    }
    function getFilteredList(): IJobOrder[] {
        let sortedList: IJobOrder[] | undefined;

        if (sortedField != null) {
            if (sortedField === "orders") {
                sortedList = sortByOrders([...jobList?.items!], "job_status");
            } else {
                sortedList = [...jobList?.items!].sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] != undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }
        }
        return sortedList ?? jobList?.items!
    }

    function handleSortFieldChange(value: string) {
        if (value === "open_orders") {
            if (sortedField && sortedField === value) {
            } else {
                setSortedField(value);
            }
        }
        else {
            if (sortedField && sortedField === value) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortedField(value);
                setSortDirection('asc');
            }
        }
    }

    function getOpenOrdersInfo(doc: any) {
        return (
            <>
                {doc.requested > 0 ?
                    <>
                        {doc.job_status === "OPEN" && <div className="open-orders-empty">
                            <div className="ord-icon">
                                <TickRedIcon width={"100%"} height={"100%"} />
                            </div>
                            <div className="open-ord-text">
                                <span>{`${doc.open_orders} Open`}</span>
                            </div>
                        </div>
                        }
                        {doc.job_status === "FILLED" && <div className="open-orders-empty">
                            <div className="ord-icon">
                                <TickGreenIcon width={"100%"} height={"100%"} />
                            </div>
                            <div className="filled-ord-text">
                                <span>{`${doc.placed} Filled`}</span>
                            </div>
                        </div>
                        }
                        {(doc.job_status === "CLOSED" || doc.job_status === "CANCELLED_BY_CLIENT" || doc.job_status === "MISTAKEN_ORDER")
                            && <div className="open-orders-empty">
                                <div className="ord-icon">
                                    <TickGreyIcon width={"100%"} height={"100%"} />
                                </div>
                                <div className="closed-ord-text">
                                    <span>{t('unfilled')}</span>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className="open-orders-empty">
                        <div className="ord-icon">
                            <TickGreyIcon width={"100%"} height={"100%"} />
                        </div>
                        <div className="closed-ord-text">
                            <span>0 orders</span>
                        </div>
                    </div>
                }
            </>
        )

    }
    function handleJobSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            }
        })
        return;
    }

    function handleCompanySelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            }
        })
        return;
    }


    function getStatus(status: string) {
        if (status === JOB_STATUS.open) return (<span style={{ color: THEME.red }}>{t('open')}</span>);
        else if (status === JOB_STATUS.filled) return (<span style={{ color: THEME.buttonColor17 }}>{t('filled')}</span>);
        else if (status === JOB_STATUS.cancelledByClient) return (<span style={{ color: "#B1B1B1" }}>{t('cancel_by_client')}</span>);
        else if (status === JOB_STATUS.mistakenOrder) return (<span style={{ color: "#B1B1B1" }}>{t('mistaken_order')}</span>);
        else if (status === JOB_STATUS.closed) return (<span style={{ color: "#B1B1B1" }}>{t('closed')}</span>);
        else return (<span>{''}</span>);
    }


    const getJobsTableList = () => {
        if (jobListState?.jobsByPage?.loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (jobListState?.jobsByPage?.error != null && jobList?.items?.length === 0) {
            return (
                <TableErrorHandler error={jobListState?.jobsByPage?.error} onRefresh={getJobs} />
            );
        }
        if (jobList?.items?.length === 0) {
            return (
                <TableEmpty title={t('no_jobs_found')} onClick={getJobs} />
            );
        }
        if (getFilteredList()?.length === 0) {
            return (
                <TableFilterEmpty title={t('no_jobs_found')} />
            );
        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList()?.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleJobSelect(doc))}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}>{getOpenOrdersInfo(doc)}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleJobSelect(doc)}><span>{doc.job_title}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={true} onClick={() => handleDepartmentSelect(doc)}><span>{doc.department_name ?? t('corporate')}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }} ><span>{getRatingCell(doc.rating)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }} ><span>{getStatus(doc.job_status)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }} ><span>{currencyConversion(doc.regular_pay_rate)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }} ><span>{currencyConversion(doc.regular_bill_rate)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(9), color: THEME.statusInActiveColor }} ><span >{doc.requested}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(10), color: THEME.buttonColor17 }} ><span >{doc.placed}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(11), color: THEME.statusInActiveColor }} ><span >{doc.open_orders}</span></TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 2;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 2;
        if (value === 5) return 1.5;
        if (value === 6) return 1.5;
        if (value === 7) return 2;
        if (value === 8) return 2;
        if (value === 9) return 1;
        if (value === 10) return 1.2;
        if (value === 11) return 1.3;
        return 1;
    }

    const getRatingCell = (value: number) => {
        return (
            <Rating name="read-only" value={value} readOnly />
        )
    }

    const handlePageChange = (pageNum: number, size: number) => {
        getJobs({ pageNum: pageNum, pageSize: size })
        setSize(size ? size : jobList?.size!)
    };

    const handleSearchChange = (value) => {
        setSearch(value)
        if (value?.length === 0) {
            getJobs({pageNum: 1, search_str: ""});
        }
    }
    const handleAccountManagersChange = (value) => {
        setAccountManagers(value)
        getJobs({ pageNum: 1, account_managers: value });
    }

    const handleRecruiters = (value) => {
        setRecruiters(value)
        getJobs({ pageNum: 1, recruiters_ids: value });
    }
    const handleStatus = (value) => {
        setStatus(value)
        getJobs({ pageNum: 1, status_ids: value });
    }

    return (
        <>
            <div className="jbs-ud-container">
                <div className="jbs-ud-table-header">
                    <TableHeaderOptions
                        totalCount={jobList?.total!}
                        search={search}
                        onSearchChange={handleSearchChange}
                        accountManagersList={accountManagersList.map(doc => ({ label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`, value: doc.id }))}
                        recruitersList={recruitersList.map(doc => ({ label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`, value: doc.id }))}
                        onChangeAccountManager={handleAccountManagersChange}
                        onChangeRecruiter={handleRecruiters}
                        selectedAccountManagers={accountManagers}
                        selectedRecruiters={recruiters}
                        selectedStatus={status}
                        onStatusChange={handleStatus}
                        companyStatusList={[...jobsStatusOptions]}
                    />
                    <div className="jbs-ud-actions">
                        <div className="jbs-ud-add-user-btn">
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_job_order')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => setShowAddJob(true)}
                            />
                        </div>
                    </div>

                </div>
                <div className="jbs-ud-table">
                    {getJobsTableList()}
                </div>
            </div>
            <div className='table-footer'>
                <div className="as-table-footer">
                    <Pagination
                        totalPages={jobList?.pages!}
                        onPageChange={handlePageChange}
                        curPageNum={currentPage}
                        totalCount={jobList?.total!}
                        size={jobList?.size!}
                        loadingState={jobListState?.jobsByPage?.loading}
                    />
                </div>
                <div>
                    {jobListState?.jobsByPage?.loading === LoadingType.succeeded &&
                        <div className="mlt-usrs-content">
                            <div className="custom-pab">
                                <div className="pab-title">
                                    <span>Total Job Orders</span>
                                </div>
                                <div className='pab-requested-value'>
                                    <span>
                                        {jobList?.items && jobList?.items?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                            return prev + +current.requested
                                        }, 0) : 0}
                                    </span>
                                    Requested
                                </div>
                                <div className='pab-placed-value'>
                                    <span>
                                        {jobList?.items && jobList?.items?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                            return prev + +current.placed
                                        }, 0) : 0}
                                    </span>
                                    Placed
                                </div>
                                <div className='pab-over-value'>
                                    <span>
                                        {jobList?.items && jobList?.items?.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                            return prev + +current.open_orders
                                        }, 0) : 0}
                                    </span>
                                    Still Needed
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            {showAddJob && <Portal>
                <CreateJobPopup
                    visible={showAddJob}
                    title={t('add_job_order')}
                    successTitle={t('new_job_order')}
                    onClose={() => setShowAddJob(false)}
                    onSuccessClose={handleAddJobSuccess}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onGotoCreated={handleOnGotoJob}
                />
            </Portal>}
        </>
    );


    ///Add user action
    function handleAddJobSuccess() {
        setShowAddJob(false);
        getJobs();
        dispatch(manageJobActions.clearManageJobState([]));
    }

    function handleGotoAgencyDashboard() {
        handleAddJobSuccess();
        props.history.push(AppRoutes.dashboardPage);
    }

    function handleOnGotoJob(id: string, name: string) {
        handleAddJobSuccess();
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            }
        })
    }

}

interface TableHeaderOptionsProps {
    totalCount: number,
    search: string,
    onSearchChange: (value: string) => void,
    /// [Account managers users]
    accountManagersList: Array<{ label: string, value: string }>,
    selectedAccountManagers: string[],
    onChangeAccountManager: (value: string[]) => void,
    /// [Account managers users]
    recruitersList: Array<{ label: string, value: string }>,
    selectedRecruiters: string[],
    onChangeRecruiter: (value: string[]) => void,
    /// [Status]
    selectedStatus: string[];
    onStatusChange: (value: string[]) => void,
    companyStatusList: Array<{ label: string, value: string }>,
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    return (
        <div className="jbs-ud-table-header-options">
            <div className="account-manager-selector">
                <CustomMultiFormSelect
                    label={t('account_manager')}
                    name={'account_manager'}
                    list={props.accountManagersList}
                    onChange={props.onChangeAccountManager}
                    required={false}
                    placeholder={t('select')}
                    value={props.selectedAccountManagers}
                    customStyle={{ width: '-webkit-fill-available' }}
                />
            </div>
            <div className="recruiter-selector">
                <CustomMultiFormSelect
                    label={t('recruiter')}
                    name={'recruiter'}
                    list={props.recruitersList}
                    onChange={props.onChangeRecruiter}
                    required={false}
                    placeholder={t('select')}
                    value={props.selectedRecruiters}
                    customStyle={{ width: '-webkit-fill-available' }}
                />
            </div>
            <div className="company-status-selector">
                <CustomMultiFormSelect
                    label={t("status")}
                    name={"company_status"}
                    list={props.companyStatusList}
                    onChange={props.onStatusChange}
                    required={false}
                    placeholder={t("all")}
                    value={props.selectedStatus}
                    customStyle={{ width: "-webkit-fill-available" }}
                />
            </div>
            <div className="jbs-ud-table-search-bar">
                <SearchBar
                    value={props.search}
                    onChange={props.onSearchChange}
                    onSearch={()=>{}}
                />
            </div>
            <div className="total-count">
                <span>{`${t('total_count')}: `} <span className="total-count-number">{formatNumber(props.totalCount ?? "")}</span> </span>
            </div>
        </div>
    );
}


export default JobsPage;