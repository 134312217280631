import React, { useEffect, useRef, useState } from "react";

import './company_job_orders_page.scss';
import SearchBar from "../../../../components/search_bar";
import { CustomButton, getPlusBtnIcon } from "../../../../utils";
import { JOB_STATUS, LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { useAppDispatch, useAppSelector, selectContactDetailsByIdState, getContactById } from "../../../../redux/store";
import {
    selectCompanyJobOrderState,
    selectCompanyDetailsByIdState,
    getCompanyById,
    getCompanyJobOrdersByPage
} from '../../../../redux/companies';
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../routes";
import { Portal } from "react-portal";
import { Rating } from "@material-ui/lab";
import OpenOrderRow from "../../../../utils/open_orders";
import { currencyConversion, formatNumber, getTableAccessIcon, sortByOrders } from "../../../../utils/helpers";
import { IJobOrder, IJobOrdersByPage } from "../../../../interfaces";
import CreateJobPopup from "../../../jobs/popups/create_job/create_job";
import { manageJobActions } from "../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer";
import Pagination from "../../../components/pagination/pagination";

const headers = [
    { 'title': 'access_job', 'code': 'access_job' },
    { 'title': 'orders', 'code': 'open_orders' },
    { 'title': 'company', 'code': 'company_name' },
    { 'title': 'department', 'code': 'department_name' },
    { 'title': 'job_title', 'code': 'job_title' },
    { 'title': 'rating', 'code': 'rating' },
    { 'title': 'status', 'code': 'job_status' },
    { 'title': 'pay_rate', 'code': 'regular_pay_rate' },
    { 'title': 'bill_rate', 'code': 'regular_bill_rate' },
    { 'title': 'requested', 'code': 'requested' },
    { 'title': 'placed', 'code': 'placed' },
    { 'title': 'over_under', 'code': 'over_under_placed' },
]

const category = [
    'access_job',
    'open_orders',
    'company_name',
    'department_name',
    'job_title',
    'rating',
    'job_status',
    'regular_pay_rate',
    'regular_bill_rate',
    'requested',
    'placed',
    'over_under_placed',
]

const pages = { companies: 'companies', contacts: 'contacts' };

const CompanyJobOrdersPage = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params } = props.match;
    const { id, departmentId } = props.match.params;
    const [searchValue, setSearchValue] = useState('');
    const [sortedField, setSortedField] = useState<null | string>('id');

    const [sortDirection, setSortDirection] = useState("asc");

    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const companyState = useAppSelector((state) => selectCompanyDetailsByIdState(params.departmentId ?? params.id)(state));
    const contactState = useAppSelector((state) => selectContactDetailsByIdState(params.id)(state));
    const jobOrderState = useAppSelector((state) => selectCompanyJobOrderState(state));
    const [companyJobsList, setCompanyJobList] = useState<IJobOrdersByPage | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(100)

    let mainPage = pages.companies;
    if (props.match.path.startsWith('/contacts/'))
        mainPage = pages.contacts;

    useEffect(() => {
        if (searchValue?.length === 0 || searchValue?.length >= 2) return getJobs(1);
        return () => {}
    }, [searchValue])

    useEffect(() => {
        if (jobOrderState?.companyJobsByPage?.loading === LoadingType.succeeded && jobOrderState?.companyJobsByPage?.response) {
            setSentRequest(false);
            setCompanyJobList(jobOrderState?.companyJobsByPage?.response)
        }
    }, [jobOrderState?.companyJobsByPage?.loading, jobOrderState?.companyJobsByPage?.response]);

    function handleSearchChange(value) {
        setSearchValue(value);
    }

    function handleSortChange(value) {
        if (value === "open_orders") {
            if (sortedField && sortedField === value) {
            } else {
                setSortedField(value);
            }
        }
        else {
            if (sortedField && sortedField === value) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortedField(value);
                setSortDirection('asc');
            }
        }
    }

    function getFilteredList() {
        let list;
        if (!companyJobsList?.items)
            return [];
        if (sortedField != null) {
            if (sortedField === "open_orders") {
                list = sortByOrders([...companyJobsList?.items], "job_status");
            } else {
                list = [...companyJobsList?.items].sort((a, b) => {
                    const valueA =
                        a[sortedField] != null && a[sortedField] != undefined
                            ? typeof a[sortedField] == typeof "1"
                                ? a[sortedField].trim().toLowerCase()
                                : a[sortedField]
                            : "";
                    const valueB =
                        b[sortedField] != null
                            ? typeof b[sortedField] == typeof "1"
                                ? b[sortedField].trim().toLowerCase()
                                : b[sortedField]
                            : "";
                    if (sortDirection === "asc") {
                        return valueA > valueB ? 1 : -1;
                    } else {
                        return valueA < valueB ? 1 : -1;
                    }
                });
            }
        }
        return list ?? companyJobsList.items
    }

    function getRequested() {
        let data = getFilteredList();
        let requested = 0;
        for (let i of data)
            requested += Number(i['requested']);
        return requested;
    }

    function getPlaced() {
        let data = getFilteredList();
        let placed = 0;
        for (let i of data)
            placed += Number(i['placed']);
        return placed;
    }

    function getOver() {
        let data = getFilteredList();
        let over = 0;
        for (let i of data)
            over += Number(i['over_under_placed'])
        return over;
    }

    function handleRetry() {
        getJobs();
    }

    function handleAccessJob(job) {
        props.history.push(`${AppRoutes.jobDetailsPage}/${job.id}/dashboard`);
    }

    function getJobs(pageNum?: number, pageSize?: number) {
        setSentRequest(true);

        const jobPageNum = pageNum ?? currentPage;
        const jobPageSize = pageSize ?? size;

        let data = {
            page: jobPageNum,
            size: jobPageSize,
            search_str: searchValue,
            ...(mainPage === pages.companies
                ? { company_id: id, department_id: departmentId }
                : { contact_id: id }),
        };
        dispatch(getCompanyJobOrdersByPage(data));
        setCurrentPage(pageNum ?? currentPage);
    }

    const handlePageChange = (pageNum: number, size: number) => {
        getJobs(pageNum, size)
        setSize(size ? size : companyJobsList?.size!)
    };

    return (
        <div>
            <div className={"cmp-jo-main-container"}>
                <div className={"cmp-jo-header"}>
                    <div className={"left-fields"}>
                        <div >
                            <SearchBar
                                value={searchValue}
                                onChange={handleSearchChange}
                                onSearch={()=>{}}
                            />
                        </div>
                        <div className={"total-count"}>
                            <div style={{ "marginRight": "3px" }}>{t("count_total")}</div>
                            <span>{formatNumber(companyJobsList?.total! ?? "")}</span>
                        </div>
                    </div>
                    <div className={"add-button"}>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t("add_job_order")}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                        />
                    </div>
                </div>
                <div className="cmp-jo-table">
                    {jobOrderState?.companyJobsByPage?.loading === LoadingType.pending && (companyState.loading === LoadingType.pending || contactState.loading === LoadingType.pending) && sentRequest ? <TableLoading /> :
                        <TableBody
                            state={jobOrderState?.companyJobsByPage}
                            getFilteredList={getFilteredList}
                            sortedField={sortedField}
                            handleSortChange={handleSortChange}
                            onRetry={handleRetry}
                            data={companyJobsList?.items}
                            accessJob={handleAccessJob}
                            handleJobSelect={handleJobSelect}
                            handleCompanySelect={handleCompanySelect}
                            handleDepartmentSelect={handleDepartmentSelect}
                            sortDirection={sortDirection}

                        />}
                </div>
            </div>
            <div className="cjo-table-footer">
                <div className="cjp-table-footer">
                    <Pagination
                        totalPages={companyJobsList?.pages!}
                        onPageChange={handlePageChange}
                        curPageNum={currentPage}
                        totalCount={companyJobsList?.total!}
                        size={companyJobsList?.size!}
                        loadingState={jobOrderState?.companyJobsByPage?.loading}
                    />
                </div>
                <div className="cmp-job-order-analysis">
                    {jobOrderState?.companyJobsByPage?.loading === LoadingType.pending && sentRequest && !showCreateDialog ? <></> :
                        <>
                            <div className="heading">
                                <span>Total Job Orders:</span>
                            </div>
                            <div className="fields">
                                <div className="field" style={{ "color": THEME.red }}>
                                    <span className="field-m">{getRequested()}</span>
                                    <span>Requested</span>
                                </div>
                                <div className="field" style={{ "color": THEME.buttonColor17 }}>
                                    <span className="field-m">{getPlaced()}</span>
                                    <span>Placed</span>
                                </div>
                                <div className="field" style={{ "color": THEME.red }}>
                                    <span className="field-m">{getOver()}</span>
                                    <span>Over / under</span>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
            {showCreateDialog && <Portal>
                <CreateJobPopup
                    visible={showCreateDialog}
                    title={t('add_job_order')}
                    successTitle={t('new_job_order')}
                    data={getDataProps()}
                    onClose={() => setShowCreateDialog(false)}
                    onSuccessClose={handleAddJobSuccess}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onGotoCreated={handleOnGotoJob}
                    contactState={contactState}
                />
            </Portal>}
        </div>
    );

    function getDataProps() {
        if (mainPage === 'companies') return { company_id: id, department_id: departmentId };
        if (mainPage === 'contacts') return { company_id: contactState.data?.company_id, department_ids: contactState.data?.department_ids };
        return undefined;
    }


    ///Add user action
    function handleAddJobSuccess() {
        setShowCreateDialog(false);
        getJobs();
        dispatch(manageJobActions.clearManageJobState([]));
    }

    function handleGotoAgencyDashboard() {
        handleAddJobSuccess();
        props.history.push(AppRoutes.dashboardPage);
    }

    function handleOnGotoJob(id: string, name: string) {
        handleAddJobSuccess();
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            }
        })
    }

    function handleJobSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: value.job_title,
            }
        })
        return;
    }

    function handleCompanySelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: IJobOrder) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            }
        })
        return;
    }
}
const TableBody = ({ state, getFilteredList, sortedField, handleSortChange, onRetry, data, accessJob, handleJobSelect, handleCompanySelect, handleDepartmentSelect, sortDirection }) => {
    function getStatus(status: string) {
        if (status === JOB_STATUS.open) return (<span style={{ color: THEME.statusInActiveColor }}>{t('open')}</span>);
        else if (status === JOB_STATUS.filled) return (<span style={{ color: THEME.buttonColor17 }}>{t('filled')}</span>);
        else if (status === JOB_STATUS.cancelledByClient) return (<span style={{ color: "#B1B1B1" }}>{t('cancelled_by_client')}</span>);
        else if (status === JOB_STATUS.mistakenOrder) return (<span style={{ color: "#B1B1B1" }}>{t('mistaken_order')}</span>);
        else if (status === JOB_STATUS.closed) return (<span style={{ color: "#B1B1B1" }}>{t('closed')}</span>);
        else return (<span>{''}</span>);
    }
    const { t } = useTranslation();
    if (state.loading === LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data && data.length === 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    return (
        <SortableTable headerList={headers} sortedField={sortedField} onSortChange={handleSortChange} flexNumber={getFlexNumber} isAsc={sortDirection}>
            {getFilteredList().map((doc, index) => {
                return (
                    <tr key={"cmp_job_order_row" + index}>
                        <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleJobSelect(doc))}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(1) }}>{OpenOrderRow(doc, 'job_status', t)}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc['company_name']}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={doc.department_name ? true : false} onClick={() => handleDepartmentSelect(doc)}><span>{doc.department_name ? doc['department_name'] : t('corporate')}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={true} onClick={() => handleJobSelect(doc)}><span>{doc['job_title']}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(5) }}><span><Rating name="read-only" value={doc['rating']} readOnly /></span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{getStatus(doc.job_status)}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{currencyConversion(doc["regular_pay_rate"])}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{currencyConversion(doc["regular_bill_rate"])}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(9) }}><span style={{ color: THEME.statusInActiveColor }}>{doc.requested}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(10) }}><span style={{ color: THEME.buttonColor17 }}>{doc.placed}</span></TableData>
                        <TableData customStyle={{ flex: getFlexNumber(11) }}><span style={{ color: THEME.statusInActiveColor }}>{doc.over_under_placed}</span></TableData>
                    </tr>
                )
            })}
        </SortableTable>
    );

    function getFlexNumber(value: number) {
        if (value === 1) return 1.5;
        if (value === 2) return 2;
        if (value === 3) return 2;
        if (value === 4) return 2;
        if (value === 5) return 2;
        if (value === 7) return 2;
        if (value === 8) return 2;
        if (value === 9) return 1.15;
        if (value === 10) return 1.15;
        if (value === 11) return 1.22
        return 1;
    }


}

export default CompanyJobOrdersPage;