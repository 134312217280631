import { t } from "i18next";
import { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import { THEME } from "../../../enums/app";
import { BODeductionAuthorityIcon } from "../../../icons";
import { ThirdPartyDeductionType } from "../../../interfaces/third_party_deduction_type";
import { CustomButton } from "../../../utils/custom_button";
import RenderInput from "../../../components/render_input/render_input";
import { AppError, FormInputOption } from "../../../interfaces";
import { US_states } from "../../../utils/States_US";
import { useState } from "react";
import { RegxPattern } from "../../../utils/regx_pattern";
import "./deduction_form.scss";
import { IconButton, Snackbar } from "@material-ui/core";
import { DeleteConfirmationDialog } from "../../components/delete_confirmation_dialog";
import { AppRoutes } from "../../../routes";
import { BackOfficeAPI } from "../../../apis/back_office_api";
import { useHistory } from "react-router-dom";
import { postSnackbarMessage, useAppDispatch } from "../../../redux/store";
import { Close } from "@material-ui/icons";
import { LoadingType } from "../../../enums";

interface IProps {
  title: string;
  onClose: () => void;
  onSubmit: (data: ThirdPartyDeductionType) => void;
  deductionInfo?: ThirdPartyDeductionType | null;
  state: {
    error?: AppError | null,
    loading: LoadingType,
    response: string | null,
  };
}

const DeductionForm: React.FunctionComponent<IProps> = (props) => {
  const initialForm: ThirdPartyDeductionType = {
    id:"",
    value: "",
    type_of_deduction: "",
    status: "",
    city: "",
    state: "",
    zipcode: "",
    website: "",
    notes: "",
    description: ""
  };

  const [formState, setFormState] = useState<ThirdPartyDeductionType>(props.deductionInfo ? props.deductionInfo : initialForm);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] =
    useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const history = useHistory();

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    setCheckValidationOnSubmit(true);
    const requiredFieldArray = [
      "value",
      "website",
      "city",
      "zipcode",
      "state",
      "status",
      "type_of_deduction",
    ];
    requiredFieldArray.map((field) => {
      handleFieldChange(field, formState[field]);
    });
    if (_isFormValid()) {
      props.onSubmit({ ...formState });
      setCheckValidationOnSubmit(false);
    }
  };

  const _isFormValid = () => {
    const textValues = [
      formState.value,
      formState.type_of_deduction,
      formState.status,
      formState.city,
      formState.state,
    ];
    const { zipcode, website } = formState;

    for (const value of textValues) {
      if (value === "") return false;
      if (RegxPattern.username.test(value) === false) return false;
    }

    if (zipcode != "" && RegxPattern.zipCode.test(zipcode) === false)
      return false;
    if (
      website != null &&
      website !== "" &&
      RegxPattern.url.test(website) === false
    )
      return false;

    return true;
  };

  const statusList = [
    { label: "Active", value: "ACTIVE", color: "green" },
    { label: "Inactive", value: "INACTIVE", color: "red" },
  ];

  const deductionTypeList = [
    { label: "Insurance", value: "Insurance" },
    { label: "Child Support", value: "Child Support" },
    { label: "Bankruptcy Garnishment", value: "Bankruptcy Garnishment" },
  ];

  const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
    /// 1st row
    {
      field: "value",
      type: "text",
      label: "deduction_entity_name",
      secure: false,
      required: true,
      placeholder: "",
      keyboardtype: "",
      customStyle: { width: "60%" },
    },
    /// 2nd row
    [
      {
        field: "website",
        type: "text",
        label: "website",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "type_of_deduction",
        type: "selector",
        label: "type_of_deduction",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: deductionTypeList,
        multi: false,
      },
      {
        field: "status",
        type: "selector",
        label: "status",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: statusList,
        multi: false,
      },
    ],
    /// 3rd row
    [
      {
        field: "city",
        type: "text",
        label: "city",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "state",
        type: "selector",
        label: "state",
        secure: false,
        required: true,
        placeholder: "select",
        keyboardtype: "",
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zipcode",
        type: "text",
        label: "zipcode",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
        maxLength: 5,
      },
    ],
    /// 4th row
    [
      {
        field: "notes",
        type: "text",
        label: "notes",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
    /// 5th row
    {
      field: "description",
      type: "text",
      label: "overview",
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
      multiline: true,
    }
  ];

  const handleFieldChange = (fieldId: string, value: any, parent?: string) => {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else if (fieldId === "value") {
      const isValid = !/[.\$]/.test(value);
      if (isValid) {
        setFormState({ ...formState, [fieldId]: value });
      }
    }
    else {
      setFormState({ ...formState, [fieldId]: value });
    }
  };

  function deductionTypeFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "value":
      case "city":
      case "state":
      case "type_of_deduction":
      case "status": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false)
          return t("validators.enterValidName");
        return null;
      }
      case "website": {
        if (!value.text) return t("validators.required");
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "zipcode": {
        if (!value.text) return t("validators.required");
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }

  /// Section one
  function getSectionOne() {
    return (
      <div className="deduction-f-section-divider">
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="deduction-f-row">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={deductionTypeFormValidators}
                      className={"deduction-f-input-holder"}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={deductionTypeFormValidators}
              className={"deduction-f-input-holder"}
              checkValidationOnSubmit={checkValidationOnSubmit}
            />
          );
        })}
      </div>
    );
  }

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await BackOfficeAPI.deleteDeductionEntity(props.deductionInfo?.id ?? "");
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.deductionAuthoritiyPage);
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };

  return (
    <div className="deduction-f-container">
      <div className="deduction-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={
            <BODeductionAuthorityIcon width={"100%"} height={"100%"} />
          }
        />
      </div>
      <div className="deduction-f-content">
        {getSectionOne()}
      </div>
      <div className="deduction-f-actions">
        {props.deductionInfo && (
          <div style={{ marginRight: "auto" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("delete_field", { name: t("") })}
              enable={true}
              backgroundColor={THEME.statusInActiveColor}
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        )}
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onClose}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.state.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={t("save")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={handleSubmit}
          />
        </div>
      </div>
      {showDeleteDialog && props.deductionInfo && (
        <DeleteConfirmationDialog
          message={t("delete_deduction_message", {
            deduction_entity: props.deductionInfo?.value,
          })}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          deleting={deleting}
          deleteType=""
          onClearError={()=>{}}
        />
      )}
      <Snackbar
        open={snackBarMessage !== null}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={snackbarAction()}
      />
    </div>
  );
};

export default DeductionForm;
