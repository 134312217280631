import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../store";
import {
    selectCreateState,
    selectState,
    selectUpdateState,
    selectCompleteUpdateState
} from "./manage_sales_calls_reducer";

const select = (state: IRootState) =>
  state.salesMarketing.planMySalesCalls.manageSalesCall;
export const selectManageSalesCallState = createSelector(select, selectState);
export const selectManageSalesCallCreateState = createSelector(
  selectManageSalesCallState,
  selectCreateState
);
export const selectManageSalesCallUpdateState = createSelector(
  selectManageSalesCallState,
  selectUpdateState
);

export const selectManageCompleteSalesCallUpdateState = createSelector(
  selectManageSalesCallState,
  selectCompleteUpdateState
);