import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import ServiceReportSection from "../../../components/service_section_page/service_section_page";
import { IReportCenterDownloadUrlParams, IReportCenterDownloadUrlRequestBody } from "../../../../../interfaces/report_center";
import { getSixtyDayCallsReportsList, getReportDownloadURL, selectFourHourGuaranteeCallsReportsList, selectFourHourGuaranteeCallsReportsListState, selectReportsDownloadState, useAppDispatch, useAppSelector, selectSixtyDayCallsReportsListState, selectSixtyDayCallsReportsList } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { LoadingType } from "../../../../../enums";
import UrlBuilder from "../../../../../apis/url_builder";
import { clearSixtyDayCallsReportsListError } from "../../../../../redux/report_center/service/sixty_day_calls_report/sixty_day_calls_report_reducer";
import { IReportCenterService } from "../../../../../interfaces/report_center_service";

const tableHeader = [
    { title: "customer", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "employee", code: "talent_name" },
    { title: "manager", code: "manager" },
    { title: "job_title", code: "job_title" },
    { title: "sixty_day_calls_date", code: "date_of_scheduled_call" },
    { title: "dm_reached", code: "dm_reached" },
    { title: "call_results", code: "call_result" },
];

interface Props extends RouteComponentProps<any> { }
const SixtyDayCallsReports: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;

    const sixtyDayCallsReportsListState = useAppSelector((state) => selectSixtyDayCallsReportsListState(state));
    const sixtyDayCallsReportsListData = useAppSelector((state) => selectSixtyDayCallsReportsList(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [companiesList, setCompaniesList] = useState<{label: string, value: string}[]>([])
    const [sixtyDayCallsReportsList, setSixtyDayCallsReportsList] = useState<IReportCenterService[] | null>(null);
    useEffect(() => {
        if (sixtyDayCallsReportsListState.loading === LoadingType.succeeded) {
            setSixtyDayCallsReportsList(sixtyDayCallsReportsListData);
        }
        return () => {
            setSixtyDayCallsReportsList(null)
        }
    }, [sixtyDayCallsReportsListState.loading]);
    useEffect(() => {
        if (sixtyDayCallsReportsListState && sixtyDayCallsReportsListState?.loading === LoadingType.succeeded) {
            const updatedCompaniesList = sixtyDayCallsReportsListData?.reduce<{ label: string; value: string }[]>((acc, company) => {
                if (!acc.some(item => item.value === company.company_id)) {
                    acc.push({ label: company.company_name, value: company.company_id });
                }
                return acc;
            }, []);
            setCompaniesList(updatedCompaniesList);
        }
    }, [sixtyDayCallsReportsListState?.loading]);

    const getSixtyDayCallsReportList = ({ start_date, end_date }: { start_date: number, end_date: number }) => {
        dispatch(getSixtyDayCallsReportsList({ start_date, end_date }))
    }
    
    function handleDownload({ params, requestBody }: { params: IReportCenterDownloadUrlParams, requestBody?: IReportCenterDownloadUrlRequestBody }) {
        dispatch(getReportDownloadURL(
            {
                baseUrl: UrlBuilder.serviceCallsRCSixtyDayCallsReport,
                start_date: params.start_date,
                end_date: params.end_date,
                company_id: params.company_id,
                department_id: params.department_id,
                download: params.download,
                downloadAR: { account_managers: requestBody?.account_manager_ids, recruiters: requestBody?.recruiter_ids },
            }));
    }

    const handleClearError = () => {
        dispatch(clearSixtyDayCallsReportsListError())
    }

    return (
        <div className="arrival-check-calls-reports-container">
            <ServiceReportSection
                tableHeader={tableHeader}
                reportListState={sixtyDayCallsReportsListState}
                reportList={sixtyDayCallsReportsList!}
                downloadUrlState={reportDownloadUrlState}
                history={history}
                companiesList={companiesList}
                tableHeaderTitle={"sixty_day_calls_report"}
                getReportList={getSixtyDayCallsReportList}
                handleDownload={handleDownload}
                onClearError={handleClearError}
            />
        </div>
    )

}
export default SixtyDayCallsReports;