import config from "../config";
import preferences from "../preferences";

const UrlBuilder = {
  getBaseUrl() {
    let savedUrl = preferences.getServerUrl();
    if (savedUrl != null) {
      return savedUrl;
    } else {
      return this.baseUrl;
    }
  },

  baseUrl: config.BASE_API_URL,
  login: "login",
  getProfile: "get-profile",
  logout: "logout",
  getAgencyLoginLogo:"get-agency-logo-download-url",

  /// [User services]
  getUsers: "get-users",
  usersDropdown: "users/dropdown",
  createUser: "create-user",
  toggleRole: "toggle-role",
  toggleUserState: "toggle-user-state",
  updateProfile: "update-profile",
  changePassword: "change-password",
  forgotPassword: "forgot-password",
  engagementActivityMethod: "engagement-activity-method",
  successProfileQuestions: "success-profile-question",
  engagementActivityType: "engagement-activity-type",
  salesCallJourneyMethod: "sales-call-journey-method",
  serviceProfile: "service-profile",
  marketingCampaignStep: "marketing-campaign-step",
  statusCode: "status-code",
  contactRole: "contact-role",
  checkDelivery: "check-delivery",
  onboardingStep: "onboarding",
  serviceCall: "service-call-type",
  trafficLightEngagementMetric: "traffic-light-engagement-metric",
  successProfileQuestionSteps: "success-profile-question-step",
  companyStatus: "company-status",
  contactStatus: "contact-status",
  jobStatus: "job-status",
  contactMethod: "contact-method",
  jobOrderPlacement: "job-order-placement",
  talentInterviewQuestions: "talent-interview-question",
  OtherDocs: "other_docs",
  agencyDetails: "get-agency-details",
  updateAgencyDetails: "update-agency-details",
  passwordExpirationUpdate: "update-password-expiration-days",
  jobOrderSource: "job-order-source",
  workCompCode: "comp-code",
  payCycle: "pay-cycle",
  payPeriod: "pay-period",
  getAgencyBranches: "get-branches",
  createAgencyBranch: "create-branch",
  updateAgencyBranch: "update-branch-details",
  assignmentType: "assignment-type",
  teamServices: "/teams-services",
  deleteTeamServices: "/teams-services/delete-a-team",
  payRollTaxConfig: "payroll-tax-configuration",
  salesTaxConfig: "sales-tax-configuration",
  workCompConfig: "work-comp-configuration",
  workCompConfigDropdown: "/work-comp-configuration/dropdown",
  infoText: "/help-task",

  /// [System Configuration]
  businessSector: "business-sector",
  skillSet: "skill-set",
  credentials: "credential",
  certifications: "certification",
  educationRequirements: "education-requirement",
  equipments: "required-equipment",
  textEmAll: "text_em_all_config",
  textEmAllSSOSetup: "text-em-all-sso/do-sso",
  emailConfiguration: "email-configuration",
  
  /// [Companies]
  companies: "companies",
  getCompanyById: "companies/get-company-by-company-id",
  companySalesProfile: "company-sales-profile-section",
  companyServiceProfile: "company-service-profile-section",
  companyShifts: "company-shifts",
  companyMetaData: "companies/meta-data",
  companySkills: "company-skills",
  companyCredentials: "company-credentials",
  companyCertification: "company-certifications",
  companyEducationRequirements: "company-education-requirements",
  companyEquipments: "company-equipments",
  companyStaffs: "company-staffs",
  getAllCompetitors: "competitors/get-all-competitors",
  getCompetitorsByPage: "/competitors/get-all-competitors-pagination",
  getCompanyDirectory: "/companies/get-company-directory",
  getCompanyDropdown: "companies/dropdown",
  getcompaniesBackOfficeInvoiceDocs: "/back-office-invoice-docs",
  getcompaniesBackOfficeSetup: "/back-office-setup",
  companyInvoices: "/billing-invoices/get-company-invoices",
  companyEdocs: "/company-related-docs",
  emailInvoices: "/billing-invoices/email-invoices",
  emailEdocs: "/company-related-docs/email-eDocs",
  getCompaniesByPage: "/companies/get-company-directory-pagination",

  /// [Departments]
  departments: "departments",
  getDepartmentsStructure: "companies/get-departments-structure",
  getDepartmentById: "departments/get-department-by-department-id",
  getDepartmentDropdown: "departments/dropdown",

  // contacts
  contacts: "contacts",
  getAllContacts: "contacts/get-all-contacts",
  getContactById: "contacts/get-contact",
  contactsDropdown: "contacts/dropdown",
  contactsName: "contacts/get-contact-name",
  contactsMigrate: "contacts/migrate",
  contactsJobOrders: "contacts/job-orders",
  contactsCompany: "contacts/company",
  contactCredentials: "contact-credentials",
  contactCertifications: "contact-certifications",
  contactEducationRequirements: "contact-education-requirements",
  contactSkills: "contact-skills",
  contactStaffs: "contact-staffs",

  contactSalesProfile: "contact-sales-profile",
  contactServiceProfile: "contact-service-profile",
  contactsByCompany: "contacts/company",
  getContactsByPage: "/contacts/get-all-contacts-pagination",
  getContactJobOrdersByPage: "/job-orders/contact-pagination",
  getContactsByCompanyDepartmentByPage: "/contacts/company-pagination",

  /// [Talent]
  talent: "talent",
  getTalentDropdown: "talent/dropdown",
  getTalentById: "talent/get-talent-by-talent-id",
  talentStatus: "talent-status",
  talentInterviewQuestion: "talent-interview-question",
  talentInterviewStatus: "talent-interview-status",
  talentSkills: "talent-skills",
  talentMatches: "talent-matches",
  talentEmploymentHistoryAll: "talent-employment-history/get-all",
  createTalentEploymentHistoryEP: "talent-employment-history",
  jobMatches: "job-matches",
  companyMatches: "company-matches",
  talentInHouseInterviews: "talent-in-house-interviews",
  talentInHouseInterviewsGetAll: "talent-in-house-interviews/get-all",
  talentClientInterviews: "talent-client-interviews",
  talentClientInterviewsGetAll: "talent-client-interviews/get-all",
  talentAvailableDates: "talent-available-dates",
  talentSSN: "talent/ssn",
  talentImport: "talent/import-talent-from-online-application",
  talentStaffs: "talent-staffs",
  archiveInterview: "send-interview-calendar-invite/archive-interviews",
  talentGetUploadUrls: "talent/get-upload-urls",
  createLoginTalent: "/talent/create-talent-user",
  getOnboardingDocsReviewedStatus:
    "/talent-onboarding-docs/get-onboarding-docs-reviewed-status",
  updateOnboardingDocsReviewedStatus:
    "/talent-onboarding-docs/update-onboarding-docs-reviewed-status",
  talentCertifications: "/talent-skill-documents",
  talentCrimeHistory: "/talent-criminal-history",
  refreshTalent: "/apolloai/refresh-talent",
  refreshContact: "/apolloai/refresh-contact",
  refreshCompany: "/apolloai/refresh-company",
  backOfficePayrollSetup: "/back-office-payroll-setup",
  backOfficePayrollSetupDirectDeposit:
    "/back-office-payroll-setup/direct-deposit-details",
  backOfficePayrollSetupDirectDepositUpdate:
    "/back-office-payroll-setup/update-direct-deposit-details",
  validateTalentDD: "/back-office-payroll-setup/validate-bankaccounts",
  terminateEmployee: "/talent/terminate-employee",
  talentDocsParser: "/talent-docs-parser",
  getTalentsByPage: "/talent/pagination",

  /// [Jobs]
  jobOrders: "job-orders",
  jobOrdersByCompany: "job-orders/company",
  jobOrdersByContact: "job-orders/contact",
  jobOrderContacts: "job-contact",
  jobOrderContactsByPage: "/job-contact/pagination",
  jobOrderInterviews: "talent-client-interviews/get-all",
  jobOrderServiceProfile: "job-order-service-profile-section",
  jobCertification: "job-certifications",
  jobCredentials: "job-credentials",
  jobProfessionalSkills: "job-professional-skills",
  jobEquipments: "job-equipments",
  jobEducations: "job-educations",
  jobShifts: "job-shifts",
  jobOrderSuccessProfile: "job-order-success-profile-section",
  jobDocuments: "job-documents",
  jobDocumentsUploadVideo: "job-documents/upload-video",
  jobSkills: "job-skills",
  jobStaffs: "job-staffs",
  jobDropdown: "job-orders/dropdown",
  jopPostGetChatGPTQuery: "job-posts/get-chatgpt-query",
  jobPosts: "job-posts",
  jobPostsToGoogle: "job-posts/post-to-google",
  jobDocSendByEmail: "job-documents/email-file",
  grossProfitCalculator: "/gross-profit-calculator",
  grossProfitCalculatorTax: "/gross-profit-calculator/tax",
  getJobsByPage: "/job-orders/pagination",
  getJobDocumentsByPage: "/job-documents/pagination",
  getCompanyJobOrdersByPage: "/job-orders/company-pagination",

  /// [Assignments]
  assignments: "assignments",
  allAssignments: "assignments/all",
  assignmentStaffs: "assignment-staffs",
  endAssignmentReason: "end-assignment-reason",
  getAssignmentDropdown: "assignments/dropdown",
  allAssignmentsByPage: "/assignments/all-pagination",

  /// [Risk Management]
  workCompClaims: "work-comp-claims",
  allworkCompClaims: "work-comp-claims/get-all",
  workCompClaimDocs: "work-comp-claims/get-docs-upload-urls",
  workCompClaimsByTalentId: "work-comp-claims/get-by-talent-id",

  unEmploymentClaims: "risk-management-unemployment-claim",
  unEmploymentClaimsByTalentId:
    "risk-management-unemployment-claim/get-by-talent-id",
  unEmploymentClaimDocs:
    "risk-management-unemployment-claim/get-docs-upload-urls",

  workCompAndComplianceReport: "risk-management-work-comp-and-compliance-report",
  workCompAndComplianceReportDocs: "risk-management-work-comp-and-compliance-report/get-docs-upload-urls",
  riskManagementInsuranceCompany: "risk-management-insurance-company",

  //Sales and Marketing
  salesCalls: "sales-calls",
  salesCallById: "sales-calls/get-by-id",
  salesCallsGetByUsers: "sales-calls/get-by-users",
  salesCallsGetByUsersByPage: "/sales-calls/get-by-users-pagination",
  competitors: "competitors",
  getCompetitorById: "competitors/get-competitor",
  salesAndMarketingDocuments: "sales-and-marketing-documents",
  uploadSalesAndMarketingVideo: "sales-and-marketing-documents/upload-video",
  sendByEmailSalesAndMarketingDocument:
    "sales-and-marketing-documents/email-document",
  sendByEmailSalesAndMarketingVideo:
    "sales-and-marketing-documents/email-video",
  marketingCampaign: "marketing-campaign",

  /// [Profile Pictures]
  preSignedURLForUpload: "files/get-profile-picture-upload-url",
  preSignedURLForDownload: "files/get-profile-picture-download-url",
  fileDownLoad: "files/get-file-download-url",
  fileView: "files/get-file-view-url",



  /// [Dashboard]
  dashboardServiceCall: "dashboard-service-call",
  dashboardServiceCallById: "dashboard-service-call/by-id",
  dashboardTotalOpenOrdersCount: "job-orders/total-open-order-count",
  getTalentAvailableToday: "talent-available-dates/get-talent-available-today",
  dashboardEngagementDetailsCustomer: "dashboard-engagement-details/customer",
  dashboardEngagementDetailsContact: "dashboard-engagement-details/contact",
  dashboardEngagementDetailsCandidate: "dashboard-engagement-details/candidate",
  dashboardEngagementDetailsGetCustomers:
    "dashboard-engagement-details/get-customers",
  dashboardEngagementDetailsGetContacts:
    "dashboard-engagement-details/get-contacts",
  dashboardEngagementDetailsGetCandidate:
    "dashboard-engagement-details/get-candidates",
  onlineApplicationsSent: "online-applications-sent",
  onBoardingLinkSent: "send-onboarding-link",
  getAvailableCandidate: "talent-available-dates/get--available-today",
  sendInterviewCalendarInvite: "send-interview-calendar-invite",

  ///[Dashboard Inbound Leads]
  dashboardInBoundLeads: "inbound-leads",
  dashboardInBoundLeadsSendInvite: "inbound-leads/send-invite",
  dashboardInBoundLeadsImport: "/inbound-leads/import",
  dashboardInBoundLeadsArchive: "/inbound-leads/archive",

  /// [Engagement-Details]
  engagementDetails: "engagement-details",

  ///[My Call Deck]
  myCallDeck: "my-call-deck",
  myCallDeckListByPage: "/my-call-deck/pagination",

  /// [Online Application]
  talentOnlineApplicationVerifyToken: "talent-online-application-verify-token",
  talentOnlineApplication: "talent-online-application",
  talentOnlineApplicationArchive:
    "talent-online-application/archive-applications",
  talentOnlineApplicationGetUploadUrls:
    "talent-online-application/get-upload-urls",

  //admin center onboarding
  adminCenterAgencyOnBoarding: "agency-onboarding",
  adminCenterAgencyOnBoardingHandbookUploadURL:
    "agency-onboarding/get-handbook-upload-url",
  adminCenterAgencyOnBoardingHandbookDownloadURL:
    "agency-onboarding/get-handbook-download-url",

  //OnboardingPortal
  onboardingPortalVerifyToken: "/onboarding-verify-token",
  talentOnBoardingGetHandbookUploadUrl:
    "/talent-onboarding/get-handbook-upload-url",
  talentOnBoardingGetDirectDepositUploadUrl:
    "/talent-onboarding/get-direct-deposit-upload-url",
  talentOnBoardingGetFw4uploadUrl: "/talent-onboarding/get-fw4-upload-url",
  talentOnBoardingGetI9UploadUrl: "/talent-onboarding/get-I9-upload-url",
  talentOnBoardingGetNoticeOfHealthcareExchangeUploadUrl:
    "/talent-onboarding/get-notice-of-healthcare-exchange-upload-url",
  talentOnBoarding: "/talent-onboarding",
  talentOnBoardingGetByTalentId: "/talent-onboarding/get-by-talent-id",
  talentOnBoardingGetHandbookDownloadUrl:
    "/talent-onboarding/get-talent-handbook-download-url",
  talentOnBoardingGetDirectDepositDownloadUrl:
    "/talent-onboarding/get-direct-deposit-download-url",
  talentOnBoardingGetFw4DownloadUrl: "/talent-onboarding/get-w4-download-url",
  talentOnBoardingGetI9DownloadUrl: "/talent-onboarding/get-i9-download-url",
  talentOnBoardingGetNoticeOfHealthcareExchangeDownloadUrl:
    "/talent-onboarding/get-notice-of-healthcare-download-url",
  validateOBDD: "/talent-onboarding/validate-bankaccounts",
  sendOnboardingLink: "/send-onboarding-link",
  sendOnboardingLinkGetAll: "/send-onboarding-link/get-all",
  sendOnboardingLinkArchiveOnboardingApplications:
    "/send-onboarding-link/archive-onboarding-applications",
  resendOnboardingApplicationsLink:
    "/send-onboarding-link/resend-onboarding-applications",
  onboardingWOTCScreeningUrl: "/cti/get-screening-url",
  onboardingESCEnrollmentUrl: "/esc/get-enrollment-url",

  //EmployeeDashboard
  EmployeeDashboardDetails: "/employee/my-details",
  employeeDashboardOnboardingDocsReviewStatus:
    "/get-onboarding-docs-reviewed-status",
  employeeDashboardOnboardingUpdateMyW4: "/update-my-w4",
  employeeDashboardOnboardingUpdateMyDD: "/update-my-direct-deposit",
  validateEmpOBDD: "/talent-onboarding-docs/validate-bankaccounts",
  employeeDashboardPayrollDetails: "/employee/my-paychecks",
  empPaycheckRevHistory: "employee/my-paycheck-reversal-history",
  empVacationHistory: "employee/my-holiday-vacation-history",

  //text-em-all Apis
  textEmAllDraftBroadCast: "/text-em-all-sso/draft-broadcast",
  textEmAllGetConversation: "/text-em-all-sso/get-conversation",

  //my-profile
  myProfile: "/get-my-profile",
  myTeamsDetails: "/teams-services/get-my-team-details",
  addTeamsDetails: "/teams-services/add-to-my-team",

  // kiniso job orders for external websites
  kinisoJobOrderList: "/job-posts/get-my-jobs",

  // user management toggle status
  toggleStatus: "/toggle-user-state",

  // agency upload logo url
  agencyLogoUploadUrl: "/get-agency-logo-upload-url",
  // agency management toggle status
  agencyToggleStatus: "/agency-management/toggle-agency-state",

  /// [Agency Management]
  getAgencies: "agency-management/get-agencies",
  getAgencyById: "agency-management/get-agency-by-id",
  createAgency: "/agency-management/create-agency",
  updateAgency: "/agency-management/update-agency",
  getAgencyLogo: "/agency-management/get-agency-logo-upload-url",

  // Apollo Search Import APIs
  apolloIndustriesDropdown: "/apolloai/get-industries",
  apolloGetCredits: "/apolloai/get-credit-info",
  apolloAddCredits: "/apolloai/add-credits",
  apolloContactsSearchAPI: "/apolloai/search-people",
  apolloContactsImportAPI: "/apolloai/import-contact",
  apolloCompaniesSearchAPI: "/apolloai/search-organization",
  apolloCompaniesById: "/apolloai/organization-detail",
  apolloCompaniesImportAPI: "/apolloai/import-organization",
  apolloTalentImportAPI: "/apolloai/import-talent",

  // Admin Center Back Office
  billingPeriod: "/billing-period",
  billingRate: "/billing-rate",
  groupBy: "/group-by",
  defaultPaymentOption: "/default-payment-option",
  paymentTerms: "/payment-terms",
  transactionType: "/transaction-type",
  additionalTax: "/additional-tax",
  employeeDeduction: "/employee-deduction",
  deductionInstitution: "/deduction-institution",
  payType: "/pay-type",
  markUp: "/mark-up",
  bankAccount: "/bank-account",
  runType: "/run-type",
  processingMethod: "/processing-method",
  mappingGroup: "/mapping-group",
  paymentMethod: "/payment-method",
  deductionType: "/deduction-type",
  employeeContribution: "/employee-contribution",
  vacationConfig: "/vacation-configuration",
  backofficeConfiguration: "/backoffice-configuration",

  // Back Office
  weeklyAccounting: "/weekly-accounting",
  weeklyAccountingOpen: "/weekly-accounting/open",
  weeklyAccountingClose: "/weekly-accounting/close",
  weekEndingDates: "/weekly-accounting/dropdown",
  weeklyTransactionCreate: "/weekly-transaction/create-transaction",
  weeklyTransaction: "/weekly-transaction",
  weeklyTransactionAssignments: "/weekly-transaction/assignments",
  timeCards: "/time-cards",
  timeCardsReport: "/time-cards/report",
  batchDropdown: "/batch/drop-down",
  batch: "/batch",
  verifyBatch: "/batch/verify",
  closeBatch: "/batch/close",
  addTimecardsBatch: "/time-cards/update-batch",
  uploadTimeCardsDoucumentUrl: "/time-cards/get-document-upload-url",
  deleteTimeCardsDoucumentUrl: "/time-cards/delete-document",
  removeTimeCard: "/time-cards/remove",
  timeCardsErrorReports: "/time-cards-error/report",
  timeCardsErrorsReportsResolve: "/time-cards-error/resolve",
  timeCardsErrorsReportApprove: "/time-cards-error/approve",
  payrollErrorReports: "/payroll-error/report",
  payrollErrorsReportsApprove: "/payroll-error/approve",
  payrollErrorsReportsResolve: "/payroll-error/resolve",
  billingInvoicesErrorReports: "/billing-error/report",
  billingInvoicesReportsResolve: "/billing-error/resolve",
  billingInvoicesReportsApprove: "/billing-error/approve",
  payrollBatch: "/payroll/batch",
  payrollPayCheck: "/payroll/paycheck",
  payrollTimecards: "/time-cards/payroll-time-cards",
  payrollBatchPost: "/payroll/post",
  payrollTransactionSummary: "/payroll/transaction-summary",
  billingBatch: "/billing-invoices/batch",
  billingTimecards: "/time-cards/billing-time-cards",
  billingInvoices: "/billing-invoices",
  billingInvoicesReport: "/billing-invoices/report",
  billingInvoicesPost: "/billing-invoices/post",
  billingInvoicesProcessPost: "/billing-invoices/process",
  getELGFileUploadURL: "/elg-change-file-upload-url",
  importELGFileURL: "/import-elg-change-file-data",
  ieReportsDirectory: "/get-import-export-files",
  exportPayrollDeduction: "/export-payroll-deduction",
  exportCensusFile: "/export-census-file",
  timeCardsRepotDownloadUrl: "/time-cards/report-download-url",
  timeCardsErrorRepotDownloadUrl: "/time-cards-error/report-download-url",
  payrollErrorRepotDownloadUrl: "/payroll-error/report-download-url",
  billingErrorRepotDownloadUrl: "/billing-error/report-download-url",
  paycheckDownloadPDF: "/payroll/get-paycheck-download-url",
  inoviceDownloadPDF: "/billing-invoices/get-invoice-download-url",
  exportCTIPayroll: "/export-cti-payroll-file",
  exportNewHireFile: "/export-new-hire-file",
  exportTerminationFile: "/export-termination-file",
  weeklyAccountingErrorReport: "/week-accounting-error/report",
  weeklyAccountingERApprove: "/week-accounting-error/approve",
  weeklyAccountingERResolve: "/week-accounting-error/resolve",
  weeklyAccountingERDownloadUrl: "/week-accounting-error/report-download-url",
  emailPaychecks: "/payroll/email-paychecks",
  getPendingInvoices: "/accounts-receivable/pending-invoices",
  createRecordPayment: "/accounts-receivable/record-payment",
  getARUploadDocsURLs: "/accounts-receivable/get-docs-upload-urls",
  voidCheck: "/paycheck-correction/void-check",
  reverseCheck: "/paycheck-correction/reverse-check",
  getCorrectedTimecards: "/invoice-correction/time-cards",
  correctInvoice: "/invoice-correction",
  payrollAuthDeduction: "/payroll/authority-deductions",
  PayrollAuthBatch: "/payroll/authority-batch",
  PayrollAuthpaycheck: "/payroll/authority-paycheck",
  PayrollDedAuthPaychechDirDownloadPdf: "/payroll/get-authority-paycheck-download-url",

  // Employee Portal
  employeePortalPayrollDetails: "/talent/paychecks",
  talentVacationHistory: "/talent/holiday-vacation-history",
  talentPaycheckRevHistory: "/talent/paycheck-reversal-history",
  talentW2: "/talent/w2",
  uploadW2:"/get-w2-upload-url",

  // Report Center
  talentAvailabilityReport: "/talent-report/get-availability-report",
  talentAssignmentReport: "talent-report/get-assignment-report",
  salesCallSummaryReport: "/report-center/sales-summary-report",
  marketingSummaryReport: "/report-center/marketing-summary-report",
  openJobOrdersReport: "/job-order-report/get-open-job-orders-report",
  jobOrdersHistoryReport: "/job-order-report/get-job-orders-history-report",
  serviceCallsRCArrivalCheckCallsReport: "/service-calls-report-center/arrival-check-calls-report",
  serviceCallsRCEndofFirstDaysCallsReport: "/service-calls-report-center/end-of-first-days-calls-report",
  serviceCallsRCEndofSecondDaysCallsReport: "/service-calls-report-center/end-of-second-days-calls-report",
  serviceCallsRCFridayCallsReport: "/service-calls-report-center/friday-calls-report",
  serviceCallsRCFourHourGuaranteeCallsReport: "/service-calls-report-center/4-hour-guarantee-report",
  serviceCallsRCJobConfirmationCallsReport: "/service-calls-report-center/job-confirmation-calls-report",
  serviceCallsRCNinetyDayCallsReport: "/service-calls-report-center/90-day-calls-report",
  serviceCallsRCSixtyDayCallsReport: "/service-calls-report-center/60-day-calls-report",
  serviceCallsRCThirtyDayCallsReport: "/service-calls-report-center/30-day-calls-report",
  paycheckRegister: "/payroll-and-tax/paycheck-register",
  newHireReport: "/payroll-and-tax/new-hire-report",
  terminationReport: "/payroll-and-tax/termination-report",
  invoiceRegister: "/finance-and-accounting/invoice-register",
  cashReceipts: "/finance-and-accounting/cash-receipts",
  aRAgingReport: "/finance-and-accounting/ar-aging-report",
  aRAgingReportInvoiceList: "/finance-and-accounting/get-company-invoices",
  payrollJournal: "/payroll-and-tax/payroll-journal",
  taxByEmployee: "/payroll-and-tax/employee-tax",
  payrollDeductions: "/payroll-and-tax/payroll-deductions",
  paymentRegister: "/payroll-and-tax/payment-register",
  payrollCashRequired: "/payroll-and-tax/payroll-cash-required",
  grossProfitReport: "/finance-and-accounting/gross-profit-report",
  rcWcCode: "/payroll-and-tax/work-comp",
  rcEmployerTax: "/payroll-and-tax/employer-tax",
  recentlyClosedWeek: "/weekly-accounting/recently-closed-week",
  companiesBilled: "/companies-billed",
  availabilityReport: "/talent-report/get-availability-report",
  employeeAssignmentReport: "/talent-report/get-assignment-report",
  holidayReport: "/payroll-and-tax/employee-holiday",
  vacationReport: "/payroll-and-tax/vacation-report",
  mtExtract: "/mt-extract",
  mtExtractFileView: "/mt-extract/get-file-view-url",
  mtExtractFiles: "/mt-extract/files",
  //integration partners
  masterTaxAuth: "/mastertax-auth",
  masterTaxConfig: "/mastertax-config",
  masterTaxExportCompanySetup: "/mastertax/export-company-setup",
  masterTaxExportPT: "/mastertax/export-pts",
  exportMasterTax: "/mastertax/export-pts-to-mastertax",
  exportRTS: "/mastertax/export-rts",
  exportRTSMasterTax: "/mastertax/export-rts-to-mastertax",

  //upload universal directories files
  importCompanies: "/import-company/get-import-company-upload-url",
  updateImportCompanies: "/import-company",
  importContacts: "/import-contact/get-import-contact-upload-url",
  updateImportContacts: "/import-contact",
  importTalents: "/import-talent/get-import-talent-upload-url",
  updateImportTalents: "/import-talent",
  getUploadedFileStatus: "/import",
  getSampleFileUrl: "/import/sample-file",

  //send email
  sendEmail: "/send-email"


};

export default UrlBuilder;