import { createSlice } from "@reduxjs/toolkit";
import * as thunk from './send_email_thunk';
import { AppError } from "../../interfaces";
import { LoadingType } from "../../enums";

export interface SendEmailState {
    sendEmail: {
        error?: AppError | null,
        loading: LoadingType,
        response: string,
    }
}

const initialState: SendEmailState = {
    sendEmail: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
}


export const sendEmailSlice = createSlice({
    name: 'sendEmail',
    initialState: initialState,
    reducers: {
        clearSendEmailStateError(state) {
            return {...state, sendEmail: {...initialState.sendEmail}};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.sendEmailThunk.pending, (state, action) => ({ ...state, sendEmail: { ...state.sendEmail, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.sendEmailThunk.rejected, (state, action) => ({ ...state, sendEmail: { ...state.sendEmail, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.sendEmailThunk.fulfilled, (state, action) => ({ ...state, sendEmail: { ...state.sendEmail, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addDefaultCase(state => ({ ...state }));
    }
});
export const sendEmailActions = sendEmailSlice.actions;
export const selectSendEmailState = (state: SendEmailState) => state;