import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CompaniesAPI } from "../../../../apis";
import ApiError from "../../../../components/api_error";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import DragAndDrop from "../../../../components/drag_and_drop/drag_and_drop";
import RenderInput from "../../../../components/render_input/render_input";
import { COMPANY_STATUS, LoadingType, THEME } from "../../../../enums";
import { CompaniesIcon, LinkIcon } from "../../../../icons";
import {
  AppError,
  CompanyStatus,
  FormInputOption,
  IBusinessSector,
  ICompanyCertification,
  ICompanyCredential,
  ICompanyEducationRequirement,
  ICompanyRequiredEquipment,
  ICompanySkillSet,
  ICompetitor,
  ICreateDepartment,
  IDepartment,
  Profile,
  ProfileRole,
} from "../../../../interfaces";
import { postSnackbarMessage, useAppDispatch } from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import {
  CustomButton,
  enforceFormat,
  formatToPhone,
  RegxPattern,
} from "../../../../utils";
import { US_states } from "../../../../utils/States_US";
import {
  // companyStatusOptions,
  lowBtnStyle,
  outlineBtnStyle,
} from "../../../../variables";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "./department_form.scss";

interface Props {
  title: string;
  successTitle: string;
  currentLoginUserName?: string;
  loading: LoadingType;
  error: AppError | null | undefined;
  company_id: string;
  parent_department_id: string,
  department?: IDepartment;
  accountManagers: Array<Profile>;
  recruiters: Array<Profile>;
  businessSectors: Array<IBusinessSector>;
  competitors: Array<ICompetitor>;

  skillSetList: Array<ICompanySkillSet>;
  credentialsList: Array<ICompanyCredential>;
  certificationsList: Array<ICompanyCertification>;
  educationList: Array<ICompanyEducationRequirement>;
  requiredEquipmentList: Array<ICompanyRequiredEquipment>;
  onClose: () => void;
  onSubmit: (doc: { forceAdd: boolean; value: ICreateDepartment, parent_department_id: string }) => void;
  onClearError: () => void;
  selectedPhoto: File | null;
  onPhotoChange: (value: File) => void;
  onRemovePhoto: () => void;

  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (name: string) => void;
  CompanyStatus: Array<CompanyStatus>;
}

const getDepartmentFormData = (value: IDepartment): ICreateDepartment => {
  return {
    company_id: value.company_id ?? "",
    name: value.name ?? "",
    status: "",
    status_id: value.status_id ?? "",
    street_address: value.street_address ?? "",
    city: value.city ?? "",
    state: value.state ?? "",
    zip_code: value.zip_code ?? "",
    website: value.website ?? "",
    phone: value.phone ?? "",
    sector_type: value.sector_type ?? "",
    summary: value.summary ?? "",
    logo: value.logo ?? "",
    social_media_links: value.social_media_links ?? {
      linkedin_url: null,
      youtube_url: null,
      facebook_url: null,
      instagram_url: null,
    },
    staffing_agency_competitors: value.staffing_agency_competitors ?? [],
    professional_skills: value.professional_skills ?? [],
    certifications: value.certifications ?? [],
    education: value.education ?? [],
    credentials: value.credentials ?? [],
    safety_equipment: value.safety_equipment ?? [],
    uses_competitors: value.uses_competitors ?? false,
  };
};

const getUserProfileRoles = (value: ProfileRole[]) => {
  return value.map((doc) => doc.role);
};

const initialForm: ICreateDepartment = {
  company_id: "",
  name: "",
  status: "",
  status_id: "",
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  website: "",
  phone: "",
  sector_type: "",
  summary: "",
  logo: "",
  social_media_links: {
    linkedin_url: null,
    youtube_url: null,
    facebook_url: null,
    instagram_url: null,
  },
  staffing_agency_competitors: [],
  professional_skills: [],
  certifications: [],
  education: [],
  credentials: [],
  safety_equipment: [],
  uses_competitors: false,
};

const DepartmentForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<ICreateDepartment>(
    props.department
      ? getDepartmentFormData(props.department)
      : { ...initialForm, company_id: props.company_id }
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await CompaniesAPI.deleteDepartment(
        props.department?.id ?? ""
      );
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.companiesPage);
      }
    } catch (e: any) {
      const message = e.response?.data?.detail?.error?.join("\n");
      setSnackBarMessage(message);
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  function handleFieldChange(fieldId: string, value: any, parent?: string) {
    if (parent) {
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }

  const formBasicInformation: (FormInputOption | FormInputOption[])[] = [
    /// 1st row
    [
      {
        field: "name",
        type: "text",
        label: "department_name",
        secure: false,
        required: true,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "website",
        type: "text",
        label: "website",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "phone",
        type: "tel",
        label: "phone",
        secure: false,
        required: false,
        placeholder: "(555) 555 - 5555",
        keyboardtype: "",
        maxLength: 16,
        onKeyUp: formatToPhone,
        onKeyDown: enforceFormat,
      },
      {
        field: "status_id",
        type: "selector",
        label: "status",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.CompanyStatus.map((doc) => ({
          label: doc.company_status,
          value: doc.id,
        })),
        multi: false,
      },
      {
        field: "sector_type",
        type: "selector",
        label: "industry",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.businessSectors.map((doc) => ({
          label: doc.business_sector,
          value: doc.id,
        })),
        multi: false,
      },
    ],

    /// 2nd row
    [
      {
        field: "street_address",
        type: "text",
        label: "street_address",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "city",
        type: "text",
        label: "city",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "state",
        type: "selector",
        label: "state",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: US_states.map((doc) => ({
          label: doc.name,
          value: doc.code,
        })),
        multi: false,
      },
      {
        field: "zip_code",
        type: "text",
        label: "zip_code",
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
        maxLength: 5
      },
    ],

    /// 3rd row
    [
      {
        field: "geo_code",
        type: "selector",
        label: "geo_code",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: [],
        multi: false,
      },
      {
        field: "school_district",
        type: "selector",
        label: "school_district",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: [],
        multi: false,
      },
    ],
  ];

  const formProfessionalInformation: (FormInputOption | FormInputOption[])[] = [
    [
      {
        field: "professional_skills",
        type: "selector",
        label: "professional_skills",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.skillSetList.map((doc) => ({
          label: doc.skill_set,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "certifications",
        type: "selector",
        label: "certifications",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.certificationsList.map((doc) => ({
          label: doc.certification,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "education",
        type: "selector",
        label: "education",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.educationList.map((doc) => ({
          label: doc.education_requirement,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "credentials",
        type: "selector",
        label: "credentials",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.credentialsList.map((doc) => ({
          label: doc.credential,
          value: doc.id,
        })),
        multi: true,
      },
      {
        field: "safety_equipment",
        type: "selector",
        label: "safety_equipment",
        secure: false,
        required: false,
        placeholder: "select",
        keyboardtype: "",
        selectorList: props.requiredEquipmentList.map((doc) => ({
          label: doc.required_equipment,
          value: doc.id,
        })),
        multi: true,
      },
    ],
    [
      {
        field: "linkedin_url",
        parentField: "social_media_links",
        type: "text",
        label: "linked_in",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "facebook_url",
        parentField: "social_media_links",
        type: "text",
        label: "facebook",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "youtube_url",
        parentField: "social_media_links",
        type: "text",
        label: "youtube",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
      {
        field: "instagram_url",
        parentField: "social_media_links",
        type: "text",
        label: "instagram",
        labelIcon: getLinkIcon(),
        pattern: RegxPattern.url,
        secure: false,
        required: false,
        placeholder: "",
        keyboardtype: "",
      },
    ],
  ];

  if (props.loading === LoadingType.succeeded) {
    return (
      <div className="cmpny-dept-f-container">
        <div className="cmpny-dept-f-header">
          <FormDialogTilteHeader
            title={props.successTitle}
            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
          />
        </div>
        <div className="cmpny-dept-f-content">
          <div className="saved-doc-holder">
            <DocSavedText>
              <span className="aknw-name">
                {t("well_done_on_creating_new", {
                  field: t("department"),
                  name: props.currentLoginUserName,
                })}
              </span>
              <span className="info-txt">{`${t("a_new_created", {
                name: t("department"),
              })}:`}</span>
              <span className="name">{formState.name}</span>
            </DocSavedText>
          </div>
        </div>
        <div className="cmpny-dept-f-actions">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
              name={t("go_to_my_agency_dashboard")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onGotoAgencyDashboard}
              customStyle={lowBtnStyle.btn}
            />
          </div>
          <div className="btn-new">
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
                ...outlineBtnStyle.text,
              }}
              name={t("create_new", { name: t("department") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setFormState(initialForm);
                props.onCreateNew();
              }}
              customStyle={outlineBtnStyle.btn}
            />
          </div>

          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("go_to_this_newly_created", { name: t("department") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => props.onGotoCreated(formState.name)}
            />
          </div>
        </div>
      </div>
    );
  }

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };

  return (
    <div className="cmpny-dept-f-container">
      <div className="cmpny-dept-f-header">
        <FormDialogTilteHeader
          title={props.title}
          titleIcon={<CompaniesIcon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="cmpny-dept-f-content">
        {getSectionOne()}
        {getSectionTwo()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="cmpny-dept-f-actions">
        {props.department && (
          <div style={{ marginRight: "auto" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("delete_field", { name: t("department") })}
              enable={true}
              backgroundColor={THEME.statusInActiveColor}
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        )}
        <div className="btn-cancel">
          <CustomButton
            loading={false}
            textStyle={{ textTransform: "capitalize" }}
            name={t("cancel")}
            enable={true}
            backgroundColor={THEME.defaultHighLightColor}
            onClick={props.onClose}
          />
        </div>

        <div className="btn-save">
          <CustomButton
            loading={props.loading === LoadingType.pending}
            textStyle={{ textTransform: "capitalize" }}
            name={
              props.error != null && props.error?.code === 409
                ? t("force_add")
                : props.department
                  ? t("update_field", { name: t("department") })
                  : t("add_field", { name: t("department") })
            }
            // enable={_isFormValid()}
            enable={true}
            backgroundColor={
              props.error != null && props.error?.code === 409
                ? THEME.secondaryColor6
                : THEME.defaultHighLightColor
            }
            onClick={() => {
              setCheckValidationOnSubmit(true);
              if (_isFormValid()) {
                props.onSubmit({
                  forceAdd:
                    props.error != null && props.error?.code === 409
                      ? true
                      : false,
                  value: {
                    ...formState,
                    company_id: props.company_id,
                    name: formState?.name.trim()
                  },
                  parent_department_id: props.parent_department_id
                })
                setCheckValidationOnSubmit(false);
              }
            }
            }
          />
        </div>
      </div>
      {showDeleteDialog && props.department && (
        <DeleteConfirmationDialog
          message={t("delete_department_message", {
            department: props.department?.name,
          })}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          deleting={deleting}
          deleteType={t("department")}
          onClearError={()=>{}}
        />
      )}
      <Snackbar
        open={snackBarMessage !== null}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={snackbarAction()}
      />
    </div>
  );

  /// Section one
  function getSectionOne() {
    return (
      <div className="cmpny-dept-f-section-divider">
        <div className="cmpny-dept-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("basic_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formBasicInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="cmpny-dept-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={companyFormValidators}
                      className={"cmpny-dept-f-input-holder"}
                      checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={companyFormValidators}
              className={"cmpny-dept-f-input-holder"}
            />
          );
        })}
      </div>
    );
  }

  function getSectionTwo() {
    return (
      <div className="cmpny-dept-f-section-divider">
        <div className="cmpny-dept-f-row  flx-start">
          <div className="section-title-txt">
            <span>{t("professional_information").toLocaleUpperCase()}</span>
          </div>
        </div>
        {formProfessionalInformation.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="cmpny-dept-f-row ">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={companyFormValidators}
                      className={"cmpny-dept-f-input-holder"}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={companyFormValidators}
              className={"cmpny-dept-f-input-holder"}
            />
          );
        })}
        <div className="cmpny-dept-f-row ">
          <div className={"cmpny-dept-f-input-holder"}>
            <DragAndDrop
              type={"small"}
              label={t("company_logo")}
              placeholder={t("upload_or_drag_a_photo")}
              selectedFile={props.selectedPhoto}
              accept={"image/*"}
              onChange={props.onPhotoChange}
              onRemove={props.onRemovePhoto}
            />
          </div>
          <RenderInput
            doc={{
              field: "summary",
              type: "text",
              label: "department_about_us",
              secure: false,
              required: false,
              placeholder: "",
              keyboardtype: "",
              multiline: true,
            }}
            formState={formState}
            handleFieldChange={handleFieldChange}
            formValidators={companyFormValidators}
            className={"cmpny-dept-f-input-holder"}
            customStyle={{ width: "-webkit-fill-available" }}
          />
        </div>
        <div className="cmpny-dept-f-row ">
          <RenderInput
            doc={{
              field: "uses_competitors",
              type: "switch",
              label: "does_this_department_use_staffing_agencies",
              secure: false,
              required: false,
              placeholder: "",
              keyboardtype: "",
              labelPosition: "top",
            }}
            formState={formState}
            handleFieldChange={handleFieldChange}
            formValidators={companyFormValidators}
            className={"cmpny-dept-f-input-holder"}
            customStyle={{ flex: 1 }}
          />
          {formState.uses_competitors && (
            <RenderInput
              doc={{
                field: "staffing_agency_competitors",
                type: "selector",
                label: "competitors_this_company_worked_with",
                secure: false,
                required: false,
                placeholder: "select",
                keyboardtype: "",
                selectorList: props.competitors.map((doc) => ({
                  label: doc.competitor_name,
                  value: doc.id,
                })),
                multi: true,
              }}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={companyFormValidators}
              className={"cmpny-dept-f-input-holder"}
              customStyle={{ flex: 2 }}
              menuPlacement={"top"}
            />
          )}
        </div>
      </div>
    );
  }

  function getLinkIcon() {
    return (
      <div className="cmpny-dept-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }

  function _isFormValid() {
    const { name, website, social_media_links, phone, zip_code } = formState;

    const { facebook_url, youtube_url, instagram_url, linkedin_url } =
      social_media_links;

    if (name === "") return false;
    if (RegxPattern.username.test(name) === false) return false;
    if (phone != '' && RegxPattern.phone.test(phone) === false) return false;
    if (zip_code != '' && RegxPattern.zipCode.test(zip_code) === false) return false;
    if (
      website != null &&
      website !== "" &&
      RegxPattern.url.test(website) === false
    )
      return false;
    if (
      linkedin_url != null &&
      linkedin_url !== "" &&
      RegxPattern.url.test(linkedin_url) === false
    )
      return false;
    if (
      facebook_url != null &&
      facebook_url !== "" &&
      RegxPattern.url.test(facebook_url) === false
    )
      return false;
    if (
      youtube_url != null &&
      youtube_url !== "" &&
      RegxPattern.url.test(youtube_url) === false
    )
      return false;
    if (
      instagram_url != null &&
      instagram_url !== "" &&
      RegxPattern.url.test(instagram_url) === false
    )
      return false;
    return true;
  }

  function companyFormValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "name": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "website":
      case "linkedin_url":
      case "facebook_url":
      case "youtube_url":
      case "instagram_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      case "phone": {
        if (!value.text) return null;
        if (RegxPattern.usPhone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "zip_code": {
        if (!value.text) return null;
        if (RegxPattern.zipCode.test(value.text) === false)
          return t("validators.enterValidZipCode");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default DepartmentForm;
