import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { SearchIcon } from "../icons";
import './search_bar.scss';

interface Props {
    placeholder?: string,
    value: string,
    onChange: (value: string) => void,
    onSearch: () => void
}

const SearchBar = (props: Props) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const { t } = useTranslation();
    return (
        <div className="sb-container ">
                <input
                    className={""}
                    type={"search"}
                    placeholder={props.placeholder ?? `${t('type_to_search')}...`}
                    onChange={event => {
                        props.onChange(event.target.value);
                    }}
                    value={props.value}
                    autoFocus={false}
                    onKeyDown={handleKeyDown}
                />
            <a className="sb-s-icon" onClick={props.onSearch}>
                <SearchIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
            </a>
        </div>
    )
}

export default SearchBar;