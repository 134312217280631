import { RouteComponentProps, useHistory } from 'react-router-dom';
import './title_card_page.scss';
import { BulbIcon, InfoTextIcon } from '../../../../icons';
import { FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { THEME } from '../../../../enums';

interface Props {
    title: string,
    titleStyle?: React.CSSProperties,
    titleIcon: FunctionComponent<SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>,
    titleList: { title: string, showIcon: boolean, route: string, onclick?: () => void }[] | [];
}

const TitleCardPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onClick = (route: string) => {
        if (route)
            history.push(route);
    }

    return (
        <div className="tc-container">
            <div className='tc-header'>
                <div className="title-icon">
                    <props.titleIcon width={'100%'} height={'100%'} />
                </div>
                <div className="title-name">
                    <span>{t(props.title)}</span>
                </div>
            </div>
            <div className='tc-content'>
                <div className='title-list-div'>
                    {props.titleList && props.titleList.map((data: { title: string, showIcon: boolean, route: string, onclick?: () => void }, index) =>
                        (data.title === 'report_center_contract_service_calls' || data.title === 'report_center_direct_hire_service_calls') ?
                            <div>
                                <div key={index}>
                                    <div className='cd-title-name' onClick={() => onClick(data?.route!)}>
                                        <span >{t(data.title)}</span>
                                    </div>
                                </div>
                            </div>
                            : (
                                <div className='title-pill' key={index}>
                                    <div className='title-name' onClick={() => onClick(data.route)}>
                                        <span >{t(data.title)}</span>
                                    </div>
                                    {data.showIcon &&
                                        <div className='title-icon'>
                                            <BulbIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} onClick={data.onclick} />
                                        </div>}
                                </div>
                            ))}
                </div>
            </div>
        </div>
    )
}

export default TitleCardPage;
