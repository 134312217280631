import { EmailOutlined } from "@material-ui/icons";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../../enums";
import { IPaycheckDeductionAuthority } from "../../../../../interfaces/back_office_deduction_authority";
import { mailInvoicePaycheckSliceActions } from "../../../../../redux/back_office/mail_paycheck_invoices/mail_paycheck_invoices_reducer";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { selectWeekEndingDatesState, selectWeekEndingDatesDDList, getWeekEndingDatesDropdownThunk } from "../../../../../redux/selectors";
import { useAppDispatch, useAppSelector, selectProfileState, selectDeductionAuthorityBatchState, selectePaycheckInvoiceState, selectMailPaycheckInvoiceState, getDeductionAuthorityPaychecksBatchThunk, mailBOPaycheckThunk, getDedAuthPaycheckPDFURLThunk } from "../../../../../redux/store";
import { CustomCheckBox, CustomButton, SpinnerScreen, currencyConversion } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import SendInvoiceEdocsByMailPopup from "../../../../companies/popup/send_invoice_edocs_mail/send_invoice_edocs_mail";
import ViewCheckInvoicePopup from "../../../popups/view_check/view_check";
import { SearchIcon } from "../../../../../icons";
import { deductionAuthorityManageBatchSliceActions } from "../../../../../redux/back_office/payroll/manage_deduction_authority/deduction_authority_manage_batch_reducer";

interface Props extends RouteComponentProps<any> { }
const DeductionPaymentsDirectoryPage: React.FunctionComponent<Props> = (
    props: Props
) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [selectedWeekDate, setSelectedWeekDate] = useState<{
        id: string;
        date: string;
    }>({ id: "", date: "" });
    const [openViewCheckPopup, setOpenViewCheckPopup] = useState<boolean>(false);
    const [paycheckViewURL, setPaycheckViewURL] = useState<string>("");
    // const [showMailPopup, setShowMailPopup] = useState<boolean>(false);
    // const profileState = useAppSelector((state) => selectProfileState(state));
    // const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""}`;
    const weekEndingDatesListState = useAppSelector((state) =>
        selectWeekEndingDatesState(state)
    );
    const weekEndingDatesList = useAppSelector((state) =>
        selectWeekEndingDatesDDList(state)
    );
     const authorityDeductions = useAppSelector((state) =>
        selectDeductionAuthorityBatchState(state)
      );
    const dedAuthPaychecksState= authorityDeductions.getDedAuthPayChecks;
    const paycheckPDFURLState = authorityDeductions.dedAuthDwnPDFViewCheck;
    // const mailPaycheckState = useAppSelector((state) =>
    //     selectMailPaycheckInvoiceState(state)
    // );
    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        if (
            weekEndingDatesListState &&
            weekEndingDatesListState.loading === LoadingType.succeeded &&
            weekEndingDatesList.length > 0
        ) {
            setSelectedWeekDate({
                id: weekEndingDatesList[0].id,
                date: getDateString(
                    weekEndingDatesList[0].week_ending_date,
                    "mm/dd/yyyy"
                ),
            });
            dispatch(
                getDeductionAuthorityPaychecksBatchThunk({ week_accounting_id: weekEndingDatesList[0].id })
            );
        }
    }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);

    useEffect(() => {
        if (paycheckPDFURLState &&
            paycheckPDFURLState.loading === LoadingType.succeeded &&
            paycheckPDFURLState.response !== ""
        ) {
            setPaycheckViewURL(paycheckPDFURLState.response);
        }
    }, [
        paycheckPDFURLState.loading,
        paycheckPDFURLState.response,
    ]);

    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === dedAuthPaychecksState.response?.length}
                onClick={handleMarkAllItem}
                disabled
            />
        );
    };
    const tableHeader = [
        // { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "deduction_name", code: "deduction_name" },
        { title: "check_number", code: "check_number" },
        { title: "check_date", code: "check_date" },
        { title: "total_amount", code: "total_amount" },
        { title: "", code: "" },
        { title: "", code: "" },
    ];
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === dedAuthPaychecksState.response?.length) {
            setSelectedItems(newSet);
        } else {
            dedAuthPaychecksState.response?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value)) {
            newSet.delete(value);
        } else {
            newSet.set(value, { id: value, name: "" });
        }
        setSelectedItems(newSet);
    };
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(
            (obj) => obj.id === val
        );
        setSelectedWeekDate({
            id: val,
            date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy"),
        });
        dispatch(getDeductionAuthorityPaychecksBatchThunk({ week_accounting_id: val }));
    };

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getDedAuthPaycheckPDFURLThunk(pay_check_id));
        setOpenViewCheckPopup(true);
    };

    function getFilteredList() {
        let list: IPaycheckDeductionAuthority[] = dedAuthPaychecksState?.response!;
        if (sortedField != null && dedAuthPaychecksState?.response) {
            list = [...dedAuthPaychecksState?.response].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list?.filter((doc) => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(
                (val) => val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const handleClose = () => {
        setOpenViewCheckPopup(false);
        dispatch(deductionAuthorityManageBatchSliceActions.clearViewPaycheckBatchState());
        setPaycheckViewURL("");
    };

    // const handleEmailClose = () => {
    //     setShowMailPopup(false);
    //     dispatch(mailInvoicePaycheckSliceActions.clearMailPaycheckState());
    //     setSelectedItems(new Map());
    // };
    // const sendPaycheckByMail = (paycheck_ids: string[]) => {
    //     dispatch(mailBOPaycheckThunk(paycheck_ids));
    // };

    return (
        <div className="payroll-paycheck-container">
            <div className="payroll-paycheck-header">
                <div className="select-row">
                    <div>
                        <CustomFormSelect
                            list={weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))}
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="bi-header-right">
                    {/* <div>
                        <CustomButton
                            textStyle={{ textTransform: "capitalize" }}
                            leftIcon={
                                <div>
                                    <EmailOutlined
                                        style={{ color: THEME.white, height: "1vw", width: "1vw" }}
                                    />
                                </div>
                            }
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => {
                                sendPaycheckByMail(
                                    Array.from(selectedItems.values(), (entry) => entry.id)
                                );
                                setShowMailPopup(true);
                            }}
                            onClick={()=>{}}
                            enable={selectedItems.size > 0}
                            customStyle={{cursor: "auto"}}
                        />
                    </div> */}
                    <div className="search-bar">
                        <SearchBar
                            value={search}
                            onChange={(value) => setSearch(value)}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>
            <div className="payroll-paycheck-content">
                <div className="payroll-paycheck-table">
                    {dedAuthPaychecksState && dedAuthPaychecksState.response && dedAuthPaychecksState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ? (
                                getFilteredList()?.map((doc) => {
                                    return (
                                        <tr key={doc.id}>
                                            {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <CustomCheckBox
                                                    name={""}
                                                    title={""}
                                                    checked={selectedItems.has(doc.id)}
                                                    onClick={() => handleMarkItem(doc.id)}
                                                    disabled
                                                />
                                            </TableData> */}
                                            <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                                <span>{doc.deduction_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{doc.check_number}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{getDateString(doc.check_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                <span>{currencyConversion(doc.total_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                <div className={"view-check-btn"}>
                                                    <CustomButton
                                                        loading={false}
                                                        customStyle={{ width: "-webkit-fill-available", cursor: "auto" }}
                                                        leftIcon={
                                                            <span style={{ color: "#fff" }}>
                                                                <SearchIcon />
                                                            </span>
                                                        }
                                                        textStyle={{ textTransform: "capitalize" }}
                                                        name={""}
                                                        enable={true}
                                                        backgroundColor={THEME.buttonBackgroundColor3}
                                                        onClick={() => handleViewCheck(doc.id)}
                                                    />
                                                </div>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                <span></span>
                                            </TableData>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(14) }}>
                                        <div
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                                fontSize: "0.9vw",
                                            }}
                                        >
                                            {t("no_data_available")}
                                        </div>
                                    </TableData>
                                </tr>
                            )}
                        </SortableTable>
                    )}
                </div>
            </div>
            {openViewCheckPopup && (
                <Portal>
                    <ViewCheckInvoicePopup
                        visible={openViewCheckPopup}
                        title={t("view_check")}
                        onClose={handleClose}
                        state={paycheckPDFURLState}
                        responseURL={paycheckViewURL}
                    />
                </Portal>
            )}
            {/* {showMailPopup && (
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => { }}
                        mailState={mailPaycheckState.mailPaycheck}
                        currentLoginUserName={currentLoginUserName}
                        title={t("hi_lets_send_paycheck", {
                            name: currentLoginUserName,
                        })}
                        successTitle={t("sent_paycheck_by_email")}
                        successMsg={t("you_sent_paycheck_to", { count: 1 })}
                        subTitle={t("to_whom_you_send_paycheck")}
                    />
                </Portal>
            )} */}
        </div>
    );
    function getFlexNumber(value: number) {
        // if (value === 0) return 0.3;
        if (value === 4) return 0.7;
        if (value === 5) return 0.5;
        return 1;
    }
};

export default DeductionPaymentsDirectoryPage;
