import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../enums";
import { OnBoardingIcon } from "../../../icons";
import {
  IAgencyOnboardingData,
  IOnboardingTeamMemberDetail,
  ITalentOnBoardingData,
} from "../../../interfaces";
import {
  getUploadW4UrlThunk,
  selectW4State,
  uploadW4Thunk,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { CustomButton, getValueFromKey } from "../../../utils";
import { WebViewerInstance } from "@pdftron/webviewer";
import "./w4.scss";
import PDFReader from "../../../components/pdf_reader/pdf_reader";
import { w4SliceActions } from "../../../redux/onboarding_portal/w4/w4_reducer";
import ApiError from "../../../components/api_error";
import { W4FormFields } from "../../pdf_fields/w4";
import { getDateString } from "../../../variables";

interface Props {
  visible: boolean;
  type: string;
  title: string;
  token: string;
  state: ITalentOnBoardingData;
  recruiter: IOnboardingTeamMemberDetail;
  agency: IAgencyOnboardingData;
  successTitle: string;
  onClose: () => void;
  onDissmiss?: () => void;
  onSuccessClose: (type: string, value?: any) => void;
}

const W4Popup: React.FunctionComponent<Props> = (props) => {
  const {
    visible,
    type,
    state,
    recruiter,
    agency,
    title,
    token,
    onClose,
    onDissmiss,
    onSuccessClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { uploadFile, uploadURL } = useAppSelector((state) =>
    selectW4State(state)
  );
  const actionRef = useRef<ActionDialogHolderType>(null);
  const instance = useRef<WebViewerInstance>();

  /// GET Upload url & initiate upload file
  useEffect(() => {
    if (uploadURL.loading === LoadingType.succeeded && uploadURL.response) {
      handleUploadFile(uploadURL.response.url);
    }
  }, [uploadURL.loading]);

  /// UPLOAD File success
  useEffect(() => {
    if (uploadFile.loading === LoadingType.succeeded) {
      onSuccessClose(type);
    }
    return () => {
      if (instance.current && state.w4_document.url) {
        cleanPDFState(instance.current);
      }
      dispatch(w4SliceActions.clearState());
    };
  }, [uploadFile.loading]);

  /// Reload document on component re-render
  useEffect(() => {
    if (instance.current && state.w4_document.url) {
      loadPDFDocument(instance.current, state.w4_document.url);
    }
  }, [state.w4_document.url]);

  function handleOnInstance(value: WebViewerInstance) {
    instance.current = value;
    instance.current.UI.disableElements(['toolsHeader']);
    loadPDFDocument(value, state.w4_document.url);
  }
  const requiredFieldsArray = [
    "topmostSubform[0].Page1[0].Step1a[0].f1_01[0]",
    "topmostSubform[0].Page1[0].Step1a[0].f1_02[0]",
    "topmostSubform[0].Page1[0].Step1a[0].f1_03[0]",
    "topmostSubform[0].Page1[0].Step1a[0].f1_04[0]",
    // "topmostSubform[0].Page1[0].Step1a[0]",
    "topmostSubform[0].Page1[0].f1_05[0]",
    "employee_signature",
    "employee_sign_date",
  ];
  const checkBoxReqFields = [
    "topmostSubform[0].Page1[0].c1_1[0]",
    "topmostSubform[0].Page1[0].c1_1[1]",
    "topmostSubform[0].Page1[0].c1_1[2]",
  ];

  const preLoadedFiledsToDisable = [
    "topmostSubform[0].Page1[0].f1_13[0]",
    "topmostSubform[0].Page1[0].f1_14[0]",
    "topmostSubform[0].Page1[0].f1_15[0]",
  ];
  const dateFields = [
    "employee_sign_date"
  ]
  function loadPDFDocument(value: WebViewerInstance, url: string) {
    const { UI, Core } = value;
    const { documentViewer, annotationManager, Annotations } = Core;

    //to disable individual input field using name
    // <<<<<<<<<----- DO NOT REMOVE THIS CODE AS IT MAY BE USED IN FUTURE REFERENCE TO DISABLE INDIVIDUAL FIELD ------>>>>>>>>>>>
    annotationManager.addEventListener(
      "annotationChanged",
      (annotations, action, { imported }) => {
        // if the annotation change occurs because of an import then
        // these are fields from inside the document
        if (imported && action === "add") {
          annotations.forEach(function (annot) {
            if (
              annot instanceof Annotations.WidgetAnnotation &&
              preLoadedFiledsToDisable.includes(annot.fieldName)
            ) {
              annot.fieldFlags.set("ReadOnly", true); // this is to disable the field
            }
            if (
              annot instanceof Annotations.WidgetAnnotation &&
              requiredFieldsArray.includes(annot.fieldName) &&
              checkBoxReqFields.includes(annot.fieldName)
            ) {
              annot.fieldFlags.set("Required", true);
              // annot.fieldFlags.set("ReadOnly", true);  // this is to disable the field
            }
          });
        }
      }
    );
    documentViewer.addEventListener("documentLoaded", () => {
      documentViewer.getAnnotationsLoadedPromise().then(() => {
        // iterate over fields
        const fieldManager = annotationManager.getFieldManager();
        Object.keys(W4FormFields).forEach((key) => {
          const doc = W4FormFields[key];

          if (doc.mapTo) {
            const field = fieldManager.getField(doc.key);
            field.setValue(
              getValueFromKey(doc.mapTo, { ...recruiter, ...agency })
            );
          }

          if (doc.type && doc.type === "date" || dateFields.includes(doc.key)) {
            const field = fieldManager.getField(doc.key);
            field.setValue(getDateString(new Date(), "mm/dd/yyyy"));


          }
        });
        UI.setFitMode(UI.FitMode.FitWidth);
      });
    });
    UI.loadDocument(url);
  }

  function cleanPDFState(value: WebViewerInstance) {
    const { documentViewer } = value.Core;
    documentViewer.removeEventListener("documentLoaded");
    // documentViewer.removeEventListener('fieldChanged');
  }

  function handleClose() {
    closePopup(onClose);
  }
  const [checkBoxMsg, setCheckBoxMsg] = useState<boolean>(false);

  // to check the valid SSN
  const regex = /^\D*(\d\D*){9}\D*$/;
  const [ssnCheck, setSSNCheck] = useState<boolean>(false);
  const [signatureMissing, setSignatureMissing] = useState<boolean>(false);

  function containsMinimum9Digits(inputString: string | number | undefined): boolean {
    if (typeof inputString === 'string') {
      const isValid = regex.test(inputString);
      return isValid;
    }

    return false;
  }
  async function handleUploadFile(url: string) {
    if (instance.current) {
      const { documentViewer, annotationManager } = instance.current.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();

      // TO check whether the required fields are empty or not
      const formFields = documentViewer
        .getAnnotationManager()
        .getFieldManager();

      const formFieldsData = formFields.getFields();
      // showing the sum of step 3 fields into the total field
      const field1 = formFieldsData.find((field) => {
        return (field.name === "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_06[0]")
      })?.getValue();
      const field2 = formFieldsData.find((field) => {
        return (field.name === "topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_07[0]")
      })?.getValue();

      const employeeSignature = formFieldsData.find((field) => {
        return field.name === 'employee_signature'; // Update with the actual field name
      })?.getValue();


      const sumField = formFields.getField("topmostSubform[0].Page1[0].f1_09[0]");
      const field1Value = field1 !== undefined && field1 !== '' ? parseFloat(field1.toString()) : 0;
      const field2Value = field2 !== undefined && field2 !== '' ? parseFloat(field2.toString()) : 0;

      const sum = field1Value + field2Value;
      sumField.setValue(sum !== 0 ? sum.toString() : "");

      // Re-import the annotations to update the document with the new sum value
      const updatedXFDFString = await annotationManager.exportAnnotations();
      await annotationManager.importAnnotations(updatedXFDFString); // Re-import after update

      // Continue with file processing
      const data = await doc.getFileData({
        xfdfString: updatedXFDFString,
      });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });

      const ssnField = formFieldsData.find((field) => {
        return (field.name === 'topmostSubform[0].Page1[0].f1_05[0]')
      })?.getValue();

      const reqFields = formFieldsData.filter((field) =>
        requiredFieldsArray.includes(field.name)
      );
      const reqCheckFields = formFieldsData.filter((field) =>
        checkBoxReqFields.includes(field.name)
      );
      const isRequiredFieldsEmpty = reqFields.some(
        (field) => field.getValue() === ""
      );
      // const isRequiredCheckboxFieldsEmpty = reqCheckFields.some(
      //   (field) => field.getValue() !== "Off"
      // );
      let trueCount = 0;
      reqCheckFields.forEach(field => {
        if (field.getValue() !== "Off") {
          trueCount++; // Increment counter if condition is true
        }
      });
      const isRequiredCheckboxFieldsEmpty = trueCount > 0;

      // Independent check for SSN
      if (containsMinimum9Digits(ssnField)) {
        setSSNCheck(false);
      } else {
        setSSNCheck(true);
      }
      // Independent check for employee signature
      if (!employeeSignature || employeeSignature.toString().trim() === "") {
        setSignatureMissing(true); // Assuming `setSignatureMissing` manages the signature state
      } else {
        setSignatureMissing(false);
      }
      // Main condition
      if (
        !isRequiredFieldsEmpty &&
        isRequiredCheckboxFieldsEmpty &&
        trueCount === 1 &&
        containsMinimum9Digits(ssnField) &&
        employeeSignature && employeeSignature.toString().trim() !== ""
      ) {
        if (trueCount === 1) {
          setCheckBoxMsg(false);
        }
        dispatch(uploadW4Thunk({ url: url, file: blob }));
      } else {
        if (trueCount === 1) {
          setCheckBoxMsg(false);
        } else {
          setCheckBoxMsg(true);
        }
      }

    }
  }

  const loading =
    uploadURL.loading === LoadingType.pending ||
    uploadFile.loading === LoadingType.pending;

  const error = uploadURL.error || uploadFile.error;

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible={visible}
      wrapperWidth={"70%"}
      onClose={onClose}
      onDissmiss={onDissmiss}
    >
      <DialogWrapper onClose={handleClose}>
        <div className="w4-container talent-onboarding">
          <div className="w4-header">
            <FormDialogTilteHeader
              title={t("type_form", { name: title })}
              subTitle={t("please_scroll_down_and_fill_out_the_form_below")}
              subTitleStyle={{ fontWeight: "normal" }}
              titleIcon={<OnBoardingIcon width={"100%"} height={"100%"} />}
            />
          </div>

          <div className="w4-content">
            <PDFReader onInstanceChange={handleOnInstance} />
            {error && (
              <div className={"error-section"}>
                <ApiError message={error.message} onClose={handleClearError} />
              </div>
            )}
          </div>
          <div className="w4-actions">
            {/* {requiredFieldMsg && (
            <div className="errorMsg">
              <span style={{ color: "Red" }}>
                {t("fill_required_fields_details_w4")}
              </span>
            </div>
            )} */}
            <div className="errorMsg">
              <span style={{ color: "Red" }}>
                {t("fill_required_fields_details_w4")}
              </span>
            </div>

            <div className="w4-actions-row">

              <div className="ssn-checkbox">
                {ssnCheck && <div>
                  <span style={{ color: "Red" }}>
                    {t("check_ssn")}
                  </span>
                </div>}
                {signatureMissing && <div>
                  <span style={{ color: "Red" }}>
                    {t("emp_sign_missing")}
                  </span>
                </div>}
                {checkBoxMsg && <div>
                  <span style={{ color: "Red" }}>
                    {t("w4_check_chebox")}
                  </span>
                </div>}
              </div>
              <div className="btn-cancel">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => closePopup(onClose)}
                />
              </div>
              <div className="btn-new">
                <CustomButton
                  loading={loading}
                  textStyle={{
                    textTransform: "capitalize",
                  }}
                  name={t("confirm_complete")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => (loading ? undefined : handleSave())}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );

  ///Handle clear error
  function handleClearError() {
    dispatch(w4SliceActions.clearState());
  }

  /// handle submit
  function handleSave() {
    dispatch(getUploadW4UrlThunk({ token, talent_id: state.profile.id }));
  }

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }
};

export default W4Popup;
