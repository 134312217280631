import { useTranslation } from "react-i18next";
import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption, ICompaniesList, IContactDropdownItem, ICreateJobForm, IDepartmentsList, JobOrderPlacement, JobOrderSource } from "../../../../../interfaces";
import { allowNumbers, convertNumberToMoney, formatToMoney, FormInput } from "../../../../../utils";
import { jobsStatusOptions, jobTypeOptions, payFeePlacementType } from "../../../../../variables";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import './step_one.scss';

interface Props {
    companies: ICompaniesList[],
    departments: IDepartmentsList[],
    contactsList: IContactDropdownItem[],
    orderSourceList: JobOrderSource[],
    placementTypeList: JobOrderPlacement[],
    data?: { company_id?: string, department_id?: string, department_ids?: string[] },
    formState: ICreateJobForm,
    handleFieldChange: (id: string, value: any, parent?: string | undefined) => void,
    validators: ((value: {
        text: string;
        field: string;
    }) => string | null) | undefined,
    checkValidationOnSubmit: boolean;
}

const StepOneForm: React.FunctionComponent<Props> = (props) => {
    const { checkValidationOnSubmit, companies, departments, contactsList, data, orderSourceList, placementTypeList, formState, handleFieldChange, validators } = props;
    const { t } = useTranslation();
    
    const targetNames = [
        "Direct Hire",
        "Retained Search",
        "Professional Search"
    ];
    
    const selectedPlacement = placementTypeList.find(item => item.id === formState.placement_type_id);
    const conditionMet = selectedPlacement && targetNames.includes(selectedPlacement.title);

    const formOptions: (FormInputOption | FormInputOption[])[] = [
        {
            field: "placement_type_id",
            type: "selector",
            label: 'placement_type',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: placementTypeList.map((doc) => ({ label: doc.title, value: doc.id })),
            multi: false,
            labelPosition: "left",
        },
        {
            field: "job_order_source_id",
            type: "selector",
            label: 'job_order_source',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: orderSourceList.map((doc) => ({ label: doc.job_order_source, value: doc.id })),
            multi: false,
            labelPosition: "left",
        },
        {
            field: "job_type",
            type: "selector",
            label: 'job_type',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: jobTypeOptions,
            multi: false,
            labelPosition: "left",
        },
        {
            field: "company_id",
            type: "selector",
            label: 'company',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: companies.map((doc) => ({ label: doc.name, value: doc.id })),
            multi: false,
            disabled: (data && data.company_id) ? true : false,
            labelPosition: "left",
        },
        {
            field: "department_id",
            type: "selector",
            label: 'department',
            secure: false,
            required: false,
            placeholder: 'Corporate',
            keyboardtype: '',
            selectorList: departments.map((doc) => ({ label: doc.name, value: doc.id })),
            multi: false,
            disabled: (data && data.company_id && data.department_id) ? true : false,
            labelPosition: "left",
        },
        {
            field: "job_title",
            type: "text",
            label: 'job_title',
            secure: false,
            required: true,
            placeholder: '',
            keyboardtype: '',
            labelPosition: "left",
        },

        {
            field: "order_date",
            type: "date",
            label: 'order_date',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: '',
            labelPosition: "left",
            isJobFormDateField: true,
        },

        {
            field: "contact_id_for_placement",
            type: "selector",
            label: 'contact_for_placement',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
        },

        {
            field: "ordered_by_contact_id",
            type: "selector",
            label: 'ordered_by',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },

        {
            field: "report_to_contact_id",
            type: "selector",
            label: 'report_to',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },
        ...(conditionMet ? [] : [
            {
                field: "regular_pay_rate",
                type: "text",
                label: 'pay_rate',
                secure: false,
                required: false,
                placeholder: '',
                keyboardtype: '',
                min: 0.00,
                step: 0.01,
                prefix: (<span>$</span>),
                labelPosition: "left" as "left" | "top" | "right",
                onBlur: formatToMoney,
                onKeyDown: formatToMoney,
                onKeyUp: formatToMoney,
            },
        ]),
        {
            field: "status",
            type: "selector",
            label: 'status',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: jobsStatusOptions,
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },
    ];


    return (
        <div className="jb-f-stp-one">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + 'row'} className="jb-f-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={validators}
                                        className={"jb-f-input-holder"}
                                        checkValidationOnSubmit={true}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"jb-f-input-holder"}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                )
            })}
            {payFeePlacementType.includes(formState.placement_type_value) &&
                <div className="dh-row">
                    <div className="jb-f-dh-holder">
                        <div className="label-span">
                            <span>{t("pay_type_and_pay_rate")}*:</span>
                        </div>
                        <div className="jb-f-dh-input">
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-required row aria-label="pay_type" name="pay_type" value={formState.pay_type}
                                        onChange={(event) => { handleFieldChange("pay_type", event.target.value); }}>
                                        <div className="radio-btn fc">
                                            <FormControlLabel value="hourly" control={<Radio />} label={t("hourly")} />
                                            <div className="radio-i">
                                                <FormInput
                                                    id={"hourly_pay_type"}
                                                    onChange={handleFieldChange}
                                                    type={"text"}
                                                    value={convertNumberToMoney(formState.hourly_pay_type, "string") as string}
                                                    label={t("")}
                                                    placeholder=""
                                                    validations={validators}
                                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                                    onBlur={formatToMoney}
                                                    onKeyDown={formatToMoney}
                                                    onKeyUp={formatToMoney}
                                                    prefix={<span>$</span>}
                                                    disabled={formState.pay_type !== "hourly"}
                                                />
                                            </div>
                                        </div>
                                        <div className="radio-btn">
                                            <FormControlLabel value="salary" control={<Radio />} label={t("salary")}/>
                                            <div className="radio-i">
                                                <FormInput
                                                    id={"salary_pay_type"}
                                                    onChange={handleFieldChange}
                                                    type={"text"}
                                                    value={convertNumberToMoney(formState.salary_pay_type, "string") as string}
                                                    label={t("")}
                                                    placeholder=""
                                                    validations={validators}
                                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                                    onBlur={formatToMoney}
                                                    onKeyDown={formatToMoney}
                                                    onKeyUp={formatToMoney}
                                                    prefix={<span>$</span>}
                                                    disabled={formState.pay_type !== "salary"}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {(formState.pay_type === "" && checkValidationOnSubmit) && <span style={{ color: "red" }} >Required Field</span>}
                        </div>
                    </div>
                    <div className="jb-f-dh-holder">
                        <div className="label-span">
                            <span>{t("fee_type")}*:</span>
                        </div>
                        <div className="jb-f-dh-input">
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-required row aria-label="fee_type" name="fee_type" value={formState.fee_type}
                                        onChange={(event) => { handleFieldChange("fee_type", event.target.value); }}>
                                        <div className="radio-btn fc">
                                            <FormControlLabel value="flat_rate" control={<Radio />} label={t("flat_rate")} />
                                            <div className="radio-i">
                                                <FormInput
                                                    id={"fee_type_flat_rate"}
                                                    onChange={handleFieldChange}
                                                    type={"text"}
                                                    value={convertNumberToMoney(formState.fee_type_flat_rate, "string") as string}
                                                    label={t("")}
                                                    placeholder=""
                                                    validations={validators}
                                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                                    onBlur={formatToMoney}
                                                    onKeyDown={formatToMoney}
                                                    onKeyUp={formatToMoney}
                                                    prefix={<span>$</span>}
                                                    disabled={formState.fee_type !== "flat_rate"}
                                                />
                                            </div>
                                        </div>
                                        <div className="radio-btn">
                                            <FormControlLabel value="percentage" control={<Radio />} label={t("percentage")} disabled={formState.pay_type === "hourly"}/>
                                            <div className="radio-i">
                                                <FormInput
                                                    id={"fee_type_percentage"}
                                                    onChange={handleFieldChange}
                                                    type={"text"}
                                                    value={formState.fee_type_percentage === 0 ? "0.00" : formState.fee_type_percentage}
                                                    label={t("")}
                                                    placeholder=""
                                                    validations={validators}
                                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                                    suffix={<span>%</span>}
                                                    onKeyUp={(e) => { allowNumbers(e); }}
                                                    onKeyDown={(e) => { allowNumbers(e); }}
                                                    disabled={formState.fee_type !== "percentage" || formState.pay_type === "hourly"}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {(formState.fee_type === ""  && checkValidationOnSubmit) && <span style={{ color: "red" }} >Required Field</span>}
                        </div>
                    </div>
                    <div className="jb-f-dh-holder">
                        <div className="label-span">
                            <span>{t("fee_amount")}:</span>
                        </div>
                        <div className="jb-f-dh-input">
                            <div className="">
                                <FormInput
                                    id={"fee_amount"}
                                    onChange={handleFieldChange}
                                    type={"text"}
                                    value={convertNumberToMoney(formState.fee_amount, "string") as string}
                                    label={t("")}
                                    placeholder=""
                                    validations={validators}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                    prefix={<span>$</span>}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default StepOneForm;