import { useState } from "react";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { currencyConversion, CustomCheckBox } from "../../../../utils";
import { getDateString } from "../../../../variables";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../../components/search_bar";
import { IDeductionAuthority, IDeductionAuthorityBatch } from "../../../../interfaces/back_office_deduction_authority";

interface Props {
  deductions: IDeductionAuthority;
  selectedCards: Set<String>;
  handleSelection: (id?: string) => void;
}

export const CreateDeductionPaymentsStepTwo = (props: Props) => {
  const { t } = useTranslation();
  const { deductions, selectedCards, handleSelection } = props;
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState('');

  const SelectAllCheckBox = () => {
    return (
      <CustomCheckBox
        name={""}
        title={""}
        checked={selectedCards.size === deductions?.payroll_deductions?.length}
        onClick={() => handleSelection()}
      />
    );
  };

  const tableHeader = [
    { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
    { title: "deduction_name", code: "deduction_name" },
    { title: "deduction_amount", code: "deduction_amount" },
    { title: "week_ending_date", code: "week_ending_date" },
  ];

  function getFlexNumber(value: number) {
    if (value === 0) return 0.2;
    if (value === 9) return 0.27;
    if (value === 20) return 1;
    return 1;
  }

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  function getFilteredList() {
    // Ensure list is initialized as an empty array if deductions or payroll_deductions is invalid
    let list: IDeductionAuthorityBatch[] = [];
  
    // Check if deductions and payroll_deductions are valid arrays
    if (deductions && Array.isArray(deductions.payroll_deductions)) {
      list = [...deductions.payroll_deductions]; // Create a shallow copy of the payroll_deductions array
    }
  
    if (deductions && sortedField) {
      // Sort the list if sortedField is provided
      list = [...deductions?.payroll_deductions].sort((a, b) => {
        const valueA = a[sortedField] != null
          ? typeof a[sortedField] === 'string'
            ? a[sortedField].trim().toLowerCase()
            : a[sortedField]
          : "";
        const valueB = b[sortedField] != null
          ? typeof b[sortedField] === 'string'
            ? b[sortedField].trim().toLowerCase()
            : b[sortedField]
          : "";
  
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
        }
      });
    }
  
    // Ensure the list is a valid array before calling filter
    return Array.isArray(list) ? list.filter(doc => {
      const str = search.trim().toLowerCase();
      return Object.values(doc).some(val =>
        val && val.toString().trim().toLowerCase().includes(str)
      );
    }) : []; // Return an empty array if list is not an array
  }
  
  return (
    <div className="cpb-step-two">
      <div className="cpb-step-header" style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
        <div className="cpb-step-right" style={{ marginTop: "1em" }}>
          <div className="total-count">
            <span>
              {`${t("total_count")}: `}
              <span className="total-count-number">
                {getFilteredList()?.length}
              </span>
            </span>
          </div>
          <div>
            <SearchBar
              value={search}
              onChange={setSearch}
              onSearch={() => { }}
            />
          </div>
        </div>
      </div>
      <div className="cpb-step-two-table">
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList().length > 0 ?
            <>
              {getFilteredList().map((doc) => {
                return (
                  <tr key={doc.deduction_id}>
                    <TableData customStyle={{ flex: getFlexNumber(0) }}>
                      <CustomCheckBox
                        name={""}
                        title={""}
                        checked={selectedCards.has(doc.deduction_id)}
                        onClick={() => handleSelection(doc.deduction_id)}
                      />
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(1) }}>
                      <span>{doc.deduction_name}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(2) }}>
                      <span>{doc.deduction_amount}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(3) }}>
                      <span>{getDateString(doc.week_ending_date)}</span>
                    </TableData>
                  </tr>
                );
              })}
            </>
            :
            <>
              <tr className="nda-div">
                <TableData customStyle={{ flex: getFlexNumber(20) }}>
                  <div>
                    {t("no_data_available")}
                  </div>
                </TableData>
              </tr>
            </>
          }
          <div className="total-row">
            <TableData
              customStyle={{
                flex: getFlexNumber(1),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>Total</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(9), backgroundColor: "#f4f4fa" }}>
              <span></span>
            </TableData>
            <TableData
              customStyle={{
                flex: getFlexNumber(1),
                backgroundColor: "#f4f4fa",
                color: "#074783",
                fontWeight: "bold",
              }}
            >
              <span>{currencyConversion(deductions?.total_pay!)}</span>
            </TableData>
            <TableData customStyle={{ flex: getFlexNumber(1), backgroundColor: "#f4f4fa" }}>
              <span></span>
            </TableData>
          </div>
        </SortableTable>
      </div>
    </div>
  );
};
