import React, { useEffect, useRef, useState } from 'react';
import { LoadingType, ProfilePictureType, THEME } from "../../../enums";
import { CustomButton, RegxPattern, enforceFormat, formatToEIN, formatToPhone, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { getProfile, selectProfileState, useAppDispatch, useAppSelector } from "../../../redux/store";
import './agency_setup_page.scss';
import '../../../custom.scss';
import {
    updateAgencyDetails, getAgencyDetails, selectAgencyDetailsState,
    createAgencyBranch, updateAgencyBranch, getAgencyBranches, selectAgencyBranchDetailsState
} from "../../../redux/admin_center";
import { AddCircleOutline } from "@material-ui/icons";
import SearchBar from "../../../components/search_bar";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../components/sortable_table/sortable_table";
import { Portal } from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import CloseIcon from "../../../../assets/icons/close-btn2.svg";
import { Agency, AgencyBranch } from '../../../interfaces';
import { FormInput } from "../../../utils/form_input";
import CustomFormSelect from '../../../components/form_selector/form_select';
import { US_states } from '../../../utils/States_US';
import ToggleSwitch from '../../../components/toggle_switch/toggle_switch';
import UploadProfile from '../../../components/image_crop/image_crop_popup';
import { DeleteIcon, EditIcon, FilesIcon,BOOpenCloseIcon } from '../../../icons';
import { DeleteConfirmationDialog } from '../../components/delete_confirmation_dialog';
import { clearAgencyDetailsUpdateState, clearAgencyDetailsUpdateStateError } from '../../../redux/admin_center/agency_setup/agency_setup_reducer';
import BranchForm from './forms/branch/add_branch';
import { agencyBranchDetailsActions } from '../../../redux/admin_center/agency_setup/agency_branch/agency_branch_reducer';
import DocSavedText from '../../../components/doc_saved_text/doc_saved_text';
import DialogWrapper from '../../../components/dialog_wrapper/dialog_wrapper';
import { FormDialogTilteHeader } from '../../../components/dialog_wrapper/dialog_wrapper';
const headers = [
    { title: 'branch_name', code: 'name' },
    { title: 'street', code: 'street_address' },
    { title: 'city', code: 'city' },
    { title: 'state', code: 'country' },
    { title: 'zip_code', code: 'zip_code' },
]

const getInitialForm = (value?: Agency): Agency => {
    if (value) {
        const { agency_logo_file_url, ...updatedValue } = value;
        return updatedValue;
    }
    return {
        id: "",
        name: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        remit_street_address: "",
        remit_city: "",
        remit_state: "",
        remit_zip_code: "",
        phone: "",
        fax: "",
        web_url: "",
        fein: "",
        search_and_import_data: true,
        is_remit_address_same_agency: true,

    } as Agency;
}

const AgencySetupPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [message, setMessage] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);


    const agencyDetailsState = useAppSelector((state) => selectAgencyDetailsState(state));
    const agencyBranchDetailsState = useAppSelector((state) => selectAgencyBranchDetailsState(state));
    const [agencyDetails, setAgencyDetails] = useState<Agency>(getInitialForm());
    const [branchDetails, setBranchDetails] = useState({
        name: '', street_address: '', city: '',
        country: '', zip_code: '', phone: '', fax: '', web_url: '', id: ''
    });

    const [showImageUpload, setShowImageUpload] = useState(false);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const [inputEnable, setInputEnable] = useState<boolean>(true);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{visible: boolean, logoName: string | undefined} | null>(null);
    const [displaySuccessPopup, setDisplaySuccessPopup] = useState<boolean>(false);
    const agencyLogoName = agencyDetails?.agency_logo_file_path?.split('/').pop() 

    useEffect(() => {
        dispatch(getAgencyDetails());
        dispatch(getAgencyBranches());
        return () => {
            dispatch(clearAgencyDetailsUpdateState())
        }
    }, [])
    useEffect(() => {
        if (agencyDetailsState.loading === LoadingType.succeeded && agencyDetailsState?.agency) {
            setAgencyDetails(getInitialForm(agencyDetailsState?.agency));
        }
    }, [agencyDetailsState.loading, agencyDetailsState?.agency])

    useEffect(() => {
        if (agencyDetailsState.update.loading === LoadingType.succeeded) {
            setMessage(agencyDetailsState.update.response ? agencyDetailsState.update.response : '');
            setTimeout(() => setMessage(''), 5000);
            setShowDeleteDialog(null)
            updateProfileURL()
            dispatch(clearAgencyDetailsUpdateState())
        }
    }, [agencyDetailsState.update.loading])


    function handleOnChange(id, value) {
        if (id === "is_remit_address_same_agency") {
            const newAddr = value ? agencyDetails.street_address : "";
            const newCity = value ? agencyDetails.city : "";
            const newState = value ? agencyDetails.state : "";
            const newZipcode = value ? agencyDetails.zip_code : "";
            setAgencyDetails({
                ...agencyDetails,
                [id]: value,
                remit_street_address: newAddr,
                remit_city: newCity,
                remit_state: newState ?? "",
                remit_zip_code: newZipcode
            });
        }
        else if (id === "street_address" || id === "city" || id === "state" || id === "zip_code") {
            const updatedDetails = { ...agencyDetails, [id]: value };
            if (agencyDetails.is_remit_address_same_agency) {
                updatedDetails["remit_" + id] = value;
            }
            setAgencyDetails(updatedDetails);
        }
        else {
            setAgencyDetails({ ...agencyDetails, [id]: value });
        }

    }

    function handleUpdate() {
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            dispatch(updateAgencyDetails(agencyDetails!));
            setInputEnable(true);
            setCheckValidationOnSubmit(false);
        }
    }

    const handleDeleteAgencyLogo = () => {
        dispatch(updateAgencyDetails({
            ...agencyDetails,
            agency_logo_file_path: ""
        }))
    }

    function handleSearchChange(value) {
        setSearchValue(value);
    }

    const handleClearError = () => {
        dispatch(clearAgencyDetailsUpdateStateError())
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function getFilteredList() {
        let list;
        if (!agencyBranchDetailsState.branches)
            return [];
        if (sortedField != null) {
            list = [...agencyBranchDetailsState.branches].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? agencyBranchDetailsState.branches).filter(doc => {
            const str = searchValue.trim().toLowerCase();
            const branchFilter = doc.name.trim().toLowerCase().includes(str);
            const streetFilter = doc.street_address ? doc.street_address.trim().toLowerCase().includes(str) : false;
            const cityFilter = doc.city ? doc.city.trim().toLowerCase().includes(str) : false;
            const stateFilter = doc.country ? doc.country.trim().toLowerCase().includes(str) : false;
            const zipFilter = doc.zip_code ? doc.zip_code.trim().toLowerCase().includes(str) : false;
            return branchFilter || streetFilter || cityFilter || stateFilter || zipFilter;
        });
    }

    function retryFetchingData() {
        dispatch(getAgencyBranches());
    }

    function handleEditBranchDialog(branchData) {
        setBranchDetails({ ...branchDetails, ...branchData });
        setShowEditDialog(true);
    }


    const handleEdit = () => {
        setInputEnable(false);
    };

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            case "phone": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.usPhone.test(value.text) === false)
                    return t("validators.enterValidPhoneNumber");
                return null;
            }
            case "zip_code":
            case "remit_zip_code": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            case "fein": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.ein.test(value.text) === false)
                    return t("validators.enterValidEIN");
                return null;
            }
            case "street_address":
            case "remit_street_address": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "city":
            case "remit_city": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "country":
            case "remit_country": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "state":
            case "remit_state": {
                if (value.text === "") return t("validators.required");
                return null;
            }
            case "web_url": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }

    function updateProfileURL() {
        setShowImageUpload(false)
        setDisplaySuccessPopup(true)
        dispatch(getProfile())
        dispatch(getAgencyDetails());
    }

    function handleClosePopup() {
        setDisplaySuccessPopup(false)
    }

    const handleClearBranchError = () => {
        dispatch(agencyBranchDetailsActions.clearAgencyDetailsCreateState())
        dispatch(agencyBranchDetailsActions.clearAgencyDetailsUpdateState())
    }

    return (
        <div className="ac-as-container">
            <div className="ac-as-header">
                <div className="ac-sytm-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-as-content">
                <div className="ac-as-content-agency">
                    <div className='ac-as-header-container'>
                        <div className="ac-as-btn-header">
                            <span>Agency Details</span>
                            <button className="icon-btn ac-ob-edit-icon">
                                <EditIcon
                                    width={"100%"}
                                    height={"100%"}
                                    style={{ color: "#fff" }}
                                    onClick={handleEdit}
                                    className="hv-icon-btn"
                                />
                            </button>
                        </div>
                        <div className="form-row">
                            <div className="form-column">
                                <FormInput
                                    id={"name"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.name}
                                    label={t("agency_name")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column">
                                <FormInput
                                    id={"fein"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.fein}
                                    label={t("fein")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    maxLength={10}
                                    validations={formValidators}
                                    onKeyUp={formatToEIN}
                                    onKeyDown={enforceFormat}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}

                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-column">
                                <FormInput
                                    id={"street_address"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.street_address}
                                    label={t("address")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <FormInput
                                    id={"city"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.city}
                                    label={t("city")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <CustomFormSelect
                                    list={US_states.map((doc) => ({
                                        label: doc.name,
                                        value: doc.code,
                                    }))}
                                    customStyle={{ width: "-webkit-fill-available" }}
                                    name={"state"}
                                    value={agencyDetails.state}
                                    required={true}
                                    label={t("state")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('state', val)}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <FormInput
                                    id={"zip_code"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.zip_code}
                                    label={t("zip_code")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable}
                                    maxLength={5}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                        </div>
                        <div className='remit-div'>
                            <div className='remit-span'>{t("remit_address")}</div>
                            <div className="form-column switch-column">
                                <ToggleSwitch
                                    onChange={(value) => handleOnChange("is_remit_address_same_agency", value)}
                                    label={t("same_address")}
                                    checked={agencyDetails.is_remit_address_same_agency}
                                    labelPosition={'right'}
                                    disabled={inputEnable}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-column">
                                <FormInput
                                    id={"remit_street_address"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.remit_street_address}
                                    label={t("address")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable || agencyDetails.is_remit_address_same_agency}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <FormInput
                                    id={"remit_city"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.remit_city}
                                    label={t("city")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable || agencyDetails.is_remit_address_same_agency}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <CustomFormSelect
                                    list={US_states.map((doc) => ({
                                        label: doc.name,
                                        value: doc.code,
                                    }))}
                                    customStyle={{ width: "-webkit-fill-available" }}
                                    name={"remit_state"}
                                    value={agencyDetails.remit_state}
                                    required={true}
                                    label={t("state")}
                                    placeholder={"Select"}
                                    onChange={(val) => handleOnChange('remit_state', val)}
                                    disabled={inputEnable || agencyDetails.is_remit_address_same_agency}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column small">
                                <FormInput
                                    id={"remit_zip_code"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.remit_zip_code}
                                    label={t("zip_code")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable || agencyDetails.is_remit_address_same_agency}
                                    maxLength={5}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-column">
                                <FormInput
                                    id={"phone"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"tel"}
                                    value={agencyDetails.phone}
                                    label={t("phone")}
                                    placeholder=""
                                    required={true}
                                    validations={formValidators}
                                    customStyle={{ minWidth: "15vw" }}
                                    maxLength={16}
                                    onKeyUp={formatToPhone}
                                    onKeyDown={enforceFormat}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column">
                                <FormInput
                                    id={"fax"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.fax}
                                    label={t("fax")}
                                    placeholder=""
                                    required={false}
                                    customStyle={{ minWidth: "15vw" }}
                                    disabled={inputEnable}
                                />
                            </div>
                            <div className="form-column">
                                <FormInput
                                    id={"web_url"}
                                    onChange={(id, val) => handleOnChange(id, val)}
                                    type={"text"}
                                    value={agencyDetails.web_url}
                                    label={t("website")}
                                    placeholder=""
                                    required={true}
                                    customStyle={{ minWidth: "15vw" }}
                                    validations={formValidators}
                                    disabled={inputEnable}
                                    checkValidationOnSubmit={checkValidationOnSubmit}
                                />
                            </div>
                            <div className="form-column switch-column">
                                <ToggleSwitch
                                    onChange={(value) => handleOnChange('search_and_import_data', value)}
                                    label={t("search_and_import_data")}
                                    checked={agencyDetails?.search_and_import_data!}
                                    labelPosition={'top'}
                                    disabled={inputEnable}
                                />
                            </div>
                        </div>
                        <div className="actions-row">
                            <div>
                                {agencyDetailsState.update.error ?
                                    <span className='as-error-message'>{agencyDetailsState.update.error.message}</span>
                                    : <span className='as-success-message'>{message}</span>}
                            </div>

                            <div className='actions'>
                                <div className="btn-cancel">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("cancel")}
                                        enable
                                        backgroundColor={THEME.secondaryColor4}
                                        onClick={() => {
                                            setAgencyDetails(getInitialForm(agencyDetailsState?.agency!));
                                            setInputEnable(true);
                                            setCheckValidationOnSubmit(true)
                                        }}
                                    />
                                </div>
                                <div className="btn-save">
                                    <CustomButton
                                        textStyle={{ textTransform: 'capitalize' }}
                                        enable={true}
                                        loading={agencyDetailsState.update.loading === LoadingType.pending}
                                        name={t("update")}
                                        backgroundColor={THEME.buttonColor1}
                                        onClick={handleUpdate}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='agency-info-content'>
                    <div><UpdateAgencyLogo handleProfileUpdate={() => setShowImageUpload(true)} onDeleteAgencyLogo={() => setShowDeleteDialog({ visible: true , logoName: agencyLogoName })} agencyDetails={agencyDetails}/></div>
                    {showImageUpload &&
                        <Portal>
                            <UploadProfile
                                visible={showImageUpload}
                                onClose={() => setShowImageUpload(false)}
                                onDismiss={() => setShowImageUpload(false)}
                                onApprove={updateProfileURL}
                                objectId={""}
                                objectType={ProfilePictureType.agencyLogo}
                                agencyLogoDownloadUrl={true}
                            />
                        </Portal>
                    }
                    {showDeleteDialog && (
                        <DeleteConfirmationDialog
                            message={t("delete_agency_logo", {
                                logoName: showDeleteDialog?.logoName,
                            })}
                            onCancel={() => setShowDeleteDialog(null)}
                            onConfirm={handleDeleteAgencyLogo}
                            deleting={agencyDetailsState.update.loading === LoadingType.pending}
                            deleteType={t("agency_logo")}
                            state={agencyDetailsState?.update}
                            onClearError={handleClearError}
                        />
                    )}
                </div>
                <div className="ac-as-content-branch">
                    <div className="header">
                        <span>Other Branches</span>
                        <div>
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                enable={true}
                                leftIcon={getPlusBtnIcon()}
                                name={t("add_branch")}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => setShowCreateDialog(true)}
                            />
                        </div>
                    </div>
                    <div className='sb-s-container'>
                        <SearchBar value={searchValue} onChange={handleSearchChange} onSearch={() => { }} />
                    </div>
                    <div className='agency-table'>
                        {agencyBranchDetailsState.loading === LoadingType.pending && !agencyBranchDetailsState.branches ? <TableLoading /> :
                            <TableBody
                                state={agencyBranchDetailsState}
                                onRetry={retryFetchingData}
                                sortedField={sortedField}
                                handleSortFieldChange={handleSortFieldChange}
                                getFilteredList={getFilteredList}
                                handleEditBranchDialog={handleEditBranchDialog}
                                sortDirection={sortDirection}
                            />
                        }
                    </div>
                </div>
            </div>
            {showCreateDialog && <Portal>
                <BranchForm
                    visible={showCreateDialog}
                    agencyBDState={agencyBranchDetailsState}
                    errorState={agencyBranchDetailsState?.create?.error}
                    onClose={() => setShowCreateDialog(false)}
                    onDismiss={() => setShowCreateDialog(false)}
                    onClearError={handleClearBranchError}
                    formValidators={formValidators}
                />
            </Portal>}
            {showEditDialog && <Portal>
                <BranchForm
                    visible={showEditDialog}
                    data={branchDetails}
                    agencyBDState={agencyBranchDetailsState}
                    errorState={agencyBranchDetailsState?.update?.error}
                    onClose={() => setShowEditDialog(false)}
                    onDismiss={() => setShowEditDialog(false)}
                    onClearError={handleClearBranchError}
                    formValidators={formValidators}
                />
            </Portal>}
            {displaySuccessPopup && (
                <ActionDialogHolder ref={actionRef}
                visible={displaySuccessPopup}
                wrapperWidth={"40%"}
                onClose={handleClosePopup}
                onDissmiss={handleClosePopup}>
                    <DialogWrapper onClose={handleClosePopup}>
                        <div className="create-batch-container">
                                            <div className="create-batch-header">
                                                <FormDialogTilteHeader
                                                    title={"Upload Agency Logo"}
                                                    titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                                                />
                                            </div>
                                            <div className="create-batch-content">
                                                <div className="saved-doc-holder">
                                                    <DocSavedText>
                                                        <div>
                                                            <span className="aknw-name">
                                                                {`Hey ${agencyDetails.name},\nGreat job! Your profile picture has been uploaded successfully.`}
                                                           </span>
                                                        </div>
                                                    </DocSavedText>
                                                </div>
                                            </div>
                            <div className="create-batch-actions">
                                <div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleClosePopup}

                                    />
                                </div>
                            <div>
                        </div>
                    </div>
                </div>
                            </DialogWrapper>
                            </ActionDialogHolder>)}
        </div>
    )

    function _isFormValid() {
        const { name, fein, street_address, city, state, zip_code, remit_street_address, remit_city, remit_state, remit_zip_code, phone, web_url } = agencyDetails;
        if ([name, fein, street_address, city, state, zip_code, phone, web_url, remit_street_address, remit_city, remit_state, remit_zip_code].some(field => field === '')) {
            return false;
        }
        if (fein.length < 10 || phone.length < 13 || zip_code.length < 5 || remit_zip_code.length < 5) {
            return false;
        }
        return true;
    }
}

const UpdateAgencyLogo = (props) => {
    const { t } = useTranslation();
    const { handleProfileUpdate, placeholder, onDeleteAgencyLogo, agencyDetails } = props;
    return (
        <>
            <div className='agency-info-title'>
                <span>Agency Logo</span>
            </div>
            <div className='agenct-logo-body'>
                <div className="agency-logo-info" onClick={handleProfileUpdate}>
                    <div className="file-icon">
                        <FilesIcon width={"100%"} height={"100%"} style={{ color: THEME.defaultHighLightColor }} />
                    </div>
                    <div className="drag-info-txt">
                        <span>{placeholder ?? t('select_or_drag_a_file')}</span>
                    </div>
                </div>
                {(agencyDetails.agency_logo_file_path !== "" && agencyDetails.agency_logo_file_path !== null) && <div className='agency-logo-delete'><DeleteIcon width={"1vw"} height={"1vw"} onClick={onDeleteAgencyLogo}/></div>}
            </div>
        </>
    );
}

const TableBody = ({ state, onRetry, sortedField, handleSortFieldChange, getFilteredList,
    handleEditBranchDialog, sortDirection }) => {
    if (state.loading === LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (state.branches && state.branches.length === 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable
                headerList={headers}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={"branch_list" + index} onClick={() => handleEditBranchDialog(doc)}>
                            {/* <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData> */}
                            <TableData customStyle={{ flex: getFlexNumber(0), wordWrap: "break-word" }}><span>{doc['name']}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1), wordWrap: "break-word" }}><span>{doc['street_address']}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2), wordWrap: "break-word" }}><span>{doc['city']}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3), wordWrap: "break-word" }}><span>{doc['country']}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4), wordWrap: "break-word" }}><span>{doc['zip_code']}</span></TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

function getFlexNumber(value: number) {
  
    return 1;
}

export default AgencySetupPage;