import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import ResumeHolder from "../../../../../components/resume_holder/resume_holder";
import SocialMediaProfile from "../../../../../components/social_media_profile/social_media_profile";
import SSNHolder from "../../../../../components/ssn_holder/ssn_holder";
import StatusBanner from "../../../../../components/status_banner/status_banner";
import { LoadingType, ProfilePictureType, TALENT_STATUS, THEME } from "../../../../../enums";
import { AssignIcon, CallIcon, EmailIcon, GlobeIcon, LocationIcon, PayrateIcon, SectorIcon } from "../../../../../icons";
import { ITalent, IWorkCompClaimData } from "../../../../../interfaces";
import { useAppDispatch, useAppSelector, getPreSignedURLForDownload, selectSingleProfilePictureStateById } from "../../../../../redux/store";
import { addressParser, currencyConversion, getArrayStrings } from "../../../../../utils";
import { getDaysAgoWithAMPM } from "../../../../../variables";
import './unemploymentclaim_details.scss';
import { TableData } from "../../../../../components/sortable_table/sortable_table";
import { AppRoutes } from "../../../../../routes";
import UserAvatar from "../../../../../components/user_avatar/user_avatar";
import UploadProfile from "../../../../../components/image_crop/image_crop_popup";
interface IUnEmploymentClaimDetails {
    talentData: ITalent,
    onSSNClick: () => void,
    history?: any,
    onResumeClick: (path:string) => void
}

const UnEmploymentClaimDetailsPage = (props: IUnEmploymentClaimDetails) => {
    const { t } = useTranslation();
    const { talentData } = props;
    const talentProfileSections = [
        {
            sectionTitle: 'sector',
            sectionIcon: SectorIcon,
            direction: 'row',
            sectionContent: [
                { title: 'sector', valueField: 'business_sector_value' },
                { title: 'job_title', valueField: 'title' },
            ]
        },
        {
            sectionTitle: 'payrate',
            sectionIcon: PayrateIcon,
            direction: 'row',
            sectionContent: [
                { title: 'payrate', valueField: 'pay_rate' },
                { title: 'resume', valueField: getResumeIcon() }, ///resume_path
            ]
        },
        {
            sectionTitle: 'geo_code',
            sectionIcon: GlobeIcon,
            direction: 'row',
            sectionContent: [
                { title: 'geo_code', valueField: 'geo_code' },
                { title: 'ss_number', valueField: getSSNLockIcon() },
            ]
        },
        {
            sectionTitle: 'address',
            sectionIcon: LocationIcon,
            direction: 'column',
            sectionContent: [
                {
                    title: '', valueField: addressParser({
                        'street_address': props.talentData ? props.talentData['street_address'] : null,
                        'city': props.talentData ? props.talentData['city'] : null,
                        'state': props.talentData ? props.talentData['state'] : null,
                        'zip_code': props.talentData ? props.talentData['zip_code'] : null,
                    })
                },
            ]
        },

        {
            sectionTitle: 'contact',
            sectionIcon: CallIcon,
            direction: 'column',
            sectionContent: [
                { title: '', valueField: 'phone' },
            ]
        },
        {
            sectionTitle: 'email',
            sectionIcon: EmailIcon,
            direction: 'column',
            sectionContent: [
                { title: '', valueField: 'email' },
            ]
        },
    ];

    function talentStatus(status: string) {
        if (status === TALENT_STATUS.active) return (<span>{`${t('status')}: `} <span style={{ color: THEME.buttonColor17 }}>{t('active')}</span> </span>);
        else if (status === TALENT_STATUS.inactive) return (<span>{`${t('status')}: `}<span style={{ color: "#b1b1b1" }}>{t('in_active')}</span></span>);
        else return (<span>{`${t('status')}: `}<span>{''}</span> </span>);
    }


    // function talentActivity() {
    //     const lastEngagementDate = props.talentData?.last_engagement_date ? getDaysAgoWithAMPM(t, props.talentData?.last_engagement_date) : null;
    //     return (
    //         <div className="tds-activity">
    //             {lastEngagementDate && <div className="last-engagement-txt">
    //                 <span>{t('last_engagement', { value: lastEngagementDate })}</span>
    //             </div>}
    //             {props.talentData?.currently_on_assignment && <div className="assignment-status">
    //                 <div className="icon">
    //                     <AssignIcon width={'100%'} height={'100%'} style={{ color: THEME.statusActiveColor }} />
    //                 </div>
    //                 <div className="status">
    //                     <span style={{ color: THEME.statusActiveColor }} >{t('currently_on_assignment')}</span>
    //                 </div>

    //             </div>}
    //         </div>
    //     )
    // }
    function getValues(obj: any) {
        if (props.talentData == null) return (<span>{''}</span>);

        if (Array.isArray(obj)) {
            return getArrayStrings(obj, props.talentData);
        }
        if (typeof obj === "object") {
            return obj;
        }
        const value = (obj !== '' && props.talentData![obj] !== null) ? props.talentData![obj] : '';
        if (obj === "pay_rate") {
            return (
                <span>{currencyConversion(value)}</span>
            );
        }
        if (obj === "business_sector_value") {
            return (
                (talentData.business_sector_value !== null && talentData.business_sector_value !== "") ? talentData.business_sector_value : <span className="na-span">NA</span>
            );
        }
        if (obj === "title") {
            return (
                (talentData.title !== null && talentData.title !== "") ? <span>{talentData.title}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "geo_code") {
            return (
                (talentData.geo_code !== null && talentData.geo_code !== "") ? <span>{talentData.geo_code}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "phone") {
            return (
                (talentData.phone !== null && talentData.phone !== "") ? <span>{talentData.phone}</span> : <span className="na-span">NA</span>
            );
        }
        if (obj === "email") {
            return (
                (talentData.email !== null && talentData.email !== "") ? <span>{talentData.email}</span> : <span className="na-span">NA</span>
            );
        }
        if (typeof obj === "object") {
            return obj;
        }

        return (
            <span>{value}</span>
        );
    }

    function getSSNLockIcon() {
        if(props.talentData?.last_4_digits_of_ssn == null || props.talentData?.last_4_digits_of_ssn == "") return <span className="na-span">NA</span>
        return (
            <SSNHolder
                value={props.talentData?.ssn}
                iconColor={THEME.defaultHighLightColor}
                onClick={props.onSSNClick}
                showFormat={true}
            />
        )
    }

    function handleUserSelect(value: ITalent) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name:
                    `${value?.first_name} ${value?.middle_name} ${value?.last_name}`,
            },
        });
        return;
    }

    function getResumeIcon() {
        if(props.talentData?.resume_file_name == null || props.talentData?.resume_file_name == "") return <span className="na-span">NA</span>
        if(props.talentData && props.talentData.resume_file_name){
            return (
                <ResumeHolder
                    path={props.talentData?.resume_file_name}
                    onClick={()=> props.talentData.resume_path != null && props.talentData.resume_path != "" ? props.onResumeClick(props.talentData.resume_path): {}}
                />
            )
        }
        return '';
    }

    return (
        <div className="un-container">
            <TalentInfo
                name={
                    <TableData isButton={true} onClick={() => handleUserSelect(props.talentData)}>{`${props.talentData?.first_name} ${props.talentData?.middle_name} ${props.talentData?.last_name}`}</TableData>
                }
                uri={props.talentData?.contact_photo}
                objectId={props.talentData?.id}
                rating={props.talentData?.engagement_rating}
                level={props.talentData?.engagement_level}
            />
            {/* {talentActivity()} */}
            <div className="un-branch-selector">
                {/* <CustomFormSelect
                    name={'department'}
                    value={branchId}
                    required={false}
                    placeholder={t('select_branch')}
                    list={departmentsState.data != null ? departmentsState.data.map((doc, index) => ({ label: doc.job_title, value: doc.id })) : []}
                    onChange={onBranchChange}
                /> */}
                {/* <div className="un-status">
                    {talentStatus(props.talentData?.status ?? '')}
                </div> */}
                <div className="un-branch-selector">
                    <div className="un-status">
                        <span>
                            {`${t("status")}:`}
                            <span className="status-txt">
                                {props.talentData?.status ?? ''}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            {talentProfileSections.map((doc, index) => {
                return (
                    <div key={index} className={doc.sectionTitle === "contact" || doc.sectionTitle === "email" ? "un-info-address-container" : "un-info-container"}>
                        <div className="un-info-icon">
                            <doc.sectionIcon width={"100%"} height={"100%"} />
                        </div>
                        <div
                            className="un-info-content"
                            style={{
                                ...(doc.direction === 'row') && { flexDirection: 'row', justifyContent: 'space-between' },
                            }}
                        >
                            {doc.sectionContent.map((data, index) => {
                                const arrayValue = getValues(data.valueField);

                                return (
                                    <div
                                        key={index}
                                        className="un-info-holder"
                                        style={{
                                            ...(doc.direction === 'row') && { flex: 1, width: "50%"},
                                            ...(index !== 0) && { marginLeft: '1em' }
                                        }
                                        }>
                                        <div className="un-into-title">
                                            {
                                                doc.sectionTitle === "address" || doc.sectionTitle === "contact" || doc.sectionTitle === "email" ?
                                                    <span style={{ display: "grid" }}>{t(data.title).toUpperCase()}</span> :
                                                    <span>{t(data.title).toUpperCase()}</span>
                                            }
                                        </div>
                                        <div className="un-info-value"
                                            style={{ marginBottom: '0.5em' }}
                                        >
                                            {
                                                doc.sectionTitle === 'email' ?
                                                    <a href={`mailto:${props.talentData?.email}`}><td><span className='email-link'>{arrayValue}</span></td></a>
                                                    : <td><span>{arrayValue}</span></td>
                                            }
                                        </div>
                                        {/* {data.lineTwo && <div className="un-info-value">{getValues(data.lineTwo)}</div>} */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <SocialMediaProfile
                title={t('social_media').toUpperCase()}
                profile={props.talentData?.social_media_links!}
            />
            {props.talentData?.is_available && <div className="available-status">
                <StatusBanner title={t('available')} />
            </div>}
        </div>
    )
}

const TalentInfo = (props) => {
    const { name, uri, rating, level } = props;
    const dispatch = useAppDispatch();
    const [sentRequest, setSentRequest] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [showImageUpload, setShowImageUpload] = useState(false);

    const profilePictureState = useAppSelector((state) => selectSingleProfilePictureStateById(ProfilePictureType.talent + '-' + props.objectId)(state));

    useEffect(() => {
        setSentRequest(true);
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
    }, [])

    function updateProfile() {
        dispatch(getPreSignedURLForDownload({ object_id: props.objectId, object_type: ProfilePictureType.talent }));
        setSentRequest(true);
    }

    useEffect(() => {
        if (sentRequest && profilePictureState.download.loading === LoadingType.succeeded) {
            setSentRequest(false);
            setImageURL(profilePictureState.download.response ?? '');
        }
    }, [profilePictureState.download.loading])

    return (
        <div className="un-user-info">
            {/* <div className="un-avatar-holder">
                <div className="un-avatar background-img" style={imageURL ? { backgroundImage: `url(${imageURL})` } : {}}></div>
            </div> */}
              <UserAvatar
                onClick={() => (setShowImageUpload(true))}
                url={imageURL}
                rating={{ value: rating, level: level }}
            />
            <div className="un-user-name">
                <span>{name}</span>
            </div>
            {showImageUpload && <Portal>
                <UploadProfile
                    onClose={() => setShowImageUpload(false)}
                    onDismiss={() => setShowImageUpload(false)}
                    visible={showImageUpload}
                    onApprove={updateProfile}
                    objectId={props.objectId}
                    objectType={ProfilePictureType.talent}
                />
            </Portal>}
        </div>
    );
}

export default UnEmploymentClaimDetailsPage;