
import { Assignment, IAssignmentApiQuery, IAssignmentsByPagePayload, ICreateAssignment, ICreateAssignmentStaff, IDeleteAssignmentStaff } from "../interfaces";
import { } from "../interfaces/talent";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const AssignmentsAPI = {

    getAllAssignments(query?: IAssignmentApiQuery) {
        if (query) {
            let queryString = '';
            for (let param of Object.keys(query))
                if (query[param])
                    queryString += `&${param}=${query[param]}`;
            queryString = queryString.slice(1,);
            return interceptor.get(
                `${UrlBuilder.allAssignments}?${queryString}`
            );
        }
        return interceptor.get(UrlBuilder.allAssignments);
    },

    getAllAssignmentsByPage(query: IAssignmentApiQuery) {
        let url = `${UrlBuilder.allAssignmentsByPage}?page=${query.page}&size=${query.size}`;
        if (query.company_id) {
            url += `&company_id=${query.company_id}`;
        }
        if (query.contact_id) {
            url += `&contact_id=${query.contact_id}`;
        }
        if (query.department_id) {
            url += `&department_id=${query.department_id}`;
        }
        if (query.talent_id) {
            url += `&talent_id=${query.talent_id}`;
        }
        if (query.job_order_id) {
            url += `&job_order_id=${query.job_order_id}`;
        }
        if (query.include_inactive) {
            url += `&include_inactive=${query.include_inactive}`;
        }
        if (query.search_str) {
            url += `&search_str=${query.search_str}`;
        }
        if (query.filter_ids) return interceptor.post(url, query.filter_ids);
        return interceptor.post(url);
    },       

    getAssignmentsByPage(doc: IAssignmentsByPagePayload) {
        let url = `${UrlBuilder.allAssignmentsByPage}?page=${doc.page}&size=${doc.size}`;
        if (doc.search_str) {
            url += `&search_str=${doc.search_str}`;
        }
        if (doc.include_inactive) {
            url += `&include_inactive=${doc.include_inactive}`;
        }
        if (doc.filter_ids) return interceptor.post(url, doc.filter_ids);
        return interceptor.post(url);
    },

    getAssignmentById(assignment_id: string) {
        return interceptor.get(`${UrlBuilder.assignments}?assignment_id=${assignment_id}`);
    },
    getAssignmentDropdown(query?: {company_id?: string, department_id?: string, talent_id?: string},){
        if (query && query.talent_id){
            return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?talent_id=${query.talent_id}`);
        }
        if(query && query.company_id){
            if(query.department_id) return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?company_id=${query.company_id}&department_id=${query.department_id}`);
            return interceptor.get(`${UrlBuilder.getAssignmentDropdown}?company_id=${query.company_id}`);
        }
        return interceptor.get(`${UrlBuilder.getAssignmentDropdown}`);
    },

    createAssignment(doc: { forceAdd: boolean, value: Assignment }) {
        if (doc.forceAdd) return interceptor.post(`${UrlBuilder.assignments}?force_add=${doc.forceAdd}`, doc.value);
        return interceptor.post(UrlBuilder.assignments, doc.value);
    },

    deleteAssignment(id: string) {
        return interceptor.delete(`${UrlBuilder.assignments}?assignment_id=${id}`);
    },

    updateAssignment(doc: Assignment) {
        return interceptor.patch(UrlBuilder.assignments, doc);
    },

    /// [stafff]
    getAssignmentStaffs(doc: { assignment_id: string, role: string }) {
        return interceptor.get(`${UrlBuilder.assignmentStaffs}?assignment_id=${doc.assignment_id}&role=${doc.role}`);
    },

    postAssignmentStaff(doc: ICreateAssignmentStaff) {
        return interceptor.post(UrlBuilder.assignmentStaffs, doc);
    },

    deleteAssignmentStaff(doc: IDeleteAssignmentStaff) {
        return interceptor.delete(UrlBuilder.assignmentStaffs, { data: doc });
    },
}