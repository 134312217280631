import { useTranslation } from "react-i18next";
import SocialMediaProfile from "../../../components/social_media_profile/social_media_profile";
import "../../../components/user_avatar/user_avatar.scss";
import { CallIcon, EmailIcon } from "../../../icons";
import { IOnboardingTeamMemberDetail } from "../../../interfaces";
import { useAppDispatch } from "../../../redux/store";
import { getArrayStrings } from "../../../utils";
import "./onboarding_team.scss";

interface Props {
  teamData: IOnboardingTeamMemberDetail | undefined;
  talentName: string | undefined;
  agencyName?: string; 
}
// interface Props extends RouteComponentProps<any> { }
const OnboardingTeam: React.FunctionComponent<Props> = (props) => {
  // const { history, location, match } = routeProps;
  const { teamData, talentName, agencyName } = props;

  // const { params } = match;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const teamProfile = [
    {
      sectionTitle: "contact",
      sectionIcon: CallIcon,
      sectionContent: [{ title: "contact", valueField: "mobile_no" }],
    },
    {
      sectionTitle: "email",
      sectionIcon: EmailIcon,
      direction: "column",
      sectionContent: [{ title: "email", valueField: "email" }],
    },
  ];
  return (
    <>
      <div className="team-title-holder">
        <span>
          {t("hi_name_meet_your_company_team", {
            name: `${talentName}`,
            company: agencyName,
          })}
        </span>
      </div>
      <div className="team-details-holder">
      <div className="user-avatar">
        <div className="avatar-holder">
          <div
            className="avatar background-img"
            style={
              teamData?.profile_picture_url
                ? { backgroundImage: `url(${teamData.profile_picture_url})` }
                : {}
            }></div>
        </div>
        <div className="ob-team-user-name">
          <span>
            {teamData?.first_name ?? ""} {teamData?.last_name ?? ""}
          </span>
        </div>
      </div>

      {teamProfile.map((doc, index) => {
        return (
          <div key={index} className="ob-team-info-container">
            <div className="ob-team-info-icon">
              <doc.sectionIcon width={"100%"} height={"100%"} />
            </div>
            <div className="ob-team-info-content">
              {doc.sectionContent.map((data, index) => {
                const arrayValue = getValues(data.valueField);
  
                return (
                  <div key={index} className="ob-team-info-holder">
                    <div className="ob-team-into-title">
                      <span>{t(data.title).toUpperCase()}</span>
                    </div>
                    <div
                      className="ob-team-info-value"
                      style={{ marginBottom: "0.5em" }}>
                      {arrayValue}
                    </div>
                    {/* {data.lineTwo && <div className="ob-team-info-value">{getValues(data.lineTwo)}</div>} */}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
        <SocialMediaProfile
          title={t("social_media").toUpperCase()}
          profile={teamData?.social_media_links!}
        />
      </div>
    </>
  );
  function getValues(obj: any) {
    if (teamData == null) return <span>{""}</span>;

    if (Array.isArray(obj)) {
      return getArrayStrings(obj, teamData);
    }
    if (typeof obj === "object") {
      return obj;
    }
    const value = obj !== "" && teamData![obj] !== null ? teamData![obj] : "";
    return <span>{value}</span>;
  }
};

export default OnboardingTeam;
