import { useTranslation } from "react-i18next";
import { postSnackbarMessage, selectSendEmailDataState, sendEmailThunk, useAppDispatch, useAppSelector } from "../../../redux/store";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../components/action_dialog_holder/action_dialog_holder";
import { useEffect, useRef, useState } from "react";
import DialogWrapper, { FormDialogTilteHeader } from "../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput } from "../../../utils";
import { ISendEmail } from "../../../interfaces/send_email";
import { EmailIcon } from "../../../icons";
import "./send_email_popup.scss"
import { LoadingType, THEME } from "../../../enums";
import ApiError from "../../../components/api_error";


interface Props {
    visible: boolean;
    data: { obj_id: string, email: string, email_2?: string };
    objectType: string;
    onClose: () => void;
    onClearError: () => void;
}

const SendEmailPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        onClose,
        onClearError,
        objectType,
        data
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sendEmailState = useAppSelector((state) => selectSendEmailDataState(state));
    const state = sendEmailState?.sendEmail
    const actionRef = useRef<ActionDialogHolderType>(null);
    useEffect(() => {
        if (state && state.loading === LoadingType.succeeded && state.response) {
            dispatch(postSnackbarMessage(state.response));
            onClearError()
            onClose()
        }
    }, [state.loading, state.response])
    const [sendEmail, setSendEmail] = useState<ISendEmail>({
        obj_type: objectType ?? "",
        subject: "",
        message: "",
        id_emails: [
            {
                obj_id: data.obj_id ?? "",
                email: (data.email_2 ? data.email_2 : data.email) ?? ""
            }
        ]
    });

    const handleFieldChange = (fieldId, value) => {
        setSendEmail({ ...sendEmail, [fieldId]: value });
    }

    const handleSubmit = () => {
        dispatch(sendEmailThunk(sendEmail))
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50vw"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={() => {
                onClose();
                onClearError();
            }}>
                <div className="send-email-container">
                    <div className="send-email-header">
                        <FormDialogTilteHeader
                            title={t("send_email")}
                            titleIcon={<EmailIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="send-email-content">
                        <div className="send-email-inout-field">
                            <FormInput
                                id={"subject"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={sendEmail?.subject}
                                label={t("subject")}
                                placeholder=""
                                required
                            />
                        </div>
                        <div className="send-email-inout-field">
                            <FormInput
                                id={"message"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={sendEmail?.message}
                                label={t("message")}
                                placeholder=""
                                multiline
                                customInputStyle={{ minHeight: "30vh", maxHeight: "50vh" }}
                                numberOfLines={20}
                            />
                        </div>
                    </div>
                    <div className="send-email-actions">
                        {state && state.error && <div className="error-holder">
                            <ApiError message={state.error.message} onClose={props.onClearError} />
                        </div>}
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.toggleDisableColor}
                                onClick={() => {
                                    onClose();
                                    onClearError();
                                }}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                loading={state?.loading === LoadingType.pending}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("send")}
                                enable={(sendEmail.subject !=="") && true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder >
    );
}

export default SendEmailPopup;