import { ArrowForward } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../enums";
import { TickBlueIcon } from "../../../../icons";
import { OnlineApplicationSent } from "../../../../interfaces/online_application_sent";
import {
  selectOnlineApplicationsSentEntities,
  selectOnlineApplicationsSentList,
  selectOnlineApplicationsSentListState,
} from "../../../../redux/dashboard/metrics/online_applications_sent_list/online_applications_sent_list_selector";
import { getOnlineApplicationsSentList } from "../../../../redux/dashboard/metrics/online_applications_sent_list/online_applications_sent_list_thunk";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { CustomButton, getContactIcon } from "../../../../utils";
import {
  formatAMPM,
  getDateFromTimeStamp,
  getDateString,
} from "../../../../variables";
import "./universal_directory.scss";
import SearchBar from "../../../../components/search_bar";

interface Props extends RouteComponentProps<any> {
  isArchiveDirectory: boolean;
}

export const OnlineApplicationsSentUniversalDirectory: React.FunctionComponent<
  Props
> = (props) => {
  const { t } = useTranslation();
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();

  const applications = useAppSelector((state) =>
    selectOnlineApplicationsSentList(state)
  );

  useEffect(() => {
    setSearch("");
  }, [props.isArchiveDirectory]);

  const tableHeader = [
    { title: "name", code: "name" },
    { title: "email", code: "email" },
    { title: "phone_number", code: "phone_number" },
    { title: "Date/Time", code: "token_date_timestamp" },
    { title: "clicked_on_link", code: "clicked_on_link" },
    { title: "type_of_follow_up", code: "clicked_on_link" },
    { title: "next_action_date_time", code: "next_action_date_time" },
    { title: "followup_goal", code: "followup_goal" },
    ...(props.isArchiveDirectory
      ? []
      : [{ title: "plan_next_action", code: "plan_next_action" }]),
  ];

  const getApps = () => dispatch(getOnlineApplicationsSentList());
  const { loading, error } = useAppSelector((state) =>
    selectOnlineApplicationsSentListState(state)
  );

  const getFilteredList = () => {
    let list: OnlineApplicationSent[] = [
      ...applications.filter((i) => i.is_archived === props.isArchiveDirectory),
    ];
    if (sortedField != null) {
      list.sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    // return list;
    return list.filter((doc) => {
      const str = search.trim().toLowerCase();
      const nameFilter = doc.name
        ? doc.name.trim().toLowerCase().includes(str)
        : false;
      const email = doc.email
        ? doc.email.trim().toLowerCase().includes(str)
        : false;
      const phoneNumber = doc.phone_number
        ? doc.phone_number
            .trim()
            .toLowerCase()
            .replace(/[- ()]/g, "")
            .includes(str)
        : false;
      return nameFilter || email || phoneNumber;
    });
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  const handleApplicationSelect = (value: OnlineApplicationSent): void => {
    //dispatch(getOnlineApplicationReceivedDetails(value.id));
    props.history.push({
      pathname: `${AppRoutes.onlineApplicationsSent}/details/${value.id}`,
      state: {
        id: value.id,
        name: value.name,
      },
    });
  };

  useEffect(() => {
    getApps();
  }, []);

  useEffect(() => {
    if (error != null && applications.length != 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);

  const getTable = () => {
    if (loading === LoadingType.pending) return <TableLoading />;
    if (error != null && applications.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getApps} />;
    }
    if (applications.length === 0 || getFilteredList().length === 0) {
      return (
        <TableEmpty title={t("No Applications found")} onClick={getApps} />
      );
    } /*
    if (getFilteredList().length === 0) {
      return <TableFilterEmpty title={t("No Applications found")} />;
    }*/
    if (getFilteredList().length !== 0) {
      return (
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}>
          {getFilteredList().map((doc) => {
            return (
              <tr key={doc.id}>
                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                  <span>{doc.name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(1) }}>
                  {doc.email}
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  onClick={() => handleApplicationSelect(doc)}>
                  <span>{doc.phone_number}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                  <span>
                    {getDateString(doc.token_date_timestamp) +
                      " " +
                      formatAMPM(
                        getDateFromTimeStamp(doc.token_date_timestamp)
                      )}
                  </span>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(4),
                    justifyContent: "center",
                  }}>
                  <div>
                    {doc.clicked_on_link ? (
                      <TickBlueIcon
                        fill="#00A4DC"
                        width=" 1.0895833333333334vw"
                        height=" 1.0895833333333334vw"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </TableData>
                <TableData
                  customStyle={{
                    flex: getFlexNumber(5),
                    justifyContent: "center",
                  }}>
                  <span>
                    {getContactIcon(
                      doc.follow_up.length
                        ? doc.follow_up[0].type_of_follow_up
                        : ""
                    )}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  {doc.follow_up.length > 0 &&
                  doc.follow_up[0].next_action_date_time ? (
                    <span>
                      {getDateString(doc.follow_up[0].next_action_date_time) +
                        " " +
                        formatAMPM(
                          getDateFromTimeStamp(
                            doc.follow_up[0].next_action_date_time
                          )
                        )}
                    </span>
                  ) : (
                    <span>NA</span>
                  )}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  {doc.follow_up.length > 0 ? (
                    <span>{doc.follow_up[0].followup_goal}</span>
                  ) : (
                    <span>NA</span>
                  )}
                </TableData>
                {!props.isArchiveDirectory && (
                  <TableData customStyle={{ flex: getFlexNumber(8) }}>
                    <CustomButton
                      loading={false}
                      textStyle={{ textTransform: "capitalize" }}
                      name={t("plan_next_step")}
                      backgroundColor={"#00BE4B"}
                      onClick={() => handleApplicationSelect(doc)}
                      customStyle={{ marginRight: "10px" }}
                      leftIcon={<ArrowForward htmlColor="white" />}
                      enable
                    />
                  </TableData>
                )}
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function getFlexNumber(value: number) {
    if (value === 1) return 2;
    if (value === 8) return 2;
    return 1;
  }

  return (
    <div className="cps-ud-container contacts-ud applications-sent-ud">
      <div className="apps-ud-actions">
        <div className="total-count">
          <span>
            {`${t("total_count")}: `}
            <b>{getFilteredList().length}</b>
          </span>
        </div>
        <div className="apps-ud-search">
          <SearchBar
            value={search}
            onChange={(value) => setSearch(value)}
            onSearch={() => {}}
          />
        </div>
      </div>
      <div className="cps-ud-table">{getTable()}</div>
    </div>
  );
};
