import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomFormSelect, {
  CustomMultiFormSelect,
} from "../../../../../components/form_selector/form_select";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { IContactSalesProfile, IContactUpdateSalesProfile } from "../../../../../entities/ContactInfo";
import { LoadingType, THEME } from "../../../../../enums";
import {
  getBusinessSectors,
  selectBusinessSectorList,
} from "../../../../../redux/admin_center";
import { contactProfileSlice } from "../../../../../redux/contacts/contact_profile/contact_profile_reducer";
import {
  selectContactSalesProfile,
  selectContactSave_state,
} from "../../../../../redux/contacts/contact_profile/contact_profile_selector";
import {
  getContactSalesProfileThunk,
  saveContactSalesProfileThunk,
} from "../../../../../redux/contacts/contact_profile/contact_profile_thunk";
import {
  getCompetitors,
  getContactById,
  postSnackbarMessage,
  selectCompaniesListState,
  selectCompetitorsData,
  selectContactDetailsByIdState,
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import { AppRoutes } from "../../../../../routes";
import {
  CustomButton,
  FormInput,
  mapOptions,
  SpinnerScreen,
  formatToMoney,
  convertNumberToMoney,
  getPlusBtnIcon,
  getContactIcon,
  getUserNameFromProfileDropdown,
} from "../../../../../utils";
import "./contact_sales_profile.scss";
import { CalenderIcon, CompletedCallIcon, CompletedCallWhiteIcon, EditIcon, ScheduledCallIcon, ScheduledCallWhiteIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import DatePicker from "react-datepicker";
import SearchBar from "../../../../../components/search_bar";
import { convertDateToTimeStamp, getDateString } from "../../../../../variables";
import { SalesCall } from "../../../../../interfaces/sales_call";
import { getContactSalesCallsList } from "../../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_thunk";
import { selectSalesCallList } from "../../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_selector";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";

export interface ContactSalesProfileProps extends RouteComponentProps<any> { }

export const ContactSalesProfile: FC<ContactSalesProfileProps> = (
  props: ContactSalesProfileProps
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { history, location, match } = props;
  const { params } = match;

  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [salesDateRange, setSalesDateRange] = useState([thirtyDaysAgo, today]);
  const [salesStartDate, salesEndDate] = salesDateRange;
  const [sortedField, setSortedField] = useState<string | null>("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [salesFormState, setSalesFormState] = useState<{ available_from: number, available_to: number }>({
    available_from: 0,
    available_to: 0,
  })
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isHovering, setIsHovering] = useState<{
    visible: boolean;
    id: string;
    column :string;
  } | null>(null);

  const salescalls: SalesCall[] = useAppSelector((state) =>
    selectSalesCallList(state)
  );

  const tableHeader = [
    { title: "complete_call", code: "complete_call" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "", code: "" },
  ];

  const completedCallsTableheader = [
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "Date of Completed Sales Call", code: "call_date_time" },
    { title: "Call Results", code: "call_result" },
    { title: "", code: "" },
  ];

  const hoveringStateColumn = {
    callGoal: "call_goal",
    callResult: "call_result",
  };


  const { loading, error, salesProfile } = useAppSelector((state) =>
    selectContactSalesProfile(state)
  );
  const contactDetails = useAppSelector((state) =>
    selectContactDetailsByIdState(params.id)(state)
  );

  const [profile, setProfile] = useState({} as IContactUpdateSalesProfile);
  const businessSectors = useAppSelector((state) =>
    selectBusinessSectorList(state)
  );
  const saveResponse = useAppSelector((state) =>
    selectContactSave_state(state)
  );

  const profileState = useAppSelector((state) => selectProfileState(state));

  const competitorState = useAppSelector((state) =>
    selectCompaniesListState(state)
  );

  const competitorsData = useAppSelector(
    (state) => selectCompetitorsData(state).data
  );
  const competitors = mapOptions(competitorsData, "competitor_name", "id");

  const [competitorList, setCompetitorList] =
    useState<{ label: string; value: string }[]>(competitors);

  const getcontactsalescalls = () => dispatch(getContactSalesCallsList({ contact_id: params.id, upcoming_calls: !showCompleted }));

  useEffect(() => {
    if (
      params &&
      params.id === salesProfile.contact_id &&
      salesProfile &&
      salesProfile.contact_id
    ) {
      updateForm(salesProfile);
    }
  }, [salesProfile]);

  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0) || ((salesFormState.available_from === 0 && salesFormState.available_to === 0))) {
      getcontactsalescalls();
    }
  }, [showCompleted, salesFormState.available_from, salesFormState.available_to]);

  useEffect(() => {
    if ((salesStartDate === null && salesEndDate === null) || (salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
      setSalesDateRange([thirtyDaysAgo, today]);
      setSalesFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo), available_to: convertDateToTimeStamp(today) })
    }
  }, [showCompleted]);


  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0)) {
      setDatePickerOpen(false)
    }
    if ((salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
      openDatePicker();
    }
  }, [salesFormState.available_from, salesFormState.available_to])

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  useEffect(() => {
    let comps = mapOptions(competitorsData, "competitor_name", "id");
    if (profile && profile.staffing_agencies) {
      comps = comps.filter(
        (item) =>
          profile.staffing_agencies.findIndex(
            (i) => i.agency_name === item.label
          ) === -1
      );
    }
    setCompetitorList(comps);
  }, [competitorsData]);

  useEffect(() => {
    getcontactsalescalls();
    dispatch(getBusinessSectors());
    dispatch(getCompetitors());

    if (params && params.id) {
      dispatch(getContactSalesProfileThunk(params.id));
      dispatch(getContactById({ contact_id: params.id }));
    }
  }, []);

  useEffect(() => {
    if (saveResponse.loading === LoadingType.succeeded) {
      dispatch(postSnackbarMessage(saveResponse.response ?? null));
      dispatch(contactProfileSlice.actions.clearSaveState());
    }
  }, [saveResponse.loading]);

  const handleCompetitorRemove = (value: string) => {
    const agencies = profile.staffing_agencies.filter(
      (ag) => ag.agency_name !== value
    );
    setProfile({ ...profile, staffing_agencies: agencies });
    const newCompetitorList = competitors.filter(
      (cl) => agencies.findIndex((ag) => ag.agency_name === cl.label) < 0
    );
    setCompetitorList(newCompetitorList);
  };

  const handleCompetitorAdd = (value: string) => {
    setCompetitorList([
      ...competitorList.filter((item) => item.value !== value),
    ]);
    const agencies = [...profile.staffing_agencies];
    const agency = competitors.find((i) => i.value === value)?.label ?? "";
    agencies.push({ agency_name: agency });
    setProfile({ ...profile, staffing_agencies: agencies });
  };

  function handleChange(value) {

    setSalesFormState({
      ...salesFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0]) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1].getTime() + (1 * 24 * 60 * 60 * 1000))) : 0,
    });
    setSalesDateRange(value);

  }

  const handleSave = () => {
    const payload: IContactSalesProfile = {
      ...profile, contact_id: params.id,
      company_annual_revenue: convertNumberToMoney(profile.company_annual_revenue, 'number') as number,
      number_of_full_time_staff: convertNumberToMoney(profile.number_of_full_time_staff, 'number') as number
    }
    dispatch(saveContactSalesProfileThunk(payload));
  };

  function updateForm(value: IContactSalesProfile) {
    setProfile({
      ...value,
      company_annual_revenue: value.company_annual_revenue ? convertNumberToMoney(value.company_annual_revenue, 'string') as string : '',
    });
  }

  const isLoading = () => {
    return (
      loading === LoadingType.pending ||
      competitorState?.loading === LoadingType.pending
    );
  };

  const PageLoading = (props) => {
    return (
      <div className="table-loading">
        <SpinnerScreen />
      </div>
    );
  };

  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };

  function handleMouseOver(id,column) {
    setIsHovering({ id: id, visible: true,column });
  }
  function handleMouseOut() {
    setIsHovering(null);
  }

  const getFilteredList = () => {
    let list: SalesCall[] = [...salescalls];
    let sortedList: SalesCall[] | undefined;

    list = list.filter(
      (item) => item.is_completed === showCompleted && item.contact_id === params.id
    );

    if (salesFormState.available_from && salesFormState.available_to) {
      sortedList = (sortedList ?? list).filter((doc) => {
        const filter =
          doc.schedule_date_time !== null && (salesFormState.available_from <= doc.schedule_date_time && doc.schedule_date_time <= salesFormState.available_to);
        return filter;
      });
      if (salesFormState.available_from && salesFormState.available_to) {
        return sortedList
      }
    }

    if (sortedField != null) {
      sortedList = [...list].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] != undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }
    // return sortedList;
    return sortedList?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const companyName = doc.company_name
        ? doc.company_name.trim().toLowerCase().includes(str)
        : false;
      const departmentName = doc.department_name
        ? doc.department_name.trim().toLowerCase().includes(str)
        : false;
      const industrySectorName = doc.industry_sector_name
        ? doc.industry_sector_name.trim().toLowerCase().includes(str)
        : false;
      const callGoal = doc.call_goal
        ? doc.call_goal.trim().toLowerCase().includes(str)
        : false;
      const callResult = doc.call_result
        ? doc.call_result.trim().toLowerCase().includes(str)
        : false;
      const contactName = `${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`
        ? `${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`.trim().toLowerCase().includes(str)
        : false;
      return companyName || departmentName || contactName || industrySectorName || callGoal || callResult;
    });
  };

  const showSpinner = () => loading === LoadingType.pending;

  function handleCompanySelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }

  function handleDepartmentSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleContactSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
      state: {
        id: value.contact_id,
        name: `${value.contact_first_name ?? ""} ${value.contact_last_name ?? ""
          }`,
      },
    });
    return;
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1.2;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 1;
    if (value === 11) return 1;
    return 1;
  }

  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && salescalls.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getcontactsalescalls} />;
    }

    if (salescalls.length === 0 || getFilteredList()?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getcontactsalescalls} />;
    }

    if (getFilteredList()?.length !== 0) {
      return (
        <SortableTable
          headerList={showCompleted ? completedCallsTableheader : tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList()?.map((doc) => {
            return (
              <tr key={doc.id}>
                {!showCompleted ? (
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    <button
                      className="complete-call"
                      onClick={() => { handleCompleteCallSelect(doc) }}
                    >
                      <span>{t("complete_call")}</span>
                    </button>
                  </TableData>
                ) : (
                  <TableData
                    customStyle={{ flex: getFlexNumber(0) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                {!showCompleted && (
                  <TableData
                    customStyle={{ flex: getFlexNumber(1) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  isButton={true}
                  onClick={() => handleDepartmentSelect(doc)}
                >
                  <span>{doc.department_name ?? t("corporate")}</span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(3) }}
                  isButton={true}
                  onClick={() => handleContactSelect(doc)}
                >
                  <span>
                    {doc.contact_first_name
                      ? doc.contact_first_name
                      : "" + " " + doc.contact_last_name
                        ? doc.contact_last_name
                        : ""}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  {doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  {getContactIcon(doc.contact_method)}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  <span>{doc.industry_sector_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                <div
                    style={{ cursor: "pointer" }}
                    onMouseOut={() => handleMouseOut()}
                    onMouseOver={() =>
                      handleMouseOver(doc.id, hoveringStateColumn.callGoal)
                    }
                    className="col-hover-container"
                  >
                    <span>
                      {doc != null && doc?.call_goal?.length > 100
                        ? doc?.call_goal?.substring(0, 100) + "..."
                        : doc?.call_goal}
                    </span>
                    {isHovering &&
                      doc.id === isHovering.id &&
                      isHovering.column === hoveringStateColumn.callGoal && (
                        <div className="parent-position">
                          <div className="child-position">
                            <span style={{ cursor: "pointer" }}>
                              {doc?.call_goal}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span>{getDateString(doc.schedule_date_time)}</span>
                </TableData>
                {showCompleted && (
                  <>
                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                      <span>{getDateString(doc.call_date_time)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                    <div
                        style={{ cursor: "pointer" }}
                        onMouseOut={() => handleMouseOut()}
                        onMouseOver={() =>
                          handleMouseOver(
                            doc.id,
                            hoveringStateColumn.callResult
                          )
                        }
                        className="col-hover-container"
                      >
                        <span>
                          {doc != null && doc?.call_result?.length > 100
                            ? doc?.call_result?.substring(0, 100) + "..."
                            : doc?.call_result}
                        </span>
                        {isHovering &&
                          doc.id === isHovering.id &&
                          isHovering.column ===
                            hoveringStateColumn.callResult && (
                            <div className="parent-position">
                              <div className="child-position">
                                <span style={{ cursor: "pointer" }}>
                                  {doc?.call_result}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </TableData>
                  </>
                )}
                <TableData customStyle={{ flex: getFlexNumber(11) }}>
                  <span>
                    <button
                      className="icon-btn tdp-edit-icon"
                      onClick={() => showCompleted ? handleCompleteCallSelect(doc) : handleEditCallSelect(doc)}
                    >
                      <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                      />
                    </button>
                  </span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  function handlePlanASalesCallSelect() {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        planCall: true,
        contactId: contactDetails.data?.id,
        companyId: contactDetails.data?.company_id,
        departmentId: contactDetails?.data?.department_ids ? contactDetails.data?.department_ids[0] : null,
        planned_by_name: getUserNameFromProfileDropdown(profileState?.profile!),
        source: "contacts",
        
      },
    });
  }
  function handleCompleteCallSelect(value: SalesCall) {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        completeCall: true,
        id: value.id ?? "",
        source: "contacts",
        contactId: params.id
      },
    });
  }
  function handleEditCallSelect(value: SalesCall) {
    // if (showCompleted) {
    //   history.push({
    //     pathname: AppRoutes.salesAndMarketingSalesCallActivity,
    //     state: {
    //       completeCall: true,
    //       id: value.id ?? "",
    //       source:"contacts",
    //       contactId:params.id
    //     },
    //   });
    // }
    // else {
    history.push({
      pathname: AppRoutes.salesAndMarketingSalesCallActivity,
      state: {
        editCall: true,
        id: value.id ?? "",
        source: "contacts",
        contactId: params.id

      },
    });
    // }

  }


  return (
    <>
      <div>
        {isLoading() ? (
          <PageLoading />
        ) : (
          <div className="cc-sales-profile-container">
            {/* <div className="cc-sales-profile-header">
        <div className="cc-sales-profile-headerText">
            {`${ContactDetails.contactInfo?.first_name ?? ''} ${ContactDetails.contactInfo?.last_name ?? ''} `} 
        </div>
      </div> */}
            {/* <div className="Line-68"></div> */}
            <div className="cc-sales-profile-content">
              <div className="cc-sp-field-row row1">
                <div className="cc-sc-fields">
                  <FormInput
                    id={"contact_annual_revenue"}
                    onChange={(field: any, value: any) => {
                      if (value !== null) {
                        setProfile((prevState: any) => ({
                          ...prevState,
                          company_annual_revenue: value,
                        }));
                      }
                    }}
                    required={false}
                    placeholder={""}
                    type={"text"}
                    value={convertNumberToMoney(profile.company_annual_revenue, "string") as string}
                    label={t("contact_annual_revenue")}
                    prefix={<span>{"$"}</span>}
                    onBlur={formatToMoney}
                    onKeyDown={formatToMoney}
                    onKeyUp={formatToMoney}
                  />
                </div>
                <div className="cc-sc-fields">
                  <FormInput
                    id={"number_of_full_time_staff"}
                    onChange={(field: any, value: any) => {
                      if (value !== null) {
                        setProfile((prevState: any) => ({
                          ...prevState,
                          number_of_full_time_staff: value,
                        }));
                      }
                    }}
                    required={false}
                    placeholder={""}
                    type={"number"}
                    value={profile.number_of_full_time_staff}
                    label={"How many full time employees does this Contact have?"}
                  />
                </div>
                <div className="cc-sc-fields">
                  <CustomFormSelect
                    name={"industry"}
                    label={
                      "Industry - Type of placements your agency will make with this Contact"
                    }
                    placeholder={"Select"}
                    value={profile.industry}
                    list={mapOptions(businessSectors, "business_sector", "id")}
                    onChange={(value) => {
                      setProfile((prevState: any) => ({
                        ...prevState,
                        industry: value,
                      }));
                    }}
                    customStyle={{ width: "-webkit-fill-available" }}
                  />
                </div>
              </div>
              <div className="cc-sp-field-row row2">
                <div className="cc-sc-fields">
                  <ToggleSwitch
                    label={t("Does this Contact use Staffing Agencies?")}
                    labelPosition={"top"}
                    checked={profile.uses_staffing_agency}
                    onChange={(value) => {
                      setProfile({
                        ...profile,
                        uses_staffing_agency: value,
                      });
                    }}
                  />
                </div>
                {profile.uses_staffing_agency && (
                  <>
                    <div
                      className="cc-sc-fields"
                    // style={{
                    //   marginTop: 5,
                    // }}
                    >
                      <CustomMultiFormSelect
                        name={"competitor"}
                        label={
                          "If yes, which staffing agencies does this Contact works with?"
                        }
                        placeholder={"Select"}
                        value={profile.staffing_agencies_ids}
                        list={competitorsData.map((doc) => ({
                          label: doc.competitor_name,
                          value: doc.id,
                        }))}
                        onChange={(value) => {
                          setProfile({
                            ...profile,
                            staffing_agencies_ids: value,
                          });
                        }}
                        customStyle={{ width: "-webkit-fill-available" }}
                      />
                    </div>
                  </>
                )}
                <div className="cc-sc-chip-field">
                  <div style={{ width: "100%" }}>
                    <FormInput
                      id={"summary"}
                      onChange={(_field: any, value: any) => {
                        if (value !== null) {
                          setProfile((prevState: any) => ({
                            ...prevState,
                            summary: value,
                          }));
                        }
                      }}
                      required={false}
                      placeholder={""}
                      type={"text"}
                      multiline
                      value={profile.summary}
                      label={t("How did we hear about this Contact")}
                    />
                  </div>
                </div>
              </div>

              <div className="cc-sp-field-row row3">
                <div className="cc-sc-fields">
                  <div style={{ width: "100%" }}>
                    <FormInput
                      id={"industry_averages"}
                      onChange={(_field: any, value: any) => {
                        if (value !== null) {
                          setProfile((prevState: any) => ({
                            ...prevState,
                            industry_averages: value,
                          }));
                        }
                      }}
                      required={false}
                      placeholder={""}
                      type={"text"}
                      multiline
                      value={profile.industry_averages}
                      label={t(
                        "Based on Industry averages here are some assumptions "
                      )}
                    />
                  </div>
                </div>
                <div className="cc-sc-fields">
                  <div style={{ width: "100%" }}>
                    <FormInput
                      id={"profile_mystique"}
                      onChange={(_field: any, value: any) => {
                        if (value !== null) {
                          setProfile((prevState: any) => ({
                            ...prevState,
                            profile_mystique: value,
                          }));
                        }
                      }}
                      required={false}
                      placeholder={""}
                      type={"text"}
                      multiline
                      value={profile.profile_mystique}
                      label={t("profile_mystique")}
                    />
                  </div>
                </div>
                <div className="cc-sc-fields">
                  <div style={{ width: "100%" }}>
                    <FormInput
                      id={"additional_notes"}
                      onChange={(_field: any, value: any) => {
                        if (value !== null) {
                          setProfile((prevState: any) => ({
                            ...prevState,
                            additional_notes: value,
                          }));
                        }
                      }}
                      required={false}
                      placeholder={""}
                      type={"text"}
                      multiline
                      value={profile.additional_notes}
                      label={"Additional Notes"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="add-role-action-holder">
              <div className="add-role-action">
                <div className="btn-no">
                  <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={t("cancel")}
                    enable
                    backgroundColor={THEME.defaultActionColor}
                    onClick={() => {
                      updateForm(salesProfile);
                      //history.push(AppRoutes.contactsPage);
                    }}
                  />
                </div>
                <div className="btn-yes">
                  <CustomButton
                    loading={saveResponse.loading === LoadingType.pending}
                    textStyle={{ textTransform: "capitalize" }}
                    name={"Save"}
                    backgroundColor={THEME.defaultHighLightColor}
                    onClick={handleSave}
                    enable
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="contact-sales-call-activity">
          <div className="sales-call-activity">
            <div className="flex-row main-header">
              <div
                className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
                  }`}
                onClick={() => setShowCompleted(false)}
              >
                {!showCompleted ? (
                  <ScheduledCallWhiteIcon
                    height={"20px"}
                    width={"20px"}
                    style={{ marginRight: "15px" }}
                  />
                ) : (
                  <ScheduledCallIcon
                    height={"20px"}
                    width={"20px"}
                    style={{ marginRight: "15px" }}
                  />
                )}
                Scheduled Sales Calls
              </div>
              <div
                className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
                  }`}
                onClick={() => setShowCompleted(true)}
              >
                {showCompleted ? (
                  <CompletedCallWhiteIcon
                    height={"20px"}
                    width={"20px"}
                    style={{ marginRight: "15px" }}
                  />
                ) : (
                  <CompletedCallIcon
                    height={"20px"}
                    width={"20px"}
                    style={{ marginRight: "15px" }}
                  />
                )}
                Completed Sales Calls
              </div>
            </div>
            <div className="table-container">
              <div className="actions">
                <div className="table-header">
                  <div className="dateRange">
                    <label>{t("date_range")}</label>
                    <div className="date-input-icon">
                      <div className="calendar-icon-div">
                        <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                      </div>
                      <DatePicker
                        selectsRange={true}
                        startDate={salesStartDate}
                        endDate={salesEndDate}
                        onChange={handleChange}
                        isClearable={true}
                        placeholderText={'Start Date -  End Date'}
                        dateFormat={'MM.dd.yyyy'}
                        onClickOutside={() => setDatePickerOpen(false)}
                        open={isDatePickerOpen}
                        onFocus={() => setDatePickerOpen(true)}
                      />
                    </div>
                  </div>
                  <div className="total-count-action">
                    <span>
                      {`${t("total_count")}: `}
                      <span className="total-count-number">
                        {getFilteredList()?.length}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="sm-sca-search">
                  <div>
                    <SearchBar
                      value={search}
                      onChange={(value) => setSearch(value)}
                      onSearch={() => { }}
                    />
                  </div>
                  {!showCompleted && (
                    <div className="add-campaign">
                      <CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        name={t("Plan a Sales Call")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={handlePlanASalesCallSelect}
                        customStyle={{ marginTop: "3em" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {getTable()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
