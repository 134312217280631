import { useEffect, useRef, useState } from 'react';
import { LoadingType, THEME } from '../../../../enums';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ICompanyServiceProfile, ICompanyUpdateServiceProfile, IShift } from '../../../../interfaces';
import { deleteCompanyShift, getCompanyServiceProfile, getCompanyShifts, saveCompanyShift, _saveCompanyServiceProfile } from '../../../../redux/companies';
import { manageCompanyProfileActions } from '../../../../redux/companies/universal_directory/company_profile/company_profile_reducer';
import { selectCompanyServiceProfile, selectRemove_state, selectSave_state, selectShiftSave_State, selectShiftsData } from '../../../../redux/companies/universal_directory/company_profile/company_profile_selector';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../../redux/store';
import { CustomButton, FormInput, SpinnerScreen, getPlusBtnIcon, convertNumberToMoney, formatToMoney } from '../../../../utils';
import './company_service_profile_page.scss';
import { cloneDeep } from 'lodash';
import { selectCheckDeliveryList, selectCheckDeliveryState, selectOnboardingStepState, selectOnboardingStepList, selectServiceCallState, selectServiceCallList, getOnboardingSteps, getCheckDelivery, getServiceCalls } from '../../../../redux/admin_center';
import CustomFormSelect from '../../../../components/form_selector/form_select';
import SortableTable, { TableData } from '../../../../components/sortable_table/sortable_table';
import EditIcon from '../../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../../assets/icons/delete-icon.svg';
import { Timer } from '@material-ui/icons';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder';
import { Portal, makeStyles } from '@material-ui/core';
import DialogWrapper, { FormDialogTilteHeader } from '../../../../components/dialog_wrapper/dialog_wrapper';
import { CompaniesIcon } from '../../../../icons';
import ToggleSwitch from '../../../../components/toggle_switch/toggle_switch';
import { convertStringTimeToTimestamp, convertTimestampToStringTime, formatAMPM, getDateFromTimeStamp } from '../../../../variables'
import TimePickerComponent from '../../../../components/time_picker/time_picker';
import { DeleteConfirmationDialog } from '../../../components/delete_confirmation_dialog';


interface Props extends RouteComponentProps<any> { }
const PageLoading = (props) => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}

const weekDays = [
    { id: "SUNDAY", label: "Sunday" },
    { id: "MONDAY", label: "Monday" },
    { id: "TUESDAY", label: "Tuesday" },
    { id: "WEDNESDAY", label: "Wednesday" },
    { id: "THURSDAY", label: "Thursday" },
    { id: "FRIDAY", label: "Friday" },
    { id: "SATURDAY", label: "Saturday" },
];

const payCycles = [
    { id: "WEEKLY", label: "Weekly" },
    { id: "BIWEEKLY", label: "Bi-Weekly" },
    { id: "SEMI_MONTHLY", label: "Semi-Monthly" },
    { id: "MONTHLY", label: "Monthly" },
];

const payPeriods = [
    { id: "PAY_PERIOD_1", label: "Pay Period 1" },
    { id: "PAY_PERIOD_2", label: "Pay Period 2" },
    { id: "PAY_PERIOD_3", label: "Pay Period 3" },
    { id: "PAY_PERIOD_4", label: "Pay Period 4" },
];

const tableHeader: any = [
    { title: 'Shift Name', code: 'shift_name' },
    { title: 'Start', code: 'start_time' },
    { title: 'End', code: 'end_time' },
    { title: 'Break', code: 'break_time' },
    { title: 'Estimated Shift Hours', code: 'estimated_shift_hours' },
    { title: 'Actions', code: 'actions' },
];


const CompanyServiceProfilePage: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { history, location, match } = props;
    const { params } = match;
    const { loading, error, serviceProfile } = useAppSelector(state => selectCompanyServiceProfile(state));
    const saveResponse = useAppSelector((state) => selectSave_state(state));
    const shiftsData = useAppSelector((state) => selectShiftsData(state));
    const [profileData, setProfileData] = useState({} as ICompanyUpdateServiceProfile);
    const [loader, setIsLoader] = useState(true);
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [availabelShifts, setAvailableShifts]: any = useState([]);
    const [selectedShift, setSelectedShift]: any = useState(null);
    const [companyId, setCompanyId]: any = useState("");
    const shiftSaveResponse = useAppSelector((state) => selectShiftSave_State(state));
    const removeResponse = useAppSelector((state) => selectRemove_state(state));

    const contractServiceCallsList = serviceCallList.filter((serviceCall) =>
      [
        'Arrival Calls',
        '4 Hour Guarantee',
        'End of 1st Day Calls',
        'End of 2nd Day Calls',
        'Friday Calls',
        'End of Assignment Call',
        'Job Confirmation Calls',
      ].includes(serviceCall.service_call)
    );
    const directHireServiceCallsList = serviceCallList.filter((serviceCall) =>
      ['Arrival Calls', '30 Day calls', '60 Day calls', '90 Day calls'].includes(serviceCall.service_call)
    )

    useEffect(() => {
        dispatch(getOnboardingSteps());
        dispatch(getCheckDelivery());
        dispatch(getServiceCalls());
        if (params && params.id) {
            dispatch(manageCompanyProfileActions.clearShiftSaveState());
            dispatch(manageCompanyProfileActions.clearState());
            setCompanyId(params.id);
            dispatch(getCompanyShifts({ companyId: params.id, departmentId: params.departmentId }));
            dispatch(getCompanyServiceProfile({ companyId: params.id, departmentId: params.departmentId }));
        }
    }, [params.id, params.departmentId]);

    useEffect(() => {
        if (serviceProfile && serviceProfile.company_id) {
            updateForm(serviceProfile);
            setIsLoader(false);
        }
    }, [serviceProfile]);

    useEffect(() => {
        if (shiftsData.data && shiftsData.data) {
            setAvailableShifts(shiftsData.data);
        }
    }, [shiftsData])

    useEffect(() => {
        if (saveResponse.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(saveResponse.response ?? null));
            dispatch(manageCompanyProfileActions.clearShiftSaveState());
        }
        return () => { }
    }, [saveResponse]);

    useEffect(() => {
        if (shiftSaveResponse.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(shiftSaveResponse.response ?? null));
            dispatch(manageCompanyProfileActions.clearShiftSaveState());
            setShowSaveDialog(false);

        }
        return () => { }
    }, [shiftSaveResponse]);

    useEffect(() => {
        if (removeResponse.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(removeResponse.response ?? null));
            dispatch(manageCompanyProfileActions.clearRemoveSaveState());
        }
        return () => { }
    }, [removeResponse.loading]);

    const handleSaveClick = () => {
        let payload = cloneDeep(profileData);
        payload.available_shifts = availabelShifts;
        dispatch(_saveCompanyServiceProfile({
            ...profileData,
            available_shifts: availabelShifts,
            mileage_rate: convertNumberToMoney(profileData.mileage_rate, 'number') as number,
        }));
    }

    function handleSaveCloseDialog() {
        setShowSaveDialog(false);
    }

    function isLoading() {
        return loading === LoadingType.pending || loader
            || checkDeliveryState.loading === LoadingType.pending
            || onboardingStepState.loading === LoadingType.pending
            || serviceCallState.loading === LoadingType.pending;
    }

    function updateForm(value: ICompanyServiceProfile) {
        setProfileData({
            ...value,
            onboarding_steps: value.onboarding_steps ? [...value.onboarding_steps] : [],
            check_delivery: value.check_delivery ? [...value.check_delivery] : [],
            service_calls: value.service_calls ? [...value.service_calls] : [],
            dh_service_calls: value.dh_service_calls ? [...value.dh_service_calls] : [],
            mileage_rate: value.mileage_rate ? convertNumberToMoney(value.mileage_rate, 'string') as string : '',
        });
    }

    const handleOnDelete = () => {
        if (selectedShift) {
            dispatch(deleteCompanyShift(selectedShift));
        }
        setShowDeleteDialog(false)
        dispatch(manageCompanyProfileActions.clearRemoveSaveState())
      };

    const handleClearError = () => {
        dispatch(manageCompanyProfileActions.clearRemoveSaveState())
    }

    return (
        <>
            {
                isLoading() ? <PageLoading /> :
                    <div className="ac-service-profile-container">
                        <div className="ac-service-profile-content">
                            {showSaveDialog && <Portal>
                                <SaveAction
                                    visible={showSaveDialog}
                                    onClose={handleSaveCloseDialog}
                                    onDismiss={handleSaveCloseDialog}
                                    setShowSaveDialog={setShowSaveDialog}
                                    shiftData={selectedShift}
                                    companyId={companyId}
                                    departmentId={params.departmentId}
                                />
                            </Portal>}
                            {showDeleteDialog && <Portal>
                                <DeleteConfirmationDialog
                                    message={t("delete_comp_service_profile_del_shift_message", {
                                        shiftName: selectedShift?.shift_name,
                                    })}
                                    onCancel={() => setShowDeleteDialog(false)}
                                    onConfirm={handleOnDelete}
                                    deleting={removeResponse.loading === LoadingType.pending}
                                    deleteType={t("shift")}
                                    state={removeResponse}
                                    onClearError={handleClearError}
                                />
                            </Portal>}
                            <div className='ac-sp-field-row' style={{ marginTop: "1em" }}><p className='ac-service-text'>ONBOARDING STEPS</p></div>

                            {onboardingStepList && onboardingStepList.map((data: any, index: number) => {
                                return (

                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.onboarding}
                                            checked={(profileData?.onboarding_steps?.filter((step: any) => step === data.id).length > 0)}
                                            onChange={(value) => {
                                                const onboarding_steps = profileData.onboarding_steps ? profileData.onboarding_steps : [];
                                                if (value) {
                                                    onboarding_steps.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        onboarding_steps: onboarding_steps
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        onboarding_steps: onboarding_steps.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>

                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text'>CHECK DELIVERY</p></div>
                            {checkDeliveryList && checkDeliveryList.map((data: any, index: number) => {
                                return (
                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.check_delivery}
                                            checked={(profileData?.check_delivery?.filter((check: any) => check === data.id).length > 0)}
                                            onChange={(value) => {
                                                const checkDelivery = profileData.check_delivery ? profileData.check_delivery : [];
                                                if (value) {
                                                    checkDelivery.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        check_delivery: checkDelivery
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        check_delivery: checkDelivery.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>
                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text'>CONTRACT SERVICE CALLS</p></div>
                            {serviceCallList && contractServiceCallsList.map((data: any, index: number) => {
                                return (
                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.service_call}
                                            checked={(profileData?.service_calls?.filter((call: any) => call === data.id).length > 0)}
                                            onChange={(value) => {
                                                const service_calls = profileData.service_calls ? profileData.service_calls : [];
                                                if (value) {
                                                    service_calls.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        service_calls: service_calls
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        service_calls: service_calls.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>
                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text'>DIRECT HIRE SERVICE CALLS</p></div>
                            {serviceCallList && directHireServiceCallsList.map((data: any, index: number) => {
                                return (
                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.service_call}
                                            checked={(profileData?.dh_service_calls?.filter((call: any) => call === data.id).length > 0)}
                                            onChange={(value) => {
                                                const dh_service_calls = profileData.dh_service_calls ? profileData.dh_service_calls : [];
                                                if (value) {
                                                    dh_service_calls.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        dh_service_calls: dh_service_calls
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        dh_service_calls: dh_service_calls.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>
                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'>
                                <div className='ac-service-field'>
                                    <label className='field-label-text'>Timeclock</label>
                                    <br></br>
                                    <ToggleSwitch label={t("active")}
                                        checked={profileData.time_clock}
                                        onChange={(value) => {
                                            setProfileData({
                                                ...profileData,
                                                time_clock: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className='ac-service-field'>
                                    <FormInput
                                        id={'mileage_rate'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    mileage_rate: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"text"}
                                        value={profileData.mileage_rate}
                                        label={"Mileage Rate"}
                                        prefix={<span>{'$'}</span>}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className='ac-sp-field-row' style={{ display: "none" }}><p className='ac-service-text'>Payroll Options</p></div>
                            <div className='ac-sp-field-row' style={{ display: "none" }}>
                                <div className="ac-sc-fields">
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"week_ending_day"}
                                        label={"Week Ending Day"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.week_ending_day : ''}
                                        list={weekDays.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    week_ending_day: value
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                                <div className="ac-sc-fields" >
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"pay_cycle"}
                                        label={"Pay Cycle"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.pay_cycle : ''}
                                        list={payCycles.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    pay_cycle: value
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                                <div className="ac-sc-fields">
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"pay_period"}
                                        label={"Pay Period"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.pay_period : ''}
                                        list={payPeriods.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    pay_period: value
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                            </div>
                            <div className='ac-sp-field-row available-shifts-header' >
                                <div className='available-shifts-txt' >
                                    <span className='ac-service-text'>{t('available_shifts')}</span>
                                </div>
                                <div className='available-shifts-actions'>
                                    <CustomButton
                                        leftIcon={getPlusBtnIcon()}
                                        loading={false}
                                        name={t('add_shift')}
                                        enable={true}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={() => {
                                            setSelectedShift({
                                                id: "",
                                                company_id: companyId,
                                                department_id: null,
                                                shift_name: "",
                                                start_time: "12:00 AM",
                                                end_time: "12:00 AM",
                                                break_in_minutes: 0,
                                                estimated_shift_hours: 0,
                                                is_deleted: false,
                                            })
                                            setShowSaveDialog(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ac-sytm-cns-content" style={{ marginTop: 10 }}>
                                <div className="ac-sytm-cns-table">

                                    <SortableTable headerList={tableHeader} isLoading={shiftsData.loading === LoadingType.pending} >
                                        {availabelShifts && availabelShifts.map((doc, index) => {
                                            return (
                                                <tr key={doc.id}>
                                                    <TableData>{doc.shift_name}</TableData>
                                                    <TableData>{doc.start_time ? formatAMPM(getDateFromTimeStamp(doc.start_time)) : ''}</TableData>
                                                    <TableData>{doc.end_time ? formatAMPM(getDateFromTimeStamp(doc.end_time)) : ""}</TableData>
                                                    <TableData>{`${doc.break_in_minutes} Minutes`}</TableData>
                                                    <TableData>{`${doc.estimated_shift_hours.toFixed(2)} Hours`}</TableData>
                                                    <TableData>
                                                        <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px', height: "1vw", width: "1vw" }}
                                                            onClick={() => {
                                                                setSelectedShift({
                                                                    ...doc,
                                                                    start_time: convertTimestampToStringTime(doc.start_time * 1000),
                                                                    end_time: convertTimestampToStringTime(doc.end_time * 1000)
                                                                });
                                                                setShowSaveDialog(true);
                                                            }} />
                                                        <img src={DeleteIcon} alt='delete_button' style={{ height: "1vw", width: "1vw" }}
                                                            onClick={() => {
                                                                setSelectedShift(doc);
                                                                setShowDeleteDialog(true);
                                                            }} />
                                                    </TableData>
                                                </tr>
                                            );
                                        })}
                                    </SortableTable>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="add-role-action-holder">
                            <div className="add-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.defaultActionColor}
                                        onClick={() => {
                                            updateForm(serviceProfile);
                                        }}
                                    />
                                </div>
                                <div className="btn-yes">
                                    <CustomButton
                                        loading={saveResponse.loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={'Save'}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleSaveClick}
                                        enable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

const SaveAction = ({ onClose, onDismiss, visible, shiftData, setShowSaveDialog, companyId, departmentId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [shiftDetails, setShiftDetails]: any = useState({ ...shiftData });
    const shiftSaveResponse = useAppSelector((state) => selectShiftSave_State(state));

    const handleClearTime = (id: string) => {
        if (id === "start_time") {
            setShiftDetails({ ...shiftDetails, start_time: "" })
        }
        if (id === "end_time") {
            setShiftDetails({ ...shiftDetails, end_time: "" })
        }
    }

    function handleUpdate() {
        const startTime = convertStringTimeToTimestamp(shiftDetails.start_time);
        const startTimeTimeStamp = startTime! / 1000
        const endTime = convertStringTimeToTimestamp(shiftDetails.end_time);
        const endTimeTimeStamp = endTime! / 1000
        const payload: IShift = {
            id: shiftDetails && shiftDetails.id ? shiftDetails.id : "",
            company_id: companyId,
            department_id: departmentId ? departmentId : null,
            shift_name: shiftDetails.shift_name,
            start_time: startTimeTimeStamp,
            end_time: endTimeTimeStamp,
            break_in_minutes: shiftDetails.break_in_minutes,
            estimated_shift_hours: shiftDetails.estimated_shift_hours,
            is_deleted: false,
        }
        dispatch(saveCompanyShift(payload));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'30%'}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={() => closePopup(onClose)}>
                <div className="cmpny-cspp-container">
                    <div className="cmpny-cspp-header">
                        <FormDialogTilteHeader
                            title={shiftDetails && shiftDetails.id ? "Edit Shift" : "Add Shift"}
                            titleIcon={<CompaniesIcon width={"100%"} height={"100%"} style={{ color: THEME.talentOptionTextColor }} />}
                        />
                    </div>
                    <div className="cmpny-cspp-content">
                        <div className={"cmpny-cspp-input-holder"}>
                            <FormInput
                                id={"shift_name"}
                                onChange={(fieldId, value) => {
                                    setShiftDetails((prevState: any) => ({
                                        ...prevState,
                                        shift_name: value,
                                    }));
                                }}
                                placeholder={"Shift Name"}
                                type={"text"}
                                value={shiftDetails.shift_name}
                                required={true}
                                customStyle={{ marginBottom: "1em" }}
                            />
                        </div>
                        <div className="cmpny-cspp-section-divider">
                            <div className="cmpny-cspp-row">
                                <div className={"cmpny-cspp-input-holder"}>
                                    <TimePickerComponent
                                        id={"start_time"}
                                        onChange={(fieldId, value) => {
                                            setShiftDetails((prevState: any) => ({
                                                ...prevState,
                                                start_time: value,
                                            }));
                                        }}
                                        placeholder={''}
                                        value={shiftDetails.start_time}
                                        label={t('start_time')}
                                        onClearTime={() => handleClearTime("start_time")}
                                        icon={<Timer />}
                                        required
                                    />
                                </div>
                                <div className={"cmpny-cspp-input-holder"}>
                                    <TimePickerComponent
                                        id={"end_time"}
                                        onChange={(fieldId, value) => {
                                            setShiftDetails((prevState: any) => ({
                                                ...prevState,
                                                end_time: value,
                                            }));
                                        }}
                                        placeholder={''}
                                        value={shiftDetails.end_time}
                                        onClearTime={() => handleClearTime("end_time")}
                                        label={t('end_time')}
                                        icon={<Timer />}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="cmpny-cspp-row">
                                <div className={"cmpny-cspp-input-holder"}>
                                    <FormInput
                                        id={'break_in_minutes'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setShiftDetails((prevState: any) => ({
                                                    ...prevState,
                                                    break_in_minutes: Number(value),
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"number"}
                                        value={shiftDetails.break_in_minutes}
                                        label={"Break Length (In Minutes)"}

                                    />
                                </div>
                                <div className={"cmpny-cspp-input-holder"}>
                                    <FormInput
                                        id={'estimated_shift_hours'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setShiftDetails((prevState: any) => ({
                                                    ...prevState,
                                                    estimated_shift_hours: Number(value),
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"number"}
                                        value={shiftDetails.estimated_shift_hours}
                                        label={"Estimated Shift Hours"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cmpny-cspp-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={shiftDetails && shiftDetails.id ? t('update') : t('add')}
                                enable={!!shiftDetails.end_time && shiftDetails.start_time}
                                loading={shiftSaveResponse.loading == LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleUpdate}
                            />
                        </div>

                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )
}
export default CompanyServiceProfilePage;