import { RouteComponentProps } from "react-router-dom";
import { getInBoundLeadGetById, selectInBoundLeadsListState,selectUpdateState,updateInboundLead, useAppDispatch, useAppSelector,postSnackbarMessage } from "../../../../../redux/store";

import { useEffect, useState,useRef } from "react";
import { useTranslation } from "react-i18next";
import InDevelopment from "../../../../../others/in_development";
import { EditIcon } from "../../../../../icons";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import StateChangeHandler from "../../../../../components/state_change_handler/state_change_handler";
import { IInBoundLeads } from "../../../../../interfaces/inbound_leads";
import LeadDashboardDetailsSection from "../../../../../components/inbound_leads_details_section/inbound_leads_details_section";
import InboundLeadInfoPage from "./inbound_lead_info/inbound_lead_info_page";
import "./dashboard.scss";
import { LoadingType } from "../../../../../enums";
import { Portal } from 'react-portal';
import { ActionDialogHolder,ActionDialogHolderType } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../../../components/dialog_wrapper/dialog_wrapper";
import EditInboundLeadForm from "../../../forms/inbound_leads_form/inbound_leads_form";
import { inBoundLeadsStateActions } from "../../../../../redux/dashboard/metrics/inbound_leads/inbound_leads_directory/inbound_leads_directory_reducer";
interface Props extends RouteComponentProps<any> { }

const InBoundLeadsDashboard: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const inBoundLeadsListState = useAppSelector((state) =>
        selectInBoundLeadsListState(state)
    );
    const updateState = useAppSelector(selectUpdateState);
    const [editInboundLeadInfo, setEditInboundLeadInfo] = useState<{ visible: boolean, data: IInBoundLeads | null } | null>(null);
    const [inboundData, setInboundData] = useState<IInBoundLeads | null>(null)
    const [tabIndex, setTabIndex] = useState<number>(0);
    const actionRef = useRef<ActionDialogHolderType>(null);


    useEffect(() => {
        getInboundLeadsDetails()
        return () => {
        }
    }, []);
    useEffect(()=>{
        if(inBoundLeadsListState.getById.loading === LoadingType.succeeded && inBoundLeadsListState.getById.response){
            setInboundData(inBoundLeadsListState.getById.response);
        }
    },[inBoundLeadsListState.getById.response,inBoundLeadsListState.getById.loading])

    useEffect(() => {
        if (updateState.loading == LoadingType.succeeded && updateState.response) {
            dispatch(postSnackbarMessage(updateState.response));
            closePopup(handleUpdateInboundLeadSuccess);
        }
        return () => { }
    }, [updateState.loading])

    const getInboundLeadsDetails = () => {
        dispatch(getInBoundLeadGetById(params.id));
    }

    function getTabList() {
        return [
            {
                title: t('inbound_lead_information'),
                content: <InboundLeadInfoPage data={inboundData}/>
            },
        ];
    }

    function getEditAction() {
        return (
            <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </button>
        );
    }

    function handleEdit() {
        setEditInboundLeadInfo({ visible: true, data: inboundData });
    }
    function handleUpdateInboundLeadSuccess () {
        setEditInboundLeadInfo(null)
        getInboundLeadsDetails()
        dispatch(inBoundLeadsStateActions.clearInboundLeadsUpdateState())
    }
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleSubmit(value: IInBoundLeads ) {
        if (inboundData && inboundData.id) {
            dispatch(updateInboundLead(value))
        }
    }

    function handleClosePopup() {
        closePopup(() => setEditInboundLeadInfo(null))
        dispatch(inBoundLeadsStateActions.clearInboundLeadsUpdateState())
    }

    function clearUpdateStateError () {
        dispatch(inBoundLeadsStateActions.clearInboundLeadsUpdateStateError())
    }
    return (
        <>
            <StateChangeHandler
                loading={inBoundLeadsListState?.getById?.loading}
                error={inBoundLeadsListState?.getById?.error}
                onRefresh={getInboundLeadsDetails}
            >
                <div className="ibl-container">
                    <div className="ibl-left-panel">
                        <LeadDashboardDetailsSection
                            inboundLeadDetails={inboundData}
                        />
                    </div>
                    <div className="ibl-right-panel">
                        <CurveTabNavigation
                            tabs={getTabList()}
                            selected={tabIndex}
                            onChange={(value) => setTabIndex(value)}
                            actions={getEditAction()}
                        />
                    </div>
                </div>
                {editInboundLeadInfo && 
                <Portal>
                    <ActionDialogHolder
                        ref={actionRef}
                        visible={editInboundLeadInfo.visible}
                        wrapperWidth={"50%"}
                        onClose={handleClosePopup}
                        
                    >
                        <DialogWrapper onClose={handleClosePopup}>
                        <EditInboundLeadForm
                                title={t('edit_inbound_lead')}
                                inboundLead={inboundData}
                                loading={updateState.loading}
                                error={updateState.error}
                                onClose={handleClosePopup}
                                onSubmit={handleSubmit}
                                onClearError={clearUpdateStateError}
                            />
                        </DialogWrapper>
                    </ActionDialogHolder>
                </Portal>}
            </StateChangeHandler>
        </>
    )
}

export default InBoundLeadsDashboard;