import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../enums";
import { AppError } from "../../interfaces";
import * as thunk from "./agency_login_logo_thunk";


export interface AgencyLoginLogo {
    error?: AppError | null,
    loading: LoadingType
    response: string,
}


const initialState: AgencyLoginLogo = {
    error: null,
    loading: LoadingType.idle,
    response: '',

}


export const agencyLoginLogoSlice = createSlice({
    name: "agencyLoginLogo",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAgencyLoginLogoThunk.pending, (state, action) => ({...state, loading: LoadingType.pending}))
            .addCase(thunk.getAgencyLoginLogoThunk.rejected, (state, action) => ({...state, loading: LoadingType.failed, error: action.payload}))
            .addCase(thunk.getAgencyLoginLogoThunk.fulfilled, (state, action) => ({...state, loading: LoadingType.succeeded, response: action.payload}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const {} = agencyLoginLogoSlice.actions;
export const selectState = (state: AgencyLoginLogo) => state;