import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getVacationReportThunk, postSnackbarMessage, selectProfileState, selectVacationReportState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import { SpinnerScreen } from "../../../../../utils";
import "./vacation_report.scss";
import { IVacationReport } from "../../../../../interfaces/report_center";
import { LoadingType, THEME } from "../../../../../enums";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import SSNHolder from "../../../../../components/ssn_holder/ssn_holder";
import { talentRequestSSNActions } from "../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer";
import { AppRoutes } from "../../../../../routes";
import { Portal } from "react-portal";
import ViewSSNPopup from "../../../../talent/popups/view_ssn/view_ssn";
import SearchBar from "../../../../../components/search_bar";
import { holidayVacationReportSliceActions } from "../../../../../redux/report_center/payroll_tax/holiday_vacation_report/holiday_vacation_report_reducer";


interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "ssn", code: "ssn" },
    { title: "talent", code: "talent_name" },
    { title: "total_hours", code: "total_hours" },
    { title: "vacation_hours_earned", code: "earned_holiday_hours" },
    { title: "vacation_hours_taken", code: "earned_holiday_hours" },
    { title: "vacation_hours_remaining", code: "earned_holiday_hours" },
    { title: "on_assignment", code: "on_assignment" },
];
const VacationReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const vacationReportState = useAppSelector((state) => selectVacationReportState(state));
    const profileState = useAppSelector((state) => selectProfileState(state));

    const [vacationReportList, setVacationReportList] = useState<IVacationReport[]>([]);

    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string; visible: boolean; } | null>(null);

    useEffect(() => {
        dispatch(holidayVacationReportSliceActions.clearVacationState());
        dispatch(getVacationReportThunk());

    }, []);

    useEffect(() => {
        if (vacationReportState.loading === LoadingType.succeeded && vacationReportState.response) {
            setVacationReportList(vacationReportState.response)
        }
    }, [vacationReportState.loading, vacationReportState.response])

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    const getFilteredList = () => {
        let list: IVacationReport[] = vacationReportList;

        if (vacationReportList && Array.isArray(vacationReportList) && sortedField != null) {
            list = [...vacationReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] !== null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] !== null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });

    }
    function getSSNInfo(value: string, id: string) {
        return (
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        );
    }
    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
        }
    }
    function handleTalentSelect(id: string, name: string) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${id}`,
            state: {
                id: id,
                name: name,
            },
        });
        return;
    }
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 0.8;
        if (value === 3) return 0.8;
        if (value === 4) return 0.8;
        if (value === 5) return 0.8;
        if (value === 6) return 0.8;
        if (value === 7) return 0.8;
        if (value === 8) return 1;
        if (value === 9) return 1;
        return 1;
    }


    return (
        <div className="vr-container">
            <div className="vr-header">
                <div className="vr-r-left">
                    <div className="vr-title">
                        {t("employee_vacation_report")}
                    </div>
                </div>
                <div className="vr-r-right">
                    <span className="total-span">
                        {`${t("total_count")}: `}
                        <span className="total-count-number">
                            {getFilteredList()?.length}
                        </span>
                    </span>
                    <SearchBar
                        value={search}
                        onChange={setSearch}
                        onSearch={() => { }}
                    />
                    <div>
                        <TabOptionsHeader onClose={() => props.history.goBack()} />
                    </div>
                </div>
            </div>
            <div className="vr-content">
                {vacationReportState.loading === LoadingType.pending ?
                    <SpinnerScreen />
                    :
                    <div className="vr-table-c">
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList().length > 0 ?
                                <>
                                    {getFilteredList()?.map((doc) => {
                                        return (
                                            <tr key={doc.talent_id}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                    {doc.ssn
                                                        ? getSSNInfo(doc.ssn.slice(-4), doc.talent_id)
                                                        : ""}
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}
                                                    isButton={true}
                                                    onClick={() => handleTalentSelect(doc.talent_id, doc.talent_name)}
                                                >
                                                    <span>{doc.talent_name}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                    <span>{doc.total_hours}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{doc.earned_holiday_hours}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{doc.vacation_hours_taken}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{doc.vacation_hours_remaining}</span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>{doc.on_assignment ? "Yes" : "No"}</span>
                                                </TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                                :
                                <>
                                    <tr>
                                        <TableData customStyle={{ flex: getFlexNumber(20), wordWrap: "break-word" }} align="center">
                                            <div style={{ width: "100%", fontSize: "0.9vw", textAlign: "center" }}>
                                                {t("no_data_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }
                        </SortableTable>
                    </div>
                }
            </div>
            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t("social_security_number")}
                        talentId={showSSNPopup.talentId}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={() => setShowSSNPopup(null)}
                    />
                </Portal>
            )}
        </div>

    );
}

export default VacationReport;