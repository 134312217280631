import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../../../store";
import { selectState } from "./inbound_leads_directory_reducer";

const select = (state: IRootState) => state.dashboard.metrics.inBoundLeadsState;
export const selectInBoundLeadsListState = createSelector(
  select,
  selectState
);

export const selectUpdateState = createSelector(
  selectInBoundLeadsListState,
  (inBoundLeadsState) => inBoundLeadsState.update
);