import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import './my_profile_dashboard.scss';
import CurveTabNavigation from "../../../../components/curve_tab_navigation/curve_tab_navigation";
import MyProfileUserInfoCard from "../../../../components/my_profile_info_card/my_profile_info_card";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import { LoadingType, ProfilePictureType, THEME } from "../../../../enums";
import { EditIcon, AdminCenterIcon, RoleManagementIcon, AgencySetup, TeamManagementIcon } from "../../../../icons";
import { clearUserDetailsState } from "../../../../redux/admin_center/user_management/user_details/user_details_reducer";
import { useAppDispatch, useAppSelector, getProfile } from "../../../../redux/store";
import { CustomButton, SpinnerScreen, getPlusBtnIcon } from "../../../../utils";
import MyProfileSection from "./components/my_profile_dashboard_details_section/my_profile_dashboard_section";
import MPTextEmAllPage from "../text_em_all/text_em_all";
import { Portal } from "react-portal";
import UpdatePassword from "../../popups/update_password/update_password";
import UploadProfile from "../../../../components/image_crop/image_crop_popup";
import UpdateMyProfileUser from "../../popups/update_my_profile/update_my_pofile_user";
import { selectMyProfileState } from "../../../../redux/my_profile/my_profile_details/my_profile_selector";
import { getMyProfile } from "../../../../redux/my_profile/my_profile_details/my_profile_thunk";
import { manageMyProfileUserActions } from "../../../../redux/my_profile/update_my_profile_user/update_user_reducer";
import { KeyboardBackspace } from "@material-ui/icons";
import { getMyProfileTeamMembersDetailsById, getMyProfileTeamsDetails, selectMyProfileTeamsDetailsList, selectMyProfileTeamsDetailsState } from "../../../../redux/my_profile";
import AddTeamMember from "../../popups/add_team_member/add_team_member";
import { clearMyProfileTeamError } from "../../../../redux/my_profile/my_profile_teams/my_profile_teams_details/my_profile_teams_details_reducer";


interface Props extends RouteComponentProps<any> { }

const tabOptions = [
    {
        title: "dashboard",
    }
];

const MyProfileDashboardPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { params } = props.match;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [editUser, setEditUser] = useState<boolean>(false);
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showAddTeamMember, setShowAddTeamMember] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [showMyTeamsOptions, setShowMyTeamsOptions] = useState<boolean>(false);
    const [teamState, setTeamState] = useState<{ id: string, name: string }>()
    const myProfileState = useAppSelector((state) => selectMyProfileState(state))
    const myProfileTeamsDetailsState = useAppSelector((state) => selectMyProfileTeamsDetailsState(state))
    const myProfileTeamsDetailsList = useAppSelector((state) => selectMyProfileTeamsDetailsList(state))
    useEffect(() => {
        dispatch(getMyProfile())
        dispatch(getMyProfileTeamsDetails())
        return () => {
            dispatch(clearUserDetailsState());
        }
    }, [])

    useEffect(() => {
        if (myProfileState?.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
        }

    }, [myProfileState?.loading])

    useEffect(() => {
        if (myProfileTeamsDetailsState?.createMyProfileTeams?.loading === LoadingType.succeeded) {
            dispatch(getMyProfileTeamsDetails())
            dispatch(getMyProfileTeamMembersDetailsById(teamState?.id!))
            setShowAddTeamMember(false)
        }
    }, [myProfileTeamsDetailsState?.createMyProfileTeams?.loading])

    const switchMyTeamsPge = () => {
        setShowMyTeamsOptions(!showMyTeamsOptions);
    };

    const TeamId = (option: { id: string, name: string }) => {
        setTeamState(option)
        dispatch(getMyProfileTeamMembersDetailsById(option.id!))
    }

    /// Edit user iocn actions
    function getEditUserAction() {
        return (
            <button className="icon-btn ac-um-usp-edit-icon" onClick={() => setEditUser(true)}>
                <EditIcon width={"1vw"} height={"1vw"} />
            </button>
        );
    }

    /// spinner 

    const DetailsLoading = () => {
        return (
            <div className="ac-um-usp-loading">
                <SpinnerScreen />
            </div>
        );
    }

    function updateProfileURL() {
        setSentRequest(true);
        dispatch(getMyProfile());
        dispatch(getProfile());
    }

    function handleProfileUpdate() {
        setShowCreate(true);
    }

    /// handle edit user 
    function handleEditUserSuccess() {
        setEditUser(false);
        dispatch(getMyProfile())
        dispatch(manageMyProfileUserActions.clearMyProfileUpdateState());
    }


    function handleClearTeamMemberError() {
        setShowAddTeamMember(false)
        dispatch(clearMyProfileTeamError())
    }

    /// User details screen
    function handleUserDetails() {
        if (myProfileState?.loading === LoadingType.pending) {
            return (
                <DetailsLoading />
            );
        }
        return (
            <>
                <div className="my-profile-dashboad-container">
                    <div className="ac-um-usp-content">
                        <div className="ac-um-usp-profile-container">
                            <MyProfileSection myProfile={myProfileState?.myProfile!} handlePasswordUpdate={() => setShowUpdatePassword(true)} handleProfileUpdate={handleProfileUpdate} />
                        </div>
                        <div className="ac-um-usp-settings-content">
                            <CurveTabNavigation
                                tabs={getTabList()}
                                selected={tabIndex}
                                onChange={(value) => setTabIndex(value)}
                                actions={getEditUserAction()}
                            />
                        </div>
                    </div>
                </div>
                {showCreate &&
                    <Portal>
                        <UploadProfile
                            onClose={() => setShowCreate(false)}
                            onDismiss={() => setShowCreate(false)}
                            visible={showCreate}
                            onApprove={updateProfileURL}
                            objectId={myProfileState?.myProfile?.id}
                            objectType={ProfilePictureType.systemUser}
                        />
                    </Portal>
                }
                {showUpdatePassword &&
                    <Portal>
                        <UpdatePassword
                            onClose={() => setShowUpdatePassword(false)}
                            onDismiss={() => setShowUpdatePassword(false)}
                            visible={showCreate}
                        // onApprove={updateProfileURL}
                        />
                    </Portal>
                }
                {showAddTeamMember &&
                    <Portal>
                        <AddTeamMember
                            visible={showAddTeamMember}
                            onClose={handleClearTeamMemberError}
                            onDissmiss={() => setShowAddTeamMember(false)}
                            onSuccessClose={() => setShowAddTeamMember(false)}
                            teamId={teamState?.id!}
                            myProfileTeamState={myProfileTeamsDetailsState}
                        />
                    </Portal>
                }
                {editUser && myProfileState?.myProfile && myProfileState?.myProfile?.branch_id && <Portal>
                    <UpdateMyProfileUser
                        visible={editUser}
                        title={t('edit_user')}
                        user={myProfileState?.myProfile}
                        branchId={myProfileState?.myProfile?.branch_id}
                        onClose={() => setEditUser(false)}
                        onSuccessClose={handleEditUserSuccess}
                    />
                </Portal>}
            </>
        )
    }

    function getTabList() {
        return [
            {
                title: t('my_teams').toUpperCase(),
                icon: TeamManagementIcon,
                content: getMyTeamsPage(),
            },
            {
                title: t('access_keys').toUpperCase(),
                icon: AdminCenterIcon,
                content: getAccessKeysPage(),
            },
        ];
    }

    function getAccessKeysPage() {
        return (
            <div>
                <MPTextEmAllPage title={true} titleHeader={true} />
            </div>
        )
    }

    function getMyTeamsPage() {
        return (
            <div className="my-profile-container">
                <div className="my-team-members-section">
                    <div className="team-name-content">
                        {showMyTeamsOptions && (
                            <div className="keyboard-backspace" onClick={switchMyTeamsPge}>
                                <KeyboardBackspace style={{ marginBottom: "0.3em" }} /><span>{teamState && teamState.name}</span>
                            </div>
                        )}
                    </div>
                    <div>
                        {showMyTeamsOptions && (
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_teammate')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => setShowAddTeamMember(true)}
                            />
                        )}
                    </div>
                </div>
                <div className="total-count">
                    <span>{`${t('total_count')}: `} <span className="total-count-number">{showMyTeamsOptions ? myProfileTeamsDetailsState?.myProfileTeams?.response?.members_details?.length : myProfileTeamsDetailsList?.length}</span> </span>
                </div>
                {showMyTeamsOptions && (
                    <div>
                        {myProfileTeamsDetailsState?.myProfileTeams.loading === LoadingType.pending ? DetailsLoading() : <div className="my-team-members-content">
                            {myProfileTeamsDetailsState &&
                                myProfileTeamsDetailsState?.myProfileTeams?.response?.members_details?.map((doc, index) => (
                                    <div key={index} className="mps-flex-card">
                                        <MyProfileUserInfoCard
                                            teamMemberData={doc}
                                            teamId={doc.id}
                                            onRemove={() => { }}
                                            onUpdate={() => { }}
                                        />
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                )}
                <div className="my-teams-section">
                    {!showMyTeamsOptions && (
                        myProfileTeamsDetailsList?.map((option, index) => (
                            <div key={index} onClick={() => TeamId(option)} className="mps-flex-card">
                                <div onClick={switchMyTeamsPge}>
                                    <MyProfileUserInfoCard
                                        teamData={option}
                                        onRemove={() => { }}
                                        onUpdate={() => { }}
                                        hideActions={false}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        )
    }

    return (

        <div className="ac-um-usp-container">
            <div className="ac-um-usp-header">
                <TabOptionsHeader list={tabOptions} onClose={() => props.history.goBack()} />
            </div>
            {handleUserDetails()}
        </div>
    )
}

export default MyProfileDashboardPage;