
import React, { useEffect, useState } from "react";

import "./talent_assignments_page.scss";
import SearchBar from "../../../../components/search_bar";
import { LoadingType, TALENT_STATUS, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { getTalentAssignmentsListByPageById, selectTalentAssignmentsState } from "../../../../redux/talent/details/assignments";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../components/table_empty/table_empty";
import { AppRoutes } from "../../../../routes";
import { Rating } from "@material-ui/lab";
import { getTableAccessIcon, currencyConversion } from "../../../../utils/helpers";
import { Assignment, IAssignmentsByPage } from "../../../../interfaces";
import Pagination from "../../../components/pagination/pagination";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import { statusOptions } from "../../../../variables";

const headers = [
    { 'title': 'access_assignment', 'code': 'access_assignment' },
    { 'title': 'talent', 'code': 'talent_name' },
    { 'title': 'company', 'code': 'company_name' },
    { 'title': 'department', 'code': 'department_name' },
    { 'title': 'job_title', 'code': 'job_title' },
    { 'title': 'rating', 'code': 'performance_rating' },
    { 'title': 'status', 'code': 'status' },
    { 'title': 'pay_rate', 'code': 'regular_pay_rate' },
    { 'title': 'bill_rate', 'code': 'regular_bill_rate' },
]

const category = [
    "talent_name",
    "company_name",
    "department_name",
    "job_title",
    "performance_rating",
    "status",
    "regular_pay_rate",
    "regular_bill_rate"
]

const TalentsAssignmentPage = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { id, departmentId } = props.match.params;
    const [totalCount, setTotalCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [sortedField, setSortedField] = useState<null | string>(null);

    const [sortDirection, setSortDirection] = useState("asc");

    const [sentRequest, setSentRequest] = useState(false);
    const [talentAssignmentsList, setTalentAssignmentsList] = useState<IAssignmentsByPage | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize]=useState(100);
    const [status, setStatus] = useState<string[]>(["ACTIVE"]);

    const assignmentState = useAppSelector((state) => selectTalentAssignmentsState(state));

    useEffect(() => {
        if (assignmentState.loading === LoadingType.succeeded && assignmentState.response) {
            setTalentAssignmentsList(assignmentState.response);
            setTotalCount(assignmentState.response.total)
          return () => { };
        }
      }, [assignmentState.loading, assignmentState.response]);

    useEffect(() => {
        if (searchValue?.length === 0 || searchValue?.length >= 2) return callAPI({ pageNum: 1 });
        return () => {}
    }, [searchValue])

    function handleSearchChange(value) {
        setSearchValue(value);
    }

    function handleSortChange(value) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        }
        else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }

    function getFilteredList() {
        let list;
        if (!talentAssignmentsList?.items)
            return [];
        if (sortedField != null) {
            list = [...talentAssignmentsList?.items].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list ?? talentAssignmentsList?.items;
    }

    function callAPI(doc?: {pageNum?: number, pageSize?: number, status_ids?: Array<string>}) {
        let data: any = {
            talent_id: id,
            page: doc?.pageNum ?? currentPage,
            size: doc?.pageSize ?? size,
            search_str: searchValue,
            filter_ids: {statuses :doc?.status_ids ?? status}
          };
        dispatch(getTalentAssignmentsListByPageById(data));
        setCurrentPage(doc?.pageNum ?? currentPage);
    }

    function handleRetry() {
        callAPI();
    }

    function handleAccessAssignment(value: Assignment) {
        props.history.push({
            pathname: `${AppRoutes.assignmentsDetailsPage}/${value.id}`,
            state: {
                id: value.id,
                name: `${value.talent_name}-${value.job_title}`,
            }
        })
        return;
    }

    useEffect(() => {
        if (assignmentState.loading === LoadingType.succeeded && sentRequest && talentAssignmentsList?.items) {
            setSentRequest(false);
        }
    }, [assignmentState.loading]);

    const handlePageChange = (pageNum: number, size: number) => {
        callAPI({pageNum: pageNum, pageSize: size});
        setSize(size ? size : talentAssignmentsList?.size!)
      };

    const handleStatus = (value) => {
        setStatus(value)
        callAPI({ pageNum: 1, status_ids: value });
    }

    return (
        <div className={"talent-asmnt-main-container"}>
            <div className={"talent-asmnt-header"}>
                <div className="search-bar">
                    <SearchBar
                        value={searchValue}
                        onChange={handleSearchChange}
                        onSearch={()=>{}}
                    />
                </div>
                <div>
                    <CustomMultiFormSelect
                        label={t("status")}
                        name={"company_status"}
                        list={[...statusOptions]}
                        onChange={handleStatus}
                        required={false}
                        placeholder={t("all")}
                        value={status}
                    />
                </div>
                <div className={"total-count"}>
                    <span>{`${t('total_count')}: `} <span className="total-count-number">{totalCount}</span> </span>
                </div>
            </div>
            <div className="tap-table">
                {assignmentState.loading === LoadingType.pending && sentRequest ? <TableLoading /> :
                    <TableBody
                        state={assignmentState}
                        getFilteredList={getFilteredList}
                        sortedField={sortedField}
                        handleSortChange={handleSortChange}
                        onRetry={handleRetry}
                        data={talentAssignmentsList?.items}
                        accessJob={handleAccessAssignment}
                        handleCompanySelect={handleCompanySelect}
                        handleDepartmentSelect={handleDepartmentSelect}
                        handleJobSelect={handleJobSelect}
                        handleTalentSelect={handleTalentSelect}
                        sortDirection={sortDirection}
                    />}
            </div>
            <div className="tap-table-footer">
                <Pagination
                    totalPages={talentAssignmentsList?.pages!}
                    onPageChange={handlePageChange}
                    curPageNum={currentPage}
                    totalCount={totalCount}
                    size={talentAssignmentsList?.size!}
                    loadingState={assignmentState?.loading}
                />
            </div>
        </div>
    )
    function handleCompanySelect(value: Assignment) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            }
        })
        return;
    }

    function handleTalentSelect(value: Assignment) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            }
        })
        return;
    }

    function handleDepartmentSelect(value: Assignment) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            }
        })
        return;
    }
    function handleJobSelect(value: Assignment) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
            state: {
                id: value.job_order_id,
                name: value.job_title,
            }
        })
        return;
    }
}

const TableBody = ({
    state,
    getFilteredList,
    sortedField,
    handleSortChange,
    onRetry,
    data,
    accessJob,
    handleCompanySelect,
    handleDepartmentSelect,
    handleJobSelect,
    handleTalentSelect,
    sortDirection
}) => {
    const { t } = useTranslation();

    function getTableData(doc, key) {
        if (key === 'performance_rating')
            return (<span><Rating name="read-only" value={doc[key]} readOnly /></span>);
        else if (key === 'regular_bill_rate' || key === 'regular_pay_rate')
            return <span>{currencyConversion(doc[key])}</span>
        else if (key === 'status') {
            if (doc[key] === TALENT_STATUS.active)
                return <span style={{ color: THEME.statusActiveColor }}>{t('active')}</span>;
            return <span style={{ color: "#b1b1b1" }}>{t('inactive')}</span>;
        }
        return <span>{doc[key]}</span>;
    }


    if (state.loading === LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data && data.length === 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    return (
        <SortableTable
            headerList={headers}
            sortedField={sortedField}
            onSortChange={handleSortChange}
            flexNumber={getFlexNumber}
            isAsc={sortDirection}
        >
            {getFilteredList().map((doc, index) => {
                return (
                    <tr key={"cmp_job_order_row" + index}>
                        <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => accessJob(doc))}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleTalentSelect(doc)}>{getTableData(doc, "talent_name")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleCompanySelect(doc)}>{getTableData(doc, "company_name")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(3) }} isButton={doc.department_name ? true : false} onClick={() => handleDepartmentSelect(doc)}>{doc.department_name ? getTableData(doc, "department_name") : t('corporate')}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(4) }} isButton={true} onClick={() => handleJobSelect(doc)}>{getTableData(doc, "job_title")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(5) }}>{getTableData(doc, "performance_rating")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(6) }}>{getTableData(doc, "status")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(7) }}>{getTableData(doc, "regular_pay_rate")}</TableData>
                        <TableData customStyle={{ flex: getFlexNumber(8) }}>{getTableData(doc, "regular_bill_rate")}</TableData>
                    </tr>
                )
            })}
        </SortableTable>
    );

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        return 1;
    }
}

export default TalentsAssignmentPage;